import React, { useState } from 'react';

// material-ui
import { Grid } from '@mui/material';
import { WppTypography, WppActionButton, WppIconCross } from '@platform-ui-kit/components-library-react';
import CatalogListView from './catalog-list-view';
import CatalogDetails from './catalog-details';
import Snackbar from '../../ui-component/snackbar';

// ==============================|| DATA CATALOG ||============================== //

function DataCatalog() {
  const [showCatalogUI, setShowCatalogUI] = useState<boolean>(false);

  return (
    <Grid container spacing={2} xs={12} sx={{ m: 1 }}>
      <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }}>
        <WppTypography tag="h2" type="2xl-heading">
          Data Catalog
        </WppTypography>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Snackbar />
        </Grid>
      </Grid>

      <Grid
        container
        item
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={showCatalogUI ? 6 : 12}>
          <CatalogListView setShowCatalogUI={setShowCatalogUI} />
        </Grid>
        {showCatalogUI
          ? (
            <Grid container item xs={6}>
              <WppActionButton onClick={() => setShowCatalogUI(false)} style={{ position: 'absolute', right: '50px', marginTop: '16px' }}>
                <WppIconCross slot="icon-start" />
              </WppActionButton>
              <CatalogDetails />
            </Grid>
          )
          : <Grid item />}
      </Grid>

    </Grid>
  );
}

export default DataCatalog;
