import React, { useState } from 'react';
import {
  Grid, TextField, SelectChangeEvent,
} from '@mui/material';
// import { Dayjs } from 'dayjs';
import {
  WppToggle,
  WppAccordion,
} from '@platform-ui-kit/components-library-react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AssetMultiStepGetResponse, ContractDto, ValuationDto } from '../../../../services/models/asset-models.d';
import { MasterDataState } from '../../../../services/models/master-data-models.d';
import { StoreState } from '../../../../store';
import { DEFAULT_ASSET_VALUATION } from '../../../../services/defaults/asset-defaults';
import Select from '../../../../ui-component/select';
import { SelectType, BooleanType, ISelectOption } from '../../../../services/models/select-models.d';
import { IValuationFormErrors } from '../../../../services/models/validation-models.d';
import { validateAny, validateNumber, validateString } from '../../../../ui-component/validation';
import { StepAccordion } from '../../../../services/models/stepper-models.d';
import { ConfigState } from '../../../../services/models/config-models.d';
import makeLocalAppearUTC from '../../../../ui-component/date';

export interface ValuationAccordionProps {
  assetData:AssetMultiStepGetResponse,
  handleFieldChange:(newValue:null | string | Date | boolean | ValuationDto | ContractDto, section: string, field: string | null) => void,
  validate:(accordion: number, formErrors: IValuationFormErrors) => void;
}

function ValuationAccordion(props:ValuationAccordionProps) {
  const itemWidth = '150px';
  const {
    assetData,
    handleFieldChange,
    validate,
  } = props;

  const valuationList:MasterDataState['api']['data']['fetchGetValuationList']['valuationList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetValuationList?.valuationList,
  );

  let valuationId: number | undefined;
  let valuationName;
  let valuationDesc;
  let valuationRecordCount;
  let valuationFileFlg;
  let valuationDays;
  let valuationType;
  let value;
  let valuationStartDt;
  let valuationEndDt;
  let currency;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;
  const [addNewValuation, setAddNewValuation] = useState<boolean>(false);

  if (typeof assetData.valuation !== 'undefined') {
    ({
      valuationId,
      valuationName,
      valuationDesc,
      valuationRecordCount,
      valuationFileFlg,
      valuationDays,
      valuationType,
      value,
      valuationStartDt,
      valuationEndDt,
      currency,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = assetData.valuation);
  } else {
    // By Default DEFAULT_ASSET_VALUATION is selected and stored in redux.
    handleFieldChange({ ...DEFAULT_ASSET_VALUATION }, 'valuation', null);
    handleFieldChange({ ...DEFAULT_ASSET_VALUATION }.valuationId.toString(), 'asset', 'valuationId');
  }

  if (!addNewValuation && valuationList !== null) {
    const selectedValuation = valuationList.find((obj) => obj.valuationId === valuationId);
    if (selectedValuation !== null) {
      valuationId = selectedValuation?.valuationId;
      valuationName = selectedValuation?.valuationName;
      valuationDesc = selectedValuation?.valuationDesc;
      valuationRecordCount = selectedValuation?.valuationRecordCount;
      valuationFileFlg = selectedValuation?.valuationFileFlg;
      valuationDays = selectedValuation?.valuationDays;
      valuationType = selectedValuation?.valuationType;
      value = selectedValuation?.value;
      valuationStartDt = selectedValuation?.valuationStartDt;
      valuationEndDt = selectedValuation?.valuationEndDt;
      currency = selectedValuation?.currency;
      // createdUserId = selectedValuation?.createdUserId;
      // createdDatetime = selectedValuation?.createdDatetime;
      // updatedUserId = selectedValuation?.updatedUserId;
      // updatedDatetime = selectedValuation?.updatedDatetime;
    }
  }

  // Validation
  const formErrors:IValuationFormErrors = {
    // valuationId: validateNumber(valuationId),
    valuationName: validateString(valuationName),
    valuationType: validateString(valuationType),
    value: validateNumber(value),
    valuationStartDt: validateString(valuationStartDt),
    valuationEndDt: validateString(valuationEndDt),
    currency: validateString(currency),
  };
  type IValuationFormErrorsObjectKey = keyof typeof formErrors;

  const onValidatedFieldChange = (newValue:null | string | Date | boolean | ValuationDto, section: string, field: string | null) => {
    const f = field as IValuationFormErrorsObjectKey;
    formErrors[f] = validateAny(newValue);
    validate(StepAccordion.Valuation, formErrors);
    handleFieldChange(newValue, section, field);
  };

  const configList:ConfigState['api']['data']['fetchGetConfigList'] = useSelector(
    (state: StoreState) => state.configReducer?.api?.data?.fetchGetConfigList,
  );

  const {
    VALUATION_TYPE,
    CURRENCY,
  } = configList;
  const valuationTypeSelectOptions:ISelectOption[] = typeof (VALUATION_TYPE) !== 'undefined' && VALUATION_TYPE.length > 0 ? VALUATION_TYPE.map((type, index) => ({ id: index, value: type })) : [];
  const valuationCurrencySelectOptions:ISelectOption[] = typeof (CURRENCY) !== 'undefined' && CURRENCY.length > 0 ? CURRENCY.map((valuationCurrency, index) => ({ id: index, value: valuationCurrency })) : [];
  return (
    <Grid container>
      <WppAccordion text="Valuation">
        <Grid container sx={{ width: 'auto' }}>
          <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Select Valuation"
                value={valuationId !== undefined ? valuationId?.toString() : ''}
                disabled={addNewValuation}
                options={valuationList}
                useIdOrValue={SelectType.useId}
                onChange={(event: SelectChangeEvent) => {
                  handleFieldChange(event.target.value, 'valuation', 'valuationId');
                  handleFieldChange(event.target.value, 'asset', 'valuationId');
                }}
                // error={formErrors.valuationId}
              />
            </Grid>
            <Grid
              item
              sx={{
                m: 1, ml: 6, mt: 3, width: itemWidth,
              }}
            >
              <WppToggle
                label="New Valuation"
                name="newValuation"
                required
                checked={addNewValuation}
                onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                  setAddNewValuation(checked);
                  onValidatedFieldChange({ ...DEFAULT_ASSET_VALUATION }, 'valuation', null);
                  onValidatedFieldChange(null, 'valuation', 'valuationId');
                  onValidatedFieldChange(null, 'asset', 'valuationId');
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                disabled={!addNewValuation}
                label="Valuation Name"
                value={typeof (valuationName) !== 'undefined' ? valuationName : ''}
                onChange={(event) => onValidatedFieldChange(event.target.value, 'valuation', 'valuationName')}
                error={formErrors.valuationName}
                color={formErrors.valuationName ? 'error' : 'primary'}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Valuation Type"
                value={typeof (valuationType) !== 'undefined' ? valuationType : ''}
                options={valuationTypeSelectOptions}
                disabled={!addNewValuation}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => onValidatedFieldChange(event.target.value, 'valuation', 'valuationType')}
                error={formErrors.valuationType}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                disabled={!addNewValuation}
                label="Valuation Days"
                value={typeof (valuationDays) !== 'undefined' ? valuationDays : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'valuation', 'valuationDays')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                disabled={!addNewValuation}
                label="Record Count"
                value={typeof (valuationRecordCount) !== 'undefined' ? valuationRecordCount : ''}
                onChange={(event) => onValidatedFieldChange(event.target.value, 'valuation', 'valuationRecordCount')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  disabled={!addNewValuation}
                  disablePast
                  value={typeof (valuationStartDt) !== 'undefined' ? makeLocalAppearUTC(valuationStartDt) : ''}
                  onChange={(newValue:any) => onValidatedFieldChange(dayjs(newValue.$d.toString()).format('YYYY-MM-DD'), 'valuation', 'valuationStartDt')}
                  renderInput={(params) => (
                    <TextField
                      disabled={!addNewValuation}
                      variant="standard"
                      focused
                      required
                      {...params}
                      error={formErrors.valuationStartDt}
                      color={formErrors.valuationStartDt ? 'error' : 'primary'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  disabled={!addNewValuation}
                  disablePast
                  value={typeof (valuationEndDt) !== 'undefined' ? makeLocalAppearUTC(valuationEndDt) : ''}
                  onChange={(newValue:any) => onValidatedFieldChange(dayjs(newValue.$d.toString()).format('YYYY-MM-DD'), 'valuation', 'valuationEndDt')}
                  renderInput={(params) => (
                    <TextField
                      disabled={!addNewValuation}
                      variant="standard"
                      focused
                      required
                      {...params}
                      error={formErrors.valuationEndDt}
                      color={formErrors.valuationEndDt ? 'error' : 'primary'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                disabled={!addNewValuation}
                label="Valuation Description"
                value={typeof (valuationDesc) !== 'undefined' ? valuationDesc : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'valuation', 'valuationDesc')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="Value"
                disabled={!addNewValuation}
                value={typeof (value) !== 'undefined' ? value : ''}
                onChange={(event) => onValidatedFieldChange(event.target.value, 'valuation', 'value')}
                error={formErrors.value}
                color={formErrors.value ? 'error' : 'primary'}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Valuation Currency"
                value={typeof (currency) !== 'undefined' ? currency : ''}
                options={valuationCurrencySelectOptions}
                disabled={!addNewValuation}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => onValidatedFieldChange(event.target.value, 'valuation', 'currency')}
                error={formErrors.currency}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth, mt: 2 }}>
              <WppToggle
                label="Valuation File"
                name="valuationFile"
                required
                disabled={!addNewValuation}
                checked={valuationFileFlg === BooleanType.True}
                onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                  if (checked) {
                    onValidatedFieldChange(BooleanType.True, 'valuation', 'valuationFileFlg');
                  } else {
                    onValidatedFieldChange(BooleanType.False, 'valuation', 'valuationFileFlg');
                  }
                }}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }} />
            <Grid item sx={{ m: 1, width: itemWidth }} />
            <Grid item sx={{ m: 1, width: itemWidth }} />
            <Grid item sx={{ m: 1, width: itemWidth }} />
          </Grid>
        </Grid>
      </WppAccordion>
    </Grid>
  );
}

export default ValuationAccordion;
