import React, {
  useState,
} from 'react';
import {
  FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, SelectChangeEvent, TextField,
} from '@mui/material';
import {
  WppAccordion,
  WppToggle,
} from '@platform-ui-kit/components-library-react';
import { useSelector } from 'react-redux';
import { AssetMultiStepGetResponse } from '../../../../services/models/asset-models.d';
import { ConfigState } from '../../../../services/models/config-models.d';
import { StoreState } from '../../../../store';
import Select from '../../../../ui-component/select';
import {
  BooleanType, ISelectOption, SelectType, ThresholdType,
} from '../../../../services/models/select-models.d';
import { validateAny, validateString } from '../../../../ui-component/validation';
import { StepAccordion } from '../../../../services/models/stepper-models.d';
import { ISchemaFormErrors } from '../../../../services/models/validation-models.d';

export interface FileConfigurationAccordionProps {
  assetData:AssetMultiStepGetResponse,
  handleFieldChange:(newValue:string | Date | boolean, section: string, field: string) => void,
  validate:(accordion: number, formErrors: ISchemaFormErrors) => void;
}

function FileConfigurationAccordion(props:FileConfigurationAccordionProps) {
  const itemWidth = '150px';
  const {
    assetData,
    handleFieldChange,
    validate,
  } = props;

  // Temporary usage only
  // const [fileType, setFileType] = useState<string>('');

  // let fileConfigId;
  let fileFormat;
  let filePattern;
  // let schedule;
  let recordDelimiter;
  let skipLinesFromTop;
  let escapeChar;
  let quoteChar;
  // eslint-disable-next-line
  let fileCharSet;
  // let skipRecLenChkFlg;
  let headerRecFlg;
  // let thresholdChkFlg;
  // let thresholdPercentage;
  // let thresholdNumOfRec;
  let duplicateRecChkFlg;
  // let fatalFileFlg;
  // let dupRecOnAllRecFlg;
  let dupRecCntThld;
  let dupPercentThld;
  let dropDupFlg;
  // let retainDupRecPos;
  // let dupRecOrderby;
  let filePath;
  // let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;

  if (typeof assetData.fileConfig !== 'undefined') {
    ({
      // fileConfigId,
      fileFormat,
      filePattern,
      // schedule,
      recordDelimiter,
      skipLinesFromTop,
      escapeChar,
      quoteChar,
      // eslint-disable-next-line
      fileCharSet,
      // skipRecLenChkFlg,
      headerRecFlg,
      // thresholdChkFlg,
      // thresholdPercentage,
      // thresholdNumOfRec,
      duplicateRecChkFlg,
      // fatalFileFlg,
      // dupRecOnAllRecFlg,
      dupRecCntThld,
      dupPercentThld,
      dropDupFlg,
      // retainDupRecPos,
      // dupRecOrderby,
      filePath,
      // status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = assetData.fileConfig);
  }

  const [duplicateCheck, setDuplicateCheck] = useState<boolean>(duplicateRecChkFlg === BooleanType.True);
  // eslint-disable-next-line
  const [isFixedOrDelimited, setIsFixedOrDelimited] = useState<string>(fileFormat === 'fixed_width' ? 'Fixed' : (fileFormat === 'delimited' ? 'Delimited' : ''));
  // eslint-disable-next-line
  const [isThresholdPercentageOrCount, setIsThresholdPercentageOrCount] = useState<string>(dupPercentThld !== null ? ThresholdType.Percentage : (dupRecCntThld !== null ? ThresholdType.Count : 'None'));
  const handleFileSeparaterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFixedOrDelimited((event.target as HTMLInputElement).value);
    handleFieldChange(event.target.value === 'Delimited' ? 'delimited' : 'fixed_width', 'fileConfig', 'fileFormat');
    if (event.target.value === 'Fixed') {
      // If Fixed, set schema discovery to false
      handleFieldChange(BooleanType.False, 'asset', 'allowSchemaDiscoveryFlg');
    }
  };
  const handleThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsThresholdPercentageOrCount((event.target as HTMLInputElement).value);
    handleFieldChange('', 'fileConfig', 'dupRecCntThld');
    handleFieldChange('', 'fileConfig', 'dupPercentThld');
  };

  // Validation
  const formErrors:ISchemaFormErrors = {
    filePattern: validateString(filePattern),
    filePath: validateString(filePath),
  };
  type ISchemaFormErrorsObjectKey = keyof typeof formErrors;

  // Attempt to detect errors during load
  // if (Object.values(formErrors).find((value) => value === true) !== -1) {
  //   validate(StepAccordion.Schema, formErrors);
  // }

  const onValidatedFieldChange = (newValue:string | Date | boolean, section: string, field: string) => {
    const f = field as ISchemaFormErrorsObjectKey;
    formErrors[f] = validateAny(newValue);
    validate(StepAccordion.Schema, formErrors);
    handleFieldChange(newValue, section, field);
  };

  const configList:ConfigState['api']['data']['fetchGetConfigList'] = useSelector(
    (state: StoreState) => state.configReducer?.api?.data?.fetchGetConfigList,
  );
  const {
    CHARACTER, QUOTE, ESCAPE, DELIMITER,
  } = configList;
  const characterSetSelectOptions:ISelectOption[] = typeof (CHARACTER) !== 'undefined' && CHARACTER.length > 0 ? CHARACTER.map((character, index) => ({ id: index, value: character })) : [];
  const quoteCharacterSelectOptions:ISelectOption[] = typeof (QUOTE) !== 'undefined' && QUOTE.length > 0 ? QUOTE.map((quote, index) => ({ id: index, value: quote })) : [];
  const escapeCharacterSelectOptions:ISelectOption[] = typeof (ESCAPE) !== 'undefined' && ESCAPE.length > 0 ? ESCAPE.map((escape, index) => ({ id: index, value: escape })) : [];
  const recordDelimiterSelectOptions:ISelectOption[] = typeof (DELIMITER) !== 'undefined' && DELIMITER.length > 0 ? DELIMITER.map((delimiter, index) => ({ id: index, value: delimiter })) : [];
  return (
    <Grid container>
      <WppAccordion text="Schema">
        <Grid container sx={{ width: 'auto' }}>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="File Path"
                value={typeof (filePath) !== 'undefined' ? filePath : ''}
                onChange={(event) => onValidatedFieldChange(event.target.value, 'fileConfig', 'filePath')}
                error={formErrors.filePath}
                color={formErrors.filePath ? 'error' : 'primary'}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="File Pattern"
                value={typeof (filePattern) !== 'undefined' ? filePattern : ''}
                onChange={(event) => onValidatedFieldChange(event.target.value, 'fileConfig', 'filePattern')}
                error={formErrors.filePattern}
                color={formErrors.filePattern ? 'error' : 'primary'}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Character Set"
                value={typeof (fileCharSet) !== 'undefined' ? fileCharSet : ''}
                disabled={false}
                options={characterSetSelectOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => handleFieldChange(event.target.value, 'fileConfig', 'fileCharSet')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Quote Character"
                value={typeof (quoteChar) !== 'undefined' ? quoteChar : ''}
                disabled={false}
                options={quoteCharacterSelectOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => handleFieldChange(event.target.value, 'fileConfig', 'quoteChar')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Escape Character"
                value={typeof (escapeChar) !== 'undefined' ? escapeChar : ''}
                disabled={false}
                options={escapeCharacterSelectOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => handleFieldChange(event.target.value, 'fileConfig', 'escapeChar')}
              />
            </Grid>
            <Grid item sx={{ width: itemWidth, mt: 3 }}>
              <WppToggle
                label="Header Row"
                name="headerRow"
                required
                checked={headerRecFlg === BooleanType.True}
                onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                  if (checked) {
                    handleFieldChange(BooleanType.True, 'fileConfig', 'headerRecFlg');
                  } else {
                    handleFieldChange(BooleanType.False, 'fileConfig', 'headerRecFlg');
                  }
                }}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              {
                headerRecFlg === BooleanType.True
                && (
                <TextField
                  variant="standard"
                  focused
                  required
                  label="Start Row"
                  value={typeof (skipLinesFromTop) !== 'undefined' ? skipLinesFromTop : ''}
                  onChange={(event) => handleFieldChange(event.target.value, 'fileConfig', 'skipLinesFromTop')}
                />
                )
              }
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">File Separator</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="percentage"
                  name="radio-buttons-group"
                  // eslint-disable-next-line
                  value={isFixedOrDelimited}
                  onChange={handleFileSeparaterChange}
                >
                  <FormControlLabel value="Fixed" control={<Radio />} label="Fixed" />
                  <FormControlLabel value="Delimited" control={<Radio />} label="Delimited" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth, ml: 2 }}>
              { isFixedOrDelimited === 'Delimited' ? (
                <Select
                  title="Record Delimiter"
                  value={typeof (recordDelimiter) !== 'undefined' ? recordDelimiter : ''}
                  disabled={false}
                  options={recordDelimiterSelectOptions}
                  useIdOrValue={SelectType.useValue}
                  onChange={(event: SelectChangeEvent) => handleFieldChange(event.target.value, 'fileConfig', 'recordDelimiter')}
                />
              )
                : <> </>}
            </Grid>
            <Grid item sx={{ width: itemWidth, mt: 4, ml: 2 }}>
              <WppToggle
                label="Duplicate Check"
                name="duplicateCheck"
                required
                checked={duplicateRecChkFlg === BooleanType.True}
                onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                  if (checked) {
                    handleFieldChange(BooleanType.True, 'fileConfig', 'duplicateRecChkFlg');
                    setDuplicateCheck(true);
                  } else {
                    handleFieldChange(BooleanType.False, 'fileConfig', 'duplicateRecChkFlg');
                    setDuplicateCheck(false);
                  }
                }}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth, ml: 2 }}>
              {
                duplicateCheck
                  ? (
                    <Grid container>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Threshold</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="percentage"
                          name="radio-buttons-group"
                          value={isThresholdPercentageOrCount}
                          onChange={handleThresholdChange}
                        >
                          <FormControlLabel value="Percentage" control={<Radio />} label="Percentage" />
                          <FormControlLabel value="Count" control={<Radio />} label="Count" />
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        variant="standard"
                        focused
                        required
                        // eslint-disable-next-line
                        value={isThresholdPercentageOrCount === ThresholdType.Percentage ? dupPercentThld : (isThresholdPercentageOrCount === ThresholdType.Count ? dupRecCntThld : '')}
                        onChange={(event) => {
                          if (isThresholdPercentageOrCount === ThresholdType.Percentage) {
                            handleFieldChange(event.target.value, 'fileConfig', 'dupPercentThld');
                          } else if (isThresholdPercentageOrCount === ThresholdType.Count) {
                            handleFieldChange(event.target.value, 'fileConfig', 'dupRecCntThld');
                          }
                        }}
                      />
                    </Grid>
                  ) : <> </>
              }
            </Grid>
            <Grid item sx={{ width: itemWidth, mt: 4 }}>
              <WppToggle
                label="Drop Duplicate"
                name="dropDuplicate"
                required
                checked={dropDupFlg === BooleanType.True}
                onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                  if (checked) {
                    handleFieldChange(BooleanType.True, 'fileConfig', 'dropDupFlg');
                  } else {
                    handleFieldChange(BooleanType.False, 'fileConfig', 'dropDupFlg');
                  }
                }}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }} />
            <Grid item sx={{ m: 1, width: itemWidth }} />
          </Grid>
        </Grid>
      </WppAccordion>
    </Grid>
  );
}

export default FileConfigurationAccordion;
