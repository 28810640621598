import React from 'react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import {
  GridRenderCellParams, GridValueGetterParams,
} from '@mui/x-data-grid';
import { CatalogData } from '../../services/models/data-catalog-models.d';
import Table from '../../ui-component/table';
import Cell from '../../ui-component/cell';

export interface CatalogDetailsTableProps {
  rows: CatalogData[],
}
const CatalogDetailsTable = function RenderCatalogDetailsTable(props:CatalogDetailsTableProps) {
  const { rows } = props;

  const columnFormat = [
    {
      field: 'attributeName', description: '', headerText: 'Column Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'attributeDescription', description: '', headerText: 'Column Description', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'businessName', description: '', headerText: 'Business Name', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'dataType', description: '', headerText: 'Data Type', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'attributeStatus', description: '', headerText: 'Status', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'attributeCreated',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'attributeModified',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const columnVisibilityModel = {
    createdUserId: false,
    updatedUserId: false,
  };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: column.sortable,
      filterable: false,
    };
    return columnFormatted;
  }));

  const onCellClick = () => {
  };

  const onPageChange = () => {
  };

  const onPageSizeChange = () => {
  };

  const onSortModelChange = () => {
  };

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={false}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={100}
        rowCount={100}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
};

export default CatalogDetailsTable;
