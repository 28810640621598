import React from 'react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import {
  GridCellParams, GridRenderCellParams, GridSortModel, GridValueGetterParams,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../ui-component/table';
import Cell from '../../../ui-component/cell';
import {
  MasterDataState, DatasetDto, DatasetTableRow, OrderDirection, FetchGetMasterDataParams, MasterDataTableNames,
} from '../../../services/models/master-data-models.d';
import { DEFAULT_MASTER_DATA_PAGINATION, EMPTY_DATASET } from '../../../services/defaults/master-data-defaults';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { StoreState } from '../../../store';
import { setMasterDataSelectedTableRowActionCreator, setMasterDataDataActionCreator, fetchGetMasterDataActionCreator } from '../../../store/actions/master-data-actions';
// import TableToolbar from '../../../ui-component/table-toolbar';

export interface DatasetTableProps {
  rows: DatasetDto[],
}
const DatasetTable = function RenderDatasetTable(props:DatasetTableProps) {
  const { rows } = props;
  const dispatch = useDispatch();

  const fetchGetDatasetListFlag: MasterDataState['api']['flags']['fetchGetDatasetListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetDatasetListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetDatasetList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetDatasetList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetDatasetList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetDatasetList?.listOrder,
  );

  // let records = 0; // records returned on page, can be less than limit
  let page = 0; // current page index
  let limit = 0; // The page size, or max records displayed
  let totalRecords = 0; // Total number of records
  // let totalPages; // Total pages based on limit
  if (typeof pagination !== 'undefined') {
    ({
      // records,
      page,
      limit,
      totalRecords,
      // totalPages,
    } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  let searchKey = '';
  let searchValue = '';
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
      searchKey,
      searchValue,
    } = listOrder);
  }

  const columnFormat = [
    {
      field: 'datasetId', description: '', headerText: 'Dataset ID', minWidth: 50, valueGetter: undefined, sortable: true,
    },
    {
      field: 'datasetName', description: '', headerText: 'Dataset Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'datasetDesc', description: '', headerText: 'Dataset Description', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'datasetCode', description: '', headerText: 'Dataset Code', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdUserId', headerText: 'Created User ID', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', headerText: 'Updated User ID', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const columnVisibilityModel = {
    createdUserId: false,
    updatedUserId: false,
  };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: column.sortable,
      filterable: false,
    };
    return columnFormatted;
  }));

  const getSelectedDataset = (selectedDatasetTableRow: DatasetTableRow) : DatasetDto => {
    const datasetDto: DatasetDto = { ...EMPTY_DATASET };
    datasetDto.datasetId = selectedDatasetTableRow.datasetId !== null ? selectedDatasetTableRow.datasetId : -1;
    datasetDto.datasetName = selectedDatasetTableRow.datasetName !== null ? selectedDatasetTableRow.datasetName : '';
    datasetDto.datasetDesc = selectedDatasetTableRow.datasetDesc !== null ? selectedDatasetTableRow.datasetDesc : '';
    datasetDto.datasetCode = selectedDatasetTableRow.datasetCode !== null ? selectedDatasetTableRow.datasetCode : '';
    datasetDto.status = selectedDatasetTableRow.status !== null ? selectedDatasetTableRow.status : '';
    datasetDto.createdUserId = selectedDatasetTableRow.createdUserId !== null ? selectedDatasetTableRow.createdUserId : '';
    datasetDto.createdDatetime = selectedDatasetTableRow.createdDatetime !== null ? selectedDatasetTableRow.createdDatetime : '';
    datasetDto.updatedUserId = selectedDatasetTableRow.updatedUserId !== null ? selectedDatasetTableRow.updatedUserId : '';
    datasetDto.updatedDatetime = selectedDatasetTableRow.updatedDatetime !== null ? selectedDatasetTableRow.updatedDatetime : '';
    return datasetDto;
  };

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const selectedTableName = 'selectedDatasetTableRow';
    const selectedDatasetTableRow:DatasetTableRow = Object.assign({}, ...rowDetails);
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: selectedDatasetTableRow }));
    const fetchGetDatasetData = 'fetchGetDatasetData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetDatasetData]: getSelectedDataset(selectedDatasetTableRow) }));
  };

  const onPageChange = (updatedPage: number) => {
    if (!fetchGetDatasetListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = updatedPage;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.DATASET, ShowSnackbar.True));
    }
  };

  const onPageSizeChange = (updatedPageSize: number) => {
    if (!fetchGetDatasetListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = updatedPageSize;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.DATASET, ShowSnackbar.True));
    }
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!fetchGetDatasetListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = page;
      fetchGetMasterDataParams.limit = limit;
      if (typeof model[0].field !== 'undefined') {
        fetchGetMasterDataParams.sortByFieldName = model[0].field;
      }
      if (typeof model[0].sort !== 'undefined') {
        fetchGetMasterDataParams.sortOrder = model[0].sort;
      }
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.DATASET, ShowSnackbar.True));
    }
  };

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={false}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={limit || 0}
        rowCount={totalRecords || 0}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
};

export default DatasetTable;
