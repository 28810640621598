import { GridSortModel } from '@mui/x-data-grid';
import {
  DEFAULT_USER_GRID_SORT_ARRAY, DESELECTED_USER_TABLE_ROW, EMPTY_ROLE_RESPONSE, EMPTY_USER_LIST_RESPONSE, EMPTY_USER_MGMT_REQUEST, EMPTY_USER_RESPONSE,
} from '../../services/defaults/user-defaults';
import { UserAction, UserActionOptions, UserState } from '../../services/models/user-models.d';

export const UserDefaultState = {
  api: {
    flags: {
      fetchGetUserListFlag: false,
      fetchGetUserByNameFlag: false,
      fetchPostUserFlag: false,
      fetchPutUserFlag: false,
      fetchDeleteUserFlag: false,
      fetchGetRoleListFlag: false,
      fetchGetRoleResourcePermissionFlag: false,

    },
    data: {
      fetchGetUserList: { ...EMPTY_USER_LIST_RESPONSE },
      fetchGetUserByName: { ...EMPTY_USER_RESPONSE },
      fetchGetRoleList: { ...EMPTY_ROLE_RESPONSE },
      fetchGetRoleResourcePermission: { ...EMPTY_USER_RESPONSE },
    },
  },
  formData: {
    userFormData: { ...EMPTY_USER_MGMT_REQUEST },
  },
  tables: {
    selectedUserTableRow: { ...DESELECTED_USER_TABLE_ROW },
  },
  userDataEdits: 0,
  sortModel: DEFAULT_USER_GRID_SORT_ARRAY,
};

function userReducer(
  state:UserState = UserDefaultState,
  action:UserAction,
): UserState {
  switch (action.type) {
    case UserActionOptions.SET_USER_API_FLAG:
      return {
        ...state,
        api: {
          ...state.api,
          flags: {
            ...state.api.flags,
            ...action.payload,
          },
        },
      };
    case UserActionOptions.SET_USER_API_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          data: {
            ...state.api.data,
            ...action.payload,
          },
        },
      };
    case UserActionOptions.SET_USER_SELECTED_TABLE_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          ...action.payload,
        },
      };
    case UserActionOptions.SET_USER_DATA_EDITS:
      return {
        ...state,
        ...action.payload,
      };
    case UserActionOptions.SET_USER_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload as GridSortModel,
      };
    case UserActionOptions.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default userReducer;
