import React, {
  useEffect,
  useState,
} from 'react';

// material-ui
import {
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MainContent from '../../../layout/MainLayout/MainContent';
import {
  FetchGetMasterDataParams, MasterDataTableNames, MasterDataState, CollectionDto, MasterDataPostType, OrderDirection,
} from '../../../services/models/master-data-models.d';
import { DEFAULT_MASTER_DATA_PAGINATION, DESELECTED_COLLECTION_TABLE_ROW, EMPTY_COLLECTION } from '../../../services/defaults/master-data-defaults';
import CollectionTable from './collection-table';
import { StatusOption } from '../../../services/models/select-models.d';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { StoreState } from '../../../store';
import {
  fetchGetMasterDataActionCreator, fetchPostMasterDataActionCreator, setMasterDataDataActionCreator, setMasterDataSelectedTableRowActionCreator,
} from '../../../store/actions/master-data-actions';
import CollectionForm from './collection-form';

function Collection() {
  const dispatch = useDispatch();

  let collectionList:MasterDataState['api']['data']['fetchGetCollectionList']['collectionList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCollectionList?.collectionList,
  );

  const selectedCollectionTableRow:MasterDataState['tables']['selectedCollectionTableRow'] = useSelector(
    (state: StoreState) => state.masterDataReducer.tables?.selectedCollectionTableRow,
  );

  const fetchGetCollectionListFlag: MasterDataState['api']['flags']['fetchGetCollectionListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetCollectionListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetCollectionList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCollectionList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetCollectionList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCollectionList?.listOrder,
  );

  let limit = 0; // The page size, or max records displayed
  if (typeof pagination !== 'undefined') {
    ({ limit } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
    } = listOrder);
  }

  if (collectionList === null || typeof collectionList === 'undefined') {
    collectionList = [];
  }

  useEffect(() => {
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.COLLECTION));
  }, [dispatch]);
  const tableHeight = '730px';
  // const tableWidth = '77vw';
  const tableWidth = '101%';
  const [showCollectionUI, setShowCollectionUI] = useState<boolean>(false);

  const getSelectedCollection = () : CollectionDto => {
    const collectionDto: CollectionDto = { ...EMPTY_COLLECTION };
    collectionDto.collectionId = selectedCollectionTableRow.collectionId !== null ? selectedCollectionTableRow.collectionId : -1;
    collectionDto.collectionName = selectedCollectionTableRow.collectionName !== null ? selectedCollectionTableRow.collectionName : '';
    collectionDto.collectionDesc = selectedCollectionTableRow.collectionDesc !== null ? selectedCollectionTableRow.collectionDesc : '';
    collectionDto.collectionSourceType = selectedCollectionTableRow.collectionSourceType !== null ? selectedCollectionTableRow.collectionSourceType : '';
    collectionDto.collectionSourceName = selectedCollectionTableRow.collectionSourceName !== null ? selectedCollectionTableRow.collectionSourceName : '';
    collectionDto.collectionSummary = selectedCollectionTableRow.collectionSummary !== null ? selectedCollectionTableRow.collectionSummary : '';
    collectionDto.collectionOwner = selectedCollectionTableRow.collectionOwner !== null ? selectedCollectionTableRow.collectionOwner : '';
    collectionDto.collectionAgencyName = selectedCollectionTableRow.collectionAgencyName !== null ? selectedCollectionTableRow.collectionAgencyName : '';
    collectionDto.collectionDate = selectedCollectionTableRow.collectionDate !== null ? selectedCollectionTableRow.collectionDate : '';
    collectionDto.status = selectedCollectionTableRow.status !== null ? selectedCollectionTableRow.status : '';
    collectionDto.createdUserId = selectedCollectionTableRow.createdUserId !== null ? selectedCollectionTableRow.createdUserId : '';
    collectionDto.createdDatetime = selectedCollectionTableRow.createdDatetime !== null ? selectedCollectionTableRow.createdDatetime : '';
    collectionDto.updatedUserId = selectedCollectionTableRow.updatedUserId !== null ? selectedCollectionTableRow.updatedUserId : '';
    collectionDto.updatedDatetime = selectedCollectionTableRow.updatedDatetime !== null ? selectedCollectionTableRow.updatedDatetime : '';
    return collectionDto;
  };

  // This currently sets status to inactive
  const handleDelete = async () => {
    const collectionDto: CollectionDto = getSelectedCollection();
    if (collectionDto.collectionId !== -1) {
      collectionDto.status = StatusOption.Inactive;
      dispatch(fetchPostMasterDataActionCreator(collectionDto, MasterDataPostType.DELETE, MasterDataTableNames.COLLECTION));
      // const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      // dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.COLLECTION));
      setShowCollectionUI(false);
      const fetchGetCollectionData = 'fetchGetCollectionData';
      dispatch(setMasterDataDataActionCreator({ [fetchGetCollectionData]: { ...EMPTY_COLLECTION } }));
      const selectedTableName = 'selectedCollectionTableRow';
      dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_COLLECTION_TABLE_ROW } }));
    }
  };

  const handleSearchCollection = (newSearchValue: string) => {
    if (!fetchGetCollectionListFlag) {
      const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = 'collectionName';
      fetchGetMasterDataParams.searchValue = newSearchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.COLLECTION, ShowSnackbar.True));
    }
  };

  return (
    <MainContent
      title="Collection"
      buttonTitles={{
        addTitle: 'Add Collection',
        editTitle: 'Edit Collection',
        deleteTitle: 'Delete Collection',
        summaryTitle: 'Collection Summary',
        importTitle: 'Import Collection',
        copyTitle: 'Copy Collection',
      }}
      addCallback={() => {
        const fetchGetCollectionData = 'fetchGetCollectionData';
        const collectionDto: CollectionDto = { ...EMPTY_COLLECTION };
        dispatch(setMasterDataDataActionCreator({ [fetchGetCollectionData]: collectionDto }));
        setShowCollectionUI(!showCollectionUI);
      }}
      editCallback={() => {
        setShowCollectionUI(!showCollectionUI);
      }}
      deleteCallback={() => handleDelete()}
      summaryCallback={() => null} // TODO
      importCallback={() => null} // TODO
      copyCallback={() => null} // TODO
      searchCallback={(newSearchValue) => handleSearchCollection(newSearchValue)}
      searchPlaceholder="Search Collections"
      editDisabled={selectedCollectionTableRow?.collectionId === null}
      deleteDisabled={selectedCollectionTableRow?.collectionId === null}
      copyDisabled
      summaryDisabled
      importDisabled

    >
      <Grid
        container
        item
        sx={{
          width: tableWidth, height: tableHeight, justifyContent: 'space-between',
        }}
      >
        <Grid item xs={showCollectionUI ? 8 : 12}>
          <CollectionTable rows={collectionList} />
        </Grid>
        {showCollectionUI
          ? (
            <Grid container item xs={4} sx={{ pl: 2.5 }}>
              <CollectionForm />
            </Grid>
          )
          : <Grid item />}
      </Grid>
    </MainContent>
  );
}

export default Collection;
