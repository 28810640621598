import React from 'react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import {
  GridCellParams, GridRenderCellParams, GridSortModel, GridValueGetterParams,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../ui-component/table';
import Cell from '../../../ui-component/cell';
import {
  FetchGetMasterDataParams, MasterDataState, MasterDataTableNames, OrderDirection, TagDto, TagTableRow,
} from '../../../services/models/master-data-models.d';
import { DEFAULT_MASTER_DATA_PAGINATION, EMPTY_TAG } from '../../../services/defaults/master-data-defaults';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { setMasterDataSelectedTableRowActionCreator, setMasterDataDataActionCreator, fetchGetMasterDataActionCreator } from '../../../store/actions/master-data-actions';
import { StoreState } from '../../../store';
// import TableToolbar from '../../../ui-component/table-toolbar';

export interface TagTableProps {
  rows: TagDto[],
}
const TagTable = function RenderTagTable(props:TagTableProps) {
  const { rows } = props;
  const dispatch = useDispatch();

  const fetchGetTagListFlag: MasterDataState['api']['flags']['fetchGetTagListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetTagListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetTagList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetTagList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetTagList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetTagList?.listOrder,
  );

  // let records = 0; // records returned on page, can be less than limit
  let page = 0; // current page index
  let limit = 0; // The page size, or max records displayed
  let totalRecords = 0; // Total number of records
  // let totalPages; // Total pages based on limit
  if (typeof pagination !== 'undefined') {
    ({
      // records,
      page,
      limit,
      totalRecords,
      // totalPages,
    } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  let searchKey = '';
  let searchValue = '';
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
      searchKey,
      searchValue,
    } = listOrder);
  }

  const columnFormat = [
    {
      field: 'tagId', description: '', headerText: 'Tag ID', minWidth: 50, valueGetter: undefined, sortable: true,
    },
    {
      field: 'tagName', description: '', headerText: 'Tag Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'tagDesc', description: '', headerText: 'Tag Description', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdUserId', headerText: 'Created User ID', valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', headerText: 'Updated User ID', valueGetter: undefined, sortable: false,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const columnVisibilityModel = {
    createdUserId: false,
    updatedUserId: false,
  };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: column.sortable,
      filterable: false,
    };
    return columnFormatted;
  }));

  const getSelectedTag = (selectedTagTableRow: TagTableRow) : TagDto => {
    const tagDto: TagDto = { ...EMPTY_TAG };
    tagDto.tagId = selectedTagTableRow.tagId !== null ? selectedTagTableRow.tagId : -1;
    tagDto.tagName = selectedTagTableRow.tagName !== null ? selectedTagTableRow.tagName : '';
    tagDto.tagDesc = selectedTagTableRow.tagDesc !== null ? selectedTagTableRow.tagDesc : '';
    tagDto.status = selectedTagTableRow.status !== null ? selectedTagTableRow.status : '';
    tagDto.createdUserId = selectedTagTableRow.createdUserId !== null ? selectedTagTableRow.createdUserId : '';
    tagDto.createdDatetime = selectedTagTableRow.createdDatetime !== null ? selectedTagTableRow.createdDatetime : '';
    tagDto.updatedUserId = selectedTagTableRow.updatedUserId !== null ? selectedTagTableRow.updatedUserId : '';
    tagDto.updatedDatetime = selectedTagTableRow.updatedDatetime !== null ? selectedTagTableRow.updatedDatetime : '';
    return tagDto;
  };

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const selectedTableName = 'selectedTagTableRow';
    const selectedTagTableRow:TagTableRow = Object.assign({}, ...rowDetails);
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: selectedTagTableRow }));
    const fetchGetTagData = 'fetchGetTagData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetTagData]: getSelectedTag(selectedTagTableRow) }));
  };

  const onPageChange = (updatedPage: number) => {
    if (!fetchGetTagListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = updatedPage;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.TAG, ShowSnackbar.True));
    }
  };

  const onPageSizeChange = (updatedPageSize: number) => {
    if (!fetchGetTagListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = updatedPageSize;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.TAG, ShowSnackbar.True));
    }
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!fetchGetTagListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = page;
      fetchGetMasterDataParams.limit = limit;
      if (typeof model[0].field !== 'undefined') {
        fetchGetMasterDataParams.sortByFieldName = model[0].field;
      }
      if (typeof model[0].sort !== 'undefined') {
        fetchGetMasterDataParams.sortOrder = model[0].sort;
      }
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.TAG, ShowSnackbar.True));
    }
  };

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={false}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={limit || 0}
        rowCount={totalRecords || 0}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
};

export default TagTable;
