import React from 'react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { GridCellParams, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import Table from '../../../../ui-component/table';
import Cell from '../../../../ui-component/cell';
import {
  AssetAttrbtRuleDto,
  RulesTableRow,
} from '../../../../services/models/asset-models.d';
import { setAssetSelectedTableRowActionCreator, setFormDataActionCreator } from '../../../../store/actions/asset-actions';
import { EMPTY_ASSET_ATTRBT_RULE } from '../../../../services/defaults/asset-defaults';
import { RuleCategory, RuleType } from '../../../../services/models/config-models.d';

export interface AttributeRulesTableProps {
  rows:RulesTableRow[]
}

function AttributeRulesTable(props:AttributeRulesTableProps): JSX.Element {
  const dispatch = useDispatch();
  const { rows } = props;

  const columnFormat = [
    {
      field: 'assetAttrbtId', headerText: 'Asset Attribute Id', // Hidden
    },
    {
      field: 'ruleId', headerText: 'Rule Id', // Hidden
    },
    {
      field: 'ruleName', description: '', headerText: 'Rule Name', minWidth: 150,
    },
    {
      field: 'ruleCategory', description: '', headerText: 'Rule Category', minWidth: 150,
    },
    {
      field: 'ruleExpression', description: '', headerText: 'Rule Expression', minWidth: 150,
    },
    {
      field: 'ruleType', description: '', headerText: 'Rule Type', minWidth: 150,
    },
    {
      field: 'thresholdPercentage', description: '', headerText: 'Threshold Percentage', minWidth: 170,
    },
    {
      field: 'lookupTable', description: '', headerText: 'Asset Name', minWidth: 150,
    },
    {
      field: 'lookupColumn', description: '', headerText: 'Attribute Name', minWidth: 150,
    },
    {
      field: 'ruleExeSeqNum', description: '', headerText: 'Rule Execution Sequence Number', minWidth: 250,
    },
    {
      field: 'ruleExeSubSeq', description: '', headerText: 'Rule Execution Sub Sequence', minWidth: 220,
    },
    {
      field: 'ruleOperator', headerText: 'Rule Operator', // Hidden
    },
    {
      field: 'ruleValue', headerText: 'Rule Value', // Hidden
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150,
    },
    {
      field: 'createdUserId', description: '', headerText: 'Created User Id', minWidth: 150,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', description: '', headerText: 'Updated User Id', minWidth: 150,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const columnVisibilityModel = {
    assetAttrbtId: false,
    ruleId: false,
    ruleOperator: false,
    ruleValue: false,
  };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: false,
      filterable: false,
    };
    return columnFormatted;
  }));

  const populateAttributeRuleForm = (selectedRulesTableRow:RulesTableRow) => {
    if (selectedRulesTableRow?.ruleId !== null) {
      const attributeRuleFormDataName = 'attributeRuleFormData';
      const assetAttrbtRuleDto: AssetAttrbtRuleDto = { ...EMPTY_ASSET_ATTRBT_RULE };

      assetAttrbtRuleDto.assetAttrbtId = selectedRulesTableRow.assetAttrbtId !== null ? selectedRulesTableRow.assetAttrbtId : -1;
      assetAttrbtRuleDto.ruleId = selectedRulesTableRow.ruleId !== null ? selectedRulesTableRow.ruleId : -1;
      // assetAttrbtRuleDto.ruleExeSeqNum = selectedRulesTableRow.ruleExeSeqNum !== null ? selectedRulesTableRow.ruleExeSeqNum : '';
      assetAttrbtRuleDto.ruleName = selectedRulesTableRow.ruleName !== null ? selectedRulesTableRow.ruleName : '';
      // assetAttrbtRuleDto.ruleDesc = selectedRulesTableRow.ruleDesc !== null ? selectedRulesTableRow.ruleDesc : '';
      assetAttrbtRuleDto.ruleCategory = selectedRulesTableRow.ruleCategory !== null ? selectedRulesTableRow.ruleCategory : RuleCategory.BASIC;
      assetAttrbtRuleDto.ruleExpression = selectedRulesTableRow.ruleExpression !== null ? selectedRulesTableRow.ruleExpression : '';
      assetAttrbtRuleDto.ruleType = selectedRulesTableRow.ruleType !== null ? selectedRulesTableRow.ruleType : RuleType.COMPARE;
      // assetAttrbtRuleDto.ruleOperator = selectedRulesTableRow.ruleOperator !== null ? selectedRulesTableRow.ruleOperator : '';
      // assetAttrbtRuleDto.ruleValue = selectedRulesTableRow.ruleValue !== null ? selectedRulesTableRow.ruleValue : '';
      assetAttrbtRuleDto.thresholdPercentage = selectedRulesTableRow.thresholdPercentage !== null ? selectedRulesTableRow.thresholdPercentage : 0;
      // assetAttrbtRuleDto.existCheckFlg = selectedRulesTableRow.existCheckFlg !== null ? selectedRulesTableRow.existCheckFlg : '';
      assetAttrbtRuleDto.lookupTable = selectedRulesTableRow.lookupTable !== null ? selectedRulesTableRow.lookupTable : '';
      assetAttrbtRuleDto.lookupColumn = selectedRulesTableRow.lookupColumn !== null ? selectedRulesTableRow.lookupColumn : '';
      assetAttrbtRuleDto.status = selectedRulesTableRow.status !== null ? selectedRulesTableRow.status : '';
      // assetAttrbtRuleDto.createdUserId = selectedRulesTableRow.createdUserId !== null ? selectedRulesTableRow.createdUserId : '';
      // assetAttrbtRuleDto.createdDatetime = selectedRulesTableRow.createdDatetime !== null ? selectedRulesTableRow.createdDatetime : '';
      // assetAttrbtRuleDto.updatedUserId = selectedRulesTableRow.updatedUserId !== null ? selectedRulesTableRow.updatedUserId : '';
      // assetAttrbtRuleDto.updatedDatetime = selectedRulesTableRow.updatedDatetime !== null ? selectedRulesTableRow.updatedDatetime : '';

      dispatch(setFormDataActionCreator({ [attributeRuleFormDataName]: assetAttrbtRuleDto }));
    }
  };

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns?.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const tableName = 'selectedRulesTableRow';
    const selectedRulesTableRow:RulesTableRow = Object.assign({}, ...rowDetails);
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: selectedRulesTableRow }));
    populateAttributeRuleForm(selectedRulesTableRow);
  };

  const onPageChange = () => null;

  const onPageSizeChange = () => null;

  const onSortModelChange = () => null; // Not implemented

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={rows.length > 100}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={rows.length > 100 ? 1 : rows.length}
        rowCount={rows?.length}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
}

export default AttributeRulesTable;
