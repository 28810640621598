import React from 'react';
import {
  WppButton, WppIconArrow, WppIconAvailableCheckmark,
} from '@platform-ui-kit/components-library-react';
import {
  Grid, Box, Stepper as MuiStepper, Step, StepLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveStep } from '../services/models/stepper-models.d';
import Tooltip from './tooltip';
import { AssetState, ContractDto, ValuationDto } from '../services/models/asset-models';
import { StoreState } from '../store';
import { setSnackbarActionCreator, snackbarMaker } from '../store/actions/snackbar-actions';

export interface StepperProps {
  titles:string[],
  currentStep:number,
  handleNextStep:() => void,
  handlePreviousStep:() => void,
  handleStepClick:(step:number) => void,
  handleSubmit:() => void,
  handleFieldChange:(newValue:null | string | Date | boolean | ValuationDto | ContractDto, section: string, field: string | null) => void,
}

function Stepper(props:StepperProps) {
  const {
    titles,
    currentStep,
    handleNextStep,
    handlePreviousStep,
    handleStepClick,
    handleSubmit,
    handleFieldChange,
  } = props;

  const style = {
    transform: 'rotate(180deg)',
  };

  const dispatch = useDispatch();

  const assetData:AssetState['api']['data']['fetchGetAssetById'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetById,
  );

  const { asset, assetAttrbtList } = assetData;
  const { status } = asset;

  const steps = titles.map((title:string, index:number) => (
    <Step
      key={`steps${Math.random().toString(36)}`}
      onClick={() => handleStepClick(index)}
      sx={{
        // Default
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          color: 'grey.800', // Just text label (DEFAULT)
        },
        // Completed Step
        '& .MuiStepLabel-iconContainer .Mui-completed': {
          borderColor: 'secondary.dark', borderRadius: '50%', border: 3, // circle color (COMPLETED)
        },
        '& .MuiStepLabel-root .Mui-completed': {
          color: 'secondary.dark', // circle color (COMPLETED)
        },
        // Active
        '& .MuiStepLabel-iconContainer .Mui-active': {
          borderColor: 'secondary.dark', borderRadius: '50%', border: 3, padding: 1.1, // circle color (ACTIVE)
        },
        '& .MuiStepLabel-root .Mui-active': {
          color: 'secondary.dark', // circle color (ACTIVE)
        },
        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
          fill: 'secondary.dark', // circle's number (ACTIVE)
        },
        // Error
        // '& .MuiStepLabel-iconContainer .Mui-completed .Mui-error': {
        //   border: 0, // circle color (ERROR)
        // },
        // '& .MuiStepLabel-root .Mui-completed .Mui-error': {
        //   color: 'error.main', // circle color (ERROR)
        // },
        // '& .MuiStepLabel-iconContainer .Mui-active .Mui-error': {
        //   borderColor: 'error.main', borderRadius: '50%', border: 3, padding: 1.1, // circle color (ERROR)
        // },
        // '& .MuiStepLabel-root .Mui-active .Mui-error': {
        //   color: 'error.main', // circle color (ERROR)
        // },
        // '& .MuiStepLabel-root .Mui-active .Mui-error .MuiStepIcon-text': {
        //   fill: 'error.main', // circle's number (ERROR)
        // },
      }}
    >
      <StepLabel error={false}>{title}</StepLabel>
    </Step>
  ));

  return (
    <Box>
      <Grid container sx={{ width: 'auto' }}>
        <Grid container item spacing={1} sx={{ justifyContent: 'center' }}>
          <Grid item>
            <Tooltip disableHoverListener={currentStep === ActiveStep.AssetConfigurationStep} title="Previous Step">
              <WppButton variant="secondary" onClick={handlePreviousStep} disabled={currentStep === ActiveStep.AssetConfigurationStep}>
                <WppIconArrow
                  slot="icon-start"
                  style={style}
                />
              </WppButton>
            </Tooltip>

          </Grid>
          <Grid item xs={8}>
            <MuiStepper activeStep={currentStep} orientation="horizontal" alternativeLabel>
              {steps}
            </MuiStepper>
          </Grid>
          <Grid item justifyContent="flex-end">
            { currentStep !== titles.length - 1
              ? (
                <Tooltip disableHoverListener={false} title="Next Step">
                  <WppButton variant="secondary" onClick={handleNextStep}>
                    <WppIconArrow slot="icon-end" />
                  </WppButton>
                </Tooltip>

              )
              : (
                <Tooltip disableHoverListener={false} title="Apply">
                  <WppButton
                    variant="secondary"
                    onClick={async () => {
                      if (status !== 'Inactive') {
                        if (typeof assetAttrbtList !== 'undefined' && assetAttrbtList !== null) {
                          if (status === 'Draft') {
                            handleFieldChange('Active', 'asset', 'status');
                          }
                          handleSubmit();
                        } else {
                          const errorMessage = 'Please add at least one attribute before applying changes';
                          dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
                        }
                      } else {
                        handleSubmit();
                      }
                    }}
                  >
                    <WppIconAvailableCheckmark slot="icon-end" />
                  </WppButton>
                </Tooltip>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Stepper;
