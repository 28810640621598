export interface CatalogState {
  api: {
    flags: {
      fetchGetCatalogAssetNameFlag: boolean,
      fetchGetCatalogMetricsFlag: boolean,
    },
    data: {
      fetchGetCatalogAssetName: CatalogDataResponse,
      fetchGetCatalogMetrics: CatalogMetricsResponse,
    },
    dataEu: {
      fetchGetCatalogAssetName: CatalogDataResponse,
      fetchGetCatalogMetrics: CatalogMetricsResponse,
    },
    dataApac: {
      fetchGetCatalogAssetName: CatalogDataResponse,
      fetchGetCatalogMetrics: CatalogMetricsResponse,
    },
    dataEmea: {
      fetchGetCatalogAssetName: CatalogDataResponse,
      fetchGetCatalogMetrics: CatalogMetricsResponse,
    },
  },
  catalogEdits: number,
  tables: {
    selectedAttributeTableRow: CatalogDataTableRow,
  },
  sortModel: GridSortModel,
}

export enum CatalogActionOptions {
  SET_CATALOG_API_FLAG = 'SET_CATALOG_API_FLAG',
  SET_CATALOG_API_DATA = 'SET_CATALOG_API_DATA',
  SET_CATALOG_SELECTED_TABLE_ROW = 'SET_CATALOG_SELECTED_TABLE_ROW',
  SET_CATALOG_SORT_MODEL = 'SET_CATALOG_SORT_MODEL',
  SET_CATALOG_DATA_EDITS = 'SET_CATALOG_DATA_EDITS',
  SET_FORM_DATA = 'SET_FORM_DATA',
  SET_CATALOG_API_EU_DATA = 'SET_CATALOG_API_EU_DATA',
  SET_CATALOG_API_APAC_DATA = 'SET_CATALOG_API_APAC_DATA',
  SET_CATALOG_API_EMEA_DATA = 'SET_CATALOG_API_EMEA_DATA',
}

export interface Flag {
  [key: string]: boolean;
}

export interface Data {
  [key: string]: any
}

export interface Count {
  [key: string]: number
}

export interface SelectedRow {
  [key: string]: CatalogTableRow
}

export interface CatalogAction {
  type: CatalogActionOptions,
  payload: Flag |
  Data |
  Count |
  CatalogTableRow
}

export interface Status {
  code: string,
  message: string,
  desc: string,
  errorList: string[],
}

export interface CatalogData {
  organizationCode: string,
  collectionName: string,
  collectionDescription: string,
  datasetName: string,
  collectionStatus: string,
  collectionSummary: string,
  collectionOwner: string,
  collectionDate: string,
  sourceType: string,
  sourceName: string,
  organizationName: string,
  assetName: string,
  assetDescription: string,
  assetStatus: string,
  assetSteward: string,
  assetCreated: string,
  assetModified: string,
  tags: string,
  autoSync: string,
  attributeName: string,
  attributeDescription: string,
  dataType: string,
  businessName: string,
  attributeIsNullable: string,
  attributeCreated: string,
  attributeModified: string,
  attributeStatus: string,
  category: string,
  subCategory: string,
  group: string,
  level: string,
  compilation: string,
  variableSource: string,
  dataProvider: string,
  royalty: string,
  restrictions: string,
  licenseUseFlag: string,
  appendUseFlag: string,
  digitalUseFlag: string,
  directUseFlag: string,
  modelUseFlag: string,
  machineLearningType: string,
  cardinality: string,
  percentNull: string,
  percentNullRounded: string,
  minValue: string,
  maxValue: string,
  minValueLength: string,
  maxValueLength: string,
  quartile: string,
  numericColumn: string,
  validValues: string,
  geoLocation: string,
}

export interface CatalogDataTableRow {
  organizationCode: string | null,
  collectionName: string | null,
  collectionDescription: string | null,
  datasetName: string | null,
  collectionStatus: string | null,
  collectionSummary: string | null,
  collectionOwner: string | null,
  collectionDate: string | null,
  sourceType: string | null,
  sourceName: string | null,
  organizationName: string | null,
  assetName: string | null,
  assetDescription: string | null,
  assetStatus: string | null,
  assetSteward: string | null,
  assetCreated: string | null,
  assetModified: string | null,
  tags: string | null,
  autoSync: string | null,
  attributeName: string | null,
  attributeDescription: string | null,
  dataType: string | null,
  businessName: string | null,
  attributeIsNullable: string | null,
  attributeCreated: string | null,
  attributeModified: string | null,
  attributeStatus: string | null,
  category: string | null,
  subCategory: string | null,
  group: string | null,
  level: string | null,
  compilation: string | null,
  variableSource: string | null,
  dataProvider: string | null,
  royalty: string | null,
  restrictions: string | null,
  licenseUseFlag: string | null,
  appendUseFlag: string | null,
  digitalUseFlag: string | null,
  directUseFlag: string | null,
  modelUseFlag: string | null,
  machineLearningType: string | null,
  cardinality: string | null,
  percentNull: string | null,
  percentNullRounded: string | null,
  minValue: string | null,
  maxValue: string | null,
  minValueLength: string | null,
  maxValueLength: string | null,
  quartile: string | null,
  numericColumn: string | null,
  validValues: string | null,
  geoLocation: string | null,
}

export interface CatalogDataResponse {
  catalogDataList: CatalogData[],
  status: Status,
}

export interface DataHolder {
  name: string,
  desc: string,
  details: { [key: string]: string } | null,
  assetData: DataHolder[],
}

export interface CatalogMetricsResponse {
  collectionCount: number,
  datasetCount: number,
  assetCount: number,
  columnCount: number,
  organizationCode: string,
  collectionData: DataHolder[],
  status: Status,
}

export interface FetchGetCatalogDetails {
  accountCode: string | null,
  organization: string | null,
  collection: string | null,
  datasetName: string | null,
  assetName: string | null,
}

export interface CatalogError {
  // assetId: number,
  status: ErrorStatus
}
