import { GridSortModel } from '@mui/x-data-grid';

export const EMPTY_CATALOG_DATA = {
  organizationCode: '',
  collectionName: '',
  collectionDescription: '',
  datasetName: '',
  collectionStatus: '',
  collectionSummary: '',
  collectionOwner: '',
  collectionDate: '',
  sourceType: '',
  sourceName: '',
  organizationName: '',
  assetName: '',
  assetDescription: '',
  assetStatus: '',
  assetSteward: '',
  assetCreated: '',
  assetModified: '',
  tags: '',
  autoSync: '',
  attributeName: '',
  attributeDescription: '',
  dataType: '',
  businessName: '',
  attributeIsNullable: '',
  attributeCreated: '',
  attributeModified: '',
  attributeStatus: '',
  category: '',
  subCategory: '',
  group: '',
  level: '',
  compilation: '',
  variableSource: '',
  dataProvider: '',
  royalty: '',
  restrictions: '',
  licenseUseFlag: '',
  appendUseFlag: '',
  digitalUseFlag: '',
  directUseFlag: '',
  modelUseFlag: '',
  machineLearningType: '',
  cardinality: '',
  percentNull: '',
  percentNullRounded: '',
  minValue: '',
  maxValue: '',
  minValueLength: '',
  maxValueLength: '',
  quartile: '',
  numericColumn: '',
  validValues: '',
  geoLocation: '',
};

export const DESELECTED_CATALOG_DATA_TABLE_ROW = {
  organizationCode: null,
  collectionName: null,
  collectionDescription: null,
  datasetName: null,
  collectionStatus: null,
  collectionSummary: null,
  collectionOwner: null,
  collectionDate: null,
  sourceType: null,
  sourceName: null,
  organizationName: null,
  assetName: null,
  assetDescription: null,
  assetStatus: null,
  assetSteward: null,
  assetCreated: null,
  assetModified: null,
  tags: null,
  autoSync: null,
  attributeName: null,
  attributeDescription: null,
  dataType: null,
  businessName: null,
  attributeIsNullable: null,
  attributeCreated: null,
  attributeModified: null,
  attributeStatus: null,
  category: null,
  subCategory: null,
  group: null,
  level: null,
  compilation: null,
  variableSource: null,
  dataProvider: null,
  royalty: null,
  restrictions: null,
  licenseUseFlag: null,
  appendUseFlag: null,
  digitalUseFlag: null,
  directUseFlag: null,
  modelUseFlag: null,
  machineLearningType: null,
  cardinality: null,
  percentNull: null,
  percentNullRounded: null,
  minValue: null,
  maxValue: null,
  minValueLength: null,
  maxValueLength: null,
  quartile: null,
  numericColumn: null,
  validValues: null,
  geoLocation: null,
};

export const EMPTY_CATALOG_DATA_ARRAY = [];
export const EMPTY_ERROR_ARRAY = [];
export const EMPTY_DATA_HOLDER_ARRAY = [];

export const DEFAULT_STATUS = {
  code: '',
  message: '',
  desc: '',
  errorList: [...EMPTY_ERROR_ARRAY],
};

export const EMPTY_CATALOG_DATA_RESPONSE = {
  catalogDataList: [...EMPTY_CATALOG_DATA_ARRAY],
  status: { ...DEFAULT_STATUS },
};

export const EMPTY_DATA_HOLDER = {
  name: '',
  desc: '',
  details: null,
  assetData: [...EMPTY_DATA_HOLDER_ARRAY],
};

export const EMPTY_CATALOG_METRICS_RESPONSE = {
  collectionCount: 0,
  datasetCount: 0,
  assetCount: 0,
  columnCount: 0,
  organizationCode: '',
  collectionData: [...EMPTY_DATA_HOLDER_ARRAY],
  status: { ...DEFAULT_STATUS },
};

export const DEFAULT_CATALOG_DATA_GRID_SORT_ARRAY:GridSortModel = [
  {
    field: 'assetName',
    sort: 'asc',
  },
];
