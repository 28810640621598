import { GridSortModel } from '@mui/x-data-grid';

export const EMPTY_NOTIFICATION_INBOUND = {
  transmissionId: -1,
  subscriptionId: -1,
  notificationId: -1,
  accountCode: '',
  assetName: '',
  collectionName: '',
  notificationEvent: '',
  notificationLevel: '',
  subscriptionCategory: '',
  subscriptionName: '',
  subscriptionDesc: '',
  subscribedData: '',
  notificationName: '',
  notificationDesc: '',
  notificationCategory: '',
  notificationValue: '',
  notificationLabel: '',
  notificationContent: '',
  notificationRecipient: '',
  resendLimit: -1,
  refreshWindow: '',
  additionalNotificationParams: null,
  status: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

export const DEFAULT_NOTIFICATION_INBOUND = {
  transmissionId: -1,
  subscriptionId: -1,
  notificationId: -1,
  accountCode: '',
  assetName: '',
  collectionName: '',
  notificationEvent: '',
  notificationLevel: 'Asset',
  subscriptionCategory: 'Internal Subscription',
  subscriptionName: 'Ontario Internal Subscription',
  subscriptionDesc: 'Ontario Internal Subscription',
  subscribedData: '',
  notificationName: '',
  notificationDesc: '',
  notificationCategory: '',
  notificationValue: 'Default',
  notificationLabel: '',
  notificationContent: '',
  notificationRecipient: '',
  resendLimit: -1,
  refreshWindow: '',
  additionalNotificationParams: null,
  status: 'Active',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

export const EMPTY_NOTIFICATION_SUBSCRIPTION_ARRAY = [];
export const EMPTY_ASSET_ARRAY = [];
export const EMPTY_ERROR_ARRAY = [];

export const DEFAULT_STATUS = {
  code: '',
  message: '',
  desc: '',
  errorList: [...EMPTY_ERROR_ARRAY],
};

export const EMPTY_NOTIFICATION_OUTBOUND = {
  accountCode: '',
  collection: '',
  assets: [...EMPTY_ASSET_ARRAY],
  subscriber: '',
  event: '',
  channelType: '',
  channelDetails: '',
  notificationLevel: '',
  refreshWindow: '',
  message: '',
  date: '',
  notificationSubscriptionList: [...EMPTY_NOTIFICATION_SUBSCRIPTION_ARRAY],
  additionalNotificationParams: null,
  status: { ...DEFAULT_STATUS },
};

export const EMPTY_NOTIFICATION_SUBSCRIPTION = {
  transmissionId: -1,
  subscriptionId: -1,
  notificationId: -1,
  accountCode: '',
  assetName: '',
  collectionName: '',
  notificationEvent: '',
  notificationLevel: '',
  subscriptionCategory: '',
  subscriptionName: '',
  subscriptionDesc: '',
  subscribedData: '',
  notificationName: '',
  notificationDesc: '',
  notificationCategory: '',
  notificationValue: '',
  notificationLabel: '',
  notificationContent: '',
  notificationRecipient: '',
  resendLimit: -1,
  refreshWindow: '',
  status: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

export const DESELECTED_NOTIFICATION_TABLE_ROW = {
  transmissionId: null,
  subscriptionId: null,
  notificationId: null,
  accountCode: null,
  assetName: null,
  collectionName: null,
  notificationEvent: null,
  notificationLevel: null,
  subscriptionCategory: null,
  subscriptionName: null,
  subscriptionDesc: null,
  subscribedData: null,
  notificationName: null,
  notificationDesc: null,
  notificationCategory: null,
  notificationValue: null,
  notificationLabel: null,
  notificationContent: null,
  notificationRecipient: null,
  resendLimit: null,
  refreshWindow: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DEFAULT_NOTIFICATION_GRID_SORT_ARRAY:GridSortModel = [
  {
    field: 'notificationName',
    sort: 'asc',
  },
];
