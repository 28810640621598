import { GridSortModel } from '@mui/x-data-grid';
import { fetchDeleteNotificationById, fetchGetNotification, fetchPostNotification } from '../../services/api/notification-service-api';
import {
  Count, Data, FetchDeleteNotificationParams, FetchGetNotificationParams, Flag, NotificationAction, NotificationActionOptions, NotificationInbound, NotificationError, SelectedRow, NotificationOutbound,
} from '../../services/models/notification-models.d';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { setSnackbarActionCreator, snackbarMaker } from './snackbar-actions';

export const setNotificationFlagActionCreator: (update: Flag) => NotificationAction = (update: Flag) => ({
  type: NotificationActionOptions.SET_NOTIFICATION_API_FLAG,
  payload: update,
});

export const setNotificationDataActionCreator: (update: Data) => NotificationAction = (update: Data) => ({
  type: NotificationActionOptions.SET_NOTIFICATION_API_DATA,
  payload: update,
});

export const setFormDataActionCreator: (update: Data) => NotificationAction = (update: Data) => ({
  type: NotificationActionOptions.SET_FORM_DATA,
  payload: update,
});

export const setNotificationSortModelActionCreator: (update: GridSortModel) => NotificationAction = (update: GridSortModel) => ({
  type: NotificationActionOptions.SET_NOTIFICATION_SORT_MODEL,
  payload: update,
});

export const setNotificationSelectedTableRowActionCreator: (update: SelectedRow) => NotificationAction = (update: SelectedRow) => ({
  type: NotificationActionOptions.SET_NOTIFICATION_SELECTED_TABLE_ROW,
  payload: update,
});

export const setNotificationEditsActionCreator: (update: Count) => NotificationAction = (update: Count) => ({
  type: NotificationActionOptions.SET_NOTIFICATION_DATA_EDITS,
  payload: update,
});

export const handleNotificationError:(errorMessage: string, error: NotificationError) => any = (defaultErrorMessage, error) => async (
  dispatch:(action:any) => any,
) => {
  let errorMessage = defaultErrorMessage;
  if (error?.status?.errorList && typeof error?.status?.errorList[0] !== 'undefined') {
    const [primaryError] = error.status.errorList;
    errorMessage = primaryError;
  }
  dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
};

// GET
export const fetchGetNotificationByAssetNameActionCreator: (
  fetchParams: FetchGetNotificationParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const { assetName } = fetchParams;
  const flagName = 'fetchGetNotificationByAssetNameFlag';
  const dataName = 'fetchGetNotificationByAssetName';
  const successMessage = `Fetched notification data of asset "${assetName}"`;
  const defaultErrorMessage = `Issue fetching notification data for asset "${assetName}"`;
  dispatch(setNotificationFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetNotification(fetchParams);
  if (response.ok) {
    const fetchedData:NotificationOutbound = await response.json();
    dispatch(setNotificationFlagActionCreator({ [flagName]: false }));
    dispatch(setNotificationDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleNotificationError(defaultErrorMessage, await response.json()));
  }
};

// POST
export const fetchPostNotificationActionCreator: (
  fetchParams: NotificationInbound,
  getFetchParams: FetchGetNotificationParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, getFetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const successMessage = 'Successfully created new notification';
  const defaultErrorMessage = 'Issue creating new notification';
  const flagName = 'fetchPostNotificationFlag';
  // const dataName = `fetchPost${tableName.charAt(0).toUpperCase()}${tableName.slice(1)}Data`;
  dispatch(setNotificationFlagActionCreator({ [flagName]: true }));
  const response = await fetchPostNotification(fetchParams);
  if (response.ok) {
    const fetchGetNotificationParams:FetchGetNotificationParams = { ...getFetchParams };
    dispatch(fetchGetNotificationByAssetNameActionCreator(fetchGetNotificationParams, ShowSnackbar.False));
    dispatch(setNotificationFlagActionCreator({ [flagName]: false }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleNotificationError(defaultErrorMessage, await response.json()));
  }
};

// DELETE
export const fetchDeleteNotificationActionCreator: (
  fetchParams: FetchDeleteNotificationParams,
  getFetchParams: FetchGetNotificationParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, getFetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchDeleteNotificationFlag';
  const successMessage = 'Successfully deleted notification';
  const defaultErrorMessage = 'Issue while deleting notification';
  dispatch(setNotificationFlagActionCreator({ [flagName]: true }));
  const response = await fetchDeleteNotificationById(fetchParams);
  if (response.ok) {
    const fetchGetNotificationParams:FetchGetNotificationParams = { ...getFetchParams };
    dispatch(fetchGetNotificationByAssetNameActionCreator(fetchGetNotificationParams, ShowSnackbar.False));
    dispatch(setNotificationFlagActionCreator({ [flagName]: false }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleNotificationError(defaultErrorMessage, await response.json()));
  }
};
