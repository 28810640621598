import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { ISelectOption, ISelectProps, SelectType } from '../services/models/select-models.d';
import {
  CollectionDto, TagDto, CategoryDto, ValuationDto, ContractDto, RuleDto,
} from '../services/models/master-data-models.d';

function Select(props: ISelectProps) {
  const {
    title,
    options,
    value,
    disabled,
    useIdOrValue,
    error,
    onChange,
    width,
    id,
  } = props;

  return (
    <FormControl
      variant="standard"
      focused
      disabled={disabled}
      sx={{ width }}
    >
      <InputLabel id={`${id}-label`}>{title}</InputLabel>
      <MuiSelect
        labelId={id}
        id={id}
        value={value}
        disabled={disabled}
        onChange={onChange}
        label={title}
        error={error}
        color={error ? 'error' : 'primary'}
      >
        <MenuItem value="">
          <em>--Select--</em>
        </MenuItem>
        { options !== null && options !== undefined && options.length > 0 ? (
          options.map((option: ISelectOption | CollectionDto | TagDto | CategoryDto | ValuationDto | ContractDto | RuleDto) => (
            <MenuItem key={Object.values(option)[0]} value={useIdOrValue === SelectType.useId ? Object.values(option)[0] : Object.values(option)[1]}>
              {Object.values(option)[1]}
            </MenuItem>
          ))
        )
          : (
            <MenuItem value="">
              <em>
                No
                {' '}
                {title}
                s
              </em>
            </MenuItem>
          )}
      </MuiSelect>
    </FormControl>
  );
}

Select.defaultProps = {
  width: 150,
  id: 'defaultId',
};
export default Select;
