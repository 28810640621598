import { host, ontarioService } from '../defaults/api-defaults';
import { FetchPostAuthenticateParams } from '../models/config-models';
import { callServiceAsPromise } from './api';

// const host = 'http://localhost:8082';
// const host = 'https://ont-dev-dtc-platform-access-npd-dev.choreograph.com';
// const ontarioService = '/ontario-service';
// const service = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_SERVICE_NAME}`;

// Todo: Make authentication fetch use callServiceAsPromise so we can do proper error handling with it

// export const fetchGetAuthenticate:((body: BodyInit) => Promise<Response>) = (
//   body,
// ) => callService(`${host}${ontarioService}/authenticate`, 'POST', body, false);

// POST
export const fetchPostAuthenticate:((params: FetchPostAuthenticateParams) => Promise<Response>) = (
  params,
) => callServiceAsPromise(`${host}${ontarioService}/authenticate`, 'POST', JSON.stringify(params), false);

export const fetchGetConfigList:(() => Promise<Response>) = () => callServiceAsPromise(`${host}${ontarioService}/config/all`);
