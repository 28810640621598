import {
  ValuationDto, ContractDto, CollectionDto, TagDto, CategoryDto, RuleDto,
} from './master-data-models.d';

export enum SelectType {
  useId = 'useID',
  useValue = 'useValue',
}

export enum BooleanType {
  True = 'Y',
  False = 'N',
}

export enum ThresholdType {
  Percentage = 'Percentage',
  Count = 'Count',
}

export enum KeyType {
  Primary = 'PK',
  Foreign = 'FK',
  Unique = 'UK',
}

export enum StatusOption {
  Active = 'Active',
  Inactive = 'Inactive',
}

export const StatusOptions: ISelectOption[] = [
  { id: 0, value: 'Active' },
  { id: 1, value: 'Inactive' },
];

export const AssetStatusOptions: ISelectOption[] = [
  { id: 0, value: 'Active' },
  { id: 1, value: 'Inactive' },
  { id: 2, value: 'Draft' },
];

export const NotificationEventOptions: ISelectOption[] = [
  { id: 0, value: 'Created' },
  { id: 1, value: 'Modified' },
];

export const NotificationChannelOptions: ISelectOption[] = [
  { id: 0, value: 'API' },
  { id: 1, value: 'File' },
  { id: 2, value: 'MessageQueue' },
  { id: 3, value: 'Database' },
];

export enum NotificationChannel {
  API = 'API',
  File = 'File',
  MessageQueue = 'MessageQueue',
  Database = 'Database',
}

export const SubscriptionLevelOptions: ISelectOption[] = [
  { id: 0, value: 'Asset' },
  { id: 1, value: 'Collection' },
];

export const ResourceOptions: ISelectOption[] = [
  { id: 0, value: 'Ontario Portal' },
];

export const RefreshWindowOptions: ISelectOption[] = [
  { id: 0, value: 'Daily' },
  { id: 1, value: 'Weekly' },
  { id: 2, value: 'Monthly' },
];

export const CategoryOptions: ISelectOption[] = [
  { id: 0, value: 'Category 1' },
  { id: 1, value: 'Category 2' },
];

interface KeyOptions {
  id: number,
  value: string,
  code: string,
}
export const KeyOptionsList: KeyOptions[] = [
  { id: 0, value: 'Primary', code: 'PK' },
  { id: 1, value: 'Foreign', code: 'FK' },
  { id: 2, value: 'Unique', code: 'UK' },
];

export interface ISelectOption {
  id: number,
  value: string,
}

export interface ISelectProps {
  title: string,
  options: ISelectOption[] | CollectionDto[] | TagDto[] | CategoryDto[] | ValuationDto[] | ContractDto[] | RuleDto[] | DatasetDto[],
  value: string | number | undefined | null,
  disabled?: boolean,
  useIdOrValue: SelectType,
  error?: boolean,
  onChange?: (param: any) => void,
  width?: number,
  id?: string,
}
