import React, { useEffect } from 'react';
import {
  Card,
  Grid,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { StatusOptions, SelectType } from '../../../services/models/select-models.d';
import Select from '../../../ui-component/select';
import {
  MasterDataState, MasterDataTableNames, CollectionDto, FetchGetMasterDataParams, MasterDataPostType,
} from '../../../services/models/master-data-models.d';
import FormButtons from '../../../ui-component/form-buttons';
import { DEFAULT_MASTER_DATA_PAGINATION, DESELECTED_COLLECTION_TABLE_ROW, EMPTY_COLLECTION } from '../../../services/defaults/master-data-defaults';
import { StoreState } from '../../../store';
import {
  setMasterDataDataActionCreator, setMasterDataEditsActionCreator, setMasterDataSelectedTableRowActionCreator, fetchPostMasterDataActionCreator,
} from '../../../store/actions/master-data-actions';
import makeLocalAppearUTC from '../../../ui-component/date';
import useAuthUser from '../../../hooks/use-auth-user';

function CollectionForm() {
  const dispatch = useDispatch();
  const activeUser = useAuthUser();
  const itemWidth = '150px';

  const collectionData:MasterDataState['api']['data']['fetchGetCollectionData'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCollectionData,
  );

  const masterDataEdits:MasterDataState['masterDataEdits'] = useSelector(
    (state: StoreState) => state.masterDataReducer.masterDataEdits,
  );

  useEffect(() => {
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    fetchGetMasterDataParams.limit = 100;
  }, [dispatch]);

  // This useEffect handles UI changes, such as a user editing a field
  useEffect(() => {}, [masterDataEdits]);

  const handleFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newResponse:any = collectionData;
    const dataName = 'fetchGetCollectionData';
    const editCounterName = 'masterDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newResponse[field] = newValue;
    }
    dispatch(setMasterDataDataActionCreator({ [dataName]: newResponse }));
    dispatch(setMasterDataEditsActionCreator({ [editCounterName]: masterDataEdits + 1 }));
  };

  const onClear = () => {
    const fetchGetCollectionData = 'fetchGetCollectionData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetCollectionData]: { ...EMPTY_COLLECTION } }));
    const selectedTableName = 'selectedCollectionTableRow';
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_COLLECTION_TABLE_ROW } }));
  };

  const handlePostCollection = (postType:MasterDataPostType) => {
    const collection: CollectionDto = collectionData;
    // Fix for the backend is to add T00:00:00.000Z to the date
    // Also check if the date has been updated. If it has, it should match YYYY-MM-DD and get the concatenation
    if (dayjs(collection.collectionDate, 'YYYY-MM-DD', true).isValid()) {
      collection.collectionDate = collection.collectionDate.concat('T00:00:00.000Z');
    }
    if (typeof activeUser.email !== 'undefined') {
      collection.updatedUserId = activeUser.email;
      if (postType === MasterDataPostType.CREATE) {
        collection.createdUserId = activeUser.email;
      }
    }
    dispatch(fetchPostMasterDataActionCreator(collection, postType, MasterDataTableNames.COLLECTION));
    onClear();
  };

  const handleSubmit = async () => {
    let collectionId = -1;
    const collectionObj = collectionData;
    ({ collectionId } = collectionObj);
    if (collectionId === -1) {
      handlePostCollection(MasterDataPostType.CREATE);
    } else {
      handlePostCollection(MasterDataPostType.UPDATE);
    }
  };

  // let collectionId;
  let collectionName;
  let collectionDesc;
  let collectionSourceType;
  let collectionSourceName;
  let collectionSummary;
  let collectionOwner;
  let collectionAgencyName;
  let collectionDate;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;

  // const collectionObj = selectedCollectionTableRow.collectionList[0];

  if (typeof collectionData !== 'undefined'
    && collectionData !== null) {
    ({
      // collectionId,
      collectionName,
      collectionDesc,
      collectionSourceType,
      collectionSourceName,
      collectionSummary,
      collectionOwner,
      collectionAgencyName,
      collectionDate,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = collectionData);
  }

  return (
    <Grid item xs={12}>
      <Card sx={{
        p: 2, elevation: 0, width: '100%', height: '100%',
      }}
      >
        <Grid container>
          <FormButtons
            onSave={handleSubmit}
            onClear={onClear}
          />
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                id="collectionName"
                variant="standard"
                focused
                required
                label="Collection Name"
                value={typeof (collectionName) !== 'undefined' ? collectionName : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'collectionName')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                rows={3}
                variant="standard"
                focused
                required
                label="Collection Description"
                value={typeof (collectionDesc) !== 'undefined' ? collectionDesc : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'collectionDesc')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="Source Type"
                value={typeof (collectionSourceType) !== 'undefined' ? collectionSourceType : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'collectionSourceType')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                rows={3}
                variant="standard"
                focused
                required
                label="Source Name"
                value={typeof (collectionSourceName) !== 'undefined' ? collectionSourceName : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'collectionSourceName')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="Collection Summary"
                value={typeof (collectionSummary) !== 'undefined' ? collectionSummary : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'collectionSummary')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                rows={3}
                variant="standard"
                focused
                required
                label="Collection Owner"
                value={typeof (collectionOwner) !== 'undefined' ? collectionOwner : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'collectionOwner')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="Collection Agency"
                value={typeof (collectionAgencyName) !== 'undefined' ? collectionAgencyName : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'collectionAgencyName')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Collection Date"
                  value={typeof (collectionDate) !== 'undefined' ? makeLocalAppearUTC(collectionDate) : ''}
                  disablePast
                  onChange={(newValue:any) => {
                    handleFieldChange(dayjs(newValue.$d.toString()).format('YYYY-MM-DD'), 'collectionDate');
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      focused
                      required
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Status"
                value={typeof (status) !== 'undefined' ? status : ''}
                disabled={false}
                options={StatusOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(
                  event:SelectChangeEvent,
                ) => handleFieldChange(event.target.value, 'status')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default CollectionForm;
