export const BLANK_EXPLORER_DATA = {
  collectionsMap: {},
  dataSetMap: {},
  assetMap: {},
  assetExplorerRequest: {},
};

export const ExplorerDefaultState = {
  api: {
    flags: {
      fetchGetAssetExplorerFlag: false,
    },
    data: {
      fetchGetAssetExplorer: BLANK_EXPLORER_DATA,
    },
  },
};
