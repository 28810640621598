export function validateNumber(n: number | string | undefined | null) {
  return typeof n === 'undefined' || n === null || n < 0 || n === '';
}

export function validateAny(v: any) {
  return typeof v === 'undefined' || v === null || v < 0 || v === '';
}

export function validateString(s: string | undefined | null) {
  return typeof s !== 'string' || typeof s === 'undefined' || s === null || s.trim() === '';
}

// Unused, could be implemented later
export function validateStringNoBlanks(s: string | undefined | null) {
  const blanksExist = /\s/;
  return s === undefined || s === null || blanksExist.test(s);
}
