import React from 'react';
import { Grid } from '@mui/material';
import {
  WppAccordion,
} from '@platform-ui-kit/components-library-react';
import { AssetMultiStepGetResponse } from '../../../../../../services/models/asset-models.d';
import { IAttributeRuleFormErrors } from '../../../../../../services/models/validation-models.d';
import RuleFormUI from './rule-form-ui';

export interface RulesAccordionProps {
  assetData:AssetMultiStepGetResponse,
  validate:(accordion: number, formErrors: IAttributeRuleFormErrors) => void;
}

function RulesAccordion(props:RulesAccordionProps) {
  const {
    assetData,
    validate,
  } = props;

  // TODO Validation
  // const [formErrors, setFormErrors] = useState<IAttributeRuleFormErrors>({});
  // type IRuleFormErrorsObjectKey = keyof IRuleFormErrors;
  // const initNewRule = () => {
  //   const errors:IAttributeRuleFormErrors = { ...formErrors };
  //   errors[selectedAttrbtIndex][selectedRuleIndex].ruleName = true;
  //   errors[selectedAttrbtIndex][selectedRuleIndex].ruleExpression = true;
  //   errors[selectedAttrbtIndex][selectedRuleIndex].status = true;
  //   validate(StepAccordion.Rule, errors);
  //   setFormErrors(errors);
  // };

  // const onFieldChange = (isRequired:boolean, newValue:string | boolean | Date | RuleDto, field: string | null, attrbtId: number, ruleId: number) => {
  //   if (isRequired) {
  //     const errors:IAttributeRuleFormErrors = { ...formErrors };
  //     const f = field as IRuleFormErrorsObjectKey;
  //     errors[selectedAttrbtIndex][selectedRuleIndex][f] = validateAny(newValue);
  //     setFormErrors(errors);
  //     validate(StepAccordion.Rule, errors);
  //   }
  //   handleAttributeRuleFieldChange(newValue, field, attrbtId, ruleId);
  // };

  // const onCopy = () => null;

  return (
    <Grid container sx={{ ml: 2, mr: 2 }}>
      <WppAccordion text="Rules" expandedByDefault withDivider={false}>
        <RuleFormUI
          assetData={assetData}
          validate={validate}
        />
      </WppAccordion>
    </Grid>
  );
}
export default RulesAccordion;
