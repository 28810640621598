import React from 'react';

// WPP Open
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { GridCellParams, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import Cell from '../../../ui-component/cell';
import Table from '../../../ui-component/table';
import { AssetAttrbtDto, AssetMultiStepGetResponse, AttributeTableRow } from '../../../services/models/asset-models.d';
import { setAssetSelectedTableRowActionCreator } from '../../../store/actions/asset-actions';

export interface AttributeSummaryTableProps {
  assetData:AssetMultiStepGetResponse
}

// function AttributeSummaryTable(props:AttributeSummaryTableProp): JSX.Element {
const AttributeSummaryTable = function RenderAttributeSummaryTable(props:AttributeSummaryTableProps) {
  const { assetData } = props;

  const dispatch = useDispatch();

  let attributeRows:AssetAttrbtDto[] = [];

  if (typeof assetData.assetAttrbtList !== 'undefined' && assetData.assetAttrbtList !== null) {
    attributeRows = assetData.assetAttrbtList;
  }
  const columnFormat = [
    {
      field: 'assetAttrbtId', description: '', headerText: 'Asset Attribute Id', minWidth: 150,
    },
    {
      field: 'assetId', description: '', headerText: 'Asset Id', minWidth: 150,
    },
    {
      field: 'assetAttrbtName', description: '', headerText: 'Attribute Name', minWidth: 150,
    },
    {
      field: 'assetAttrbtDesc', description: '', headerText: 'Description', minWidth: 150,
    },
    {
      field: 'assetAttrbtKeyTypeCode', description: '', headerText: 'Key Type Code', minWidth: 150,
    },
    {
      field: 'dataType', description: '', headerText: 'Data Type', minWidth: 150,
    },
    {
      field: 'categoryId', description: '', headerText: 'Category Id', minWidth: 150,
    },
    {
      field: 'tagId', description: '', headerText: 'Tag Id', minWidth: 150,
    },
    {
      field: 'lineageId', description: '', headerText: 'Lineage Id', minWidth: 150,
    },
    {
      field: 'assetAttrbtSize', description: '', headerText: 'Size', minWidth: 150,
    },
    {
      field: 'assetAttrbtNullable', description: '', headerText: 'Nullable', minWidth: 150,
    },
    {
      field: 'assetAttrbtPrecision', description: '', headerText: 'Precision', minWidth: 150,
    },
    {
      field: 'assetAttrbtScale', description: '', headerText: 'Scale', minWidth: 150,
    },
    {
      field: 'colIndex', description: '', headerText: 'Column Index', minWidth: 150,
    },
    {
      field: 'colStartPos', description: '', headerText: 'Column Start Position', minWidth: 170,
    },
    {
      field: 'colEndPos', description: '', headerText: 'Column End Position', minWidth: 170,
    },
    {
      field: 'dupColFlg', description: '', headerText: 'Duplicate Column Flag', minWidth: 170,
    },
    {
      field: 'colLabel', description: '', headerText: 'Column Label', minWidth: 150,
    },
    {
      field: 'dateFormat', description: '', headerText: 'Date Format', minWidth: 150,
    },
    {
      field: 'timeFormat', description: '', headerText: 'Time Format', minWidth: 150,
    },
    {
      field: 'fieldType', description: '', headerText: 'Field Type', minWidth: 150,
    },
    {
      field: 'upperCaseExcludFlg', description: '', headerText: 'Uppercase Excluded Flag', minWidth: 200,
    },
    {
      field: 'selectiveKillfillFlg', description: '', headerText: 'Selective Kill Fill Flag', minWidth: 170,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150,
    },
    {
      field: 'createdUserId', description: '', headerText: 'Created User Id', minWidth: 150,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', description: '', headerText: 'Updated User Id', minWidth: 150,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'versionId', description: '', headerText: 'Version Id', minWidth: 150,
    },
  ];

  const filterModel = { items: [] };

  const attributeColumns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: false,
      filterable: false,
    };
    return columnFormatted;
  }));

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = attributeColumns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const tableName = 'selectedAttributeTableRow';
    const selectedAttributeTableRow:AttributeTableRow = Object.assign({}, ...rowDetails);
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: selectedAttributeTableRow }));
  };

  const columnVisibilityModel = {
    assetAttrbtId: false,
    assetId: false,
  };

  const onPageChange = () => null;

  const onPageSizeChange = () => null;

  // const onRefreshButtonClick = () => {
  //     return null;
  // };

  const onSortModelChange = () => null; // Not implemented

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={attributeColumns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={attributeRows.length > 100}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={attributeRows.length > 100 ? 1 : attributeRows.length}
        rowCount={attributeRows.length}
        rows={attributeRows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
};

export default AttributeSummaryTable;
