import React, {
  ReactNode,
  useState,
} from 'react';

// material-ui
import {
  Box,
  Grid,
} from '@mui/material';

// Choreograph OS
import {
  WppButton,
  WppIconSearch,
  WppIconCross,
  WppIconEdit,
  WppIconPlus,
  WppIconTrash,
  WppIconImport,
  WppTypography,
  WppTextInput,
  WppIconDocument,
  WppIconCopy,
  WppIconAvailableCheckmark,
} from '@platform-ui-kit/components-library-react';
import Snackbar from '../../../ui-component/snackbar';
import Tooltip from '../../../ui-component/tooltip';

export interface MainContentProps {
  title:string,
  buttonTitles: { addTitle: string, editTitle: string, deleteTitle: string, summaryTitle: string, importTitle: string, copyTitle: string }
  children: ReactNode;
  hideSearch?: boolean;
  addCallback?: () => void;
  editCallback?: () => void;
  deleteCallback?: () => void;
  summaryCallback?: () => void;
  importCallback?: () => void;
  copyCallback?: () => void;
  searchCallback?: (searchValue: string) => void;
  searchPlaceholder?: string;
  addDisabled?: boolean;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
  summaryDisabled?: boolean;
  importDisabled?: boolean;
  copyDisabled?: boolean;
  idPrefix?: string;

}

function MainContent(props:MainContentProps) {
  const [value, setValue] = useState<string>();
  const {
    children,
    title,
    buttonTitles,
    hideSearch,
    addCallback,
    editCallback,
    deleteCallback,
    summaryCallback,
    importCallback,
    copyCallback,
    searchCallback,
    searchPlaceholder,
    addDisabled,
    editDisabled,
    deleteDisabled,
    summaryDisabled,
    importDisabled,
    copyDisabled,
    idPrefix,
  } = props;

  const [deleteReadyToConfirm, setDeleteReadyToConfirm] = useState<boolean>(false);
  const snackbarWidth = '101%';

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
        <Grid
          container
          item
          xs={6}
          sx={{
            justifyContent: 'flex-start', flexDirection: 'column', mt: 3.5, ml: 2.5,
          }}
        >
          <Grid container item sx={{ mb: 1 }}>
            <WppTypography id={`${idPrefix}-title`} tag="h2" type="2xl-heading">
              {title}
            </WppTypography>
          </Grid>
          <Grid item sx={{ width: snackbarWidth, mt: 1 }}>
            <Snackbar />
          </Grid>
        </Grid>
        <Grid container item xs={5} spacing={1} sx={{ justifyContent: 'flex-end', m: 2 }}>
          {!hideSearch
            && (
            <Box sx={{ m: 1 }}>
              <WppTextInput
                id={`${idPrefix}-search-bar`}
                name="search"
                size="m"
                placeholder={searchPlaceholder}
                value={value}
                onWppChange={(e:CustomEvent) => {
                  setValue(e.detail.value);
                  if (typeof searchCallback !== 'undefined') {
                    searchCallback(e.detail.value);
                  }
                }}
              >
                <WppIconSearch slot="icon-start" aria-label="Search icon" onClick={() => null} />
                <WppIconCross
                  slot="icon-end"
                  aria-label="Clear icon"
                  onClick={() => {
                    setValue('');
                    if (typeof searchCallback !== 'undefined') {
                      searchCallback('');
                    }
                  }}
                />
              </WppTextInput>
            </Box>
            )}
          <Grid item sx={{ mt: 0.5 }}>
            <Tooltip disableHoverListener={typeof addDisabled !== 'undefined' && addDisabled} title={buttonTitles.addTitle}>
              <WppButton id={`${idPrefix}-add-button`} size="s" variant="secondary" disabled={addDisabled} onClick={addCallback}>
                <WppIconPlus slot="icon-start" />
              </WppButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 0.5 }}>
            <Tooltip disableHoverListener={typeof editDisabled !== 'undefined' && editDisabled} title={buttonTitles.editTitle}>
              <WppButton id={`${idPrefix}-edit-button`} size="s" variant="secondary" disabled={editDisabled} onClick={editCallback}>
                <WppIconEdit slot="icon-start" />
              </WppButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 0.5 }}>
            <Tooltip disableHoverListener={typeof deleteDisabled !== 'undefined' && deleteDisabled} title={buttonTitles.deleteTitle}>
              <WppButton
                id={`${idPrefix}-delete-button`}
                size="s"
                variant="secondary"
                disabled={deleteDisabled}
                onClick={() => {
                  if (deleteReadyToConfirm) {
                    if (typeof deleteCallback !== 'undefined') {
                      deleteCallback();
                    }
                    setDeleteReadyToConfirm(false);
                  } else {
                    setDeleteReadyToConfirm(true);
                  }
                }}
              >
                {deleteReadyToConfirm
                  ? <WppIconAvailableCheckmark slot="icon-start" />
                  : <WppIconTrash slot="icon-start" />}
              </WppButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 0.5 }}>
            <Tooltip disableHoverListener={typeof summaryDisabled !== 'undefined' && summaryDisabled} title={buttonTitles.summaryTitle}>
              <WppButton id={`${idPrefix}-summary-button`} size="s" variant="secondary" disabled={summaryDisabled} onClick={summaryCallback}>
                <WppIconDocument slot="icon-start" />
              </WppButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 0.5 }}>
            <Tooltip disableHoverListener={typeof copyDisabled !== 'undefined' && copyDisabled} title={buttonTitles.copyTitle}>
              <WppButton id={`${idPrefix}-copy-button`} size="s" variant="secondary" disabled={copyDisabled} onClick={copyCallback}>
                <WppIconCopy slot="icon-start" />
              </WppButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 0.5 }}>
            <Tooltip disableHoverListener={typeof importDisabled !== 'undefined' && importDisabled} title={buttonTitles.importTitle}>
              <WppButton id={`${idPrefix}-import-button`} size="s" variant="secondary" disabled={importDisabled} onClick={importCallback}>
                <WppIconImport slot="icon-start" />
              </WppButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container sx={{ flexDirection: 'column', m: 1 }}>
          <Grid item sx={{ m: 1, mr: 3 }}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MainContent.defaultProps = {
  hideSearch: false,
  addCallback: undefined,
  editCallback: undefined,
  deleteCallback: undefined,
  summaryCallback: undefined,
  importCallback: undefined,
  copyCallback: undefined,
  searchCallback: undefined,
  searchPlaceholder: undefined,
  addDisabled: false,
  editDisabled: false,
  deleteDisabled: false,
  summaryDisabled: false,
  importDisabled: false,
  copyDisabled: false,
  idPrefix: '',
};

export default MainContent;
