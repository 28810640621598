import React from 'react';
import {
  GridCellParams, GridRenderCellParams, GridSortModel, GridValueGetterParams,
} from '@mui/x-data-grid';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import Cell from '../../ui-component/cell';
import Table from '../../ui-component/table';
import {
  fetchGetUserByUsernameActionCreator, fetchGetUsersActionCreator, setUserDataActionCreator, setUserSelectedTableRowActionCreator,
} from '../../store/actions/user-actions';
import {
  FetchGetUserByUsernameParams,
  FetchGetUsersParams,
  UserMgmtResponse,
  UserState,
  UserTableRow,
} from '../../services/models/user-models.d';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { StoreState } from '../../store';
import { DEFAULT_USER_PAGINATION, EMPTY_USER_RESPONSE, OrderDirection } from '../../services/defaults/user-defaults';

function UserTable(props: any) {
  const { rows } = props;
  const dispatch = useDispatch();

  const fetchGetUserListFlag: UserState['api']['flags']['fetchGetUserListFlag'] = useSelector(
    (state: StoreState) => state.userReducer.api?.flags?.fetchGetUserListFlag,
  );
  const pagination: UserState['api']['data']['fetchGetUserList']['pagination'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetUserList?.pagination,
  );
  const listOrder: UserState['api']['data']['fetchGetUserList']['listOrder'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetUserList?.listOrder,
  );

  // let records = 0; // records returned on page, can be less than limit
  let page = 0; // current page index
  let limit = 0; // The page size, or max records displayed
  let totalRecords = 0; // Total number of records
  // let totalPages; // Total pages based on limit
  if (typeof pagination !== 'undefined') {
    ({
      // records,
      page,
      limit,
      totalRecords,
      // totalPages,
    } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  let searchKey = '';
  let searchValue = '';
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
      searchKey,
      searchValue,
    } = listOrder);
  }

  const selectedUserDetails:UserState['api']['data']['fetchGetUserByName'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetUserByName,
  );

  const columnFormat = [
    {
      field: 'userName', description: '', headerText: 'Username', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'firstName', description: '', headerText: 'First Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'midName', description: '', headerText: 'Middle Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'lastName', description: '', headerText: 'Last Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'email', description: '', headerText: 'Email', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'phone', description: '', headerText: 'Phone', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'lastLoginDatetime',
      description: '',
      headerText: 'Last Login Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'roleName', description: '', headerText: 'Role', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'accountName', description: '', headerText: 'Account Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    // {
    //   field: 'permissions', description: '', headerText: 'Permissions', minWidth: 150, valueGetter: undefined, sortable: true,
    // },
    // {
    //   field: 'resourceName', description: '', headerText: 'Resource Name', minWidth: 150, valueGetter: undefined, sortable: true,
    // },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'createdUserId', description: '', headerText: 'Created User ID', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', description: '', headerText: 'Updated User ID', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const columnVisibilityModel = {
    userId: false,
  };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params: GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: column.sortable,
      filterable: false,
    };
    return columnFormatted;
  }));

  const getSelectedUser = (selectedUserTableRow: UserTableRow) : UserMgmtResponse => {
    const user: UserMgmtResponse = { ...EMPTY_USER_RESPONSE };
    const fetchParams: FetchGetUserByUsernameParams = {
      userName: selectedUserTableRow.userName !== null ? selectedUserTableRow.userName : '',
    };
    dispatch(fetchGetUserByUsernameActionCreator(fetchParams, ShowSnackbar.False));
    if (typeof selectedUserDetails !== 'undefined' && selectedUserDetails !== null) {
      return selectedUserDetails;
    }
    return user;
  };

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const selectedTableName = 'selectedUserTableRow';
    const selectedUserTableRow:UserTableRow = Object.assign({}, ...rowDetails);
    dispatch(setUserSelectedTableRowActionCreator({ [selectedTableName]: selectedUserTableRow }));
    const fetchGetUserData = 'fetchGetUserData';
    dispatch(setUserDataActionCreator({ [fetchGetUserData]: getSelectedUser(selectedUserTableRow) }));
  };

  const onPageChange = (updatedPage: number) => {
    if (!fetchGetUserListFlag) {
      const fetchGetUsersParams: FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
      fetchGetUsersParams.page = updatedPage;
      fetchGetUsersParams.limit = limit;
      fetchGetUsersParams.sortByFieldName = sortByFieldName;
      fetchGetUsersParams.sortOrder = sortOrder;
      fetchGetUsersParams.searchKey = searchKey;
      fetchGetUsersParams.searchValue = searchValue;
      dispatch(fetchGetUsersActionCreator(fetchGetUsersParams, ShowSnackbar.True));
    }
  };

  const onPageSizeChange = (updatedPageSize: number) => {
    if (!fetchGetUserListFlag) {
      const fetchGetUsersParams: FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
      fetchGetUsersParams.page = 0;
      fetchGetUsersParams.limit = updatedPageSize;
      fetchGetUsersParams.sortByFieldName = sortByFieldName;
      fetchGetUsersParams.sortOrder = sortOrder;
      fetchGetUsersParams.searchKey = searchKey;
      fetchGetUsersParams.searchValue = searchValue;
      dispatch(fetchGetUsersActionCreator(fetchGetUsersParams, ShowSnackbar.True));
    }
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!fetchGetUserListFlag) {
      const fetchGetUsersParams: FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
      fetchGetUsersParams.page = page;
      fetchGetUsersParams.limit = limit;
      if (typeof model[0].field !== 'undefined') {
        fetchGetUsersParams.sortByFieldName = model[0].field;
      }
      if (typeof model[0].sort !== 'undefined') {
        fetchGetUsersParams.sortOrder = model[0].sort;
      }
      fetchGetUsersParams.searchKey = searchKey;
      fetchGetUsersParams.searchValue = searchValue;
      dispatch(fetchGetUsersActionCreator(fetchGetUsersParams, ShowSnackbar.True));
    }
  };

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={false}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={limit || 0}
        rowCount={totalRecords || 0}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
}

export default UserTable;
