import { GridSortModel } from '@mui/x-data-grid';
import { RuleCategory, RuleType } from '../models/config-models.d';
import {
  CategoryDto, CollectionDto, ContractDto, DatasetDto, RuleDto, SourceDto, TagDto, TargetDto, ValuationDto,
} from '../models/master-data-models.d';

// pagination defaults

export const DEFAULT_MASTER_DATA_GRID_SORT_ARRAY:GridSortModel = [
  {
    field: 'updatedDatetime',
    sort: 'desc',
  },
];

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const DEFAULT_MASTER_DATA_PAGINATION = {
  page: 0,
  limit: 10,
  sortByFieldName: 'updatedDatetime',
  sortOrder: OrderDirection.DESC,
  searchKey: '',
  searchValue: '',
};

// table defaults

export const DESELECTED_CATEGORY_TABLE_ROW = {
  categoryId: null,
  categoryName: null,
  categoryDesc: null,
  categoryParentId: null,
  categoryLevel: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DESELECTED_TAG_TABLE_ROW = {
  tagId: null,
  tagName: null,
  tagDesc: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DESELECTED_SOURCE_TABLE_ROW = {
  sourceId: null,
  sourceType: null,
  sourceName: null,
  sourceDesc: null,
  sourceDirectory: null,
  sourceTechnologyType: null,
  sourceConnection: null,
  authenticationKey: null,
  authenticationValue: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DESELECTED_TARGET_TABLE_ROW = {
  targetId: null,
  targetType: null,
  targetName: null,
  targetDesc: null,
  targetDirectory: null,
  targetTechnologyType: null,
  targetConnection: null,
  authenticationKey: null,
  authenticationValue: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DESELECTED_COLLECTION_TABLE_ROW = {
  collectionId: null,
  collectionName: null,
  collectionDesc: null,
  collectionSourceType: null,
  collectionSourceName: null,
  collectionSummary: null,
  collectionOwner: null,
  collectionAgencyName: null,
  collectionDate: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DESELECTED_CONTRACT_TABLE_ROW = {
  contractId: null,
  contractName: null,
  contractDesc: null,
  usageType: null,
  contractType: null,
  contractStartDt: null,
  contractEndDt: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DESELECTED_DATASET_TABLE_ROW = {
  datasetId: null,
  datasetName: null,
  datasetDesc: null,
  datasetCode: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DESELECTED_VALUATION_TABLE_ROW = {
  valuationId: null,
  valuationName: null,
  valuationDesc: null,
  valuationRecordCount: null,
  valuationFileFlg: null,
  valuationDays: null,
  valuationType: null,
  value: null,
  valuationStartDt: null,
  valuationEndDt: null,
  currency: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

export const DESELECTED_RULE_TABLE_ROW = {
  ruleId: null,
  ruleName: null,
  errorCode: null,
  accountId: null,
  ruleDesc: null,
  ruleCategory: null,
  ruleType: null,
  ruleExpression: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

// defaults for FrequencyResponse

export const EMPTY_CATEGORY = {
  categoryId: -1,
  categoryName: '',
  categoryDesc: '',
  categoryParentId: -1,
  categoryLevel: 0,
  status: '',
  createdUserId: 'ontuser',
  createdDatetime: '',
  updatedUserId: 'ontuser',
  updatedDatetime: '',
};

export const EMPTY_TAG = {
  tagId: -1,
  tagName: '',
  tagDesc: '',
  status: '',
  createdUserId: 'ontuser',
  createdDatetime: '',
  updatedUserId: 'ontuser',
  updatedDatetime: '',
};

export const DEFAULT_TAG = {
  tagId: -1,
  tagName: '',
  tagDesc: '',
  status: 'Active',
  createdUserId: 'ontuser',
  createdDatetime: '',
  updatedUserId: 'ontuser',
  updatedDatetime: '',
};

export const EMPTY_SOURCE = {
  sourceId: -1,
  sourceType: '',
  sourceName: '',
  sourceDesc: '',
  sourceDirectory: '',
  sourceTechnologyType: '',
  sourceConnection: '',
  authenticationKey: '',
  authenticationValue: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

export const EMPTY_TARGET = {
  targetId: -1,
  targetType: '',
  targetName: '',
  targetDesc: '',
  targetDirectory: '',
  targetTechnologyType: '',
  targetConnection: '',
  authenticationKey: '',
  authenticationValue: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

export const EMPTY_COLLECTION = {
  collectionId: -1,
  collectionName: '',
  collectionDesc: '',
  collectionSourceType: '',
  collectionSourceName: '',
  collectionSummary: '',
  collectionOwner: '',
  collectionAgencyName: '',
  collectionDate: '',
  status: '',
  createdUserId: 'ontuser',
  createdDatetime: '',
  updatedUserId: 'ontuser',
  updatedDatetime: '',
};

export const EMPTY_CONTRACT = {
  contractId: -1,
  contractName: '',
  contractDesc: '',
  usageType: '',
  contractType: '',
  contractStartDt: '',
  contractEndDt: '',
  status: '',
  createdUserId: 'ontuser',
  createdDatetime: '',
  updatedUserId: 'ontuser',
  updatedDatetime: '',
};

export const EMPTY_DATASET = {
  datasetId: -1,
  datasetName: '',
  datasetDesc: '',
  datasetCode: '',
  status: '',
  createdUserId: 'ontuser',
  createdDatetime: '',
  updatedUserId: 'ontuser',
  updatedDatetime: '',
};

export const EMPTY_VALUATION = {
  valuationId: -1,
  valuationName: '',
  valuationDesc: '',
  valuationRecordCount: -1,
  valuationFileFlg: '',
  valuationDays: -1,
  valuationType: '',
  value: -1,
  valuationStartDt: '',
  valuationEndDt: '',
  currency: 'USD',
  status: '',
  createdUserId: 'ontuser',
  createdDatetime: '',
  updatedUserId: 'ontuser',
  updatedDatetime: '',
};

export const EMPTY_RULE = {
  ruleId: -1,
  ruleName: '',
  errorCode: null,
  accountId: 1,
  ruleDesc: '',
  ruleCategory: RuleCategory.BASIC,
  ruleType: RuleType.COMPARE,
  ruleExpression: '',
  status: '',
  createdUserId: 'ontuser',
  createdDatetime: '',
  updatedUserId: 'ontuser',
  updatedDatetime: '',
};

export const EMPTY_CATEGORY_ARRAY:CategoryDto[] = [];
export const EMPTY_TAG_ARRAY:TagDto[] = [];
export const EMPTY_SOURCE_ARRAY:SourceDto[] = [];
export const EMPTY_TARGET_ARRAY:TargetDto[] = [];
export const EMPTY_COLLECTION_ARRAY:CollectionDto[] = [];
export const EMPTY_CONTRACT_ARRAY:ContractDto[] = [];
export const EMPTY_DATASET_ARRAY:DatasetDto[] = [];
export const EMPTY_VALUATION_ARRAY:ValuationDto[] = [];
export const EMPTY_RULE_ARRAY:RuleDto[] = [];
export const EMPTY_MASTER_CONFIG_ARRAY:string[] = [];

export const EMPTY_STATUS = {
  code: '',
  message: '',
  desc: '',
};

export const EMPTY_PAGINATION = {
  records: 0,
  page: 0,
  limit: 0,
  totalRecords: 0,
  totalPages: 0,
};

export const EMPTY_LIST_ORDER = {
  sortByFieldName: '',
  sortOrder: OrderDirection.DESC,
  searchKey: '',
  searchValue: '',
};

export const BLANK_CATEGORY_DATA = {
  category: { ...EMPTY_CATEGORY },
  categoryList: EMPTY_CATEGORY_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_TAG_DATA = {
  tag: { ...EMPTY_TAG },
  tagList: EMPTY_TAG_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_SOURCE_DATA = {
  source: { ...EMPTY_SOURCE },
  sourceList: EMPTY_SOURCE_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_TARGET_DATA = {
  target: { ...EMPTY_TARGET },
  targetList: EMPTY_TARGET_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_COLLECTION_DATA = {
  collection: { ...EMPTY_COLLECTION },
  collectionList: EMPTY_COLLECTION_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_CONTRACT_DATA = {
  contract: { ...EMPTY_CONTRACT },
  contractList: EMPTY_CONTRACT_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_DATASET_DATA = {
  dataset: { ...EMPTY_DATASET },
  datasetList: EMPTY_DATASET_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_VALUATION_DATA = {
  valuation: { ...EMPTY_VALUATION },
  valuationList: EMPTY_VALUATION_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_RULE_DATA = {
  rule: { ...EMPTY_RULE },
  ruleList: EMPTY_RULE_ARRAY,
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const BLANK_MASTER_CONFIG_DATA = {
  QUOTE: EMPTY_MASTER_CONFIG_ARRAY,
  NOTIFICATION: EMPTY_MASTER_CONFIG_ARRAY,
  DATATYPE: EMPTY_MASTER_CONFIG_ARRAY,
  RULE_CATEGORY: EMPTY_MASTER_CONFIG_ARRAY,
  RULE_TYPE: EMPTY_MASTER_CONFIG_ARRAY,
  CHARACTER: EMPTY_MASTER_CONFIG_ARRAY,
  DELIMITER: EMPTY_MASTER_CONFIG_ARRAY,
  ESCAPE: EMPTY_MASTER_CONFIG_ARRAY,
};

// May change this later but they return the same thing right now
export const BLANK_CATEGORY_LIST = { ...BLANK_CATEGORY_DATA };
export const BLANK_TAG_LIST = { ...BLANK_TAG_DATA };
export const BLANK_SOURCE_LIST = { ...BLANK_SOURCE_DATA };
export const BLANK_TARGET_LIST = { ...BLANK_TARGET_DATA };
export const BLANK_COLLECTION_LIST = { ...BLANK_COLLECTION_DATA };
export const BLANK_CONTRACT_LIST = { ...BLANK_CONTRACT_DATA };
export const BLANK_DATASET_LIST = { ...BLANK_DATASET_DATA };
export const BLANK_VALUATION_LIST = { ...BLANK_VALUATION_DATA };
export const BLANK_RULE_LIST = { ...BLANK_RULE_DATA };
