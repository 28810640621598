import React from 'react';
import {
  WppFloatingButton,
  WppIconLogout,
  WppTypography,
  // WppIconProfile,
} from '@platform-ui-kit/components-library-react';
import {
  Grid,
  Toolbar,
  AppBar,
  Box,
  CssBaseline,
} from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import useWindowDimensions from '../../../hooks/use-window-dimensions';
import Sidebar from '../Sidebar';
import Tooltip from '../../../ui-component/tooltip';
import useAuthUser from '../../../hooks/use-auth-user';

function Header() {
  const { width } = useWindowDimensions();
  const appBarHeight = 64;
  const appBarLeft = 225;
  const { oktaAuth } = useOktaAuth();
  const loggingOut = async () => oktaAuth.signOut();
  const activeUser = useAuthUser();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { given_name } = activeUser;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          bgcolor: 'background.paper', height: appBarHeight, left: appBarLeft, width: width - appBarLeft,
        }}
      >
        <Toolbar>
          <Grid container sx={{ m: 2, height: 30 }} justifyContent="space-between">
            <Grid item xs={3} />
            <Grid container item xs={9} justifyContent="flex-end">
              <Box sx={{ mt: 1, mr: 2 }}>
                {given_name === ''
                  ? <div />
                  : (
                    <WppTypography type="l-body">
                      Welcome,
                      {' '}
                      {given_name}
                    </WppTypography>
                  )}
              </Box>
              <Tooltip title="Log Out" disableHoverListener={false}>
                <WppFloatingButton onClick={() => loggingOut()}>
                  <WppIconLogout />
                </WppFloatingButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Sidebar />
    </Box>
  );
}

export default Header;
