import { ExplorerDefaultState } from '../../services/defaults/explorer-defaults';
import { ExplorerState, ExplorerActionOptions, ExplorerAction } from '../../services/models/explorer-models.d';

function ExplorerReducer(
  state:ExplorerState = ExplorerDefaultState,
  action:ExplorerAction,
): ExplorerState {
  switch (action.type) {
    case ExplorerActionOptions.SET_ASSET_API_FLAG:
      return {
        ...state,
        api: {
          ...state.api,
          data: {
            ...state.api.data,
            ...action.payload,
          },
        },
      };
    case ExplorerActionOptions.SET_ASSET_API_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          data: {
            ...state.api.data,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
}

export default ExplorerReducer;
