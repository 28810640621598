import { GridSortModel } from '@mui/x-data-grid';
import { RoleDto, UserDto, UserMgmtResourceDto } from '../models/user-models';

export const EMPTY_ERROR_ARRAY:string[] = [];
export const EMPTY_PERMISSION_ARRAY:string[] = [];
export const EMPTY_STATUS = {
  code: '',
  message: '',
  desc: '',
  errorList: [...EMPTY_ERROR_ARRAY],
};

export const EMPTY_USER = {
  userId: -1,
  userName: '',
  firstName: '',
  midName: '',
  lastName: '',
  email: '',
  phone: '',
  lastLoginDatetime: '',
  status: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
  roleName: '',
  accountName: '',
};

export const DESELECTED_USER_TABLE_ROW = {
  userId: null,
  userName: null,
  firstName: null,
  midName: null,
  lastName: null,
  email: null,
  phone: null,
  lastLoginDatetime: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
  roleName: null,
  accountName: null,
};

export const EMPTY_USER_MGMT_RESOURCE = {
  resourceName: '',
  permissions: [],
};

export const EMPTY_USER_MGMT_RESOURCE_ARRAY:UserMgmtResourceDto[] = [];
export const EMPTY_USER_MGMT_ROLE = {
  roleName: '',
  roleDesc: '',
  resources: [...EMPTY_USER_MGMT_RESOURCE_ARRAY],
};

export const EMPTY_USER_MGMT_REQUEST = {
  user: { ...EMPTY_USER },
  role: { ...EMPTY_USER_MGMT_ROLE },
};

export const EMPTY_ROLE = {
  roleId: -1,
  roleName: '',
  roleDesc: '',
  status: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

export const EMPTY_USER_ARRAY:UserDto[] = [];
export const EMPTY_ROLE_ARRAY:RoleDto[] = [];

export const EMPTY_PAGINATION = {
  records: 0,
  page: 0,
  limit: 0,
  totalRecords: 0,
  totalPages: 0,
};

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}
export const EMPTY_LIST_ORDER = {
  sortByFieldName: '',
  sortOrder: OrderDirection.DESC,
  searchKey: '',
  searchValue: '',
};

export const EMPTY_USER_RESPONSE = {
  user: { ...EMPTY_USER },
  role: { ...EMPTY_USER_MGMT_ROLE },
  status: { ...EMPTY_STATUS },
};

export const EMPTY_ROLE_RESPONSE = {
  roleList: [...EMPTY_ROLE_ARRAY],
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const EMPTY_USER_LIST_RESPONSE = {
  userList: [...EMPTY_USER_ARRAY],
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};

export const DEFAULT_USER_GRID_SORT_ARRAY:GridSortModel = [
  {
    field: 'updatedDatetime',
    sort: 'desc',
  },
];

export const DEFAULT_USER_PAGINATION = {
  page: 0,
  limit: 10,
  sortByFieldName: 'updatedDatetime',
  sortOrder: OrderDirection.DESC,
  searchKey: '',
  searchValue: '',
};
