import React, {
  useEffect,
  useState,
} from 'react';

// material-ui
import {
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CategoryTable from './category-table';
import CategoryForm from './category-form';
import MainContent from '../../../layout/MainLayout/MainContent';
import {
  FetchGetMasterDataParams, MasterDataTableNames, MasterDataState, CategoryDto, MasterDataPostType, OrderDirection,
} from '../../../services/models/master-data-models.d';
import { DEFAULT_MASTER_DATA_PAGINATION, DESELECTED_CATEGORY_TABLE_ROW, EMPTY_CATEGORY } from '../../../services/defaults/master-data-defaults';
import { StatusOption } from '../../../services/models/select-models.d';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { StoreState } from '../../../store';
import {
  fetchGetMasterDataActionCreator, fetchPostMasterDataActionCreator, setMasterDataDataActionCreator, setMasterDataSelectedTableRowActionCreator,
} from '../../../store/actions/master-data-actions';

function Category() {
  const dispatch = useDispatch();

  let categoryList:MasterDataState['api']['data']['fetchGetCategoryList']['categoryList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCategoryList?.categoryList,
  );

  const selectedCategoryTableRow:MasterDataState['tables']['selectedCategoryTableRow'] = useSelector(
    (state: StoreState) => state.masterDataReducer.tables?.selectedCategoryTableRow,
  );
  const fetchGetCategoryListFlag: MasterDataState['api']['flags']['fetchGetCategoryListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetCategoryListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetCategoryList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCategoryList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetCategoryList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCategoryList?.listOrder,
  );

  let limit = 0; // The page size, or max records displayed
  if (typeof pagination !== 'undefined') {
    ({ limit } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
    } = listOrder);
  }

  if (categoryList === null || typeof categoryList === 'undefined') {
    categoryList = [];
  }

  useEffect(() => {
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CATEGORY));
  }, [dispatch]);

  const tableHeight = '730px';
  const tableWidth = '101%';
  const [showCategoryUI, setShowCategoryUI] = useState<boolean>(false);

  const getSelectedCategory = () : CategoryDto => {
    const categoryDto: CategoryDto = { ...EMPTY_CATEGORY };
    categoryDto.categoryId = selectedCategoryTableRow.categoryId !== null ? selectedCategoryTableRow.categoryId : -1;
    categoryDto.categoryName = selectedCategoryTableRow.categoryName !== null ? selectedCategoryTableRow.categoryName : '';
    categoryDto.categoryDesc = selectedCategoryTableRow.categoryDesc !== null ? selectedCategoryTableRow.categoryDesc : '';
    categoryDto.categoryParentId = selectedCategoryTableRow.categoryParentId !== null ? selectedCategoryTableRow.categoryParentId : -1;
    categoryDto.categoryLevel = selectedCategoryTableRow.categoryLevel !== null ? selectedCategoryTableRow.categoryLevel : -1;
    categoryDto.status = selectedCategoryTableRow.status !== null ? selectedCategoryTableRow.status : '';
    categoryDto.createdUserId = selectedCategoryTableRow.createdUserId !== null ? selectedCategoryTableRow.createdUserId : '';
    categoryDto.createdDatetime = selectedCategoryTableRow.createdDatetime !== null ? selectedCategoryTableRow.createdDatetime : '';
    categoryDto.updatedUserId = selectedCategoryTableRow.updatedUserId !== null ? selectedCategoryTableRow.updatedUserId : '';
    categoryDto.updatedDatetime = selectedCategoryTableRow.updatedDatetime !== null ? selectedCategoryTableRow.updatedDatetime : '';
    return categoryDto;
  };

  // This currently sets status to inactive
  const handleDelete = async () => {
    const categoryDto: CategoryDto = getSelectedCategory();
    if (categoryDto.categoryId !== -1) {
      categoryDto.status = StatusOption.Inactive;
      dispatch(fetchPostMasterDataActionCreator(categoryDto, MasterDataPostType.DELETE, MasterDataTableNames.CATEGORY));
      // const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      // dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CATEGORY));
      setShowCategoryUI(false);
      const fetchGetCategoryData = 'fetchGetCategoryData';
      dispatch(setMasterDataDataActionCreator({ [fetchGetCategoryData]: { ...EMPTY_CATEGORY } }));
      const selectedTableName = 'selectedCategoryTableRow';
      dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_CATEGORY_TABLE_ROW } }));
    }
  };

  const handleSearchCategory = (newSearchValue: string) => {
    if (!fetchGetCategoryListFlag) {
      const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = 'categoryName';
      fetchGetMasterDataParams.searchValue = newSearchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CATEGORY, ShowSnackbar.True));
    }
  };

  return (
    <MainContent
      idPrefix="category"
      title="Category"
      buttonTitles={{
        addTitle: 'Add Category',
        editTitle: 'Edit Category',
        deleteTitle: 'Delete Category',
        summaryTitle: 'Category Summary',
        importTitle: 'Import Category',
        copyTitle: 'Copy Category',
      }}
      addCallback={() => {
        const fetchGetCategoryData = 'fetchGetCategoryData';
        const categoryDto: CategoryDto = { ...EMPTY_CATEGORY };
        dispatch(setMasterDataDataActionCreator({ [fetchGetCategoryData]: categoryDto }));
        setShowCategoryUI(!showCategoryUI);
      }}
      editCallback={() => {
        setShowCategoryUI(!showCategoryUI);
      }}
      deleteCallback={() => handleDelete()}
      summaryCallback={() => null} // TODO
      importCallback={() => null} // TODO
      copyCallback={() => null} // TODO
      searchCallback={(newSearchValue) => handleSearchCategory(newSearchValue)}
      searchPlaceholder="Search Categories"
      editDisabled={selectedCategoryTableRow?.categoryId === null}
      deleteDisabled={selectedCategoryTableRow?.categoryId === null}
      copyDisabled
      summaryDisabled
      importDisabled
    >
      <Grid
        container
        item
        sx={{
          width: tableWidth, height: tableHeight, justifyContent: 'space-between',
        }}
      >
        <Grid item xs={showCategoryUI ? 8 : 12}>
          <CategoryTable rows={categoryList} />
        </Grid>
        {showCategoryUI
          ? (
            <Grid container item xs={4} sx={{ pl: 2.5 }}>
              <CategoryForm />
            </Grid>
          )
          : <Grid item />}
      </Grid>
    </MainContent>
  );
}

export default Category;
