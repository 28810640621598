import { host, ontarioService, catalogRoute } from '../defaults/api-defaults';
import { FetchGetCatalogDetails } from '../models/data-catalog-models.d';
import { callServiceAsPromise } from './api';

// GET
export const fetchGetCatalogDetails:((params: FetchGetCatalogDetails) => Promise<Response>) = (params) => {
  const {
    accountCode,
    organization,
    collection,
    datasetName,
    assetName,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${catalogRoute}/getCatalog?accountCode=${accountCode}&organization=${organization}&collection=${collection}&datasetName=${datasetName}&assetName=${assetName}`);
  // return callServiceAsPromise(`${host}${ontarioService}${catalogRoute}/getCatalog?assetName=${assetName}`);
};

// GET
export const fetchGetCatalogMetrics:((params: FetchGetCatalogDetails) => Promise<Response>) = (params) => {
  const {
    accountCode,
    organization,
    collection,
    datasetName,
    assetName,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${catalogRoute}/getMetrics?accountCode=${accountCode}&organization=${organization}&collection=${collection}&datasetName=${datasetName}&assetName=${assetName}`);
};
