const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

const makeLocalAppearUTC = (value: string | number | Date | undefined) => {
  let utcFromLocal = null;
  if (typeof value !== 'undefined') {
    const dateTime = new Date(value);
    utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
  }
  return utcFromLocal;
};

export default makeLocalAppearUTC;
