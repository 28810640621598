import { GridSortModel } from '@mui/x-data-grid';
import {
  fetchGetAssets, fetchGetAssetById, fetchDeleteAssetById, fetchPostAsset, fetchPutAsset, fetchGetCopyAsset,
} from '../../services/api/asset-service-api';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import {
  AssetAction,
  AssetActionOptions,
  AssetError,
  AssetListResponse,
  AssetMultiStepGetResponse,
  AssetMultiStepPostRequest,
  AssetMultiStepPostResponse,
  AssetMultiStepPutRequest,
  Count,
  Data,
  FetchDeleteAssetByIdParams,
  FetchGetAssetByIdParams,
  FetchGetAssetsParams,
  FetchGetCopyAssetParams,
  Flag,
  SelectedRow,
} from '../../services/models/asset-models.d';
import { setSnackbarActionCreator, snackbarMaker } from './snackbar-actions';
import { DEFAULT_ASSET_PAGINATION } from '../../services/defaults/asset-defaults';

export const setAssetFlagActionCreator: (update: Flag) => AssetAction = (update: Flag) => ({
  type: AssetActionOptions.SET_ASSET_API_FLAG,
  payload: update,
});

export const setAssetDataActionCreator: (update: Data) => AssetAction = (update: Data) => ({
  type: AssetActionOptions.SET_ASSET_API_DATA,
  payload: update,
});

export const setAssetCreationFormDataActionCreator: (update: Data) => AssetAction = (update: Data) => ({
  type: AssetActionOptions.SET_ASSET_CREATION_FORM_DATA,
  payload: update,
});

export const setFormDataActionCreator: (update: Data) => AssetAction = (update: Data) => ({
  type: AssetActionOptions.SET_FORM_DATA,
  payload: update,
});

export const setAssetSortModelActionCreator: (update: GridSortModel) => AssetAction = (update: GridSortModel) => ({
  type: AssetActionOptions.SET_ASSET_SORT_MODEL,
  payload: update,
});

export const setAssetSelectedTableRowActionCreator: (update: SelectedRow) => AssetAction = (update: SelectedRow) => ({
  type: AssetActionOptions.SET_ASSET_SELECTED_TABLE_ROW,
  payload: update,
});

export const setAssetDataEditsActionCreator: (update: Count) => AssetAction = (update: Count) => ({
  type: AssetActionOptions.SET_ASSET_DATA_EDITS,
  payload: update,
});

export const handleAssetError:(errorMessage: string, error: AssetError) => any = (defaultErrorMessage, error) => async (
  dispatch:(action:any) => any,
) => {
  let errorMessage = defaultErrorMessage;
  if (typeof error?.status?.errorList === 'undefined') {
    errorMessage = 'An internal server error occurred. Please contact an administrator.';
    dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
  } else {
    if (typeof error?.status?.errorList[0] !== 'undefined') {
      const [primaryError] = error.status.errorList;
      errorMessage = primaryError;
    }
    dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
  }
};

// GET

// get all assets
export const fetchGetAssetsActionCreator: (
  fetchParams: FetchGetAssetsParams,
  showSnackbar: ShowSnackbar
) => any = (fetchParams, showSnackbar) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchGetAssetListFlag';
  const dataName = 'fetchGetAssetList';
  const successMessage = 'Fetched asset data successfully';
  const defaultErrorMessage = 'Issue fetching asset data';
  dispatch(setAssetFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetAssets(fetchParams);
  if (response.ok) {
    const fetchedData:AssetListResponse = await response.json();
    dispatch(setAssetFlagActionCreator({ [flagName]: false }));
    dispatch(setAssetDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleAssetError(defaultErrorMessage, await response.json()));
  }
};

// get asset by ID
export const fetchGetAssetByIdActionCreator: (
  fetchParams: FetchGetAssetByIdParams,
  isForLookupRule: boolean,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, isForLookupRule, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  // const { id } = fetchParams;
  const flagName = 'fetchGetAssetByIdFlag';
  const dataName = isForLookupRule ? 'fetchGetAssetByIdForLookupRules' : 'fetchGetAssetById';
  const successMessage = 'Fetched data for the selected asset';
  const defaultErrorMessage = 'Issue fetching data for the selected asset';
  dispatch(setAssetFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetAssetById(fetchParams);
  if (response.ok) {
    const fetchedData:AssetMultiStepGetResponse = await response.json();
    dispatch(setAssetFlagActionCreator({ [flagName]: false }));
    dispatch(setAssetDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleAssetError(defaultErrorMessage, await response.json()));
  }
};

// get copy asset by ID
export const fetchGetCopyAssetActionCreator: (
  fetchParams: FetchGetCopyAssetParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  // const { id } = fetchParams;
  const flagName = 'fetchGetAssetByIdFlag';
  const dataName = 'fetchGetAssetById';
  const successMessage = 'Copy asset created for selected asset';
  const defaultErrorMessage = 'Issue fetching copy asset data for the selected asset';
  dispatch(setAssetFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetCopyAsset(fetchParams);
  if (response.ok) {
    const fetchedData:AssetMultiStepGetResponse = await response.json();
    dispatch(setAssetFlagActionCreator({ [flagName]: false }));
    dispatch(setAssetDataActionCreator({ [dataName]: fetchedData }));
    // TO DO : Remove get all asset
    const fetchGetAssetsParams:FetchGetAssetsParams = { ...DEFAULT_ASSET_PAGINATION };
    dispatch(fetchGetAssetsActionCreator(fetchGetAssetsParams, ShowSnackbar.True));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleAssetError(defaultErrorMessage, await response.json()));
  }
};

// PUT
export const fetchPutAssetActionCreator: (
  fetchParams: AssetMultiStepPutRequest,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const { asset } = fetchParams;
  // const { assetName } = asset;
  const flagName = 'fetchPutAssetDataFlag';
  const successMessage = 'Successfully updated the asset';
  const defaultErrorMessage = 'Issue updating the asset';
  dispatch(setAssetFlagActionCreator({ [flagName]: true }));
  const response = await fetchPutAsset(fetchParams);
  if (response.ok) {
    const fetchGetAssetByIdParams: FetchGetAssetByIdParams = {
      id: asset.assetId,
      requestedEntities: [],
    };
    dispatch(fetchGetAssetByIdActionCreator(fetchGetAssetByIdParams, false, ShowSnackbar.False));
    dispatch(setAssetFlagActionCreator({ [flagName]: false }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleAssetError(defaultErrorMessage, await response.json()));
  }
};

// POST
export const fetchPostAssetActionCreator: (
  fetchParams: AssetMultiStepPostRequest,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchPostAssetDataFlag';
  let successMessage = 'Successfully created new asset';
  const defaultErrorMessage = 'Issue creating new asset';
  dispatch(setAssetFlagActionCreator({ [flagName]: true }));
  const response = await fetchPostAsset(fetchParams);
  if (response.ok) {
    const fetchedData:AssetMultiStepPostResponse = await response.json();
    const fetchGetAssetByIdParams: FetchGetAssetByIdParams = {
      id: fetchedData.assetId,
      requestedEntities: [],
    };
    dispatch(fetchGetAssetByIdActionCreator(fetchGetAssetByIdParams, false, ShowSnackbar.False));
    dispatch(setAssetFlagActionCreator({ [flagName]: false }));
    successMessage = `Successfully created new asset of ID "${fetchedData.assetId}"`;
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleAssetError(defaultErrorMessage, await response.json()));
  }
};

// DELETE
export const fetchDeleteAssetsByIdActionCreator: (
  fetchParams: FetchDeleteAssetByIdParams,
  assetName: string,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, assetName, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchDeleteAssetDataFlag';
  const successMessage = `Deleted asset "${assetName}" successfully (the status is now inactive)`;
  const defaultErrorMessage = 'Issue deleting asset data';
  dispatch(setAssetFlagActionCreator({ [flagName]: true }));
  const response = await fetchDeleteAssetById(fetchParams);
  if (response.ok) {
    dispatch(setAssetFlagActionCreator({ [flagName]: false }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleAssetError(defaultErrorMessage, await response.json()));
  }
};
