export interface NotificationState {
  api: {
    flags: {
      fetchPostNotificationFlag: boolean,
      fetchDeleteNotificationFlag: boolean,
      fetchGetNotificationByAssetNameFlag: boolean,
    },
    data: {
      fetchGetNotificationByAssetName: NotificationOutbound,
    },
  },
  formData: {
    notificationFormData: NotificationInbound,
  },
  notificationEdits: number,
  tables: {
    selectedNotificationTableRow: NotificationTableRow,
  },
  sortModel: GridSortModel,
}

export enum NotificationActionOptions {
  SET_NOTIFICATION_API_FLAG = 'SET_NOTIFICATION_API_FLAG',
  SET_NOTIFICATION_API_DATA = 'SET_NOTIFICATION_API_DATA',
  SET_NOTIFICATION_SELECTED_TABLE_ROW = 'SET_NOTIFICATION_SELECTED_TABLE_ROW',
  SET_NOTIFICATION_SORT_MODEL = 'SET_NOTIFICATION_SORT_MODEL',
  SET_NOTIFICATION_DATA_EDITS = 'SET_NOTIFICATION_DATA_EDITS',
  SET_FORM_DATA = 'SET_FORM_DATA',
}

export interface Flag {
  [key: string]: boolean;
}

export interface Data {
  [key: string]: any
}

export interface Count {
  [key: string]: number
}

export interface SelectedRow {
  [key: string]: NotificationTableRow
}

export interface NotificationAction {
  type: NotificationActionOptions,
  payload: Flag |
  Data |
  Count |
  NotificationTableRow
}

export interface FetchGetNotificationParams {
  assetName: string,
  accountCode: string,
}

export interface FetchDeleteNotificationParams {
  transmissionId: number,
}

export interface NotificationInbound {
  transmissionId: number,
  subscriptionId: number,
  notificationId: number,
  accountCode: string,
  assetName: string,
  collectionName: string,
  notificationEvent: string,
  notificationLevel: string,
  subscriptionCategory: string,
  subscriptionName: string,
  subscriptionDesc: string,
  subscribedData: string,
  notificationName: string,
  notificationDesc: string,
  notificationCategory: string,
  notificationValue: string,
  notificationLabel: string,
  notificationContent: string,
  notificationRecipient: string,
  resendLimit: number,
  refreshWindow: string,
  additionalNotificationParams: { [key: string]: string } | null,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface NotificationOutbound {
  accountCode: string,
  collection: string,
  assets: string[],
  subscriber: string,
  event: string,
  channelType: string,
  channelDetails: string,
  notificationLevel: string,
  refreshWindow: string,
  message: string,
  date: string,
  notificationSubscriptionList: NotificationSubscription[],
  additionalNotificationParams: { [key: string]: string } | null,
  status: Status,
}

export interface NotificationSubscription {
  transmissionId: number,
  subscriptionId: number,
  notificationId: number,
  accountCode: string,
  assetName: string,
  collectionName: string,
  notificationEvent: string,
  notificationLevel: string,
  subscriptionCategory: string,
  subscriptionName: string,
  subscriptionDesc: string,
  subscribedData: string,
  notificationName: string,
  notificationDesc: string,
  notificationCategory: string,
  notificationValue: string,
  notificationLabel: string,
  notificationContent: string,
  notificationRecipient: string,
  resendLimit: number,
  refreshWindow: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface NotificationTableRow {
  transmissionId: number | null,
  subscriptionId: number | null,
  notificationId: number | null,
  accountCode: string | null,
  assetName: string | null,
  collectionName: string | null,
  notificationEvent: string | null,
  notificationLevel: string | null,
  subscriptionCategory: string | null,
  subscriptionName: string | null,
  subscriptionDesc: string | null,
  subscribedData: string | null,
  notificationName: string | null,
  notificationDesc: string | null,
  notificationCategory: string | null,
  notificationValue: string | null,
  notificationLabel: string | null,
  notificationContent: string | null,
  notificationRecipient: string | null,
  resendLimit: number | null,
  refreshWindow: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface NotificationError {
  // assetId: number,
  status: ErrorStatus
}

export interface Status {
  code: string,
  message: string
  desc: string | null,
  errorList: string[]
}
