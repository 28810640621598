export enum IAssetFormErrorReadableName {
  // Asset
  assetName = 'Asset Name',
  assetDesc = 'Asset Description',
  status = 'Status',
  collectionId = 'Select Collection',
  categoryId = 'Select Category',
  tagId = 'Select Tag',
  // Schema
  filePattern = 'File Pattern',
  filePath = 'File Path',
  // Valuation
  valuationId = 'Select Valuation',
  valuationName = 'Valuation Name',
  valuationType = 'Valuation Type',
  value = 'Valuation Value',
  valuationStartDt = 'Valuation Start Date',
  valuationEndDt = 'Valuation End Date',
  // Contract
  contractId = 'Select Contract',
  contractName = 'Contract Name',
  contractType = 'Contract Type',
  contractStartDt = 'Contract Start Date',
  contractEndDt = 'Contract End Date',
  // Attribute
  assetAttrbtId = 'Select Attribute',
  assetAttrbtName = 'Attribute Name',
  assetAttrbtDesc = 'Attribute Description',
  dataType = 'Attribute Data Type',
  // tagId = 'Select Tag', // repeat
  assetAttrbtNullable = 'Attribute Nullable',
  // status = 'Asset Name', // status
  // Rule
  // assetAttrbtId = 'Asset Name', // Repeat
  ruleId = 'Select Rule',
  ruleName = 'Rule Name',
  ruleCategory = 'Rule Category',
  ruleExpression = 'Rule Expression',
  ruleType = 'Rule Type',
  // status = 'Asset Name', // Repeat
  datasetId = 'Select Dataset',
}

export interface IAssetFormErrors {
  assetName: boolean,
  assetDesc: boolean,
  status: boolean,
  collectionId: boolean,
  categoryId: boolean,
  tagId: boolean,
  datasetId?: boolean,
}

export interface ISchemaFormErrors {
  filePattern: boolean,
  filePath: boolean,
}

export interface IValuationFormErrors {
  // valuationId: boolean,
  valuationName: boolean,
  valuationType: boolean,
  value: boolean,
  valuationStartDt: boolean,
  valuationEndDt: boolean,
  currency: boolean,
}

export interface IContractFormErrors {
  // contractId: boolean,
  contractName: boolean,
  contractType: boolean,
  contractStartDt: boolean,
  contractEndDt: boolean,
}

export interface IAttributeFormErrors {
  assetAttrbtId: boolean,
  assetAttrbtName: boolean,
  assetAttrbtDesc: boolean,
  dataType: boolean,
  tagId: boolean,
  assetAttrbtNullable: boolean,
  status: boolean,
}

export interface IRuleFormErrors {
  assetAttrbtId: boolean,
  ruleId: boolean,
  ruleName: boolean,
  ruleCategory: boolean,
  ruleExpression: boolean,
  ruleType: boolean,
  status: boolean,
}

export interface IAttributeRuleFormErrors {
  [key:number]: IRuleFormErrors[]
}

export interface IAssetMultiStepFormErrors {
  asset: IAssetFormErrors | null,
  schema: ISchemaFormErrors | null,
  valuation: IValuationFormErrors | null,
  contract: IContractFormErrors | null,
  attributes: IAttributeFormErrors[] | null,
  rules: IAttributeRuleFormErrors | null,
}

export const DefaultAttributeFormError = {
  assetAttrbtId: false,
  assetAttrbtName: false,
  assetAttrbtDesc: false,
  dataType: false,
  tagId: false,
  assetAttrbtNullable: false,
  status: false,
};

export const DefaultRuleFormError = {
  assetAttrbtId: false,
  ruleId: false,
  ruleName: false,
  ruleCategory: false,
  ruleExpression: false,
  ruleType: false,
  status: false,
};

export const DefaultFormError = {
  asset: {
    assetName: false,
    assetDesc: false,
    assetCite: false,
    assetType: false,
    assetOwner: false,
    assetOrigin: false,
    status: false,
    collectionId: false,
    categoryId: false,
    tagId: false,
  },
  schema: {
    filePath: false,
    filePattern: false,
  },
  valuation: {
    valuationId: false,
    valuationName: false,
    valuationDesc: false,
    valuationRecordCount: false,
    valuationFileFlg: false,
    valuationDays: false,
    valuationType: false,
    value: false,
    valuationStartDt: false,
    valuationEndDt: false,
    currency: false,
  },
  contract: {
    contractId: false,
    contractName: false,
    contractDesc: false,
    usageType: false,
    contractType: false,
    contractStartDt: false,
    contractEndDt: false,
  },
  attributes: [],
  rules: {},
};
