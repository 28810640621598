import { GridSortModel } from '@mui/x-data-grid';
import {
  fetchDeleteUser, fetchGetRoleResourcePermission, fetchGetRoles, fetchGetUserByUserName, fetchGetUsers, fetchPostUser, fetchPutUser,
} from '../../services/api/user-service-api';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import {
  Count,
  Data, FetchDeleteUserParams, FetchGetRoleResourcePermissionParams, FetchGetRolesParams, FetchGetUserByUsernameParams, FetchGetUsersParams, Flag, RoleResponse, SelectedRow, UserAction, UserActionOptions, UserError, UserListResponse, UserMgmtRequest, UserMgmtResponse,
} from '../../services/models/user-models.d';
import { setSnackbarActionCreator, snackbarMaker } from './snackbar-actions';

export const setUserFlagActionCreator: (update: Flag) => UserAction = (update: Flag) => ({
  type: UserActionOptions.SET_USER_API_FLAG,
  payload: update,
});

export const setUserDataActionCreator: (update: Data) => UserAction = (update: Data) => ({
  type: UserActionOptions.SET_USER_API_DATA,
  payload: update,
});

export const setFormDataActionCreator: (update: Data) => UserAction = (update: Data) => ({
  type: UserActionOptions.SET_FORM_DATA,
  payload: update,
});

export const setUserSortModelActionCreator: (update: GridSortModel) => UserAction = (update: GridSortModel) => ({
  type: UserActionOptions.SET_USER_SORT_MODEL,
  payload: update,
});

export const setUserSelectedTableRowActionCreator: (update: SelectedRow) => UserAction = (update: SelectedRow) => ({
  type: UserActionOptions.SET_USER_SELECTED_TABLE_ROW,
  payload: update,
});

export const setUserEditsActionCreator: (update: Count) => UserAction = (update: Count) => ({
  type: UserActionOptions.SET_USER_DATA_EDITS,
  payload: update,
});

export const handleUserError:(errorMessage: string, error: UserError) => any = (defaultErrorMessage, error) => async (
  dispatch:(action:any) => any,
) => {
  let errorMessage = defaultErrorMessage;
  if (typeof error?.status?.errorList[0] !== 'undefined') {
    const [primaryError] = error.status.errorList;
    errorMessage = primaryError;
  }
  dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
};

// GET
export const fetchGetUsersActionCreator: (
  fetchParams: FetchGetUsersParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchGetUserListFlag';
  const dataName = 'fetchGetUserList';
  const successMessage = 'Fetched user data successfully';
  const defaultErrorMessage = 'Issue fetching user data';
  dispatch(setUserFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetUsers(fetchParams);
  if (response.ok) {
    const fetchedData:UserListResponse = await response.json();
    dispatch(setUserFlagActionCreator({ [flagName]: false }));
    dispatch(setUserDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleUserError(defaultErrorMessage, await response.json()));
  }
};

// GET
export const fetchGetRolesActionCreator: (
  fetchParams: FetchGetRolesParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchGetRoleListFlag';
  const dataName = 'fetchGetRoleList';
  const successMessage = 'Fetched role list';
  const defaultErrorMessage = 'Issue fetching role data';
  dispatch(setUserFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetRoles(fetchParams);
  if (response.ok) {
    const fetchedData:RoleResponse = await response.json();
    dispatch(setUserFlagActionCreator({ [flagName]: false }));
    dispatch(setUserDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleUserError(defaultErrorMessage, await response.json()));
  }
};

// GET
export const fetchGetUserByUsernameActionCreator: (
  fetchParams: FetchGetUserByUsernameParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchGetUserByNameFlag';
  const dataName = 'fetchGetUserByName';
  const successMessage = 'Fetched user successfully';
  const defaultErrorMessage = 'Issue fetching user data';
  dispatch(setUserFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetUserByUserName(fetchParams);
  if (response.ok) {
    const fetchedData:UserMgmtResponse = await response.json();
    dispatch(setUserFlagActionCreator({ [flagName]: false }));
    dispatch(setUserDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleUserError(defaultErrorMessage, await response.json()));
  }
};

// GET
export const fetchGetRoleResourcePermissionActionCreator: (
  fetchParams: FetchGetRoleResourcePermissionParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchGetRoleResourcePermissionFlag';
  const dataName = 'fetchGetRoleResourcePermission';
  const successMessage = 'Fetched permission successfully';
  const defaultErrorMessage = 'Issue fetching permission data';
  dispatch(setUserFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetRoleResourcePermission(fetchParams);
  if (response.ok) {
    const fetchedData:UserMgmtResponse = await response.json();
    dispatch(setUserFlagActionCreator({ [flagName]: false }));
    dispatch(setUserDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleUserError(defaultErrorMessage, await response.json()));
  }
};

// POST
export const fetchPostUserActionCreator: (
  fetchParams: UserMgmtRequest,
  fetchGetParams: FetchGetUsersParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, fetchGetParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
) => {
  const successMessage = 'Successfully created new user';
  const defaultErrorMessage = 'Issue creating new user';
  const flagName = 'fetchPostUserFlag';
  dispatch(setUserFlagActionCreator({ [flagName]: true }));
  const response = await fetchPostUser(fetchParams);
  if (response.ok) {
    dispatch(fetchGetUsersActionCreator(fetchGetParams, ShowSnackbar.False));
    dispatch(setUserFlagActionCreator({ [flagName]: false }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleUserError(defaultErrorMessage, await response.json()));
  }
};

// PUT
export const fetchPutUserActionCreator: (
  fetchParams: UserMgmtRequest,
  fetchGetParams: FetchGetUsersParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, fetchGetParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
) => {
  const successMessage = 'Successfully edited user';
  const defaultErrorMessage = 'Issue editing user';
  const flagName = 'fetchPutUserFlag';
  dispatch(setUserFlagActionCreator({ [flagName]: true }));
  const response = await fetchPutUser(fetchParams);
  if (response.ok) {
    dispatch(fetchGetUsersActionCreator(fetchGetParams, ShowSnackbar.False));
    dispatch(setUserFlagActionCreator({ [flagName]: false }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleUserError(defaultErrorMessage, await response.json()));
  }
};

// DELETE
export const fetchDeleteUserActionCreator: (
  fetchParams: FetchDeleteUserParams,
  fetchGetParams: FetchGetUsersParams,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, fetchGetParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchDeleteUserFlag';
  const successMessage = 'Successfully deleted user';
  const defaultErrorMessage = 'Issue deleting user data';
  dispatch(setUserFlagActionCreator({ [flagName]: true }));
  const response = await fetchDeleteUser(fetchParams);
  if (response.ok) {
    dispatch(fetchGetUsersActionCreator(fetchGetParams, ShowSnackbar.False));
    dispatch(setUserFlagActionCreator({ [flagName]: false }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleUserError(defaultErrorMessage, await response.json()));
  }
};
