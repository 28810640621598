import React, {
  useEffect,
  useState,
} from 'react';
import { Grid } from '@mui/material';
import { WppSpinner } from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import { Buffer } from 'buffer';
import dayjs from 'dayjs';
import {
  DEFAULT_ASSET,
  DEFAULT_ASSET_CONTRACT,
  DEFAULT_ASSET_VALUATION,
  DEFAULT_FILE_CONFIG,
} from '../../services/defaults/asset-defaults';
import {
  AssetState,
  FetchGetAssetByIdParams,
  ValuationDto,
  ContractDto,
} from '../../services/models/asset-models.d';
import {
  fetchGetAssetByIdActionCreator,
  setAssetDataActionCreator,
  fetchPostAssetActionCreator,
  fetchPutAssetActionCreator,
  setAssetDataEditsActionCreator,
  setAssetCreationFormDataActionCreator,
} from '../../store/actions/asset-actions';
import AssetConfiguration from './Steps/AssetConfiguration';
import AttributeConfiguration from './Steps/AttributeConfiguration';
import PreviewAsset from './Steps/Preview';
import { FetchGetMasterDataParams, MasterDataTableNames } from '../../services/models/master-data-models.d';
import {
  ActiveStep,
  StepAccordion,
  StepName,
  StepTitle,
} from '../../services/models/stepper-models.d';
import {
  DefaultFormError,
  IAssetFormErrors,
  IAssetMultiStepFormErrors,
  IAttributeFormErrors,
  IContractFormErrors,
  IAttributeRuleFormErrors,
  ISchemaFormErrors,
  IValuationFormErrors,
  IAssetFormErrorReadableName,
} from '../../services/models/validation-models.d';
import AssetCreationLayout from './Layout/asset-creation-layout';
import { DEFAULT_MASTER_DATA_PAGINATION } from '../../services/defaults/master-data-defaults';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { setSnackbarActionCreator, snackbarMaker } from '../../store/actions/snackbar-actions';
import { StoreState } from '../../store';
import fetchGetConfigListActionCreator from '../../store/actions/config-actions';
import { fetchGetMasterDataActionCreator } from '../../store/actions/master-data-actions';
import ScheduleStep from './Steps/Schedule';
import useAuthUser from '../../hooks/use-auth-user';
import Stepper from '../../ui-component/stepper';

function CreateAssetBase() {
  window.Buffer = window.Buffer || Buffer;
  const [currentStep, setCurrentStep] = useState(ActiveStep.AssetConfigurationStep);
  const [multiStepFormErrors, setMultiStepFormErrors] = useState<IAssetMultiStepFormErrors>(DefaultFormError);
  let currentStepTitle = StepTitle.AssetConfigurationStep;

  const dispatch = useDispatch();
  const activeUser = useAuthUser();

  const assetData:AssetState['api']['data']['fetchGetAssetById'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetById,
  );
  const assetDataEdits:AssetState['assetDataEdits'] = useSelector(
    (state: StoreState) => state.assetReducer.assetDataEdits,
  );
  const selectedAssetTableRow:AssetState['tables']['selectedAssetTableRow'] = useSelector(
    (state: StoreState) => state.assetReducer.tables?.selectedAssetTableRow,
  );
  const assetCreationFormData:AssetState['assetCreationFormData'] = useSelector(
    (state: StoreState) => state.assetReducer.assetCreationFormData,
  );

  // For the loading spinner:
  const fetchPostAssetDataFlag:AssetState['api']['flags']['fetchPostAssetDataFlag'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.flags?.fetchPostAssetDataFlag,
  );
  const fetchGetAssetByIdFlag:AssetState['api']['flags']['fetchGetAssetByIdFlag'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.flags?.fetchGetAssetByIdFlag,
  );

  // This useEffect handles fetching for information to populate the page once when the page is loaded
  useEffect(() => {
    if (selectedAssetTableRow.assetId) {
      const fetchGetAssetByIdParams:FetchGetAssetByIdParams = {
        id: parseInt(selectedAssetTableRow.assetId, 10),
        requestedEntities: [],
      };
      // get asset
      dispatch(fetchGetAssetByIdActionCreator(fetchGetAssetByIdParams, false));
      // set the form to use selected assetID
      const objectToEdit = 'assetCreationFormData';
      dispatch(setAssetCreationFormDataActionCreator({ [objectToEdit]: { asset: { assetId: selectedAssetTableRow.assetId } } }));
    } else if (typeof assetData.asset.assetId === 'undefined' || assetData.asset.assetId === -1) {
      dispatch(setAssetDataActionCreator({
        fetchGetAssetById: {
          asset: { ...DEFAULT_ASSET },
          // source: { ...DEFAULT_ASSET_SOURCE },
          // target: { ...DEFAULT_ASSET_TARGET },
          contract: { ...DEFAULT_ASSET_CONTRACT },
          valuation: { ...DEFAULT_ASSET_VALUATION },
          fileConfig: { ...DEFAULT_FILE_CONFIG },
        },
      }));
    }

    // get master data for dropdowns
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    fetchGetMasterDataParams.limit = 100;

    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.COLLECTION, ShowSnackbar.False));
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CATEGORY, ShowSnackbar.False));
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.SOURCE, ShowSnackbar.False));
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.TARGET, ShowSnackbar.False));
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.TAG, ShowSnackbar.False));
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.DATASET, ShowSnackbar.False));
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CONTRACT, ShowSnackbar.False));
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.VALUATION, ShowSnackbar.False));
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.RULE, ShowSnackbar.False));
    // get config for dropdowns
    dispatch(fetchGetConfigListActionCreator(ShowSnackbar.False));
  }, [dispatch, selectedAssetTableRow.assetId, assetData?.asset?.assetId]);

  // This useEffect handles UI changes, such as a user editing a field
  useEffect(() => {}, [assetDataEdits]);

  const handlePostAsset = () => {
    // Remove various fields that should not occur in POST
    const {
      asset,
      fileConfig,
      contract,
      valuation,
    } = assetData;

    if (typeof activeUser.email !== 'undefined') {
      asset.createdUserId = activeUser.email;
      asset.updatedUserId = activeUser.email;
      fileConfig.createdUserId = activeUser.email;
      fileConfig.updatedUserId = activeUser.email;
    }

    let fetchPostBody: any = {
      asset, fileConfig,
    };

    if (typeof asset.contractId === 'undefined' || asset.contractId === null) {
      // If a new contract is being added
      if (typeof activeUser.email !== 'undefined') {
        contract.createdUserId = activeUser.email;
        contract.updatedUserId = activeUser.email;
      }
      // Fix for the backend is to add T00:00:00.000Z to the date
      // Also check if the date has been updated. If it has, it should match YYYY-MM-DD and get the concatenation
      if (dayjs(contract.contractStartDt, 'YYYY-MM-DD', true).isValid()) {
        contract.contractStartDt = contract.contractStartDt.concat('T00:00:00.000Z');
      }
      if (dayjs(contract.contractEndDt, 'YYYY-MM-DD', true).isValid()) {
        contract.contractEndDt = contract.contractEndDt.concat('T00:00:00.000Z');
      }
      fetchPostBody = { ...fetchPostBody, contract };
    }

    if (typeof asset.valuationId === 'undefined' || asset.valuationId === null) {
      // If a new valuation is being added
      if (typeof activeUser.email !== 'undefined') {
        valuation.createdUserId = activeUser.email;
        valuation.updatedUserId = activeUser.email;
      }
      // Fix for the backend is to add T00:00:00.000Z to the date
      // Also check if the date has been updated. If it has, it should match YYYY-MM-DD and get the concatenation
      if (dayjs(valuation.valuationStartDt, 'YYYY-MM-DD', true).isValid()) {
        valuation.valuationStartDt = valuation.valuationStartDt.concat('T00:00:00.000Z');
      }
      if (dayjs(valuation.valuationEndDt, 'YYYY-MM-DD', true).isValid()) {
        valuation.valuationEndDt = valuation.valuationEndDt.concat('T00:00:00.000Z');
      }
      fetchPostBody = { ...fetchPostBody, valuation };
    }
    dispatch(fetchPostAssetActionCreator(fetchPostBody));
  };

  const handlePutAsset = () => {
    let {
      asset,
      contract,
      valuation,
      source,
      target,
      fileConfig,
      assetAttrbtList,
    } = assetData;

    // const {
    //   asset,
    // } = assetCreationFormData;

    let createdDatetime = '';
    let updatedDatetime = '';
    let fetchPutBody:any = {};

    // Remove various fields that should not occur in PUT
    if (typeof asset !== 'undefined' && asset !== null) {
      let datasetName = '';
      let collectionName = '';
      let categoryName = '';
      let tagName = '';
      let sourceName = '';
      let targetName = '';
      let valuationName = '';
      let contractName = '';
      let newAsset: any = null;
      ({
        datasetName,
        collectionName,
        categoryName,
        tagName,
        sourceName,
        targetName,
        valuationName,
        contractName,
        createdDatetime,
        updatedDatetime,
        ...newAsset
      } = asset);
      asset = newAsset;
      if (typeof activeUser.email !== 'undefined') {
        asset.updatedUserId = activeUser.email;
      }
      fetchPutBody = { asset };
    }

    // if (typeof asset.fileConfigId === 'undefined' || asset.fileConfigId === null) {
    if (typeof fileConfig !== 'undefined' && fileConfig !== null) {
      let newFileConfig: any = null;
      let fileConfigId = -1;
      if (fileConfig.fileConfigId !== -1) {
        ({
          createdDatetime, updatedDatetime, ...newFileConfig
        } = fileConfig);
      } else {
        ({
          fileConfigId, createdDatetime, updatedDatetime, ...newFileConfig
        } = fileConfig);
      }
      fileConfig = newFileConfig;
      if (typeof activeUser.email !== 'undefined') {
        fileConfig.updatedUserId = activeUser.email;
      }
      fetchPutBody = { ...fetchPutBody, fileConfig };
    }
    // }

    if (typeof asset.contractId === 'undefined' || asset.contractId === null) {
      if (typeof contract !== 'undefined' && contract !== null) {
        let newContract: any = null;
        let contractId = -1;
        if (contract.contractId !== -1) {
          ({
            createdDatetime, updatedDatetime, ...newContract
          } = contract);
        } else {
          ({
            contractId, createdDatetime, updatedDatetime, ...newContract
          } = contract);
        }
        contract = newContract;
        // Fix for the backend is to add T00:00:00.000Z to the date
        // Also check if the date has been updated. If it has, it should match YYYY-MM-DD and get the concatenation
        if (dayjs(contract.contractStartDt, 'YYYY-MM-DD', true).isValid()) {
          contract.contractStartDt = contract.contractStartDt.concat('T00:00:00.000Z');
        }
        if (dayjs(contract.contractEndDt, 'YYYY-MM-DD', true).isValid()) {
          contract.contractEndDt = contract.contractEndDt.concat('T00:00:00.000Z');
        }
        if (typeof activeUser.email !== 'undefined') {
          contract.updatedUserId = activeUser.email;
        }
        fetchPutBody = { ...fetchPutBody, contract };
      }
    }

    if (typeof asset.valuationId === 'undefined' || asset.valuationId === null) {
      if (typeof valuation !== 'undefined' && valuation !== null) {
        let valuationId = -1;
        let newValuation: any = null;
        if (valuation.valuationId !== -1) {
          ({
            createdDatetime, updatedDatetime, ...newValuation
          } = valuation);
        } else {
          ({
            valuationId, createdDatetime, updatedDatetime, ...newValuation
          } = valuation);
        }
        valuation = newValuation;
        // Fix for the backend is to add T00:00:00.000Z to the date
        // Also check if the date has been updated. If it has, it should match YYYY-MM-DD and get the concatenation
        if (dayjs(valuation.valuationStartDt, 'YYYY-MM-DD', true).isValid()) {
          valuation.valuationStartDt = valuation.valuationStartDt.concat('T00:00:00.000Z');
        }
        if (dayjs(valuation.valuationEndDt, 'YYYY-MM-DD', true).isValid()) {
          valuation.valuationEndDt = valuation.valuationEndDt.concat('T00:00:00.000Z');
        }
        if (typeof activeUser.email !== 'undefined') {
          valuation.updatedUserId = activeUser.email;
        }
        fetchPutBody = { ...fetchPutBody, valuation };
      }
    }

    if (typeof asset.sourceId === 'undefined' || asset.sourceId === null) {
      if (typeof source !== 'undefined' && source !== null) {
        let sourceId = -1;
        let newSource: any = null;
        if (source.sourceId !== -1) {
          ({
            createdDatetime, updatedDatetime, ...newSource
          } = source);
        } else {
          ({
            sourceId, createdDatetime, updatedDatetime, ...newSource
          } = source);
        }
        source = newSource;
        if (typeof activeUser.email !== 'undefined') {
          source.updatedUserId = activeUser.email;
        }
        fetchPutBody = { ...fetchPutBody, source };
      }
    }

    if (typeof asset.targetId === 'undefined' || asset.targetId === null) {
      if (typeof target !== 'undefined' && target !== null) {
        let targetId = -1;
        let newTarget: any = null;
        if (target.targetId !== -1) {
          ({
            createdDatetime, updatedDatetime, ...newTarget
          } = target);
        } else {
          ({
            targetId, createdDatetime, updatedDatetime, ...newTarget
          } = target);
        }
        target = newTarget;
        if (typeof activeUser.email !== 'undefined') {
          target.updatedUserId = activeUser.email;
        }
        fetchPutBody = { ...fetchPutBody, target };
      }
    }

    // Remove attributeId from new attributes
    // A new attribute's ID is currently designated with a random number after decimal
    if (typeof assetAttrbtList !== 'undefined' && assetAttrbtList !== null) {
      assetAttrbtList = assetAttrbtList.map((attrbt, attrbtIndex) => {
        let assetAttrbtId = -1;
        let newAttrbt: any = null;
        // Remove ruleId from new rules
        // A new rule's ID is currently designated with a random number after decimal
        assetAttrbtList[attrbtIndex].assetAttrbtRuleList = attrbt.assetAttrbtRuleList?.map((rule, ruleIndex) => {
          let ruleId = -1;
          let newRule: any = null;
          // Attribute is new, rule is new
          if ((!Number.isInteger(rule.assetAttrbtId)) && (!Number.isInteger(rule.ruleId))) {
            ({
              assetAttrbtId, ruleId, createdDatetime, updatedDatetime, ...newRule
            } = rule);
          // Attribute is new, rule already exists
          } else if ((!Number.isInteger(rule.assetAttrbtId)) && (Number.isInteger(rule.ruleId))) {
            ({
              assetAttrbtId, createdDatetime, updatedDatetime, ...newRule
            } = rule);
          // Attribute exists, rule is new
          } else if ((Number.isInteger(rule.assetAttrbtId)) && (!Number.isInteger(rule.ruleId))) {
            ({
              ruleId, createdDatetime, updatedDatetime, ...newRule
            } = rule);
          // Attribute exists, rule exists
          } else {
            ({
              createdDatetime, updatedDatetime, ...newRule
            } = rule);
          }
          // Fix for backend issue is to add base64 encoding
          newRule.ruleExpression = Buffer.from(newRule.ruleExpression).toString('base64');
          assetAttrbtList[attrbtIndex].assetAttrbtRuleList[ruleIndex] = { ...newRule };
          if (typeof activeUser.email !== 'undefined') {
            assetAttrbtList[attrbtIndex].assetAttrbtRuleList[ruleIndex].updatedUserId = activeUser.email;
          }
          return assetAttrbtList[attrbtIndex].assetAttrbtRuleList[ruleIndex];
        });
        // Attribute is new
        if (!Number.isInteger(attrbt.assetAttrbtId)) {
          ({
            assetAttrbtId, createdDatetime, updatedDatetime, ...newAttrbt
          } = attrbt);
        // Attribute exists
        } else {
          ({
            createdDatetime, updatedDatetime, ...newAttrbt
          } = attrbt);
        }
        assetAttrbtList[attrbtIndex] = { ...newAttrbt };
        if (typeof activeUser.email !== 'undefined') {
          assetAttrbtList[attrbtIndex].updatedUserId = activeUser.email;
        }
        return assetAttrbtList[attrbtIndex];
      });
      fetchPutBody = { ...fetchPutBody, assetAttrbtList };
    }
    dispatch(fetchPutAssetActionCreator(fetchPutBody));
  };

  const cleanForm = () => {
    let isDirty = false;

    Object.values(multiStepFormErrors).forEach((section) => {
      Object.values(section).forEach((field: any, fieldIndex: number) => {
        if (typeof field === 'object') {
          if (Array.isArray(field)) {
            field.forEach((rule) => Object.values(rule).forEach((val) => {
              if (val) {
                isDirty = true;
                // const errorMessage = `Please ${section} ${field} for errors before continuing.`;
                // dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
              }
            }));
          } else {
            Object.values(field).forEach((val) => {
              if (val) {
                isDirty = true;
                // const errorMessage = `Please ${section} ${field} for errors before continuing.`;
                // dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
              }
            });
          }
        } else if (field) {
          isDirty = true;
          let errorMessage = 'Please check for errors before continuing.';
          let readableField;
          const readableFieldIndex = Object.keys(IAssetFormErrorReadableName).indexOf(Object.keys(section)[fieldIndex]);
          if (readableFieldIndex !== -1) {
            readableField = Object.values(IAssetFormErrorReadableName)[readableFieldIndex];
            errorMessage = `Please check '${readableField}' for errors before continuing.`;
          }
          dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
        }
      });
    });

    return !isDirty;
  };

  const handleSubmit = async (callback?: () => void) => {
    let assetId = -1;
    ({ assetId } = assetData.asset);

    if (cleanForm()) {
      if (typeof assetId === 'undefined' || assetId === -1) {
        handlePostAsset();
      } else {
        handlePutAsset();
      }

      // TODO uncomment to stop changing screen if a validation error occurs
      if (callback) {
        callback();
      }
    } else {
      // const errorMessage = 'Please check the form for errors before continuing.';
      // dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
    }

    // TODO comment to start changing screen even if a validation error occurs
    // if (callback) {
    //   callback();
    // }
  };

  const handleNextStep = () => {
    handleSubmit(() => {
      if (currentStep !== ActiveStep.PreviewAssetStep) {
        setCurrentStep(currentStep + 1);
      }
    });
  };

  const handlePreviousStep = () => {
    handleSubmit(() => {
      if (assetData.asset && assetData.asset.assetId !== -1) {
        setCurrentStep(currentStep - 1);
      }
    });
  };

  const handleStepClick = (step:number) => {
    handleSubmit(() => {
      setCurrentStep(step);
    });
  };

  const handleFieldChange = (newValue:null | string | Date | boolean | ValuationDto | ContractDto, section: string, field: string | null) => {
    const newFormData:any = assetCreationFormData;
    const newData:any = assetData;
    const dataName = 'fetchGetAssetById';
    const formName = 'assetCreationFormData';
    const editCounterName = 'assetDataEdits';
    if (!newFormData[section]) {
      newFormData[section] = {};
    }
    if (!newData[section]) {
      newData[section] = {};
    }
    if (field !== null) {
      newFormData[section][field] = newValue;
      newData[section][field] = newValue;
    } else {
      newFormData[section] = newValue;
      newData[section] = newValue;
    }
    dispatch(setAssetCreationFormDataActionCreator({ [formName]: newFormData }));
    dispatch(setAssetDataActionCreator({ [dataName]: newData }));
    dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
  };

  const validate = (
    accordion: number,
    formErrors: IAssetFormErrors | ISchemaFormErrors | IValuationFormErrors | IContractFormErrors | IAttributeFormErrors[] | IAttributeRuleFormErrors,
  ) => {
    if (currentStepTitle === StepTitle.AssetConfigurationStep) {
      if (accordion === StepAccordion.Asset) {
        const errors = { ...multiStepFormErrors };
        errors.asset = formErrors as IAssetFormErrors;
        setMultiStepFormErrors(errors);
      } else if (accordion === StepAccordion.Schema) {
        const errors = { ...multiStepFormErrors };
        errors.schema = formErrors as ISchemaFormErrors;
        setMultiStepFormErrors(errors);
      } else if (accordion === StepAccordion.Valuation) {
        const errors = { ...multiStepFormErrors };
        errors.valuation = formErrors as IValuationFormErrors;
        setMultiStepFormErrors(errors);
      } else if (accordion === StepAccordion.Contract) {
        const errors = { ...multiStepFormErrors };
        errors.contract = formErrors as IContractFormErrors;
        setMultiStepFormErrors(errors);
      }
    } else if (currentStepTitle === StepTitle.AttributeConfigurationStep) {
      if (accordion === StepAccordion.Attribute) {
        const errors = { ...multiStepFormErrors };
        errors.attributes = formErrors as IAttributeFormErrors[];
        setMultiStepFormErrors(errors);
      } else if (accordion === StepAccordion.Rule) {
        const errors = { ...multiStepFormErrors };
        errors.rules = formErrors as IAttributeRuleFormErrors;
        setMultiStepFormErrors(errors);
      }
    }
  };

  const stepUI = () => {
    switch (currentStep) {
      case ActiveStep.AssetConfigurationStep:
        currentStepTitle = StepTitle.AssetConfigurationStep;
        return (
          <AssetConfiguration
            assetData={assetData}
            handleFieldChange={handleFieldChange}
            validate={validate}
          />
        );
      case ActiveStep.AttributeConfigurationStep:
        currentStepTitle = StepTitle.AttributeConfigurationStep;
        return (
          <AttributeConfiguration
            assetData={assetData}
            validate={validate}
          />
        );
      case ActiveStep.ScheduleStep:
        currentStepTitle = StepTitle.ScheduleStep;
        return (
          <ScheduleStep
            assetData={assetData}
            handleFieldChange={handleFieldChange}
            validate={validate}
            handlePutAsset={handlePutAsset}
          />
        );
      case ActiveStep.PreviewAssetStep:
        currentStepTitle = StepTitle.PreviewAssetStep;
        return (
          <PreviewAsset
            assetData={assetData}
            handleFieldChange={handleFieldChange}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <AssetCreationLayout title={currentStepTitle}>
      <Grid container flexDirection="column">
        <Grid item sx={{ m: 1 }}>
          {fetchPostAssetDataFlag && fetchGetAssetByIdFlag
            ? (
              <Grid container sx={{ width: 'auto', justifyContent: 'center' }}>
                <WppSpinner size="l" />
              </Grid>
            )
            : (
              <Stepper
                titles={[
                  StepName.AssetConfigurationStep,
                  StepName.AttributeConfigurationStep,
                  StepName.ScheduleStep,
                  StepName.PreviewAssetStep,
                ]}
                currentStep={currentStep}
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                handleStepClick={handleStepClick}
                handleSubmit={handleSubmit}
                handleFieldChange={handleFieldChange}
              />
            )}
        </Grid>
        <Grid item sx={{ m: 1 }}>
          {fetchPostAssetDataFlag && fetchGetAssetByIdFlag
            ? <div />
            : stepUI()}
        </Grid>
      </Grid>
    </AssetCreationLayout>
  );
}

export default CreateAssetBase;
