import React, { useState } from 'react';
import {
  Button,
  Card,
  Grid,
  Box,
  Divider,
  TextField,
} from '@mui/material';
import { LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { useDispatch } from 'react-redux';
import MainLayout from '../../layout/MainLayout';
import Overview from '../overview';
import styles from './login.module.scss';
import { fetchPostAuthenticate } from '../../services/api/config-service-api';
import { FetchPostAuthenticateParams, FetchPostAuthenticateResponse } from '../../services/models/config-models';
import Snackbar from '../../ui-component/snackbar';
import { setSnackbarActionCreator, snackbarMaker } from '../../store/actions/snackbar-actions';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginErroredFlag, setLoginErroredFlag] = useState(false);
  const authHook = useOktaAuth();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    const fetchPostAuthenticateParams:FetchPostAuthenticateParams = { username, password };
    const response = await fetchPostAuthenticate(fetchPostAuthenticateParams);
    const fetchedData:FetchPostAuthenticateResponse = await response.json();
    if (response.ok) {
      setLoginErroredFlag(false);
      window.sessionStorage.setItem('jwt', fetchedData.token);
    } else {
      const errorMessage = 'Login failed. Please try again.';
      setLoginErroredFlag(true);
      dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
    }
  };

  const handleOktaLogin = async () => {
    if (authHook !== null && authHook?.oktaAuth !== null) {
      authHook?.oktaAuth.signInWithRedirect({ originalUri: '/' });
    }
  };

  const { authState } = useOktaAuth();

  return (
    <div>
      {!authState?.isAuthenticated ? (
        <Grid
          container
          spacing={2}
          sx={{ width: '100%', height: '100%' }}
          className={styles.login}
        >
          <Grid
            item
            xs={8}
            className={styles['login-image']}
          />
          <Grid
            container
            xs={4}
            sx={{ justifyContent: 'center' }}
            className={styles['login-window']}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                padding: '25% 0',
              }}
            >
              <Card
                sx={{
                  m: 15,
                  p: 5,
                  pt: 4,
                  pb: 5,
                  height: '50vh',
                  minWidth: 200,
                  minHeight: 400,
                  justifyContent: 'space-between',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ justifyContent: 'center', display: 'flex', mb: 1 }}>
                  <WppTypography type="2xl-heading">Login</WppTypography>
                </Box>
                <Grid container item sx={{ width: '100%' }}>
                  <TextField
                    focused
                    required
                    label="Username / Email"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    sx={{ width: '100%', mb: 1 }}
                    disabled // Remove when backend is complete
                  />
                </Grid>
                <Grid container item sx={{ width: '100%', mb: 1 }}>
                  <TextField
                    focused
                    required
                    label="Password"
                    value={password}
                    type="password"
                    onChange={(event) => setPassword(event.target.value)}
                    sx={{ width: '100%', mb: 1 }}
                    disabled // Remove when backend is complete
                  />
                </Grid>
                {loginErroredFlag ? (
                  <Box sx={{ mb: 1 }}>
                    <Snackbar />
                  </Box>
                ) : null}
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={handleLogin}
                  className={styles['login-button']}
                  disabled // Remove when backend is complete
                  // disabled={username === '' || password === ''}
                  sx={{ mb: 1 }}
                >
                  Login
                </Button>
                <Divider orientation="horizontal" sx={{ mb: 1 }} />
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={handleOktaLogin}
                  className={styles['login-button']}
                  // disabled={username === '' || password !== ''}
                  sx={{ mb: 1 }}
                >
                  Continue With Okta
                </Button>
              </Card>
            </Box>
          </Grid>
        </Grid>
      )
        : (
          <MainLayout>
            <SecureRoute path="/" exact component={LoginCallback}>
              <Overview />
            </SecureRoute>
          </MainLayout>
        )}
    </div>

  );
}

export default Login;
