export interface ExplorerState {
  api: {
    flags: {
      fetchGetAssetExplorerFlag: boolean
    },
    data: {
      fetchGetAssetExplorer: ExplorerGetResponse
    }
  }
}

export interface Flag {
  [key: string]: boolean;
}

export interface Data {
  [key: string]: any
}

export enum ExplorerActionOptions {
  SET_ASSET_API_FLAG = 'SET_ASSET_API_FLAG',
  SET_ASSET_API_DATA = 'SET_ASSET_API_DATA',
}

export interface ExplorerAction {
  type: ExplorerActionOptions,
  payload: Flag | Data | Count | ExplorerGetResponse,
}

export interface FetchGetAssetExplorerParams {
  collectionId?: string,
  datasetId?: string,
}

export interface ExplorerGetResponse {
  collectionsMap: {
    [key: number]: string
  },
  dataSetMap: {
    [key: number]: string
  },
  assetMap: {
    [key: number]: string
  },
  assetExplorerRequest: {
    [key: string]: string
  },
}

export enum ExplorerObjectType {
  COLLECTION,
  DATASET,
  ASSET,
}

// Error Handling

export interface ErrorStatus {
  code: string,
  message: string
  desc: string | null,
  errorList: string[]
}
export interface ExplorerError {
  // assetId: number,
  status: ErrorStatus
}
