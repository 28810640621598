import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { useDispatch } from 'react-redux';
import Cell from '../../../../ui-component/cell';
import Table from '../../../../ui-component/table';

function ScheduleTable(props: any) {
  const { rows } = props;
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const columnFormat = [
    {
      field: 'scheduleExpression', description: '', headerText: 'Schedule Expression', minWidth: 170,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 125,
    },
    {
      field: 'scheduleDescription', description: '', headerText: 'Description', minWidth: 500,
    },
  ];

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: undefined,
      sortable: false,
      filterable: false,
    };
    return columnFormatted;
  }));

  const onCellClick = () => null;

  const onPageChange = () => null;

  const onPageSizeChange = () => null;

  const onSortModelChange = () => null; // Not implemented

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={undefined}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={false}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={100} // Will be dynamic
        rowCount={100} // Will be dynamic
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
}

export default ScheduleTable;
