// This file contains models for the asset API and Redux

import { GridSortModel } from '@mui/x-data-grid';
import { RuleCategory, RuleType } from './config-models.d';

// tables

export interface AssetTableRow {
  assetId: string | null,
  accountName: string | null,
  accountCode: string | null,
  datasetName: string | null,
  assetName: string | null,
  assetDesc: string | null,
  assetLongDesc: string | null,
  assetType: string | null,
  assetOwner: string | null,
  collectionName: string | null,
  categoryName: string | null,
  tagName: string | null,
  sourceName: string | null,
  targetName: string | null,
  fileConfigId: string | null,
  valuationName: string | null,
  contractName: string | null,
  loadType: string | null,
  derivedAssetFlg: string | null,
  dataCatalogPushFlg: string | null,
  allowSchemaDiscoveryFlg: string | null,
  notificationEnableFlg: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface AttributeTableRow {
  assetAttrbtId: number | null,
  assetId: number | null,
  assetAttrbtName: string | null,
  assetAttrbtDesc: string | null,
  assetAttrbtKeyTypeCode: string | null,
  dataType: string | null,
  categoryId: number | null,
  tagId: number | null,
  lineageId: number | null,
  assetAttrbtSize: number | null,
  assetAttrbtNullable: string | null,
  assetAttrbtPrecision: number | null,
  assetAttrbtScale: number | null,
  colIndex: string | null,
  colStartPos: string | null,
  colEndPos: string | null,
  dupColFlg: string | null,
  colLabel: string | null,
  dateFormat: string | null,
  timeFormat: string | null,
  defaultValue: string | null,
  upperCaseExcludFlg: string | null,
  selectiveKillfillFlg: string | null,
  rulesEnabledFlg: string | null,
  licenceFlg: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
  assetAttrbtRuleList: AssetAttrbtRuleDto[] | null,
}

export interface RulesTableRow {
  assetAttrbtId: number | null,
  ruleId: number | null,
  ruleExeSeqNum: number | null,
  ruleName: string | null,
  ruleDesc: string | null,
  ruleCategory: RuleCategory | null,
  ruleExpression: string | null,
  ruleType: RuleType | null,
  ruleOperator: string | null,
  ruleValue: string | null,
  thresholdPercentage: number | null,
  existCheckFlg: string | null,
  lookupTable: string | null,
  lookupColumn: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

// redux reducer state

export interface AssetState {
  api: {
    flags: {
      fetchPutAssetDataFlag: boolean,
      fetchPostAssetDataFlag: boolean,
      fetchDeleteAssetDataFlag: boolean,
      fetchGetAssetListFlag: boolean,
      fetchGetAssetByIdFlag: boolean,
    },
    data: {
      fetchGetAssetList: AssetListResponse,
      fetchGetAssetById: AssetMultiStepGetResponse,
      fetchGetAssetByIdForLookupRules: AssetMultiStepGetResponse,
    },
  },
  formData: {
    attributeFormData: AssetAttrbtDto,
    attributeRuleFormData: AssetAttrbtRuleDto,
    scheduleFormData: ScheduleDetails,
  },
  tables: {
    selectedAssetTableRow: AssetTableRow,
    selectedAttributeTableRow: AttributeTableRow,
    selectedRulesTableRow: RulesTableRow,
    // selectedLookupRulesTableRow: LookupRulesTableRow,
  },
  assetCreationFormData: any,
  assetDataEdits: number,
  sortModel: GridSortModel,
}

// redux actions

export enum AssetActionOptions {
  SET_ASSET_API_FLAG = 'SET_ASSET_API_FLAG',
  SET_ASSET_API_DATA = 'SET_ASSET_API_DATA',
  SET_ASSET_SELECTED_TABLE_ROW = 'SET_ASSET_SELECTED_TABLE_ROW',
  DELETE_ASSET_SELECTED_TABLE_ROW = 'DELETE_ASSET_SELECTED_TABLE_ROW',
  SET_ASSET_SORT_MODEL = 'SET_ASSET_SORT_MODEL',
  SET_ASSET_DATA_EDITS = 'SET_ASSET_DATA_EDITS',
  SET_FORM_DATA = 'SET_FORM_DATA',
  SET_ASSET_CREATION_FORM_DATA = 'SET_ASSET_CREATION_FORM_DATA',
}

export interface Flag {
  [key: string]: boolean;
}

export interface Data {
  [key: string]: any
}

export interface Count {
  [key: string]: number
}

export interface SelectedRow {
  [key: string]: AssetTableRow | AttributeTableRow | RulesTableRow
}

export interface AssetAction {
  type: AssetActionOptions,
  payload: Flag | Data | Count | AssetTableRow | AttributeTableRow | RulesTableRow
}

// fetching models

export interface FetchDeleteAssetByIdParams {
  id: number,
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface FetchGetAssetsParams {
  page: number,
  limit: number,
  sortByFieldName: string,
  sortOrder: 'asc' | 'desc' | null,
  searchKey: string,
  searchValue: string,
}

export interface FetchGetCopyAssetParams {
  assetId: number
}

export interface FetchGetAssetByIdParams {
  id: number,
  requestedEntities: string[],
}

export interface FetchGetAssetAttrbtsByIdParams {
  assetId: number,
  page: number,
  limit: number,
}

export interface FetchGetAssetByNameParams {
  assetName: string,
  requestedEntities: string[],
}

// asset-service models

export interface AssetAttrbtDto {
  assetAttrbtId: number,
  assetId: number,
  assetAttrbtName: string,
  assetAttrbtDesc: string,
  assetAttrbtKeyTypeCode: string,
  dataType: string,
  categoryId: number,
  tagId: number,
  lineageId: number | null,
  assetAttrbtSize: number,
  assetAttrbtNullable: string,
  assetAttrbtPrecision: number,
  assetAttrbtScale: number,
  colIndex: number,
  colStartPos: number,
  colEndPos: number,
  dupColFlg: string,
  colLabel: string,
  dateFormat: string,
  timeFormat: string,
  defaultValue: string,
  upperCaseExcludFlg: string,
  selectiveKillfillFlg: string,
  rulesEnabledFlg: string,
  licenceFlg: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
  assetAttrbtRuleList: AssetAttrbtRuleDto[],
}

export interface AssetAttrbtRuleDto {
  assetAttrbtId: number,
  ruleId: number,
  ruleExeSeqNum: number,
  ruleName: string,
  ruleDesc: string,
  ruleCategory: RuleCategory,
  ruleExpression: string,
  ruleType: RuleType,
  ruleOperator: string,
  ruleValue: string,
  thresholdPercentage: number,
  existCheckFlg: string,
  lookupTable: string,
  lookupColumn: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface AssetDto {
  assetId: number,
  accountId: number,
  accountName: string,
  accountCode: string,
  datasetId: number,
  datasetName: string,
  assetName: string,
  assetDesc: string,
  assetLongDesc: string,
  assetType: string | null,
  assetOwner: string | null,
  collectionName: string,
  frequencyName: string,
  categoryName: string,
  tagName: string,
  sourceName: string,
  targetName: string,
  fileConfigId: number,
  valuationName: string,
  contractName: string,
  collectionId: number,
  categoryId: number,
  tagId: number,
  sourceId: number,
  targetId: number,
  valuationId: number,
  contractId: number,
  loadType: string | null,
  derivedAssetFlg: string,
  dataCatalogPushFlg: string,
  allowSchemaDiscoveryFlg: string,
  notificationEnableFlg: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
  versionId: number,
}

export interface AssetMultiStepPutRequest {
  asset: AssetDto,
  contract?: ContractDto,
  valuation?: ValuationDto,
  source?: SourceDto,
  target?: TargetDto,
  fileConfig?: FileConfigDto,
  assetScript?: AssetScriptDto,
  assetVersion?: AssetVersionDto,
  assetAttrbtList?: AssetAttrbtDto[],
  lineageList?: LineageDto[],
}

export interface AssetScriptDto {
  assetId: number,
  versionId: number,
  assetScript: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface AssetVersionDto {
  assetId: number,
  versionId: number,
  versionDesc: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface ContractDto {
  contractId: number,
  contractName: string,
  contractDesc: string,
  usageType: string,
  contractType: string,
  contractStartDt: string,
  contractEndDt: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
  status: string,
}

export interface FileConfigDto {
  fileConfigId: number,
  fileFormat: string,
  filePattern: string,
  schedule: string,
  recordDelimiter: string,
  skipLinesFromTop: number,
  escapeChar: string,
  quoteChar: string,
  fileCharSet: string,
  skipRecLenChkFlg: string,
  headerRecFlg: string,
  thresholdChkFlg: string,
  thresholdPercentage: number | null,
  thresholdNumOfRec: number | null,
  duplicateRecChkFlg: string,
  fatalFileFlg: string,
  dupRecOnAllRecFlg: string,
  dupRecCntThld: number | null,
  dupPercentThld: number | null,
  dropDupFlg: string,
  retainDupRecPos: string,
  dupRecOrderby: string,
  filePath:string
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface LineageDto {
  lineageId: number,
  assetId: number,
  assetAttrbtId: number,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
  status: string,
}

export interface SourceDto {
  sourceId: number,
  sourceType: string,
  sourceName: string,
  sourceDesc: string,
  sourceDirectory: string,
  sourceTechnologyType: string,
  sourceConnection: string,
  authenticationKey: string,
  authenticationValue: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
  status: string,
}

export interface TargetDto {
  targetId: number,
  targetType: string,
  targetName: string,
  targetDesc: string,
  targetDirectory: string,
  targetTechnologyType: string,
  targetConnection: string,
  authenticationKey: string,
  authenticationValue: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
  status: string,
}

export interface ValuationDto {
  valuationId: number,
  valuationName: string,
  valuationDesc: string,
  valuationRecordCount: number,
  valuationFileFlg: string,
  valuationDays: number,
  valuationType: string,
  value: number,
  valuationStartDt: string,
  valuationEndDt: string,
  currency: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
  status: string,
}

export interface ScheduleDetails {
  scheduleExpression: string,
  scheduleDescription: string,
  status: string,
}

export interface AssetMultiStepPutResponse {
  status: Status
}

export interface Status {
  code: string,
  message: string,
  desc: string,
}

export interface AssetMultiStepPostRequest {
  asset: any,
  fileConfig: any,
  contract?: any,
  valuation?: any,
}

export interface AssetMultiStepPostResponse {
  assetId: number,
  status: Status,
}

export interface AssetMultiStepGetResponse {
  asset: AssetDto,
  contract: ContractDto,
  valuation: ValuationDto,
  source: SourceDto,
  target: TargetDto,
  fileConfig: FileConfigDto,
  assetScript: AssetScriptDto,
  assetVersion: AssetVersionDto,
  assetAttrbtList: AssetAttrbtDto[],
  lineageList: LineageDto[],
  status: Status,
}

export interface ListOrder {
  sortByFieldName: string,
  sortOrder: OrderDirection,
  searchKey: string,
  searchValue: string,
}

export interface AssetListResponse {
  assets: AssetDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface Pagination {
  records: number,
  page: number,
  limit: number,
  totalRecords: number,
  totalPages: number,
}

// Error Handling

export interface ErrorStatus {
  code: string,
  message: string
  desc: string | null,
  errorList: string[]
}
export interface AssetError {
  assetId: number,
  status: ErrorStatus
}
