import React from 'react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import {
  GridCellParams, GridRenderCellParams, GridSortModel, GridValueGetterParams,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../ui-component/table';
import Cell from '../../../ui-component/cell';
import {
  CollectionDto, CollectionTableRow, FetchGetMasterDataParams, MasterDataState, MasterDataTableNames, OrderDirection,
} from '../../../services/models/master-data-models.d';
import { DEFAULT_MASTER_DATA_PAGINATION, EMPTY_COLLECTION } from '../../../services/defaults/master-data-defaults';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { StoreState } from '../../../store';
import { setMasterDataSelectedTableRowActionCreator, setMasterDataDataActionCreator, fetchGetMasterDataActionCreator } from '../../../store/actions/master-data-actions';

export interface CollectionTableProps {
  rows: CollectionDto[],
}
const CollectionTable = function RenderCollectionTable(props:CollectionTableProps) {
  const { rows } = props;
  const dispatch = useDispatch();

  const fetchGetCollectionListFlag: MasterDataState['api']['flags']['fetchGetCollectionListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetCollectionListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetCollectionList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCollectionList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetCollectionList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCollectionList?.listOrder,
  );

  // let records = 0; // records returned on page, can be less than limit
  let page = 0; // current page index
  let limit = 0; // The page size, or max records displayed
  let totalRecords = 0; // Total number of records
  // let totalPages; // Total pages based on limit
  if (typeof pagination !== 'undefined') {
    ({
      // records,
      page,
      limit,
      totalRecords,
      // totalPages,
    } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  let searchKey = '';
  let searchValue = '';
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
      searchKey,
      searchValue,
    } = listOrder);
  }

  const columnFormat = [
    {
      field: 'collectionId', description: '', headerText: 'Collection ID', minWidth: 50, valueGetter: undefined, sortable: true,
    },
    {
      field: 'collectionName', description: '', headerText: 'Collection Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'collectionDesc', description: '', headerText: 'Collection Description', minWidth: 200, valueGetter: undefined, sortable: false,
    },
    {
      field: 'collectionSourceType', description: '', headerText: 'Source Type', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'collectionSourceName', description: '', headerText: 'Source Name', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'collectionSummary', description: '', headerText: 'Summary', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'collectionOwner', description: '', headerText: 'Owner', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'collectionAgencyName', description: '', headerText: 'Agency', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'collectionDate',
      description: '',
      headerText: 'Collection Date',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: false,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdUserId', headerText: 'Created User ID', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', headerText: 'Updated User ID', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const columnVisibilityModel = {
    createdUserId: false,
    updatedUserId: false,
  };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: column.sortable,
      filterable: false,
    };
    return columnFormatted;
  }));

  const getSelectedCollection = (selectedCollectionTableRow: CollectionTableRow) : CollectionDto => {
    const collectionDto: CollectionDto = { ...EMPTY_COLLECTION };
    collectionDto.collectionId = selectedCollectionTableRow.collectionId !== null ? selectedCollectionTableRow.collectionId : -1;
    collectionDto.collectionName = selectedCollectionTableRow.collectionName !== null ? selectedCollectionTableRow.collectionName : '';
    collectionDto.collectionDesc = selectedCollectionTableRow.collectionDesc !== null ? selectedCollectionTableRow.collectionDesc : '';
    collectionDto.collectionSourceType = selectedCollectionTableRow.collectionSourceType !== null ? selectedCollectionTableRow.collectionSourceType : '';
    collectionDto.collectionSourceName = selectedCollectionTableRow.collectionSourceName !== null ? selectedCollectionTableRow.collectionSourceName : '';
    collectionDto.collectionSummary = selectedCollectionTableRow.collectionSummary !== null ? selectedCollectionTableRow.collectionSummary : '';
    collectionDto.collectionOwner = selectedCollectionTableRow.collectionOwner !== null ? selectedCollectionTableRow.collectionOwner : '';
    collectionDto.collectionAgencyName = selectedCollectionTableRow.collectionAgencyName !== null ? selectedCollectionTableRow.collectionAgencyName : '';
    collectionDto.collectionDate = selectedCollectionTableRow.collectionDate !== null ? selectedCollectionTableRow.collectionDate : '';
    collectionDto.status = selectedCollectionTableRow.status !== null ? selectedCollectionTableRow.status : '';
    collectionDto.createdUserId = selectedCollectionTableRow.createdUserId !== null ? selectedCollectionTableRow.createdUserId : '';
    collectionDto.createdDatetime = selectedCollectionTableRow.createdDatetime !== null ? selectedCollectionTableRow.createdDatetime : '';
    collectionDto.updatedUserId = selectedCollectionTableRow.updatedUserId !== null ? selectedCollectionTableRow.updatedUserId : '';
    collectionDto.updatedDatetime = selectedCollectionTableRow.updatedDatetime !== null ? selectedCollectionTableRow.updatedDatetime : '';
    return collectionDto;
  };

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const selectedTableName = 'selectedCollectionTableRow';
    const selectedCollectionTableRow:CollectionTableRow = Object.assign({}, ...rowDetails);
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: selectedCollectionTableRow }));
    const fetchGetCollectionData = 'fetchGetCollectionData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetCollectionData]: getSelectedCollection(selectedCollectionTableRow) }));
  };

  const onPageChange = (updatedPage: number) => {
    if (!fetchGetCollectionListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = updatedPage;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.COLLECTION, ShowSnackbar.True));
    }
  };

  const onPageSizeChange = (updatedPageSize: number) => {
    if (!fetchGetCollectionListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = updatedPageSize;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.COLLECTION, ShowSnackbar.True));
    }
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!fetchGetCollectionListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = page;
      fetchGetMasterDataParams.limit = limit;
      if (typeof model[0].field !== 'undefined') {
        fetchGetMasterDataParams.sortByFieldName = model[0].field;
      }
      if (typeof model[0].sort !== 'undefined') {
        fetchGetMasterDataParams.sortOrder = model[0].sort;
      }
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.COLLECTION, ShowSnackbar.True));
    }
  };

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={false}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={limit || 0}
        rowCount={totalRecords || 0}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
};

export default CollectionTable;
