import { GridSortModel } from '@mui/x-data-grid';
import {
  BLANK_CATEGORY_LIST,
  DESELECTED_CATEGORY_TABLE_ROW,
  BLANK_COLLECTION_LIST,
  BLANK_CONTRACT_DATA,
  BLANK_DATASET_LIST,
  BLANK_SOURCE_DATA,
  BLANK_SOURCE_LIST,
  BLANK_TAG_LIST,
  BLANK_TARGET_DATA,
  BLANK_TARGET_LIST,
  BLANK_VALUATION_DATA,
  BLANK_VALUATION_LIST,
  DESELECTED_COLLECTION_TABLE_ROW,
  DESELECTED_CONTRACT_TABLE_ROW,
  DESELECTED_DATASET_TABLE_ROW,
  DESELECTED_SOURCE_TABLE_ROW,
  DESELECTED_TAG_TABLE_ROW,
  DESELECTED_TARGET_TABLE_ROW,
  DESELECTED_VALUATION_TABLE_ROW,
  BLANK_CONTRACT_LIST,
  DEFAULT_MASTER_DATA_GRID_SORT_ARRAY,
  BLANK_RULE_DATA,
  DESELECTED_RULE_TABLE_ROW,
  EMPTY_TAG,
  EMPTY_COLLECTION,
  EMPTY_DATASET,
  EMPTY_RULE,
  EMPTY_CATEGORY,
} from '../../services/defaults/master-data-defaults';
import { MasterDataAction, MasterDataActionOptions, MasterDataState } from '../../services/models/master-data-models.d';

export const MasterDataDefaultState = {
  api: {
    flags: {
      fetchGetCategoryListFlag: false,
      fetchGetCategoryDataFlag: false,
      fetchPostCategoryDataFlag: false,
      fetchDeleteCategoryDataFlag: false,
      fetchGetTagListFlag: false,
      fetchGetTagDataFlag: false,
      fetchPostTagDataFlag: false,
      fetchDeleteTagDataFlag: false,
      fetchGetSourceListFlag: false,
      fetchGetSourceDataFlag: false,
      fetchPostSourceDataFlag: false,
      fetchDeleteSourceDataFlag: false,
      fetchGetTargetListFlag: false,
      fetchGetTargetDataFlag: false,
      fetchPostTargetDataFlag: false,
      fetchDeleteTargetDataFlag: false,
      fetchGetCollectionListFlag: false,
      fetchGetCollectionDataFlag: false,
      fetchPostCollectionDataFlag: false,
      fetchDeleteCollectionDataFlag: false,
      fetchGetContractListFlag: false,
      fetchGetContractDataFlag: false,
      fetchPostContractDataFlag: false,
      fetchDeleteContractDataFlag: false,
      fetchGetDatasetListFlag: false,
      fetchGetDatasetDataFlag: false,
      fetchPostDatasetDataFlag: false,
      fetchDeleteDatasetDataFlag: false,
      fetchGetValuationListFlag: false,
      fetchGetValuationDataFlag: false,
      fetchPostValuationDataFlag: false,
      fetchDeleteValuationDataFlag: false,
      fetchGetRuleListFlag: false,
      fetchGetRuleDataFlag: false,
      fetchPostRuleDataFlag: false,
      fetchDeleteRuleDataFlag: false,
    },
    data: {
      fetchGetCategoryList: { ...BLANK_CATEGORY_LIST },
      fetchGetCategoryData: { ...EMPTY_CATEGORY },
      fetchGetTagList: { ...BLANK_TAG_LIST },
      fetchGetTagData: { ...EMPTY_TAG },
      fetchGetSourceList: { ...BLANK_SOURCE_LIST },
      fetchGetSourceData: { ...BLANK_SOURCE_DATA },
      fetchGetTargetList: { ...BLANK_TARGET_LIST },
      fetchGetTargetData: { ...BLANK_TARGET_DATA },
      fetchGetCollectionList: { ...BLANK_COLLECTION_LIST },
      fetchGetCollectionData: { ...EMPTY_COLLECTION },
      fetchGetContractList: { ...BLANK_CONTRACT_LIST },
      fetchGetContractData: { ...BLANK_CONTRACT_DATA },
      fetchGetDatasetList: { ...BLANK_DATASET_LIST },
      fetchGetDatasetData: { ...EMPTY_DATASET },
      fetchGetValuationList: { ...BLANK_VALUATION_LIST },
      fetchGetValuationData: { ...BLANK_VALUATION_DATA },
      fetchGetRuleList: { ...BLANK_RULE_DATA },
      fetchGetRuleData: { ...EMPTY_RULE },
    },
  },
  masterDataEdits: 0,
  tables: {
    selectedCategoryTableRow: { ...DESELECTED_CATEGORY_TABLE_ROW },
    selectedTagTableRow: { ...DESELECTED_TAG_TABLE_ROW },
    selectedSourceTableRow: { ...DESELECTED_SOURCE_TABLE_ROW },
    selectedTargetTableRow: { ...DESELECTED_TARGET_TABLE_ROW },
    selectedCollectionTableRow: { ...DESELECTED_COLLECTION_TABLE_ROW },
    selectedContractTableRow: { ...DESELECTED_CONTRACT_TABLE_ROW },
    selectedDatasetTableRow: { ...DESELECTED_DATASET_TABLE_ROW },
    selectedValuationTableRow: { ...DESELECTED_VALUATION_TABLE_ROW },
    selectedRuleTableRow: { ...DESELECTED_RULE_TABLE_ROW },
  },
  sortModel: DEFAULT_MASTER_DATA_GRID_SORT_ARRAY,
};

function masterDataReducer(
  state:MasterDataState = MasterDataDefaultState,
  action:MasterDataAction,
): MasterDataState {
  switch (action.type) {
    case MasterDataActionOptions.SET_MASTER_DATA_API_FLAG:
      return {
        ...state,
        api: {
          ...state.api,
          flags: {
            ...state.api.flags,
            ...action.payload,
          },
        },
      };
    case MasterDataActionOptions.SET_MASTER_DATA_API_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          data: {
            ...state.api.data,
            ...action.payload,
          },
        },
      };
    case MasterDataActionOptions.SET_MASTER_DATA_SELECTED_TABLE_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          ...action.payload,
        },
      };
    case MasterDataActionOptions.SET_MASTER_DATA_DATA_EDITS:
      return {
        ...state,
        ...action.payload,
      };
    case MasterDataActionOptions.SET_MASTER_DATA_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload as GridSortModel,
      };
    default:
      return state;
  }
}

export default masterDataReducer;
