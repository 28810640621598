import React from 'react';
import { Switch } from 'react-router-dom';
import {
  Grid, Card, CssBaseline,
} from '@mui/material';
import Header from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

type MainLayoutProps = {
  children: React.ReactNode;
};

function MainLayout(props: MainLayoutProps) {
  const { children } = props;
  return (
    <Grid container sx={{ flexDirection: 'column', bgcolor: 'background.paper' }}>
      <CssBaseline />
      <Grid container item>
        <Grid item xs={2}>
          {/* header and sidebar */}
          <Header />
        </Grid>
        <Grid item xs={10}>
          {/* main content */}
          <Card sx={{
            mt: 10,
            mr: 4,
            ml: -4,
            mb: 4,
            bgcolor: 'background.paper',
            elevation: 2,
            border: 1,
            borderColor: 'grey.500',
            minHeight: '88vh',
            // overflow: 'auto',
          }}
          >
            <Switch>
              {children}
            </Switch>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MainLayout;
