import { GridSortModel } from '@mui/x-data-grid';
import {
  fetchGetMasterData, fetchPostMasterData,
} from '../../services/api/master-data-service-api';
import { DEFAULT_MASTER_DATA_PAGINATION } from '../../services/defaults/master-data-defaults';
import {
  CategoryDto,
  CollectionDto,
  Count,
  Data,
  DatasetDto,
  FetchGetMasterDataParams,
  Flag,
  MasterDataAction,
  MasterDataActionOptions,
  MasterDataError,
  MasterDataPostType,
  MasterDataResponse,
  MasterDataTableNames,
  RuleDto,
  SelectedRow,
  TagDto,
} from '../../services/models/master-data-models.d';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { setSnackbarActionCreator, snackbarMaker } from './snackbar-actions';

export const setMasterDataFlagActionCreator: (update: Flag) => MasterDataAction = (update: Flag) => ({
  type: MasterDataActionOptions.SET_MASTER_DATA_API_FLAG,
  payload: update,
});

export const setMasterDataDataActionCreator: (update: Data) => MasterDataAction = (update: Data) => ({
  type: MasterDataActionOptions.SET_MASTER_DATA_API_DATA,
  payload: update,
});

export const setMasterDataSortModelActionCreator: (update: GridSortModel) => MasterDataAction = (update: GridSortModel) => ({
  type: MasterDataActionOptions.SET_MASTER_DATA_SORT_MODEL,
  payload: update,
});

export const setMasterDataSelectedTableRowActionCreator: (update: SelectedRow) => MasterDataAction = (update: SelectedRow) => ({
  type: MasterDataActionOptions.SET_MASTER_DATA_SELECTED_TABLE_ROW,
  payload: update,
});

export const setMasterDataEditsActionCreator: (update: Count) => MasterDataAction = (update: Count) => ({
  type: MasterDataActionOptions.SET_MASTER_DATA_DATA_EDITS,
  payload: update,
});

export const handleMasterDataError:(errorMessage: string, error: MasterDataError) => any = (defaultErrorMessage, error) => async (
  dispatch:(action:any) => any,
) => {
  let errorMessage = defaultErrorMessage;
  if (typeof error?.status?.errorList[0] !== 'undefined') {
    const [primaryError] = error.status.errorList;
    errorMessage = primaryError;
  }
  dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
};

// get all master data
export const fetchGetMasterDataActionCreator: (
  fetchParams: FetchGetMasterDataParams,
  tableName: MasterDataTableNames,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, tableName, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = `fetchGet${tableName.charAt(0).toUpperCase()}${tableName.slice(1)}ListFlag`;
  const dataName = `fetchGet${tableName.charAt(0).toUpperCase()}${tableName.slice(1)}List`;
  const successMessage = `Fetched ${tableName} data successfully`;
  const defaultErrorMessage = `Issue fetching ${tableName} data`;
  dispatch(setMasterDataFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetMasterData(fetchParams, tableName);
  if (response.ok) {
    const fetchedData:MasterDataResponse = await response.json();
    dispatch(setMasterDataFlagActionCreator({ [flagName]: false }));
    dispatch(setMasterDataDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleMasterDataError(defaultErrorMessage, await response.json()));
  }
};

// This is unused code

// get master data by ID
// export const fetchGetMasterDataByIdActionCreator: (
//   fetchParams: FetchGetMasterDataByIdParams,
//   tableName: MasterDataTableNames,
//   showSnackbar?: ShowSnackbar,
// ) => any = (fetchParams, tableName, showSnackbar = ShowSnackbar.True) => async (
//   dispatch:(action:any) => any,
//   // getState:() => any,
// ) => {
//   const { id } = fetchParams;
//   const flagName = `fetchGet${tableName.charAt(0).toUpperCase()}${tableName.slice(1)}DataFlag`;
//   const dataName = `fetchGet${tableName.charAt(0).toUpperCase()}${tableName.slice(1)}Data`;
//   const successMessage = `Fetched ${tableName} data of ID "${id}"`;
//   const defaultErrorMessage = `Issue fetching ${tableName} data for ID "${id}"`;
//   dispatch(setMasterDataFlagActionCreator({ [flagName]: true }));
//   const response = await fetchGetMasterDataById(fetchParams, tableName);
//   if (response.ok) {
//     const fetchedData:MasterDataResponse = await response.json();
//     dispatch(setMasterDataFlagActionCreator({ [flagName]: false }));
//     dispatch(setMasterDataDataActionCreator({ [dataName]: fetchedData }));
//     if (showSnackbar) {
//       dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
//     }
//   } else {
//     dispatch(handleMasterDataError(defaultErrorMessage, await response.json()));
//   }
// };

export const fetchPostMasterDataActionCreator: (
  fetchParams: TagDto | CollectionDto | DatasetDto | RuleDto | CategoryDto,
  postType: MasterDataPostType,
  tableName: MasterDataTableNames,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, postType, tableName, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  let successMessage = `Successfully created new ${tableName}`;
  let defaultErrorMessage = `Issue creating new ${tableName}`;
  if (postType === MasterDataPostType.UPDATE) {
    successMessage = `Successfully updated ${tableName}`;
    defaultErrorMessage = `Issue updating ${tableName}`;
  } else if (postType === MasterDataPostType.DELETE) {
    successMessage = `Successfully deleted ${tableName} (status set to 'Inactive')`;
    defaultErrorMessage = `Issue deleting ${tableName}`;
  }
  const flagName = `fetchPost${tableName.charAt(0).toUpperCase()}${tableName.slice(1)}DataFlag`;
  // const dataName = `fetchPost${tableName.charAt(0).toUpperCase()}${tableName.slice(1)}Data`;
  dispatch(setMasterDataFlagActionCreator({ [flagName]: true }));
  const response = await fetchPostMasterData(fetchParams, tableName);
  if (response.ok) {
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, tableName, ShowSnackbar.False));
    dispatch(setMasterDataFlagActionCreator({ [flagName]: false }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleMasterDataError(defaultErrorMessage, await response.json()));
  }
};
