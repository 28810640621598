import React, {
  Dispatch, SetStateAction, useState, useEffect, useRef,
} from 'react';

// material-ui
import {
  Box,
  Grid,
} from '@mui/material';
import {
  // WppIconCollection,
  WppIconCube,
  // WppIconStatisticDocument,
  WppSpinner,
  WppTypography,
  WppAccordion,
} from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import InfoCard from '../../ui-component/info-card';
import { InfoDetailRecord } from '../../services/models/ui-component-model';
import InfoDetailsCard from '../../ui-component/info-details-card';
import { CatalogState, DataHolder, FetchGetCatalogDetails } from '../../services/models/data-catalog-models';
import { StoreState } from '../../store';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { EMPTY_DATA_HOLDER } from '../../services/defaults/data-catalog-defaults';
import {
  fetchGetCatalogByAssetNameActionCreator,
  fetchGetCatalogMetricsActionCreator,
  fetchGetCatalogMetricsRegionActionCreator,
} from '../../store/actions/data-catalog-actions';

export interface CatalogListViewProps {
  setShowCatalogUI: Dispatch<SetStateAction<boolean>>,
}

interface IResourcesCount {
  collectionCount: number;
  assetCount: number;
  columnCount: number;
}

interface ISelectedCollection {
  region: string;
  collName: string;
}

function CatalogListView(props: CatalogListViewProps) {
  const assetRef = useRef<null | HTMLDivElement>(null);
  const { setShowCatalogUI } = props;
  const [showAssets, setShowAssets] = useState<boolean>(false);
  const [resourcesCount, setResourcesCount] = useState<IResourcesCount>({ collectionCount: 0, assetCount: 0, columnCount: 0 });
  const [assetTileDataList, setAssetTileDataList] = useState<InfoDetailRecord[]>([]);
  const [selectedCollection, setselectedCollection] = useState<ISelectedCollection>({ region: '', collName: '' });

  const dispatch = useDispatch();

  const catalogMetrics: CatalogState['api']['data']['fetchGetCatalogMetrics'] = useSelector(
    (state: StoreState) => state.catalogReducer.api?.data?.fetchGetCatalogMetrics,
  );

  const catalogMetricsFlag: CatalogState['api']['flags']['fetchGetCatalogMetricsFlag'] = useSelector(
    (state: StoreState) => state.catalogReducer.api?.flags?.fetchGetCatalogMetricsFlag,
  );

  const catalogMetricsEu: CatalogState['api']['dataEu']['fetchGetCatalogMetrics'] = useSelector(
    (state: StoreState) => state.catalogReducer.api?.dataEu?.fetchGetCatalogMetrics,
  );

  const catalogMetricsApac: CatalogState['api']['dataApac']['fetchGetCatalogMetrics'] = useSelector(
    (state: StoreState) => state.catalogReducer.api?.dataApac?.fetchGetCatalogMetrics,
  );

  const catalogMetricsEmea: CatalogState['api']['dataEmea']['fetchGetCatalogMetrics'] = useSelector(
    (state: StoreState) => state.catalogReducer.api?.dataEmea?.fetchGetCatalogMetrics,
  );

  const fetchParamVal = (reg: string) => {
    const fetchParam: FetchGetCatalogDetails = {
      accountCode: '',
      organization: `c9h-${reg}`,
      collection: '',
      datasetName: '',
      assetName: '',
    };
    return fetchParam;
  };

  useEffect(() => {
    dispatch(fetchGetCatalogMetricsActionCreator(fetchParamVal('na'), ShowSnackbar.False));
    dispatch(fetchGetCatalogMetricsRegionActionCreator(fetchParamVal('eu'), ShowSnackbar.False, 'eu'));
    dispatch(fetchGetCatalogMetricsRegionActionCreator(fetchParamVal('apac'), ShowSnackbar.False, 'apac'));
    dispatch(fetchGetCatalogMetricsRegionActionCreator(fetchParamVal('emea'), ShowSnackbar.False, 'emea'));
  }, [dispatch]);

  // const searchPlaceholder = 'Search by Tag';
  // const [value, setValue] = useState<string>();
  // const searchCallback = (searchValue: string) : void => {
  // };

  // let collectionCount;
  // let datasetCount;
  // let assetCount;
  // let columnCount;
  // let organizationCode;
  // let status;
  let collectionData: DataHolder[] | undefined;

  if (typeof catalogMetrics !== 'undefined'
    && catalogMetrics !== null) {
    ({
      // collectionCount,
      // datasetCount,
      // assetCount,
      // columnCount,
      // eslint-disable-next-line
      // organizationCode,
      // eslint-disable-next-line
      // status,
      collectionData,
    } = catalogMetrics);
  }

  const getCollectionList = (reg: string) => {
    let collectionDataArr = [];
    let regionVal = '';
    if (reg === 'eu') {
      collectionDataArr = catalogMetricsEu?.collectionData;
      regionVal = 'Europe';
    } else if (reg === 'apac') {
      collectionDataArr = catalogMetricsApac?.collectionData;
      regionVal = 'APAC';
    } else if (reg === 'emea') {
      collectionDataArr = catalogMetricsEmea?.collectionData;
      regionVal = 'EMEA';
    } else {
      collectionDataArr = catalogMetrics?.collectionData;
      regionVal = 'North America';
    }
    const collectionTileList: InfoDetailRecord[] = collectionDataArr !== undefined ? collectionDataArr?.map((collection) => ({
      title: collection.name,
      line1: collection.desc,
      line2: regionVal,
      line3: `Assets - ${collection?.details?.Assets !== undefined ? collection?.details?.Assets : 0}`,
    })) : [];
    return collectionTileList;
  };

  const collectionTileDataList = getCollectionList('na');
  const collectionTileDataListEu = getCollectionList('eu');
  const collectionTileDataListApac = getCollectionList('apac');
  const collectionTileDataListEmea = getCollectionList('emea');

  // const collectionTileDataList: InfoDetailRecord[] = collectionData !== undefined ? collectionData?.map((collection) => ({
  //   title: collection.name,
  //   line1: collection.desc,
  //   line2: `Dataset - ${collection?.details?.Datasets !== undefined ? collection?.details?.Datasets : 0}`,
  //   line3: `Assets - ${collection?.details?.Assets !== undefined ? collection?.details?.Assets : 0}`,
  // })) : [];

  useEffect(() => {
    const collCountNa = catalogMetrics?.collectionCount ? catalogMetrics?.collectionCount : 0;
    const collCountEu = catalogMetricsEu?.collectionCount ? catalogMetricsEu?.collectionCount : 0;
    const collCountApac = catalogMetricsApac?.collectionCount ? catalogMetricsApac?.collectionCount : 0;
    const collCountEmea = catalogMetricsEmea?.collectionCount ? catalogMetricsEmea?.collectionCount : 0;
    const assetCountNa = catalogMetrics?.assetCount ? catalogMetrics?.assetCount : 0;
    const assetCountEu = catalogMetricsEu?.assetCount ? catalogMetricsEu?.assetCount : 0;
    const assetCountApac = catalogMetricsApac?.assetCount ? catalogMetricsApac?.assetCount : 0;
    const assetCountEmea = catalogMetricsEmea?.assetCount ? catalogMetricsEmea?.assetCount : 0;
    const columnCountNa = catalogMetrics?.columnCount ? catalogMetrics?.columnCount : 0;
    const columnCountEu = catalogMetricsEu?.columnCount ? catalogMetricsEu?.columnCount : 0;
    const columnCountApac = catalogMetricsApac?.columnCount ? catalogMetricsApac?.columnCount : 0;
    const columnCountEmea = catalogMetricsEmea?.columnCount ? catalogMetricsEmea?.columnCount : 0;
    const colCount = collCountNa + collCountEu + collCountApac + collCountEmea;
    const assetCountAll = assetCountNa + assetCountEu + assetCountApac + assetCountEmea;
    const columnCountAll = columnCountNa + columnCountEu + columnCountApac + columnCountEmea;
    setResourcesCount({ collectionCount: colCount, assetCount: assetCountAll, columnCount: columnCountAll });
  }, [catalogMetrics, catalogMetricsEu, catalogMetricsApac, catalogMetricsEmea]);

  // let assetTileDataList:InfoDetailRecord[] = [];

  const handleCollectionClick = (record: InfoDetailRecord, region = '') => {
    if (assetRef && assetRef.current && assetRef.current.offsetTop) {
      window.scrollTo({ behavior: 'smooth', top: assetRef.current.offsetTop });
    }
    let collArr;
    if (region === 'EU') {
      collArr = catalogMetricsEu?.collectionData;
    } else if (region === 'APAC') {
      collArr = catalogMetricsApac?.collectionData;
    } else if (region === 'EMEA') {
      collArr = catalogMetricsEmea?.collectionData;
    } else {
      collArr = collectionData;
    }
    const collectionDetails: DataHolder | undefined = collArr !== undefined ? collArr?.find((collection) => collection.name === record.title) : { ...EMPTY_DATA_HOLDER };
    const listCol = collectionDetails !== undefined ? collectionDetails?.assetData?.map((asset) => ({
      title: asset.name,
      line1: asset.desc,
      line2: `Columns - ${asset?.details?.Columns !== undefined ? asset?.details?.Columns : 0}`,
      line3: '',
    })) : [];
    setAssetTileDataList(listCol);
    setselectedCollection({ region, collName: record?.title });
  };

  const handleAssetClick = (record: InfoDetailRecord) => {
    const fetchParams: FetchGetCatalogDetails = {
      accountCode: '',
      organization: '',
      collection: '',
      datasetName: '',
      assetName: record.title,
    };
    dispatch(fetchGetCatalogByAssetNameActionCreator(fetchParams, ShowSnackbar.True));
  };

  return (
    <Grid container item xs={12}>
      {
        catalogMetricsFlag ? (
          <Grid container xs={12} sx={{ width: 'auto', justifyContent: 'center' }}>
            <WppSpinner size="l" />
          </Grid>
        )
          : (

            <Grid container xs={12} sx={{ m: 1 }}>
              <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
                <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }}>
                  <Grid item>
                    <WppIconCube slot="icon-start" height={30} width={30} />
                  </Grid>
                  <Grid item sx={{ ml: 1 }}>
                    <WppTypography tag="h4" type="xl-heading">
                      Available Resources for all regions - NA, EU, APAC, EMEA
                    </WppTypography>
                  </Grid>
                </Grid>
                {/* <Grid container item xs={6} sx={{ justifyContent: 'flex-end', mt: -1, pr: 10 }}>
                  <Box sx={{ m: 1 }}>
                    <WppTextInput
                      name="search"
                      size="m"
                      placeholder={searchPlaceholder}
                      value={value}
                      onWppChange={(e:CustomEvent) => {
                        setValue(e.detail.value);
                        if (typeof searchCallback !== 'undefined') {
                          searchCallback(e.detail.value);
                        }
                      }}
                    >
                      <WppIconSearch slot="icon-start" aria-label="Search icon" onClick={() => null} />
                      <WppIconCross
                        slot="icon-end"
                        aria-label="Clear icon"
                        onClick={() => {
                          setValue('');
                          if (typeof searchCallback !== 'undefined') {
                            searchCallback('');
                          }
                        }}
                      />
                    </WppTextInput>
                  </Box>
                </Grid> */}
              </Grid>
              <Grid container item xs={12} sx={{ justifyContent: 'flex-start', mt: 2 }}>
                {/* <InfoCard infoName="Collections" infoValue={typeof (collectionCount) !== 'undefined' ? collectionCount : 0} />
                <InfoCard infoName="Datasets" infoValue={typeof (datasetCount) !== 'undefined' ? datasetCount : 0} />
                <InfoCard infoName="Assets" infoValue={typeof (assetCount) !== 'undefined' ? assetCount : 0} />
                <InfoCard infoName="Columns" infoValue={typeof (columnCount) !== 'undefined' ? columnCount : 0} /> */}
                <InfoCard infoName="Regions" infoValue={4} />
                <InfoCard infoName="Collections" infoValue={resourcesCount?.collectionCount} />
                <InfoCard infoName="Assets" infoValue={resourcesCount?.assetCount} />
                <InfoCard infoName="Columns" infoValue={resourcesCount?.columnCount} />
              </Grid>
              <Grid container item xs={12} sx={{ justifyContent: 'flex-start', mt: 3, mr: 3 }}>
                {/* <Grid item>
                  <WppIconCollection slot="icon-start" height={30} width={30} />
                </Grid>
                <Grid item sx={{ ml: 1 }}>
                  <WppTypography tag="h4" type="xl-heading">
                    Collections
                  </WppTypography>
                </Grid> */}
                <Grid item xs={12}>
                  <WppAccordion text="Collections - NA Region" expandedByDefault size="l" withDivider className="wpp-spacing-8-bottom ont-collection-title">
                    <Grid container item xs={12} sx={{ justifyContent: 'center', mt: 2 }}>
                      <Box display="flex" flexWrap="wrap" width="100%" gap={2}>
                        {
                          collectionTileDataList?.length > 0
                            ? collectionTileDataList.map((record, index) => (
                              <InfoDetailsCard
                                // eslint-disable-next-line
                                key={index}
                                record={record}
                                callBack={() => {
                                  setShowAssets(true);
                                  handleCollectionClick(record, 'NA');
                                }}
                              />
                            ))
                            : (
                              <WppTypography tag="h4" type="m-midi">
                                No Collections
                              </WppTypography>
                            )
                        }
                      </Box>
                    </Grid>
                  </WppAccordion>
                  <WppAccordion text="Collections - EU Region" className="wpp-spacing-8-bottom" size="l" withDivider>
                    <Grid container item xs={12} sx={{ justifyContent: 'center', mt: 2 }}>
                      <Box display="flex" flexWrap="wrap" width="100%" gap={2}>
                        {
                          collectionTileDataListEu?.length > 0
                            ? collectionTileDataListEu.map((euRecord, idx) => (
                              <InfoDetailsCard
                                // eslint-disable-next-line
                                key={'eu_' + idx}
                                record={euRecord}
                                callBack={() => {
                                  setShowAssets(true);
                                  handleCollectionClick(euRecord, 'EU');
                                }}
                              />
                            ))
                            : (
                              <WppTypography tag="h4" type="m-midi">
                                No Collections
                              </WppTypography>
                            )
                        }
                      </Box>
                    </Grid>
                  </WppAccordion>
                  <WppAccordion text="Collections - APAC Region" className="wpp-spacing-8-bottom" size="l" withDivider>
                    <Grid container item xs={12} sx={{ justifyContent: 'center', mt: 2 }}>
                      <Box display="flex" flexWrap="wrap" width="100%" gap={2}>
                        {
                          collectionTileDataListApac?.length > 0
                            ? collectionTileDataListApac.map((apacRecord, idx) => (
                              <InfoDetailsCard
                                // eslint-disable-next-line
                                key={'apac' + idx}
                                record={apacRecord}
                                callBack={() => {
                                  setShowAssets(true);
                                  handleCollectionClick(apacRecord, 'APAC');
                                }}
                              />
                            ))
                            : (
                              <div>
                                <WppTypography tag="h4" type="m-midi">
                                  No Collections
                                </WppTypography>
                              </div>
                            )
                        }
                      </Box>
                    </Grid>
                  </WppAccordion>
                  <WppAccordion text="Collections - EMEA Region" className="wpp-spacing-8-bottom" size="l" withDivider>
                    <Grid container item xs={12} sx={{ justifyContent: 'center', mt: 2 }}>
                      <Box display="flex" flexWrap="wrap" width="100%" gap={2}>
                        {
                          collectionTileDataListEmea?.length > 0
                            ? collectionTileDataListEmea.map((emeaRecord, idx) => (
                              <InfoDetailsCard
                                // eslint-disable-next-line
                                key={'emea' + idx}
                                record={emeaRecord}
                                callBack={() => {
                                  setShowAssets(true);
                                  handleCollectionClick(emeaRecord, 'EMEA');
                                }}
                              />
                            ))
                            : (
                              <div>
                                <WppTypography tag="h4" type="m-midi">
                                  No Collections
                                </WppTypography>
                              </div>
                            )
                        }
                      </Box>
                    </Grid>
                  </WppAccordion>
                </Grid>
              </Grid>
              {
                showAssets && (
                  <Grid container item>
                    {/* <Grid container item xs={12} sx={{ justifyContent: 'flex-start', mt: 4 }}>
                      <Grid item>
                        <WppIconStatisticDocument slot="icon-start" height={30} width={30} />
                      </Grid>
                      <Grid item sx={{ ml: 1 }}>
                        <WppTypography tag="h4" type="l-strong">
                          <span>Assets</span>
                          {selectedCollection.collName && (
                            <>
                              <span> - </span>
                              <span>{selectedCollection.collName}</span>
                            </>
                          )}
                          {selectedCollection.region && (
                            <>
                              <span> - </span>
                              <span>{selectedCollection.region}</span>
                            </>
                          )}
                        </WppTypography>
                      </Grid>
                    </Grid> */}
                    <Grid container item xs={12} sx={{ justifyContent: 'center', mt: 1 }}>
                      <WppAccordion
                        text={`Assets - ${selectedCollection.collName} - ${selectedCollection.region}`}
                        className="wpp-spacing-8-bottom"
                        size="l"
                        withDivider={false}
                        expandedByDefault
                      >
                        <Grid container item xs={12} sx={{ justifyContent: 'center', mt: 2 }}>
                          <Box display="flex" flexWrap="wrap" width="100%" gap={2}>
                            {
                              assetTileDataList?.length > 0
                                ? assetTileDataList.map((record, index) => (
                                  <InfoDetailsCard
                                    // eslint-disable-next-line
                                    key={index}
                                    record={record}
                                    callBack={() => {
                                      setShowCatalogUI(true);
                                      handleAssetClick(record);
                                    }}
                                  />
                                ))
                                : (
                                  <WppTypography tag="h4" type="m-midi">
                                    No Assets
                                  </WppTypography>
                                )
                            }
                          </Box>
                        </Grid>
                      </WppAccordion>
                    </Grid>
                  </Grid>
                )
              }
              <div id="assetRef" ref={assetRef} />
            </Grid>
          )
      }
    </Grid>
  );
}

export default CatalogListView;
