// This file contains models for the master data API and Redux
import { GridSortModel } from '@mui/x-data-grid';
import { RuleCategory, RuleType } from './config-models.d';

// tables

export interface ListOrder {
  sortByFieldName: string,
  sortOrder: OrderDirection,
  searchKey: string,
  searchValue: string,
}

export interface CategoryTableRow {
  categoryId: number | null,
  categoryName: string | null,
  categoryDesc: string | null,
  categoryParentId: number | null,
  categoryLevel: number | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface TagTableRow {
  tagId: number | null,
  tagName: string | null,
  tagDesc: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface SourceTableRow {
  sourceId: number | null,
  sourceType: string | null,
  sourceName: string | null,
  sourceDesc: string | null,
  sourceDirectory: string | null,
  sourceTechnologyType: string | null,
  sourceConnection: string | null,
  authenticationKey: string | null,
  authenticationValue: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface TargetTableRow {
  targetId: number | null,
  targetType: string | null,
  targetName: string | null,
  targetDesc: string | null,
  targetDirectory: string | null,
  targetTechnologyType: string | null,
  targetConnection: string | null,
  authenticationKey: string | null,
  authenticationValue: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface CollectionTableRow {
  collectionId: number | null,
  collectionName: string | null,
  collectionDesc: string | null,
  collectionSourceType: string | null,
  collectionSourceName: string | null,
  collectionSummary: string | null,
  collectionOwner: string | null,
  collectionAgencyName: string | null,
  collectionDate: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface ContractTableRow {
  contractId: number | null,
  contractName: string | null,
  contractDesc: string | null,
  usageType: string | null,
  contractType: string | null,
  contractStartDt: string | null,
  contractEndDt: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface DatasetTableRow {
  datasetId: number | null,
  datasetName: string | null,
  datasetDesc: string | null,
  datasetCode: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface ValuationTableRow {
  valuationId: number | null,
  valuationName: string | null,
  valuationDesc: string | null,
  valuationRecordCount: number | null,
  valuationFileFlg: string | null,
  valuationDays: number | null,
  valuationType: string | null,
  value: number | null,
  valuationStartDt: string | null,
  valuationEndDt: string | null,
  currency: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

export interface RuleTableRow {
  ruleId: number | null,
  ruleName: string | null,
  errorCode: string | null,
  accountId: number | null,
  ruleDesc: string | null,
  ruleCategory: RuleCategory | null,
  ruleType: RuleType | null,
  ruleExpression: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
}

// redux reducer state

export interface MasterDataState {
  api: {
    flags: {
      fetchGetCategoryListFlag: boolean,
      fetchGetCategoryDataFlag: boolean,
      fetchPostCategoryDataFlag: boolean,
      fetchDeleteCategoryDataFlag: boolean,
      fetchGetTagListFlag: boolean,
      fetchGetTagDataFlag: boolean,
      fetchPostTagDataFlag: boolean,
      fetchDeleteTagDataFlag: boolean,
      fetchGetSourceListFlag: boolean,
      fetchGetSourceDataFlag: boolean,
      fetchPostSourceDataFlag: boolean,
      fetchDeleteSourceDataFlag: boolean,
      fetchGetTargetListFlag: boolean,
      fetchGetTargetDataFlag: boolean,
      fetchPostTargetDataFlag: boolean,
      fetchDeleteTargetDataFlag: boolean,
      fetchGetCollectionListFlag: boolean,
      fetchGetCollectionDataFlag: boolean,
      fetchPostCollectionDataFlag: boolean,
      fetchDeleteCollectionDataFlag: boolean,
      fetchGetContractListFlag: boolean,
      fetchGetContractDataFlag: boolean,
      fetchPostContractDataFlag: boolean,
      fetchDeleteContractDataFlag: boolean,
      fetchGetDatasetListFlag: boolean,
      fetchGetDatasetDataFlag: boolean,
      fetchPostDatasetDataFlag: boolean,
      fetchDeleteDatasetDataFlag: boolean,
      fetchGetValuationListFlag: boolean,
      fetchGetValuationDataFlag: boolean,
      fetchPostValuationDataFlag: boolean,
      fetchDeleteValuationDataFlag: boolean,
      fetchGetRuleListFlag: boolean,
      fetchGetRuleDataFlag: boolean,
      fetchPostRuleDataFlag: boolean,
      fetchDeleteRuleDataFlag: boolean,
    },
    data: {
      fetchGetCategoryList: CategoryResponse,
      fetchGetCategoryData: CategoryDto,
      fetchGetTagList: TagResponse,
      fetchGetTagData: TagDto,
      fetchGetSourceList: SourceResponse,
      fetchGetSourceData: SourceResponse,
      fetchGetTargetList: TargetResponse,
      fetchGetTargetData: TargetResponse,
      fetchGetCollectionList: CollectionResponse,
      fetchGetCollectionData: CollectionDto,
      fetchGetContractList: ContractResponse,
      fetchGetContractData: ContractResponse,
      fetchGetDatasetList: DatasetResponse,
      fetchGetDatasetData: DatasetDto,
      fetchGetValuationList: ValuationResponse,
      fetchGetValuationData: ValuationResponse,
      fetchGetRuleList: RuleResponse,
      fetchGetRuleData: RuleDto,
    },
  },
  tables: {
    selectedCategoryTableRow: CategoryTableRow,
    selectedTagTableRow: TagTableRow,
    selectedSourceTableRow: SourceTableRow,
    selectedTargetTableRow: TargetTableRow,
    selectedCollectionTableRow: CollectionTableRow,
    selectedContractTableRow: ContractTableRow,
    selectedDatasetTableRow: DatasetTableRow,
    selectedValuationTableRow: ValuationTableRow,
    selectedRuleTableRow: RuleTableRow,
  },
  masterDataEdits: number,
  sortModel: GridSortModel,
}

// redux actions

export enum MasterDataActionOptions {
  SET_MASTER_DATA_API_FLAG = 'SET_MASTER_DATA_API_FLAG',
  SET_MASTER_DATA_API_DATA = 'SET_MASTER_DATA_API_DATA',
  SET_MASTER_DATA_SELECTED_TABLE_ROW = 'SET_MASTER_DATA_SELECTED_TABLE_ROW',
  SET_MASTER_DATA_SORT_MODEL = 'SET_MASTER_DATA_SORT_MODEL',
  SET_MASTER_DATA_DATA_EDITS = 'SET_MASTER_DATA_DATA_EDITS',
}

export interface Flag {
  [key: string]: boolean;
}

export interface Data {
  [key: string]: any
}

export interface Count {
  [key: string]: number
}

export interface SelectedRow {
  [key: string]: CategoryTableRow |
  TagTableRow |
  SourceTableRow |
  TargetTableRow |
  CollectionTableRow |
  ContractTableRow |
  DatasetTableRow |
  ValuationTableRow |
  RuleTableRow
}

export interface MasterDataAction {
  type: MasterDataActionOptions,
  payload: Flag |
  Data |
  CategoryTableRow |
  TagTableRow |
  SourceTableRow |
  TargetTableRow |
  CollectionTableRow |
  ContractTableRow |
  DatasetTableRow |
  ValuationTableRow |
  RuleTableRow
}

export enum MasterDataTableNames {
  CATEGORY = 'category',
  COLLECTION = 'collection',
  CONTRACT = 'contract',
  DATASET = 'dataset',
  FILE_CONFIG = 'fileconfig',
  SOURCE = 'source',
  TAG = 'tag',
  TARGET = 'target',
  VALUATION = 'valuation',
  RULE = 'rule',
}

export enum MasterDataPostType {
  CREATE,
  UPDATE,
  DELETE,
}

// fetching models

// common for master data /all GET
export interface FetchGetMasterDataParams {
  page: number,
  limit: number,
  sortByFieldName: string,
  sortOrder: 'asc' | 'desc' | null,
  searchKey: string,
  searchValue: string,
}

// common for master data /{id} GET
export interface FetchGetMasterDataByIdParams {
  id: number,
}

// common for master data /{id} DELETE
export interface FetchDeleteMasterDataByIdParams {
  id: number,
}

export interface MasterDataResponse {
  response:
  CategoryResponse |
  CollectionResponse |
  ContractResponse |
  DatasetResponse |
  FileConfigResponse |
  SourceResponse |
  TagResponse |
  TargetResponse |
  ValuationResponse |
  RuleResponse
}

// master data models

export interface ValuationDto {
  valuationId: number,
  valuationName: string,
  valuationDesc: string,
  valuationRecordCount: number,
  valuationFileFlg: string,
  valuationDays: number,
  valuationType: string,
  value: number,
  valuationStartDt: string,
  valuationEndDt: string,
  currency: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum OrderNullHandling {
  NATIVE = 'NATIVE',
  NULLS_FIRST = 'NULLS_FIRST',
  NULLS_LAST = 'NULLS_LAST',
}

export interface Order {
  direction: OrderDirection,
  property: string,
  ignoreCase: boolean,
  nullHandling: OrderNullHandling,
  descending: boolean,
  ascending: boolean,
}

export interface Pagination {
  records: number,
  page: number,
  limit: number,
  totalRecords: number,
  totalPages: number,
}

export interface Status {
  code: string,
  message: string,
  desc: string,
}

export interface ValuationResponse {
  valuation: ValuationDto,
  valuationList: ValuationDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface TargetDto {
  targetId: number,
  targetType: string,
  targetName: string,
  targetDesc: string,
  targetDirectory: string,
  targetTechnologyType: string,
  targetConnection: string,
  authenticationKey: string,
  authenticationValue: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface TargetResponse {
  target: TargetDto
  targetList: TargetDto[]
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface TagDto {
  tagId: number,
  tagName: string,
  tagDesc: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface TagResponse {
  tag: TagDto,
  tagList: TagDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface SourceDto {
  sourceId: number,
  sourceType: string,
  sourceName: string,
  sourceDesc: string,
  sourceDirectory: string,
  sourceTechnologyType: string,
  sourceConnection: string,
  authenticationKey: string,
  authenticationValue: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface SourceResponse {
  source: SourceDto,
  sourceList: SourceDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface FileConfigDto {
  fileConfigId: number,
  fileFormat: string,
  recordDelimiter: string,
  filePattern: string,
  schedule: string,
  skipLinesFromTop: string,
  escapeChar: string,
  quoteChar: string,
  fileCharSet: string,
  skipRecLenChkFlg: string,
  headerRecFlg: string,
  thresholdChkFlg: string,
  thresholdPercentage: string,
  thresholdNumOfRec: string,
  duplicateRecChkFlg: string,
  fatalFileFlg: string,
  dupRecOnAllRecFlg: string,
  dupRecCntThld: string,
  dupPercentThld: string,
  dropDupFlg: string,
  retainDupRecPos: string,
  dupRecOrderby: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface FileConfigResponse {
  fileConfig: FileConfigDto,
  fileConfigList: FileConfigDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface DatasetDto {
  datasetId: number,
  datasetName: string,
  datasetDesc: string,
  datasetCode: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface DatasetResponse {
  dataset: DatasetDto,
  datasetList: DatasetDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface ContractDto {
  contractId: number,
  contractName: string,
  contractDesc: string,
  usageType: string,
  contractType: string,
  contractStartDt: string,
  contractEndDt: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface ContractResponse {
  contract: ContractDto,
  contractList: ContractDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface CollectionDto {
  collectionId: number,
  collectionName: string,
  collectionDesc: string,
  collectionSourceType: string,
  collectionSourceName: string,
  collectionSummary: string,
  collectionOwner: string,
  collectionAgencyName: string,
  collectionDate: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface CollectionResponse {
  collection: CollectionDto,
  collectionList: CollectionDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface CategoryDto {
  categoryId: number,
  categoryName: string,
  categoryDesc: string,
  categoryParentId: number,
  categoryLevel: number,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface CategoryResponse {
  category: CategoryDto,
  categoryList: CategoryDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface RuleDto {
  ruleId: number,
  ruleName: string,
  errorCode: string | null,
  accountId: number,
  ruleDesc: string,
  ruleCategory: RuleCategory,
  ruleType: RuleType,
  ruleExpression: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface RuleResponse {
  rule: RuleDto,
  ruleList: RuleDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface MaseterConfigReponse {
  QUOTE: string[],
  NOTIFICATION: string[],
  DATATYPE: string[],
  RULE_CATEGORY: string[],
  RULE_TYPE: string[],
  CHARACTER: string[],
  DELIMITER: string[],
  ESCAPE: string[],
}

// Error Handling

export interface ErrorStatus {
  code: string,
  message: string
  desc: string | null,
  errorList: string[]
}
export interface MasterDataError {
  // assetId: number,
  status: ErrorStatus
}
