import React, { useEffect } from 'react';
import {
  Card,
  Grid,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StatusOptions, SelectType, ISelectOption } from '../../../services/models/select-models.d';
import Select from '../../../ui-component/select';
import {
  FetchGetMasterDataParams,
  MasterDataState, MasterDataTableNames, CategoryDto, MasterDataPostType,
} from '../../../services/models/master-data-models.d';
import FormButtons from '../../../ui-component/form-buttons';
import { DEFAULT_MASTER_DATA_PAGINATION, DESELECTED_CATEGORY_TABLE_ROW, EMPTY_CATEGORY } from '../../../services/defaults/master-data-defaults';
import { StoreState } from '../../../store';
import {
  fetchGetMasterDataActionCreator, setMasterDataDataActionCreator, setMasterDataEditsActionCreator, setMasterDataSelectedTableRowActionCreator, fetchPostMasterDataActionCreator,
} from '../../../store/actions/master-data-actions';
import { IdPrefix } from '../../../services/models/ui-component-model.d';
import useAuthUser from '../../../hooks/use-auth-user';

const AssetCategoryForm = function RenderCategoryTable() {
  const dispatch = useDispatch();
  const activeUser = useAuthUser();
  const itemWidth = '150px';

  const categoryData:MasterDataState['api']['data']['fetchGetCategoryData'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCategoryData,
  );

  const masterDataEdits:MasterDataState['masterDataEdits'] = useSelector(
    (state: StoreState) => state.masterDataReducer.masterDataEdits,
  );

  // This useEffect handles UI changes, such as a user editing a field
  useEffect(() => {}, [masterDataEdits]);

  let categoryList:MasterDataState['api']['data']['fetchGetCategoryList']['categoryList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCategoryList?.categoryList,
  );

  if (categoryList === null || typeof categoryList === 'undefined') {
    categoryList = [];
  }

  useEffect(() => {
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CATEGORY));
  }, [dispatch]);

  const handleFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newResponse:any = categoryData;
    const dataName = 'fetchGetCategoryData';
    const editCounterName = 'masterDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newResponse[field] = newValue;
    }
    dispatch(setMasterDataDataActionCreator({ [dataName]: newResponse }));
    dispatch(setMasterDataEditsActionCreator({ [editCounterName]: masterDataEdits + 1 }));
  };

  const onClear = () => {
    const fetchGetCategoryData = 'fetchGetCategoryData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetCategoryData]: { ...EMPTY_CATEGORY } }));
    const selectedTableName = 'selectedCategoryTableRow';
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_CATEGORY_TABLE_ROW } }));
  };

  const handlePostCategory = (postType:MasterDataPostType) => {
    const category: CategoryDto = categoryData;
    if (typeof activeUser.email !== 'undefined') {
      category.updatedUserId = activeUser.email;
      if (postType === MasterDataPostType.CREATE) {
        category.createdUserId = activeUser.email;
      }
    }
    dispatch(fetchPostMasterDataActionCreator(category, postType, MasterDataTableNames.CATEGORY));
    onClear();
  };

  const handleSubmit = async () => {
    let categoryId = -1;
    const categoryObj = categoryData;
    ({ categoryId } = categoryObj);
    if (categoryId === -1) {
      handlePostCategory(MasterDataPostType.CREATE);
    } else {
      handlePostCategory(MasterDataPostType.UPDATE);
    }
  };

  // let categoryId;
  let categoryName;
  let categoryDesc;
  // let categoryLevel;
  let categoryParentId;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;

  // const categoryObj = selectedCategoryTableRow.categoryList[0];

  if (typeof categoryData !== 'undefined'
    && categoryData !== null) {
    ({
      // categoryId,
      categoryName,
      categoryDesc,
      // categoryLevel,
      categoryParentId,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = categoryData);
  }

  const categoryListSelectOptions:ISelectOption[] = categoryList.map((category) => ({ id: category.categoryId, value: category.categoryName }));
  return (
    <Grid item xs={12}>
      <Card sx={{
        p: 2, elevation: 0, width: '100%', height: '100%',
      }}
      >
        <Grid container>
          <FormButtons
            onSave={handleSubmit}
            onClear={onClear}
          />
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                // id={`${IdPrefix.CATEGORY_FORM}-name-field`}
                id="category-name"
                variant="standard"
                focused
                required
                label="Category Name"
                value={typeof (categoryName) !== 'undefined' ? categoryName : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'categoryName')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                id={`${IdPrefix.CATEGORY_FORM}-description-field`}
                rows={3}
                variant="standard"
                focused
                required
                label="Category Description"
                value={typeof (categoryDesc) !== 'undefined' ? categoryDesc : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'categoryDesc')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                id={`${IdPrefix.CATEGORY_FORM}-parent-category-field`}
                title="Parent Category"
                value={typeof (categoryParentId) !== 'undefined' ? categoryParentId : ''}
                options={categoryListSelectOptions}
                useIdOrValue={SelectType.useId}
                onChange={(
                  event:SelectChangeEvent,
                ) => {
                  const id = event.target.value;
                  handleFieldChange(id, 'categoryParentId');
                  if (id !== null) {
                    const selectedParentCategory = categoryList.find((obj) => obj.categoryId === parseInt(id, 10));
                    const level = selectedParentCategory?.categoryLevel !== undefined ? selectedParentCategory.categoryLevel + 1 : 0;
                    handleFieldChange(level.toString(), 'categoryLevel');
                  }
                }}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                id={`${IdPrefix.CATEGORY_FORM}-status-field`}
                title="Status"
                value={typeof (status) !== 'undefined' ? status : ''}
                options={StatusOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(
                  event:SelectChangeEvent,
                ) => handleFieldChange(event.target.value, 'status')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default AssetCategoryForm;
