import React, {
  useEffect,
  useState,
} from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Buffer } from 'buffer';
import RuleTable from './rule-table';
import RuleForm from './rule-form';
import MainContent from '../../../layout/MainLayout/MainContent';
import {
  FetchGetMasterDataParams, MasterDataTableNames, MasterDataState, OrderDirection, RuleDto, MasterDataPostType,
} from '../../../services/models/master-data-models.d';
import {
  DEFAULT_MASTER_DATA_PAGINATION, DESELECTED_RULE_TABLE_ROW, EMPTY_RULE,
} from '../../../services/defaults/master-data-defaults';
import { StatusOption } from '../../../services/models/select-models.d';
import { RuleCategory, RuleType } from '../../../services/models/config-models.d';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { StoreState } from '../../../store';
import {
  fetchGetMasterDataActionCreator, fetchPostMasterDataActionCreator, setMasterDataDataActionCreator, setMasterDataSelectedTableRowActionCreator,
} from '../../../store/actions/master-data-actions';
import fetchGetConfigListActionCreator from '../../../store/actions/config-actions';

function Rule() {
  window.Buffer = window.Buffer || Buffer;
  const dispatch = useDispatch();

  const selectedRuleTableRow:MasterDataState['tables']['selectedRuleTableRow'] = useSelector(
    (state: StoreState) => state.masterDataReducer.tables?.selectedRuleTableRow,
  );

  useEffect(() => {
    // get master data for dropdowns
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    fetchGetMasterDataParams.limit = 10;
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.RULE));
    // get config for dropdowns
    dispatch(fetchGetConfigListActionCreator(ShowSnackbar.False));
  }, [dispatch]);

  let ruleList:MasterDataState['api']['data']['fetchGetRuleList']['ruleList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetRuleList?.ruleList,
  );

  if (ruleList === null || typeof ruleList === 'undefined') {
    ruleList = [];
  }

  const fetchGetRuleListFlag: MasterDataState['api']['flags']['fetchGetRuleListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetRuleListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetRuleList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetRuleList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetRuleList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetRuleList?.listOrder,
  );

  let limit = 0; // The page size, or max records displayed
  if (typeof pagination !== 'undefined') {
    ({ limit } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
    } = listOrder);
  }

  const tableHeight = '730px';
  // const tableWidth = '77vw';
  const tableWidth = '101%';
  const [showRuleUI, setShowRuleUI] = useState<boolean>(false);

  const getSelectedRule = () : RuleDto => {
    const ruleDto: RuleDto = { ...EMPTY_RULE };
    ruleDto.ruleId = selectedRuleTableRow.ruleId !== null ? selectedRuleTableRow.ruleId : -1;
    ruleDto.ruleName = selectedRuleTableRow.ruleName !== null ? selectedRuleTableRow.ruleName : '';
    ruleDto.ruleDesc = selectedRuleTableRow.ruleDesc !== null ? selectedRuleTableRow.ruleDesc : '';
    ruleDto.status = selectedRuleTableRow.status !== null ? selectedRuleTableRow.status : '';
    ruleDto.errorCode = selectedRuleTableRow.errorCode !== null ? selectedRuleTableRow.errorCode : '';
    ruleDto.accountId = selectedRuleTableRow.accountId !== null ? selectedRuleTableRow.accountId : -1;
    ruleDto.ruleCategory = selectedRuleTableRow.ruleCategory !== null ? selectedRuleTableRow.ruleCategory : RuleCategory.BASIC;
    ruleDto.ruleType = selectedRuleTableRow.ruleType !== null ? selectedRuleTableRow.ruleType : RuleType.COMPARE;
    ruleDto.ruleExpression = selectedRuleTableRow.ruleExpression !== null ? selectedRuleTableRow.ruleExpression : '';
    ruleDto.createdUserId = selectedRuleTableRow.createdUserId !== null ? selectedRuleTableRow.createdUserId : '';
    ruleDto.createdDatetime = selectedRuleTableRow.createdDatetime !== null ? selectedRuleTableRow.createdDatetime : '';
    ruleDto.updatedUserId = selectedRuleTableRow.updatedUserId !== null ? selectedRuleTableRow.updatedUserId : '';
    ruleDto.updatedDatetime = selectedRuleTableRow.updatedDatetime !== null ? selectedRuleTableRow.updatedDatetime : '';
    return ruleDto;
  };

  // This currently sets status to inactive
  const handleDelete = async () => {
    const ruleDto: RuleDto = getSelectedRule();
    if (ruleDto.ruleId !== -1) {
      ruleDto.status = StatusOption.Inactive;
      // Fix for backend issue is to add base64 encoding
      ruleDto.ruleExpression = Buffer.from(ruleDto.ruleExpression).toString('base64');
      dispatch(fetchPostMasterDataActionCreator(ruleDto, MasterDataPostType.DELETE, MasterDataTableNames.RULE));
      setShowRuleUI(false);
      const fetchGetRuleData = 'fetchGetRuleData';
      dispatch(setMasterDataDataActionCreator({ [fetchGetRuleData]: { ...EMPTY_RULE } }));
      const selectedTableName = 'selectedRuleTableRow';
      dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_RULE_TABLE_ROW } }));
    }
  };

  const handleSearchRule = (newSearchValue: string) => {
    if (!fetchGetRuleListFlag) {
      const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = 'ruleName';
      fetchGetMasterDataParams.searchValue = newSearchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.RULE, ShowSnackbar.True));
    }
  };

  return (
    <MainContent
      title="Rule"
      buttonTitles={{
        addTitle: 'Add Rule',
        editTitle: 'Edit Rule',
        deleteTitle: 'Delete Rule',
        summaryTitle: 'Rule Summary',
        importTitle: 'Import Rule',
        copyTitle: 'Copy Rule',
      }}
      addCallback={() => {
        const fetchGetRuleData = 'fetchGetRuleData';
        const ruleDto: RuleDto = { ...EMPTY_RULE };
        dispatch(setMasterDataDataActionCreator({ [fetchGetRuleData]: ruleDto }));
        setShowRuleUI(!showRuleUI);
      }}
      editCallback={() => {
        setShowRuleUI(!showRuleUI);
      }}
      deleteCallback={() => handleDelete()}
      summaryCallback={() => null} // TODO
      importCallback={() => null} // TODO
      copyCallback={() => null} // TODO
      searchCallback={(newSearchValue) => handleSearchRule(newSearchValue)}
      searchPlaceholder="Search Rules"
      editDisabled={selectedRuleTableRow?.ruleId === null}
      deleteDisabled={selectedRuleTableRow?.ruleId === null}
      copyDisabled
      summaryDisabled
      importDisabled
    >
      <Grid
        container
        item
        sx={{
          width: tableWidth, height: tableHeight, justifyContent: 'space-between',
        }}
      >
        <Grid item xs={showRuleUI ? 8 : 12}>
          <RuleTable rows={ruleList} />
        </Grid>
        {showRuleUI
          ? (
            <Grid container item xs={4} sx={{ pl: 2.5 }}>
              <RuleForm />
            </Grid>
          )
          : <Grid item />}
      </Grid>
    </MainContent>
  );
}

export default Rule;
