import React, {
  Dispatch, SetStateAction, useCallback, useRef,
} from 'react';
import 'react-js-cron/dist/styles.css';
// material-ui
import {
  Divider, Grid, SelectChangeEvent, TextField, TextFieldProps,
} from '@mui/material';
import Cron, { CronError } from 'react-js-cron';
import { WppToggle } from '@platform-ui-kit/components-library-react';
import Select from '../../../../ui-component/select';
import { StatusOptions, SelectType, BooleanType } from '../../../../services/models/select-models.d';
import FormButtons from '../../../../ui-component/form-buttons';

export interface SchedulerFormProps {
  scheduleExpression: string,
  setScheduleExpression: Dispatch<SetStateAction<string>>,
  scheduleExpressionText: string,
  setScheduleExpressionText: Dispatch<SetStateAction<string>>,
  status: string,
  setStatus: Dispatch<SetStateAction<string>>,
  wantNotification: boolean,
  setWantNotification: Dispatch<SetStateAction<boolean>>,
  handleFieldChange:(newValue:string | Date | boolean, section: string, field: string) => void,
  handlePutAsset: () => void;
  error: CronError,
  onError: Dispatch<SetStateAction<CronError>>,
}

function SchedulerForm(props: SchedulerFormProps) {
  const {
    scheduleExpression,
    setScheduleExpression,
    scheduleExpressionText,
    setScheduleExpressionText,
    status,
    setStatus,
    wantNotification,
    setWantNotification,
    handleFieldChange,
    handlePutAsset,
    // eslint-disable-next-line
    error,
    onError,
  } = props;

  const inputRef = useRef<TextFieldProps>(null);
  const customSetValue = useCallback(
    (newValue: string) => {
      setScheduleExpression(newValue);
      setScheduleExpressionText(newValue);
    },
    [setScheduleExpression, setScheduleExpressionText],
  );
  const handleSubmit = async () => {
    handleFieldChange(wantNotification ? BooleanType.True : BooleanType.False, 'asset', 'notificationEnableFlg');
    handleFieldChange(scheduleExpressionText, 'fileConfig', 'schedule');
    handlePutAsset();
  };
  const defaultSchedule = '0 0 * * *';
  const resetSchedule = async () => {
    setScheduleExpression(defaultSchedule);
    setScheduleExpressionText(defaultSchedule);
    handleFieldChange(defaultSchedule, 'fileConfig', 'schedule');
  };
  return (
    <Grid container xs={12}>
      <FormButtons
        onSave={handleSubmit}
        onClear={resetSchedule}
      />
      <Grid container item xs={12} sx={{ m: 1, mt: 1 }}>
        <Grid item xs={4}>
          <TextField
            variant="standard"
            focused
            label="Expression"
            value={scheduleExpressionText}
            inputRef={inputRef}
            onBlur={(event) => {
              setScheduleExpression(event.target.value);
            }}
            onChange={(event: any) => {
              customSetValue(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Select
            title="Status"
            value={typeof (status) !== 'undefined' ? status : ''}
            options={StatusOptions}
            useIdOrValue={SelectType.useValue}
            onChange={(event: SelectChangeEvent) => setStatus(event.target.value)}
          />
        </Grid>
        <Grid item xs={4} sx={{ mt: 3, mb: 3 }}>
          <Divider>OR</Divider>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Cron
            clearButton={false}
            value={scheduleExpression}
            setValue={customSetValue}
            onError={onError}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2 }}
        >
          <WppToggle
            label="Enable Notifications"
            name="wantNotification"
            required
            checked={wantNotification}
            onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
              if (checked) {
                setWantNotification(true);
              } else {
                setWantNotification(false);
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SchedulerForm;
