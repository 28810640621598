import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid, InputAdornment, SelectChangeEvent, TextField,
} from '@mui/material';
import {
  WppButton, WppIconAvailableCheckmark, WppIconDone, WppIconPlus, WppIconTrash, WppInlineMessage, WppToggle,
} from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_ASSET_PAGINATION, DESELECTED_ATTRIBUTE_RULES_TABLE_ROW, EMPTY_ASSET_ATTRBT_RULE } from '../../../../../../services/defaults/asset-defaults';
import {
  AssetAttrbtDto,
  AssetAttrbtRuleDto, AssetMultiStepGetResponse, AssetState, FetchGetAssetByIdParams, FetchGetAssetsParams,
} from '../../../../../../services/models/asset-models.d';
import { ConfigState, RuleCategory, RuleType } from '../../../../../../services/models/config-models.d';
import { MasterDataState } from '../../../../../../services/models/master-data-models.d';
import {
  ISelectOption, SelectType, StatusOption, StatusOptions,
} from '../../../../../../services/models/select-models.d';
import { ShowSnackbar } from '../../../../../../services/models/snackbar-models.d';
import { StepAccordion } from '../../../../../../services/models/stepper-models.d';
import { DefaultRuleFormError, IAttributeRuleFormErrors } from '../../../../../../services/models/validation-models.d';
import { StoreState } from '../../../../../../store';
import {
  fetchGetAssetByIdActionCreator,
  fetchGetAssetsActionCreator,
  setAssetDataActionCreator,
  setAssetDataEditsActionCreator,
  setAssetSelectedTableRowActionCreator,
  setFormDataActionCreator,
} from '../../../../../../store/actions/asset-actions';
import Select from '../../../../../../ui-component/select';
import { setSnackbarActionCreator, snackbarMaker } from '../../../../../../store/actions/snackbar-actions';
import Tooltip from '../../../../../../ui-component/tooltip';

export interface RuleUIProps {
  assetData:AssetMultiStepGetResponse,
  validate:(accordion: number, formErrors: IAttributeRuleFormErrors) => void;
}

function RuleFormUI(props:RuleUIProps) {
  const [deleteReadyToConfirm, setDeleteReadyToConfirm] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<IAttributeRuleFormErrors>({});
  const textFieldWidth = '130px';
  const selectWidth = 130;
  const {
    assetData,
    validate,
  } = props;

  const dispatch = useDispatch();

  const selectedRulesTableRow:AssetState['tables']['selectedRulesTableRow'] = useSelector(
    (state: StoreState) => state.assetReducer.tables.selectedRulesTableRow,
  );

  const selectedAttributeTableRow:AssetState['tables']['selectedAttributeTableRow'] = useSelector(
    (state: StoreState) => state.assetReducer.tables.selectedAttributeTableRow,
  );

  const attributeRuleFormData:AssetState['formData']['attributeRuleFormData'] = useSelector(
    (state: StoreState) => state.assetReducer.formData.attributeRuleFormData,
  );

  const assetDataEdits:AssetState['assetDataEdits'] = useSelector(
    (state: StoreState) => state.assetReducer.assetDataEdits,
  );

  let assetAttrbtId = -1;
  let ruleId = -1;
  // let ruleExeSeqNum;
  let ruleName;
  // let ruleDesc;
  let ruleCategory;
  let ruleExpression;
  let ruleType;
  // let ruleOperator;
  // let ruleValue;
  let thresholdPercentage;
  // let existCheckFlg;
  let lookupTable;
  let lookupColumn;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;

  const [addNewRule, setAddNewRule] = useState<boolean>(false);
  const [selectedAssetName, setSelectedAssetName] = useState<string>(typeof (lookupTable) !== 'undefined' ? lookupTable : '');

  let selectedAttrbtIndex = -1;
  const attrbtSelected = selectedAttributeTableRow.assetAttrbtId !== null && typeof (assetData?.assetAttrbtList) !== 'undefined';
  if (selectedAttributeTableRow.assetAttrbtId !== null && typeof (assetData.assetAttrbtList) !== 'undefined') {
    assetData.assetAttrbtList.map((attrbt:AssetAttrbtDto, index:number) => {
      if (attrbt.assetAttrbtId === selectedAttributeTableRow.assetAttrbtId) {
        selectedAttrbtIndex = index;
      }
      return null;
    });
  }
  let selectedRuleIndex = -1;
  const ruleSelected = selectedRulesTableRow.ruleId !== null
    && typeof assetData?.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList !== 'undefined'
    && assetData?.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList !== null;
  if (selectedRulesTableRow.ruleId !== null && assetData?.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList !== null) {
    assetData?.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList.map((attrbtRule:AssetAttrbtRuleDto, index:number) => {
      if (attrbtRule.ruleId === selectedRulesTableRow.ruleId) {
        selectedRuleIndex = index;
      }
      return null;
    });
  }
  if (typeof attributeRuleFormData !== 'undefined' && typeof attributeRuleFormData !== null) {
    ({
      // eslint-disable-next-line
      assetAttrbtId,
      ruleId,
      // ruleExeSeqNum,
      ruleName,
      // ruleDesc,
      ruleCategory,
      ruleExpression,
      ruleType,
      // ruleOperator,
      // ruleValue,
      thresholdPercentage,
      // existCheckFlg,
      lookupTable,
      lookupColumn,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = attributeRuleFormData);
  }

  const handleAttributeRuleFormFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newData:any = attributeRuleFormData;
    const dataName = 'attributeRuleFormData';
    const editCounterName = 'assetDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newData[field] = newValue;
    }
    dispatch(setFormDataActionCreator({ [dataName]: newData }));
    dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
  };

  const configList:ConfigState['api']['data']['fetchGetConfigList'] = useSelector(
    (state: StoreState) => state.configReducer?.api?.data?.fetchGetConfigList,
  );
  const ruleList:MasterDataState['api']['data']['fetchGetRuleList']['ruleList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetRuleList?.ruleList,
  );
  const assetList:AssetState['api']['data']['fetchGetAssetList']['assets'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetList?.assets,
  );
  const attrbtList:AssetState['api']['data']['fetchGetAssetByIdForLookupRules']['assetAttrbtList'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetByIdForLookupRules?.assetAttrbtList,
  );

  // TO-DO Get attributeList from selectedAsset.
  let selectedAsset;
  if (assetList !== null && assetList.length > 0) {
    selectedAsset = assetList.find((obj) => obj.assetName === selectedAssetName);
  }
  const selectedAssetId = typeof (selectedAsset?.assetId) !== 'undefined' ? selectedAsset?.assetId : -1;
  useEffect(() => {
    // const defaultSort:string[] = [];
    const fetchGetAssetsParams: FetchGetAssetsParams = DEFAULT_ASSET_PAGINATION;
    fetchGetAssetsParams.limit = 100;
    fetchGetAssetsParams.sortByFieldName = 'assetName';
    fetchGetAssetsParams.sortOrder = 'desc';
    dispatch(fetchGetAssetsActionCreator(fetchGetAssetsParams, ShowSnackbar.True));

    if (selectedAssetId !== -1) {
      const fetchGetAssetByIdParams:FetchGetAssetByIdParams = {
        id: selectedAssetId,
        requestedEntities: [],
      };
      // get asset
      dispatch(fetchGetAssetByIdActionCreator(fetchGetAssetByIdParams, true));
    }
  }, [dispatch, selectedAssetId]);

  const onAddNewRule = (checked: boolean) => {
    // if (checked) {
    //   initNewRule();
    // }
    setAddNewRule(checked);
  };

  const onAdd = () => {
    let assetAttrbtRuleList:AssetAttrbtRuleDto[] = [];
    if (typeof (assetData.assetAttrbtList) !== 'undefined'
    && assetData.assetAttrbtList !== null
    && assetData.assetAttrbtList[selectedAttrbtIndex] !== null
    && assetData.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList !== null
    && assetData.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList !== undefined) {
      ({ assetAttrbtRuleList } = assetData.assetAttrbtList[selectedAttrbtIndex]);
    }
    assetAttrbtRuleList?.push({ ...attributeRuleFormData });
    const errors = { ...formErrors };
    if (!errors[selectedAttrbtIndex]) {
      errors[selectedAttrbtIndex] = [];
    }
    errors[selectedAttrbtIndex].push({ ...DefaultRuleFormError });
    validate(StepAccordion.Rule, errors);
    setFormErrors(errors);
    const attrbtRuleListLength = assetAttrbtRuleList?.length;
    assetAttrbtRuleList[attrbtRuleListLength - 1].assetAttrbtId = selectedAttributeTableRow?.assetAttrbtId !== null ? selectedAttributeTableRow?.assetAttrbtId : -1;
    assetAttrbtRuleList[attrbtRuleListLength - 1].ruleId = assetAttrbtRuleList[attrbtRuleListLength - 1].ruleId !== null
      && assetAttrbtRuleList[attrbtRuleListLength - 1].ruleId !== -1 ? assetAttrbtRuleList[attrbtRuleListLength - 1].ruleId : Math.random() + attrbtRuleListLength;
    const newAsset = { ...assetData };
    newAsset.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList = [...assetAttrbtRuleList];
    const dataName = 'fetchGetAssetById';
    const editCounterName = 'assetDataEdits';
    const attributeRuleFormDataName = 'attributeRuleFormData';
    const tableName = 'selectedRulesTableRow';
    dispatch(setAssetDataActionCreator({ [dataName]: newAsset }));
    dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: { ...DESELECTED_ATTRIBUTE_RULES_TABLE_ROW } }));
    dispatch(setFormDataActionCreator({ [attributeRuleFormDataName]: { ...EMPTY_ASSET_ATTRBT_RULE } }));
    const successMessage = 'Added a new Attribute Rule';
    dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
  };

  const onEdit = () => {
    let assetAttrbtRuleList:AssetAttrbtRuleDto[] = [];
    if (typeof (assetData.assetAttrbtList) !== 'undefined'
    && assetData.assetAttrbtList !== null
    && assetData.assetAttrbtList[selectedAttrbtIndex] !== null
    && assetData.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList !== null) {
      ({ assetAttrbtRuleList } = assetData.assetAttrbtList[selectedAttrbtIndex]);
    }
    assetAttrbtRuleList.splice(selectedRuleIndex, 1, { ...attributeRuleFormData });
    const errors = { ...formErrors };
    if (!errors[selectedAttrbtIndex]) {
      errors[selectedAttrbtIndex] = [];
    }
    errors[selectedAttrbtIndex].splice(selectedRuleIndex, 1, { ...DefaultRuleFormError });
    validate(StepAccordion.Rule, errors);
    setFormErrors(errors);
    const newAsset = { ...assetData };
    newAsset.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList = [...assetAttrbtRuleList];
    const dataName = 'fetchGetAssetById';
    const editCounterName = 'assetDataEdits';
    const attributeRuleFormDataName = 'attributeRuleFormData';
    const tableName = 'selectedRulesTableRow';
    dispatch(setAssetDataActionCreator({ [dataName]: newAsset }));
    dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: { ...DESELECTED_ATTRIBUTE_RULES_TABLE_ROW } }));
    dispatch(setFormDataActionCreator({ [attributeRuleFormDataName]: { ...EMPTY_ASSET_ATTRBT_RULE } }));
    const successMessage = 'Attribute Rule Edited';
    dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
  };

  const onDelete = () => {
    if (deleteReadyToConfirm) {
      let assetAttrbtRuleList:AssetAttrbtRuleDto[] = [];
      if (attrbtSelected && ruleSelected
        && (typeof selectedRuleIndex !== 'undefined') && selectedRuleIndex !== -1) {
        if (typeof (assetData.assetAttrbtList) !== 'undefined'
          && assetData.assetAttrbtList !== null
          && assetData.assetAttrbtList[selectedAttrbtIndex] !== null
          && assetData.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList !== null) {
          ({ assetAttrbtRuleList } = assetData.assetAttrbtList[selectedAttrbtIndex]);
        }
        if (Number.isInteger(attributeRuleFormData.ruleId)) {
          attributeRuleFormData.status = StatusOption.Inactive;
          assetAttrbtRuleList.splice(selectedRuleIndex, 1, { ...attributeRuleFormData });
        } else {
          assetAttrbtRuleList.splice(selectedRuleIndex, 1);
        }
        const errors = { ...formErrors };
        if (!errors[selectedAttrbtIndex]) {
          errors[selectedAttrbtIndex] = [];
        }
        errors[selectedAttrbtIndex].splice(selectedRuleIndex, 1);
        validate(StepAccordion.Rule, errors);
        setFormErrors(errors);
        const newAsset = { ...assetData };
        newAsset.assetAttrbtList[selectedAttrbtIndex].assetAttrbtRuleList = assetAttrbtRuleList;
        const dataName = 'fetchGetAssetById';
        const editCounterName = 'assetDataEdits';
        const attributeRuleFormDataName = 'attributeRuleFormData';
        const tableName = 'selectedRulesTableRow';
        dispatch(setAssetDataActionCreator({ [dataName]: newAsset }));
        dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
        dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: { ...DESELECTED_ATTRIBUTE_RULES_TABLE_ROW } }));
        dispatch(setFormDataActionCreator({ [attributeRuleFormDataName]: { ...EMPTY_ASSET_ATTRBT_RULE } }));
        const successMessage = 'Selected attribute rule deleted';
        dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
      }
    } else {
      setDeleteReadyToConfirm(true);
    }
  };

  const populateAttributeRuleForm = (selectedRuleId:number | undefined) => {
    const selectedId = selectedRuleId !== undefined ? selectedRuleId : -1;
    const rule = ruleList?.find((obj) => obj.ruleId === selectedId);
    if (typeof (rule) !== 'undefined' && rule?.ruleId !== null) {
      const attributeRuleFormDataName = 'attributeRuleFormData';
      const assetAttrbtRuleDto: AssetAttrbtRuleDto = { ...EMPTY_ASSET_ATTRBT_RULE };

      assetAttrbtRuleDto.assetAttrbtId = selectedAttributeTableRow.assetAttrbtId !== null ? selectedAttributeTableRow.assetAttrbtId : -1;
      assetAttrbtRuleDto.ruleId = rule.ruleId !== null ? rule.ruleId : -1;
      // assetAttrbtRuleDto.ruleExeSeqNum = rule.ruleExeSeqNum !== null ? rule.ruleExeSeqNum : '';
      assetAttrbtRuleDto.ruleName = rule.ruleName !== null ? rule.ruleName : '';
      // assetAttrbtRuleDto.ruleDesc = rule.ruleDesc !== null ? rule.ruleDesc : '';
      assetAttrbtRuleDto.ruleCategory = rule.ruleCategory !== null ? rule.ruleCategory : RuleCategory.BASIC;
      assetAttrbtRuleDto.ruleExpression = rule.ruleExpression !== null ? rule.ruleExpression : '';
      assetAttrbtRuleDto.ruleType = rule.ruleType !== null ? rule.ruleType : RuleType.COMPARE;
      // assetAttrbtRuleDto.ruleOperator = rule.ruleOperator !== null ? rule.ruleOperator : '';
      // assetAttrbtRuleDto.ruleValue = rule.ruleValue !== null ? rule.ruleValue : '';
      // assetAttrbtRuleDto.existCheckFlg = rule.existCheckFlg !== null ? rule.existCheckFlg : '';
      assetAttrbtRuleDto.status = rule.status !== null ? rule.status : '';
      // assetAttrbtRuleDto.createdUserId = rule.createdUserId !== null ? rule.createdUserId : '';
      // assetAttrbtRuleDto.createdDatetime = rule.createdDatetime !== null ? rule.createdDatetime : '';
      // assetAttrbtRuleDto.updatedUserId = rule.updatedUserId !== null ? rule.updatedUserId : '';
      // assetAttrbtRuleDto.updatedDatetime = rule.updatedDatetime !== null ? rule.updatedDatetime : '';

      dispatch(setFormDataActionCreator({ [attributeRuleFormDataName]: { ...assetAttrbtRuleDto } }));
    }
  };

  const { RULE_CATEGORY, RULE_TYPE } = configList;
  const ruleCategorySelectOptions:ISelectOption[] = RULE_CATEGORY.map((ruleCategoryOption, index) => ({ id: index, value: ruleCategoryOption }));
  const ruleTypeSelectOptions:ISelectOption[] = RULE_TYPE.map((ruleTypeOption, index) => ({ id: index, value: ruleTypeOption }));
  const ruleListSelectOptions:ISelectOption[] = ruleList.map((rule) => ({ id: rule.ruleId, value: rule.ruleName }));
  const assetListSelectOptions:ISelectOption[] = assetList.map((asset) => ({ id: asset.assetId, value: asset.assetName }));
  const attrbtListSelectOptions:ISelectOption[] = attrbtList?.length > 0 ? attrbtList?.map((attrbt) => ({ id: attrbt.assetAttrbtId, value: attrbt.assetAttrbtName })) : [];
  return (
    <Grid container sx={{ flexDirection: 'column', spacing: 1 }}>
      <Grid item>
        {
          selectedAttrbtIndex === -1 ? (
            <Box sx={{ mb: 1, ml: 1 }}>
              <WppInlineMessage
                size="s"
                message="Please select an attribute from the table before adding a rule"
                type="warning"
                showTooltipFrom={200}
              />
            </Box>
          ) : null
        }
      </Grid>
      <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
        <Grid item sx={{ m: 1, width: textFieldWidth }}>
          <Select
            title="Select Rule"
            value={ruleId?.toString()}
            // value={ruleName}
            disabled={addNewRule}
            options={ruleListSelectOptions}
            useIdOrValue={SelectType.useId}
            width={selectWidth}
            onChange={(
              event:SelectChangeEvent,
            ) => {
              populateAttributeRuleForm(parseInt(event.target.value, 10));
            }}
          />
        </Grid>
        <Grid
          item
          sx={{
            m: 1, mt: 3, ml: -4, width: textFieldWidth,
          }}
        >
          <WppToggle
            label="New Rule"
            name="newRule"
            required
            checked={addNewRule}
            onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
              onAddNewRule(checked);
            }}
          />
        </Grid>
        <Grid item sx={{ m: 1, width: textFieldWidth }} />
        <Grid item sx={{ m: 1, width: textFieldWidth }} />
      </Grid>
      <Grid container item xs={11} sx={{ justifyContent: 'space-between' }}>
        <Grid item sx={{ m: 1, width: textFieldWidth }}>
          <TextField
            variant="standard"
            focused
            required
            disabled={!addNewRule}
            label="Rule Name"
            value={typeof (ruleName) !== 'undefined' ? ruleName : ''}
            onChange={(event) => { handleAttributeRuleFormFieldChange(event.target.value, 'ruleName'); }}
          />
        </Grid>
        <Grid item sx={{ m: 1, width: textFieldWidth }}>
          <Select
            title="Rule Category"
            options={ruleCategorySelectOptions}
            value={typeof (ruleCategory) !== 'undefined' ? ruleCategory : ''}
            disabled={!addNewRule}
            useIdOrValue={SelectType.useValue}
            width={selectWidth}
            onChange={(
              event:SelectChangeEvent,
            ) => {
              handleAttributeRuleFormFieldChange(event.target.value, 'ruleCategory');
              if (event.target.value === RuleCategory.LOOKUP || event.target.value === RuleCategory.PROFILE) {
                handleAttributeRuleFormFieldChange('', 'ruleType');
              }
              // setSelectedRuleCategory(event.target.value);
            }}
            key="ruleCategory"
          />
        </Grid>
        <Grid item sx={{ m: 1, width: textFieldWidth }}>
          <Select
            title="Rule Status"
            value={typeof (status) !== 'undefined' ? status : ''}
            disabled={!addNewRule}
            options={StatusOptions}
            useIdOrValue={SelectType.useValue}
            width={selectWidth}
            onChange={(event: SelectChangeEvent) => handleAttributeRuleFormFieldChange(event.target.value, 'status')}
          />
        </Grid>
        <Grid item sx={{ m: 1, width: textFieldWidth }}>
          {
            ruleCategory === RuleCategory.BASIC
            && (
            <Select
              title="Rule Type"
              options={ruleTypeSelectOptions}
              value={typeof (ruleType) !== 'undefined' ? ruleType : ''}
              disabled={!addNewRule}
              useIdOrValue={SelectType.useValue}
              width={selectWidth}
              onChange={(
                event:SelectChangeEvent,
              ) => handleAttributeRuleFormFieldChange(event.target.value, 'ruleType')}
              key="ruleType"
              // error={formErrors.ruleType}
            />
            )
          }
          {
            ruleCategory === RuleCategory.LOOKUP
            && (
              <Select
                title="Asset Name"
                options={assetListSelectOptions}
                value={typeof (lookupTable) !== 'undefined' ? lookupTable : ''}
                disabled={!addNewRule}
                useIdOrValue={SelectType.useValue}
                width={selectWidth}
                onChange={(
                  event:SelectChangeEvent,
                ) => {
                  handleAttributeRuleFormFieldChange(event.target.value, 'lookupTable');
                  setSelectedAssetName(event.target.value);
                }}
                key="ruleType"
              />
            )
          }
          {
            ruleCategory === RuleCategory.PROFILE
            && (
            <TextField
              variant="standard"
              focused
              label="Threshold Percentage"
              value={typeof (thresholdPercentage) !== 'undefined' ? thresholdPercentage : ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(event) => handleAttributeRuleFormFieldChange(event.target.value, 'thresholdPercentage')}
            />
            )
          }
        </Grid>
      </Grid>
      <Grid
        container
        // item sx={{ flexDirection: 'row' }}
        sx={{ justifyContent: 'space-between' }}
      >
        {
            ruleCategory === RuleCategory.LOOKUP
            && (
              <Grid item sx={{ m: 1, width: textFieldWidth }}>
                <Select
                  title="Attribute Name"
                  options={attrbtListSelectOptions}
                  value={typeof (lookupColumn) !== 'undefined' ? lookupColumn : ''}
                  disabled={!addNewRule}
                  useIdOrValue={SelectType.useValue}
                  width={selectWidth}
                  onChange={(
                    event:SelectChangeEvent,
                  ) => {
                    handleAttributeRuleFormFieldChange(event.target.value, 'lookupColumn');
                  }}
                />
              </Grid>
            )
          }
        <Grid item sx={{ m: 1, width: '260px' }}>
          <TextField
            variant="standard"
            focused
            required
            fullWidth
            disabled={!addNewRule}
            multiline
            label="Rule Expression"
            value={typeof (ruleExpression) !== 'undefined' ? ruleExpression : ''}
            onChange={(event) => handleAttributeRuleFormFieldChange(event.target.value, 'ruleExpression')}
          />
        </Grid>
        <Grid item sx={{ m: 1, width: '80px' }} />
        {
            ruleCategory !== RuleCategory.LOOKUP
            && (
              <Grid item sx={{ m: 1, width: '130px' }} />
            )
          }
        <Grid item sx={{ m: 1, width: '130px' }} />
      </Grid>
      <Grid container item sx={{ ml: 1, mt: 1 }}>
        {
          selectedRulesTableRow?.ruleId === -1 || selectedRulesTableRow?.ruleId === null ? (
            <Grid item sx={{ mr: 1 }}>
              <Tooltip disableHoverListener={false} title="Add Rule">
                <WppButton size="s" variant="secondary" onClick={() => onAdd()}>
                  <WppIconPlus slot="icon-start" />
                </WppButton>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item sx={{ mr: 1 }}>
              <Tooltip disableHoverListener={false} title="Save Changes">
                <WppButton size="s" variant="secondary" onClick={() => onEdit()}>
                  <WppIconDone slot="icon-start" />
                </WppButton>
              </Tooltip>
            </Grid>
          )
        }
        <Grid item sx={{ mr: 1 }}>
          <Tooltip disableHoverListener={typeof !ruleSelected !== 'undefined' && !ruleSelected} title={deleteReadyToConfirm ? 'Confirm Deletion' : 'Delete'}>
            <WppButton size="s" variant="secondary" disabled={!ruleSelected} onClick={() => onDelete()}>
              {deleteReadyToConfirm
                ? <WppIconAvailableCheckmark slot="icon-start" />
                : <WppIconTrash slot="icon-start" />}
            </WppButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>

  );
}
export default RuleFormUI;
