import { Grid, Link } from '@mui/material';
import {
  WppCard, WppTypography,
} from '@platform-ui-kit/components-library-react';
import React from 'react';

export interface InfoCardProps {
  infoName: string,
  infoValue: string | number,
}

function InfoCard(props:InfoCardProps) {
  const {
    infoName, infoValue,
  } = props;
  return (
    <Link
      component="button"
      underline="none"
      onClick={() => {
      }}
    >
      <Grid container item xs={2}>
        <Grid
          item
          sx={{
            ml: 1, mr: 1,
          }}
        >
          <WppCard variant="tertiary" interactive withBorder>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: 'center',
                width: 100,
              }}
            >
              <Grid
                container
                item
                xs={12}
                sx={{
                  justifyContent: 'center',
                }}
              >
                <WppTypography
                  type="m-strong"
                  style={{ '--wpp-typography-color': '#6526ad' }}
                >
                  {infoName}
                </WppTypography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sx={{
                  justifyContent: 'center',
                }}
              >
                <WppTypography
                  type="4xl-display"
                  // style={{ '--wpp-typography-4xl-display-font-family': 'Big Shoulders Display' }}
                  style={{ '--wpp-typography-color': '#414141' }}
                >
                  {infoValue}
                </WppTypography>
              </Grid>
            </Grid>
          </WppCard>
        </Grid>
      </Grid>
    </Link>
  );
}

export default InfoCard;
