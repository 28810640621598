import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import {
  WppTypography, WppIconCopy, WppIconButton, WppTooltip,
} from '@platform-ui-kit/components-library-react';
import styles from '../../index.module.scss';
import DataCatalogPieChart from './dataCatalogPieChart';
import useWindowDimensions from '../../hooks/use-window-dimensions';

// ==============================|| OVERVIEW ||============================== //

function Overview() {
  const { width } = useWindowDimensions();
  const [tokenValue, setTokenValue] = useState<string>();

  let overviewDataCloudSummaryWidth = 600;
  let buildingBlocksWidth = 500;
  let overviewAppFeaturesWidth = 600;
  const buildingBlocksHeight = 500;
  const mediumSizeScreen = 1600;
  const smallSizeScreen = 1200;
  const mediumSizeAdjustment = 400;
  const smallSizeAdjustment = 300;

  if (width < mediumSizeScreen) {
    overviewDataCloudSummaryWidth = mediumSizeAdjustment;
    buildingBlocksWidth = mediumSizeAdjustment;
    overviewAppFeaturesWidth = mediumSizeAdjustment;
    if (width < smallSizeScreen) {
      overviewDataCloudSummaryWidth = smallSizeAdjustment;
      buildingBlocksWidth = smallSizeAdjustment;
      overviewAppFeaturesWidth = smallSizeAdjustment;
    }
  }

  useEffect(() => {
    const oktaToken = localStorage.getItem('okta-token-storage'); // sessionStorage.getItem(key)
    if (oktaToken && oktaToken !== 'undefined' && oktaToken.length > 0) {
      const token = JSON.parse(oktaToken).accessToken;
      setTokenValue(token?.accessToken);
      if (!sessionStorage.getItem('jwt') && token?.accessToken) {
        window.sessionStorage.setItem('jwt', token?.accessToken);
      }
    }
  }, []);

  const copyToClipboard = (val: any) => {
    navigator.clipboard.writeText(val);
  };

  return (
    <Grid container spacing={2} className={styles.overview}>
      <Grid container item xs={12} sx={{ justifyContent: 'flex-start', mt: 3.5, ml: 3.5 }}>
        <div className={styles.copyToken}>
          <WppTypography tag="h2" type="2xl-heading">
            Overview
          </WppTypography>
          <div className={styles.tokenWrapper}>
            {tokenValue && (
              <>
                <pre className={styles.tokenVal}>
                  {'Okta Token: '}
                  {tokenValue}
                </pre>
                <WppTooltip text="Copy Token">
                  <WppIconButton size="s" onClick={() => copyToClipboard(tokenValue)}>
                    <WppIconCopy />
                  </WppIconButton>
                </WppTooltip>
              </>
            )}
          </div>
        </div>
      </Grid>
      <Grid container item xs={12} justifyContent="center" sx={{ mt: -3 }}>
        <WppTypography type="xl-heading">Ontario — Self Driving Data Management Modular System</WppTypography>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12} sx={{ borderBottom: 1 }}>
          <Grid container item xs={6} justifyContent="center" alignItems="center" sx={{ borderRight: 1 }}>
            <img
              src="../../../images/overview-data-cloud-summary.png"
              width={overviewDataCloudSummaryWidth}
              className={styles.overviewImages}
              alt="A summary of the Data Cloud process"
            />
          </Grid>
          <Grid container item xs={6} justifyContent="center" alignItems="center">
            {sessionStorage.getItem('jwt') && <Box id="dataCatalogPieChart"><DataCatalogPieChart /></Box>}
            {/* <Box id="dataCatalogPieChart"><DataCatalogPieChart /></Box> */}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6} justifyContent="center" alignItems="center" sx={{ borderRight: 1, height: buildingBlocksHeight }}>
            <img
              src="../../../images/overview-building-blocks.png"
              width={buildingBlocksWidth}
              height={undefined}
              className={styles.overviewImages}
              alt="A summary of the building blocks for the application"
            />
          </Grid>
          <Grid container item xs={6} justifyContent="center" alignItems="center">
            <img
              src="../../../images/overview-app-features.png"
              width={overviewAppFeaturesWidth}
              height={undefined}
              className={styles.overviewImages}
              alt="An overview of the features within the application"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Overview;
