import {
  Box, Grid, Link, Paper,
} from '@mui/material';
import {
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import React, { useState } from 'react';
import { InfoDetailRecord } from '../services/models/ui-component-model';
import Tooltip from './tooltip';

export interface InfoDetailsCardProps {
  record: InfoDetailRecord,
  callBack?: () => void;
}

function InfoDetailsCard(props:InfoDetailsCardProps) {
  const {
    record, callBack,
  } = props;
  const [elevation, setElevation] = useState(1);
  const tooltipVisibleMinLength = 13;
  return (
    <Link
      component="button"
      underline="none"
      onClick={callBack}
    >
      <Paper
        sx={{
          height: 130,
          width: 195,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '1em',
        }}
        elevation={elevation}
        onMouseEnter={() => setElevation(5)}
        onMouseLeave={() => setElevation(1)}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box textAlign="center" marginTop={1}>
            <Grid
              container
              item
              sx={{
                justifyContent: 'center',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: 150,
                overflow: 'hidden',
              }}
            >
              <Grid item xs={12}>
                {
                  record?.title?.length >= tooltipVisibleMinLength
                    ? (
                      <Tooltip disableHoverListener={false} title={record.title}>
                        <WppTypography
                          type="m-strong"
                          style={{ '--wpp-typography-color': 'black' }}
                        >
                          {record.title}
                        </WppTypography>
                      </Tooltip>
                    ) : (
                      <WppTypography
                        type="m-strong"
                        style={{ '--wpp-typography-color': 'black' }}
                      >
                        {record.title}
                      </WppTypography>
                    )
                }
              </Grid>
              {/* <Grid item xs={12}>
                {
                  record?.line1?.length >= tooltipVisibleMinLength
                    ? (
                      <Tooltip disableHoverListener={false} title={record.line1}>
                        <WppTypography
                          type="m-midi"
                          style={{ '--wpp-typography-color': 'black' }}
                        >
                          {record.line1}
                        </WppTypography>
                      </Tooltip>
                    ) : (
                      <WppTypography
                        type="m-midi"
                        style={{ '--wpp-typography-color': 'black' }}
                      >
                        {record.line1}
                      </WppTypography>
                    )
                }
              </Grid> */}
              <Grid item xs={12}>
                <WppTypography
                  type="m-midi"
                // style={{ '--wpp-typography-color': '#6526ad' }}
                >
                  {record.line2}
                </WppTypography>
              </Grid>
              <Grid item xs={12}>
                <WppTypography
                  type="m-midi"
                  style={{ '--wpp-typography-color': '#6526ad' }}
                >
                  {record.line3}
                </WppTypography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Link>
  );
}

InfoDetailsCard.defaultProps = {
  callBack: undefined,
};

export default InfoDetailsCard;
