import React, {
  ReactNode,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainContent from '../../../layout/MainLayout/MainContent';
import {
  DEFAULT_ASSET, DEFAULT_ASSET_CONTRACT, DEFAULT_ASSET_PAGINATION, DEFAULT_ASSET_VALUATION, DEFAULT_FILE_CONFIG, DESELECTED_ASSET_TABLE_ROW,
} from '../../../services/defaults/asset-defaults';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import {
  AssetState, FetchGetAssetsParams, FetchGetCopyAssetParams, OrderDirection,
} from '../../../services/models/asset-models.d';
import { StoreState } from '../../../store';
import {
  fetchDeleteAssetsByIdActionCreator,
  fetchGetAssetsActionCreator,
  fetchGetCopyAssetActionCreator,
  setAssetDataActionCreator,
  setAssetSelectedTableRowActionCreator,
} from '../../../store/actions/asset-actions';
import { NavigationPageUrls } from '../../../services/models/navigation-models.d';

export interface AssetTableLayoutProps {
  title?: string,
  reloadOnDelete?: boolean,
  children: ReactNode,
}

function AssetTableLayout(props:AssetTableLayoutProps) {
  const {
    title,
    reloadOnDelete,
    children,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedAssetTableRow:AssetState['tables']['selectedAssetTableRow'] = useSelector(
    (state: StoreState) => state.assetReducer.tables?.selectedAssetTableRow,
  );
  const fetchGetAssetListFlag: AssetState['api']['flags']['fetchGetAssetListFlag'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.flags?.fetchGetAssetListFlag,
  );
  const pagination: AssetState['api']['data']['fetchGetAssetList']['pagination'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetList?.pagination,
  );
  const listOrder: AssetState['api']['data']['fetchGetAssetList']['listOrder'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetList?.listOrder,
  );

  let page = 0;
  let limit = 0;
  if (typeof pagination !== 'undefined') {
    ({
      page,
      limit,
    } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  let searchKey = '';
  let searchValue = '';
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
      searchKey,
      searchValue,
    } = listOrder);
  }

  const addAsset = () => {
    const tableName = 'selectedAssetTableRow';
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: DESELECTED_ASSET_TABLE_ROW }));
    dispatch(setAssetDataActionCreator({
      fetchGetAssetById: {
        asset: { ...DEFAULT_ASSET },
        contract: { ...DEFAULT_ASSET_CONTRACT },
        valuation: { ...DEFAULT_ASSET_VALUATION },
        fileConfig: { ...DEFAULT_FILE_CONFIG },
      },
    }));
    history.push(NavigationPageUrls.ASSET_DETAILS);
  };

  const copyAsset = () => {
    const selectedassetId = selectedAssetTableRow?.assetId !== null ? selectedAssetTableRow?.assetId : '-1';
    const tableName = 'selectedAssetTableRow';
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: DESELECTED_ASSET_TABLE_ROW }));
    const fetchGetCopyAssetParams:FetchGetCopyAssetParams = {
      assetId: parseInt(selectedassetId, 10),
    };
    dispatch(fetchGetCopyAssetActionCreator(fetchGetCopyAssetParams));
  };

  const deleteAsset = () => {
    if (selectedAssetTableRow.assetId !== null && selectedAssetTableRow.assetName !== null) {
      dispatch(fetchDeleteAssetsByIdActionCreator({ id: parseInt(selectedAssetTableRow.assetId, 10) }, selectedAssetTableRow.assetName));
    }
    if (reloadOnDelete && !fetchGetAssetListFlag) {
      const fetchGetAssetsParams:FetchGetAssetsParams = { ...DEFAULT_ASSET_PAGINATION };
      fetchGetAssetsParams.page = page;
      fetchGetAssetsParams.limit = limit;
      fetchGetAssetsParams.sortByFieldName = sortByFieldName;
      fetchGetAssetsParams.sortOrder = sortOrder;
      fetchGetAssetsParams.searchKey = searchKey;
      fetchGetAssetsParams.searchValue = searchValue;
      dispatch(fetchGetAssetsActionCreator(fetchGetAssetsParams, ShowSnackbar.False));
    }
  };

  const handleSearchAssets = (newSearchValue: string) => {
    if (!fetchGetAssetListFlag) {
      const fetchGetAssetsParams:FetchGetAssetsParams = { ...DEFAULT_ASSET_PAGINATION };
      fetchGetAssetsParams.page = 0;
      fetchGetAssetsParams.limit = limit;
      fetchGetAssetsParams.sortByFieldName = sortByFieldName;
      fetchGetAssetsParams.sortOrder = sortOrder;
      fetchGetAssetsParams.searchKey = 'assetName';
      fetchGetAssetsParams.searchValue = newSearchValue;
      dispatch(fetchGetAssetsActionCreator(fetchGetAssetsParams, ShowSnackbar.True));
    }
  };

  return (
    <MainContent
      title={title || 'Assets'}
      buttonTitles={{
        addTitle: 'Add Asset',
        editTitle: 'Edit Asset',
        deleteTitle: 'Delete Asset',
        summaryTitle: 'Asset Summary',
        importTitle: 'Import Asset',
        copyTitle: 'Copy Asset',
      }}
      addCallback={addAsset}
      editCallback={() => history.push(NavigationPageUrls.ASSET_DETAILS)}
      deleteCallback={() => deleteAsset()}
      summaryCallback={() => history.push(NavigationPageUrls.ASSET_SUMMARY)}
      copyCallback={copyAsset}
      importCallback={() => null}
      searchCallback={(newSearchValue) => handleSearchAssets(newSearchValue)}
      searchPlaceholder="Search by Asset Name"
      editDisabled={selectedAssetTableRow?.assetId === null}
      deleteDisabled={selectedAssetTableRow?.assetId === null}
      summaryDisabled={selectedAssetTableRow?.assetId === null}
      copyDisabled={selectedAssetTableRow?.assetId === null}
      importDisabled
    >
      {children}
    </MainContent>
  );
}

AssetTableLayout.defaultProps = {
  title: 'Assets',
  reloadOnDelete: false,
};

export default AssetTableLayout;
