import { host, ontarioService, userRoute } from '../defaults/api-defaults';
import {
  FetchDeleteUserParams, FetchGetRoleResourcePermissionParams, FetchGetRolesParams, FetchGetUserByUsernameParams, FetchGetUsersParams, UserMgmtRequest,
} from '../models/user-models';
import { callServiceAsPromise } from './api';

// GET ALL
export const fetchGetUsers:((params: FetchGetUsersParams) => Promise<Response>) = (params) => {
  const {
    page,
    limit,
    sortByFieldName,
    sortOrder,
    searchKey,
    searchValue,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${userRoute}/user/all?page=${page}&limit=${limit}&sortByFieldName=${sortByFieldName}&sortOrder=${sortOrder}&searchKey=${searchKey}&searchValue=${searchValue}`);
};

// GET User
export const fetchGetUserByUserName:((params: FetchGetUserByUsernameParams) => Promise<Response>) = (params) => {
  const {
    userName,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${userRoute}/user/name?userName=${userName}`);
};

// GET ALL
export const fetchGetRoles:((params: FetchGetRolesParams) => Promise<Response>) = (params) => {
  const {
    page,
    limit,
    sortByFieldName,
    sortOrder,
    searchKey,
    searchValue,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${userRoute}/role/all?page=${page}&limit=${limit}&sortByFieldName=${sortByFieldName}&sortOrder=${sortOrder}&searchKey=${searchKey}&searchValue=${searchValue}`);
};

// GET
export const fetchGetRoleResourcePermission:((params: FetchGetRoleResourcePermissionParams) => Promise<Response>) = (params) => {
  const {
    roleName,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${userRoute}/roleresourcepermission?roleName=${roleName}`);
};

// POST
export const fetchPostUser:(
  (params: UserMgmtRequest) => Promise<Response>
) = (params) => callServiceAsPromise(`${host}${ontarioService}${userRoute}/user`, 'POST', JSON.stringify(params));

// PUT
export const fetchPutUser:(
  (params: UserMgmtRequest) => Promise<Response>
) = (params) => callServiceAsPromise(`${host}${ontarioService}${userRoute}/user`, 'PUT', JSON.stringify(params));

// DELETE
export const fetchDeleteUser:((params: FetchDeleteUserParams) => Promise<Response>) = (params) => {
  const {
    userName,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${userRoute}/user/${userName}`, 'DELETE');
};
