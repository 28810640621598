import { GridSortModel } from '@mui/x-data-grid';
import { fetchGetCatalogDetails, fetchGetCatalogMetrics } from '../../services/api/data-catalog-service-api';
import {
  CatalogAction, CatalogActionOptions, CatalogDataResponse, CatalogError, CatalogMetricsResponse, Count, Data, FetchGetCatalogDetails, Flag, SelectedRow,
} from '../../services/models/data-catalog-models.d';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { setSnackbarActionCreator, snackbarMaker } from './snackbar-actions';

export const setCatalogFlagActionCreator: (update: Flag) => CatalogAction = (update: Flag) => ({
  type: CatalogActionOptions.SET_CATALOG_API_FLAG,
  payload: update,
});

export const setCatalogDataActionCreator: (update: Data) => CatalogAction = (update: Data) => ({
  type: CatalogActionOptions.SET_CATALOG_API_DATA,
  payload: update,
});

export const setFormDataActionCreator: (update: Data) => CatalogAction = (update: Data) => ({
  type: CatalogActionOptions.SET_FORM_DATA,
  payload: update,
});

export const setCatalogSortModelActionCreator: (update: GridSortModel) => CatalogAction = (update: GridSortModel) => ({
  type: CatalogActionOptions.SET_CATALOG_SORT_MODEL,
  payload: update,
});

export const setCatalogSelectedTableRowActionCreator: (update: SelectedRow) => CatalogAction = (update: SelectedRow) => ({
  type: CatalogActionOptions.SET_CATALOG_SELECTED_TABLE_ROW,
  payload: update,
});

export const setCatalogEditsActionCreator: (update: Count) => CatalogAction = (update: Count) => ({
  type: CatalogActionOptions.SET_CATALOG_DATA_EDITS,
  payload: update,
});

export const setCatalogDataActionCreatorEu: (update: Data) => CatalogAction = (update: Data) => ({
  type: CatalogActionOptions.SET_CATALOG_API_EU_DATA,
  payload: update,
});

export const setCatalogDataActionCreatorApac: (update: Data) => CatalogAction = (update: Data) => ({
  type: CatalogActionOptions.SET_CATALOG_API_APAC_DATA,
  payload: update,
});

export const setCatalogDataActionCreatorEmea: (update: Data) => CatalogAction = (update: Data) => ({
  type: CatalogActionOptions.SET_CATALOG_API_EMEA_DATA,
  payload: update,
});

export const handleCatalogError:(errorMessage: string, error: CatalogError) => any = (defaultErrorMessage, error) => async (
  dispatch:(action:any) => any,
) => {
  let errorMessage = defaultErrorMessage;
  if (typeof error?.status?.errorList[0] !== 'undefined') {
    const [primaryError] = error.status.errorList;
    errorMessage = primaryError;
  }
  dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
};

// GET
export const fetchGetCatalogByAssetNameActionCreator: (
  fetchParams: FetchGetCatalogDetails,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const { assetName } = fetchParams;
  const flagName = 'fetchGetCatalogAssetNameFlag';
  const dataName = 'fetchGetCatalogAssetName';
  const successMessage = `Fetched Catalog details of asset "${assetName}"`;
  const defaultErrorMessage = `Issue fetching Catalog details for asset "${assetName}"`;
  dispatch(setCatalogFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetCatalogDetails(fetchParams);
  if (response.ok) {
    const fetchedData:CatalogDataResponse = await response.json();
    dispatch(setCatalogFlagActionCreator({ [flagName]: false }));
    dispatch(setCatalogDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleCatalogError(defaultErrorMessage, await response.json()));
  }
};

// GET
export const fetchGetCatalogMetricsActionCreator: (
  fetchParams: FetchGetCatalogDetails,
  showSnackbar?: ShowSnackbar,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchGetCatalogMetricsFlag';
  const dataName = 'fetchGetCatalogMetrics';
  const successMessage = 'Fetched Catalog Metrics';
  const defaultErrorMessage = 'Issue fetching Catalog Metrics';
  dispatch(setCatalogFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetCatalogMetrics(fetchParams);
  if (response.ok) {
    const fetchedData:CatalogMetricsResponse = await response.json();
    dispatch(setCatalogFlagActionCreator({ [flagName]: false }));
    dispatch(setCatalogDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleCatalogError(defaultErrorMessage, await response.json()));
  }
};
export const fetchGetCatalogMetricsRegionActionCreator: (
  fetchParams: FetchGetCatalogDetails,
  showSnackbar?: ShowSnackbar,
  region?: string,
) => any = (fetchParams, showSnackbar = ShowSnackbar.True, region = 'eu') => async (
  dispatch: (action: any) => any,
) => {
  const flagName = 'fetchGetCatalogMetricsFlag';
  const dataName = 'fetchGetCatalogMetrics';
  const successMessage = 'Fetched Catalog Metrics';
  const defaultErrorMessage = 'Issue fetching Catalog Metrics';
  dispatch(setCatalogFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetCatalogMetrics(fetchParams);
  if (response.ok) {
    const fetchedData: CatalogMetricsResponse = await response.json();
    dispatch(setCatalogFlagActionCreator({ [flagName]: false }));
    if (region && region === 'apac') {
      dispatch(setCatalogDataActionCreatorApac({ [dataName]: fetchedData }));
    } else if (region && region === 'emea') {
      dispatch(setCatalogDataActionCreatorEmea({ [dataName]: fetchedData }));
    } else {
      dispatch(setCatalogDataActionCreatorEu({ [dataName]: fetchedData }));
    }
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleCatalogError(defaultErrorMessage, await response.json()));
  }
};
