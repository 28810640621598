// Reducer State

export interface SnackbarState {
  open: boolean,
  severity: 'error' | 'information' | 'success' | 'warning',
  message: string,
}

// Actions

export enum SnackbarActionOptions {
  SET_SNACKBAR = 'SET_SNACKBAR',
}

export interface SnackbarAction {
  type: SnackbarActionOptions,
  payload: SnackbarState
}

export enum ShowSnackbar {
  False = false,
  True = true,
}
