export interface Status {
  code: string,
  message: string,
  desc: string,
  errorList: string[],
}

export interface UserState {
  api: {
    flags: {
      fetchGetUserListFlag: boolean,
      fetchGetUserByNameFlag: boolean,
      fetchPostUserFlag: boolean,
      fetchPutUserFlag: boolean,
      fetchDeleteUserFlag: boolean,
      fetchGetRoleListFlag: boolean,
      fetchGetRoleResourcePermissionFlag: boolean,

    },
    data: {
      fetchGetUserList: UserListResponse,
      fetchGetUserByName: UserMgmtResponse,
      fetchGetRoleList: RoleResponse,
      fetchGetRoleResourcePermission: UserMgmtResponse,
    },
  },
  formData: {
    userFormData: UserMgmtRequest,
  },
  tables: {
    selectedUserTableRow: UserTableRow,
  },
  userDataEdits: number,
  sortModel: GridSortModel,
}

export enum UserActionOptions {
  SET_USER_API_FLAG = 'SET_USER_API_FLAG',
  SET_USER_API_DATA = 'SET_USER_API_DATA',
  SET_USER_SELECTED_TABLE_ROW = 'SET_USER_SELECTED_TABLE_ROW',
  SET_USER_SORT_MODEL = 'SET_USER_SORT_MODEL',
  SET_USER_DATA_EDITS = 'SET_USER_DATA_EDITS',
  SET_FORM_DATA = 'SET_FORM_DATA',
}

export interface Flag {
  [key: string]: boolean;
}

export interface UserError {
  status: ErrorStatus
}

export interface Data {
  [key: string]: any
}

export interface Count {
  [key: string]: number
}

export interface SelectedRow {
  [key: string]: UserTableRow
}

export interface UserAction {
  type: UserActionOptions,
  payload: Flag |
  Data |
  Count |
  UserTableRow
}

export interface FetchGetUsersParams {
  page: number,
  limit: number,
  sortByFieldName: string,
  sortOrder: 'asc' | 'desc' | null,
  searchKey: string,
  searchValue: string,
}

export interface FetchGetRolesParams {
  page: number,
  limit: number,
  sortByFieldName: string,
  sortOrder: 'asc' | 'desc' | null,
  searchKey: string,
  searchValue: string,
}

export interface FetchGetRoleResourcePermissionParams {
  roleName: string,
}

export interface FetchGetUserByUsernameParams {
  userName: string,
}

export interface ErrorResponse {
  status: Status,
}

export interface UserDto {
  userId: number,
  userName: string,
  firstName: string,
  midName: string,
  lastName: string,
  email: string,
  phone: string,
  lastLoginDatetime: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
  roleName: string,
  accountName: string,
}

export interface UserTableRow {
  userId: number | null,
  userName: string | null,
  firstName: string | null,
  midName: string | null,
  lastName: string | null,
  email: string | null,
  phone: string | null,
  lastLoginDatetime: string | null,
  status: string | null,
  createdUserId: string | null,
  createdDatetime: string | null,
  updatedUserId: string | null,
  updatedDatetime: string | null,
  roleName: string | null,
  accountName: string | null,
}

export interface UserMgmtResourceDto {
  resourceName: string,
  permissions: string[],
}

export interface UserMgmtRoleDto {
  roleName: string,
  roleDesc: string,
  resources: UserMgmtResourceDto[],
}

export interface UserMgmtRequest {
  user: UserDto,
  role: UserMgmtRoleDto,
}

export interface FetchDeleteUserParams {
  userName: string,
}

export interface RoleDto {
  roleId: number,
  roleName: string,
  roleDesc: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface ListOrder {
  sortByFieldName: string,
  sortOrder: OrderDirection,
  searchKey: string,
  searchValue: string,
}

export interface Pagination {
  records: number,
  page: number,
  limit: number,
  totalRecords: number,
  totalPages: number,
}

export interface RoleResponse {
  roleList: RoleDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface PermissionDto {
  permissionId: number,
  permissionName: string,
  permissionDesc: string,
  status: string,
  createdUserId: string,
  createdDatetime: string,
  updatedUserId: string,
  updatedDatetime: string,
}

export interface PermissionResponse {
  permissionList: PermissionDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}

export interface UserMgmtResponse {
  user: UserDto,
  role: UserMgmtRoleDto,
  status: Status,
}

export interface UserListResponse {
  userList: UserDto[],
  pagination: Pagination,
  listOrder: ListOrder,
  status: Status,
}
