import React, { useEffect } from 'react';

// material-ui
import { Grid } from '@mui/material';
import { WppAccordion, WppTypography } from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import AssetDetailsSummary from '../../AssetSummary/asset-details-summary';
import AttributeSummaryTable from '../../AssetSummary/attribute-table';
import RulesSummaryTable from '../../AssetSummary/rules-summary-table';
import { AssetMultiStepGetResponse } from '../../../../services/models/asset-models.d';
import { BooleanType } from '../../../../services/models/select-models.d';
import NotificationTable from '../Schedule/notification-table';
import { FetchGetNotificationParams, NotificationState } from '../../../../services/models/notification-models';
import { StoreState } from '../../../../store';
import { fetchGetNotificationByAssetNameActionCreator } from '../../../../store/actions/notification-action';

export interface PreviewAssetProps {
  assetData:AssetMultiStepGetResponse,
  handleFieldChange:(newValue:string | Date | boolean, section: string, field: string) => void,
}

function PreviewAsset(props:PreviewAssetProps) {
  // eslint-disable-next-line
  const { assetData, handleFieldChange } = props;
  const dispatch = useDispatch();
  let notificationEnableFlg;
  if (typeof assetData.asset !== 'undefined') {
    ({
      notificationEnableFlg,
    } = assetData.asset);
  }

  const tableHeight = 250;
  const tableWidth = '100%';

  const fetchGetNotificationByAssetName:NotificationState['api']['data']['fetchGetNotificationByAssetName']['notificationSubscriptionList'] = useSelector(
    (state: StoreState) => state.notificationReducer.api?.data?.fetchGetNotificationByAssetName?.notificationSubscriptionList,
  );

  useEffect(() => {
    const assetObj = assetData.asset;
    const fetchGetNotificationParams:FetchGetNotificationParams = {
      assetName: assetObj.assetName,
      accountCode: assetObj.accountCode,
    };
    dispatch(fetchGetNotificationByAssetNameActionCreator(fetchGetNotificationParams));
  }, [assetData.asset, dispatch]);

  const notificationRows: any = fetchGetNotificationByAssetName !== null && fetchGetNotificationByAssetName !== undefined
  && fetchGetNotificationByAssetName.length > 0 ? fetchGetNotificationByAssetName : [];

  const notificationTableHeight = 200;

  return (
    <Grid container>
      <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }}>
        <Grid item xs={6}>
          <AssetDetailsSummary
            assetData={assetData}
          />
        </Grid>
        <Grid container xs={6} item sx={{ justifyContent: 'space-between' }}>
          <WppAccordion text="Asset Attributes" expandedByDefault>
            <Grid item xs={12} sx={{ width: tableWidth, height: tableHeight }}>
              <AttributeSummaryTable
                assetData={assetData}
              />
            </Grid>
          </WppAccordion>
        </Grid>
      </Grid>
      <Grid
        container
        xs={6}
        sx={{ mt: 2 }}
      >
        <Grid container item xs={12} sx={{ alignItems: 'flex-start' }}>
          { notificationEnableFlg === BooleanType.True
            ? (
              <WppAccordion text="Notification">
                <Grid
                  sx={{ width: tableWidth, height: notificationTableHeight }}
                >
                  <NotificationTable
                    rows={notificationRows}
                  />
                </Grid>
              </WppAccordion>
            )
            : <> </> }
        </Grid>
      </Grid>
      <Grid container item xs={6} sx={{ mt: 2 }}>
        <WppAccordion text="Rules">
          <WppTypography type="m-body">
            Select an attribute to view associated rules
          </WppTypography>
          <Grid item xs={12} sx={{ width: tableWidth, height: 200 }}>
            <RulesSummaryTable
              assetData={assetData}
            />
          </Grid>
        </WppAccordion>
      </Grid>
    </Grid>
  );
}

export default PreviewAsset;
