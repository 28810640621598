import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { WppAccordion, WppTypography } from '@platform-ui-kit/components-library-react';
import AssetDetailsSummary from './asset-details-summary';
import AttributeSummaryTable from './attribute-table';
import RulesSummaryTable from './rules-summary-table';
import { StoreState } from '../../../store';
import { AssetState, FetchGetAssetByIdParams } from '../../../services/models/asset-models.d';
import { fetchGetAssetByIdActionCreator } from '../../../store/actions/asset-actions';
import MainContent from '../../../layout/MainLayout/MainContent';
import { BooleanType } from '../../../services/models/select-models.d';
import { FetchGetNotificationParams, NotificationState } from '../../../services/models/notification-models';
import NotificationTable from '../Steps/Schedule/notification-table';
import { fetchGetNotificationByAssetNameActionCreator } from '../../../store/actions/notification-action';

function AssetSummary() {
  const dispatch = useDispatch();
  const selectedAssetTableRow:AssetState['tables']['selectedAssetTableRow'] = useSelector(
    (state: StoreState) => state.assetReducer.tables?.selectedAssetTableRow,
  );

  useEffect(() => {
    if (selectedAssetTableRow.assetId) {
      const fetchGetAssetByIdParams:FetchGetAssetByIdParams = {
        id: parseInt(selectedAssetTableRow.assetId, 10),
        requestedEntities: [],
      };
      dispatch(fetchGetAssetByIdActionCreator(fetchGetAssetByIdParams, false));
    }
  }, [dispatch, selectedAssetTableRow.assetId]);

  const assetData:AssetState['api']['data']['fetchGetAssetById'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetById,
  );

  const tableHeight = 250;
  const tableWidth = '101%';
  let notificationEnableFlg;
  if (typeof assetData.asset !== 'undefined') {
    ({
      notificationEnableFlg,
    } = assetData.asset);
  }

  const fetchGetNotificationByAssetName:NotificationState['api']['data']['fetchGetNotificationByAssetName']['notificationSubscriptionList'] = useSelector(
    (state: StoreState) => state.notificationReducer.api?.data?.fetchGetNotificationByAssetName?.notificationSubscriptionList,
  );

  useEffect(() => {
    const assetObj = assetData.asset;
    const fetchGetNotificationParams:FetchGetNotificationParams = {
      assetName: assetObj.assetName,
      accountCode: assetObj.accountCode,
    };
    dispatch(fetchGetNotificationByAssetNameActionCreator(fetchGetNotificationParams));
  }, [assetData.asset, dispatch]);

  const notificationRows: any = fetchGetNotificationByAssetName !== null && fetchGetNotificationByAssetName !== undefined
  && fetchGetNotificationByAssetName.length > 0 ? fetchGetNotificationByAssetName : [];
  const notificationTableHeight = 200;

  return (
    <MainContent
      title="Asset Summary"
      hideSearch
      buttonTitles={{
        addTitle: 'Add Summary',
        editTitle: 'Edit Summary',
        deleteTitle: 'Delete Summary',
        summaryTitle: 'Summary',
        importTitle: 'Import Summary',
        copyTitle: 'Copy Summary',
      }}
      searchPlaceholder="Search"
      addDisabled
      editDisabled
      deleteDisabled
      summaryDisabled
      importDisabled
      copyDisabled
    >
      <Grid container sx={{ p: 1 }}>
        <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }}>
          <Grid item xs={6}>
            <AssetDetailsSummary
              assetData={assetData}
            />
          </Grid>
          <Grid container xs={6} item sx={{ justifyContent: 'space-between' }}>
            <WppAccordion text="Asset Attributes" expandedByDefault>
              <Grid item xs={12} sx={{ width: tableWidth, height: tableHeight }}>
                <AttributeSummaryTable
                  assetData={assetData}
                />
              </Grid>
            </WppAccordion>
          </Grid>
        </Grid>
        <Grid container item xs={6} sx={{ mt: 2 }}>
          {/* <Grid item xs={6}>
          <WppAccordion text="Source">
            <SourceTargetSummary
              assetData={assetData}
            />
          </WppAccordion>
        </Grid>
        <Grid item xs={6}>
          <WppAccordion text="Target">
            <TargetSummary
              assetData={assetData}
            />
          </WppAccordion>
        </Grid> */}
          <Grid item xs={6} sx={{ alignItems: 'flex-start' }}>
            { notificationEnableFlg === BooleanType.True
              ? (
                <WppAccordion text="Notification">
                  <Grid
                    sx={{ width: tableWidth, height: notificationTableHeight }}
                  >
                    <NotificationTable
                      rows={notificationRows}
                    />
                  </Grid>
                </WppAccordion>
              )
              : <> </> }
          </Grid>
        </Grid>
        <Grid container item xs={6} sx={{ mt: 2 }}>
          <WppAccordion text="Rules">
            <Grid item xs={12}>
              <WppTypography type="xl-heading">
                Rules
              </WppTypography>
            </Grid>
            <Grid item xs={12} sx={{ width: tableWidth, height: 200 }}>
              <RulesSummaryTable
                assetData={assetData}
              />
            </Grid>
          </WppAccordion>
        </Grid>
      </Grid>
    </MainContent>
  );
}
export default AssetSummary;
