import React, {
  useEffect,
} from 'react';
import { Grid } from '@mui/material';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ListAllAssetsTable from './list-all-assets-table';
import { StoreState } from '../../store';
import {
  AssetState,
  FetchGetAssetsParams,
} from '../../services/models/asset-models.d';
import { fetchGetAssetsActionCreator } from '../../store/actions/asset-actions';
import { DEFAULT_ASSET_PAGINATION } from '../../services/defaults/asset-defaults';
import AssetTableLayout from './Layout/asset-table-layout';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';

function ListAllAssets() {
  const tableWidth = '101%';
  const tableHeight = 730;

  const dispatch = useDispatch();

  let rows:AssetState['api']['data']['fetchGetAssetList']['assets'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetList?.assets,
  );

  if (rows === null || typeof rows === 'undefined') {
    rows = [];
  }

  useEffect(() => {
    const fetchGetAssetsParams:FetchGetAssetsParams = { ...DEFAULT_ASSET_PAGINATION };
    dispatch(fetchGetAssetsActionCreator(fetchGetAssetsParams, ShowSnackbar.True));
  }, [dispatch]);

  return (
    <AssetTableLayout reloadOnDelete>
      <Grid container item sx={{ width: tableWidth, height: tableHeight }}>
        <Grid item xs={12}>
          <ListAllAssetsTable rows={rows} />
        </Grid>
      </Grid>
    </AssetTableLayout>
  );
}

export default ListAllAssets;
