import { host, ontarioService, masterDataRoute } from '../defaults/api-defaults';
import {
  CategoryDto,
  CollectionDto,
  DatasetDto,
  FetchDeleteMasterDataByIdParams,
  FetchGetMasterDataByIdParams, FetchGetMasterDataParams, MasterDataTableNames, RuleDto, TagDto,
} from '../models/master-data-models.d';
import { callServiceAsPromise } from './api';

// const host = 'https://ont-dev-dtc-platform-access-npd-dev.choreograph.com';
// const ontarioService = '/ontario-service';
// const assetRoute = '/masterdata';
// const host = 'http://localhost:8081';
// const ontarioService = '/master-data-service';
// const assetRoute = '';
// const service = 'http://localhost:8081/master-data-service';

// May have an issue with response type because MasterDataResponse is not shaped like AssetDataResponse
export const fetchGetMasterData:((params: FetchGetMasterDataParams, tableName: MasterDataTableNames) => Promise<Response>) = (params, tableName) => {
  const {
    page,
    limit,
    sortByFieldName,
    sortOrder,
    searchKey,
    searchValue,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${masterDataRoute}/${tableName}/all?page=${page}&limit=${limit}&sortByFieldName=${sortByFieldName}&sortOrder=${sortOrder}&searchKey=${searchKey}&searchValue=${searchValue}`);
};

// GET by ID
export const fetchGetMasterDataById:((params: FetchGetMasterDataByIdParams, tableName: MasterDataTableNames) => Promise<Response>) = (params, tableName) => {
  const { id } = params;
  return callServiceAsPromise(`${host}${ontarioService}${masterDataRoute}/${tableName}/${id}`);
};

// POST
export const fetchPostMasterData:(
  (params: TagDto | CollectionDto | DatasetDto | RuleDto | CategoryDto, tableName: MasterDataTableNames) => Promise<Response>
) = (params, tableName) => callServiceAsPromise(`${host}${ontarioService}${masterDataRoute}/${tableName}`, 'POST', JSON.stringify(params));

// DELETE
export const fetchDeleteMasterDataById:((params: FetchDeleteMasterDataByIdParams, tableName: MasterDataTableNames) => Promise<Response>) = (params, tableName) => {
  const { id } = params;
  return callServiceAsPromise(`${host}${ontarioService}${masterDataRoute}/${tableName}/${id}`, 'DELETE');
};
