import React from 'react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import Table from '../../../ui-component/table';
import Cell from '../../../ui-component/cell';
import {
  AssetAttrbtRuleDto, AssetMultiStepGetResponse, AssetState,
} from '../../../services/models/asset-models.d';
import { StoreState } from '../../../store';

export interface RulesSummaryTableProps {
  assetData:AssetMultiStepGetResponse
}

function RulesSummaryTable(props:RulesSummaryTableProps): JSX.Element {
  const { assetData } = props;
  const { assetAttrbtList } = assetData;

  // eslint-disable-next-line
  const selectedAttributeTableRow:AssetState['tables']['selectedAttributeTableRow'] = useSelector(
    (state: StoreState) => state.assetReducer.tables.selectedAttributeTableRow,
  );

  let rows:AssetAttrbtRuleDto[] = [];
  if (selectedAttributeTableRow.assetAttrbtId !== null && typeof (assetAttrbtList) !== 'undefined' && assetAttrbtList !== null) {
    const assetAttrbt = assetAttrbtList.find((attribute) => (selectedAttributeTableRow.assetAttrbtId === attribute.assetAttrbtId));
    if (typeof assetAttrbt !== 'undefined') {
      const { assetAttrbtRuleList } = assetAttrbt;
      if (typeof assetAttrbtRuleList !== 'undefined' && assetAttrbtRuleList !== null) {
        rows = assetAttrbtRuleList;
      }
    }
  }

  const columnFormat = [
    {
      field: 'assetAttrbtId', headerText: 'Asset Attribute Id', // Hidden
    },
    {
      field: 'ruleId', headerText: 'Rule Id', // Hidden
    },
    {
      field: 'ruleName', description: '', headerText: 'Rule Name', minWidth: 150,
    },
    {
      field: 'ruleCategory', description: '', headerText: 'Rule Category', minWidth: 150,
    },
    {
      field: 'ruleExpression', description: '', headerText: 'Rule Expression', minWidth: 150,
    },
    {
      field: 'ruleType', description: '', headerText: 'Rule Type', minWidth: 150,
    },
    {
      field: 'thresholdPercentage', description: '', headerText: 'Threshold Percentage', minWidth: 170,
    },
    {
      field: 'lookupTable', description: '', headerText: 'Asset Name', minWidth: 150,
    },
    {
      field: 'lookupColumn', description: '', headerText: 'Attribute Name', minWidth: 150,
    },
    {
      field: 'ruleExeSeqNum', description: '', headerText: 'Rule Execution Sequence Number', minWidth: 250,
    },
    {
      field: 'ruleExeSubSeq', description: '', headerText: 'Rule Execution Sub Sequence', minWidth: 220,
    },
    {
      field: 'ruleOperator', headerText: 'Rule Operator', // Hidden
    },
    {
      field: 'ruleValue', headerText: 'Rule Value', // Hidden
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150,
    },
    {
      field: 'createdUserId', description: '', headerText: 'Created User Id', minWidth: 150,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', description: '', headerText: 'Updated User Id', minWidth: 150,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const filterModel = { items: [] };

  const columnVisibilityModel = {
    assetAttrbtId: false,
    ruleId: false,
    ruleOperator: false,
    ruleValue: false,
  };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: false,
      filterable: false,
    };
    return columnFormatted;
  }));

  const onCellClick = () => null;

  const onPageChange = () => null;

  const onPageSizeChange = () => null;

  const onSortModelChange = () => null; // Not implemented

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={rows.length > 100}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={rows.length > 100 ? 1 : rows.length}
        rowCount={rows.length}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
}

export default RulesSummaryTable;
