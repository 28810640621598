import BLANK_CONFIG_LIST from '../../services/defaults/config-defaults';
import { ConfigState, ConfigAction, ConfigActionOptions } from '../../services/models/config-models.d';

export const ConfigDefaultState = {
  api: {
    flags: {
      fetchGetConfigListFlag: false,
    },
    data: {
      fetchGetConfigList: BLANK_CONFIG_LIST,
    },
  },
};

function ConfigReducer(
  state:ConfigState = ConfigDefaultState,
  action:ConfigAction,
): ConfigState {
  switch (action.type) {
    case ConfigActionOptions.SET_CONFIG_API_FLAG:
      return {
        ...state,
        api: {
          ...state.api,
          flags: {
            ...state.api.flags,
            ...action.payload,
          },
        },
      };
    case ConfigActionOptions.SET_CONFIG_API_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          data: {
            ...state.api.data,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
}

export default ConfigReducer;
