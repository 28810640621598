import React, { useState } from 'react';
import {
  Grid, SelectChangeEvent, TextField,
} from '@mui/material';
import {
  WppAccordion, WppToggle,
} from '@platform-ui-kit/components-library-react';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import { AssetMultiStepGetResponse, ContractDto, ValuationDto } from '../../../../services/models/asset-models.d';
import { MasterDataState } from '../../../../services/models/master-data-models.d';
import { StoreState } from '../../../../store';
import { DEFAULT_ASSET_CONTRACT } from '../../../../services/defaults/asset-defaults';
import Select from '../../../../ui-component/select';
import { ISelectOption, SelectType } from '../../../../services/models/select-models.d';
import { IContractFormErrors } from '../../../../services/models/validation-models.d';
import { validateAny, validateString } from '../../../../ui-component/validation';
import { StepAccordion } from '../../../../services/models/stepper-models.d';
import { ConfigState } from '../../../../services/models/config-models.d';
import makeLocalAppearUTC from '../../../../ui-component/date';

export interface ContractAccordionProps {
  assetData:AssetMultiStepGetResponse,
  handleFieldChange:(newValue:null | string | Date | boolean | ValuationDto | ContractDto, section: string, field: string | null) => void,
  validate:(accordion: number, formErrors: IContractFormErrors) => void;
}

function ContractAccordion(props:ContractAccordionProps) {
  const {
    assetData,
    handleFieldChange,
    validate,
  } = props;

  const contractList:MasterDataState['api']['data']['fetchGetContractList']['contractList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetContractList?.contractList,
  );

  let contractId: number | undefined;
  let contractName;
  let contractDesc;
  let usageType;
  let contractType;
  let contractStartDt;
  let contractEndDt;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;

  const [addNewContract, setAddNewContract] = useState<boolean>(false);

  if (typeof assetData.contract !== 'undefined') {
    ({
      contractId,
      contractName,
      contractDesc,
      usageType,
      contractType,
      contractStartDt,
      contractEndDt,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = assetData.contract);
  } else {
    // By Default DEFAULT_ASSET_CONTRACT is selected and stored in redux.
    handleFieldChange({ ...DEFAULT_ASSET_CONTRACT }, 'contract', null);
    handleFieldChange({ ...DEFAULT_ASSET_CONTRACT }.contractId.toString(), 'asset', 'contractId');
  }

  if (!addNewContract && contractList !== null) {
    const selectedContract = contractList.find((obj) => obj.contractId === contractId);
    if (selectedContract !== null) {
      contractId = selectedContract?.contractId;
      contractName = selectedContract?.contractName;
      contractDesc = selectedContract?.contractDesc;
      usageType = selectedContract?.usageType;
      contractType = selectedContract?.contractType;
      contractStartDt = selectedContract?.contractStartDt;
      contractEndDt = selectedContract?.contractEndDt;
      // createdUserId = selectedContract?.createdUserId;
      // createdDatetime = selectedContract?.createdDatetime;
      // updatedUserId = selectedContract?.updatedUserId;
      // updatedDatetime = selectedContract?.updatedDatetime;
    }
  }
  const itemWidth = '150px';
  // const [endDate, setEndDate] = React.useState<Dayjs | null>(null);

  const formErrors:IContractFormErrors = {
    // contractId: validateNumber(contractId),
    contractName: validateString(contractName),
    contractType: validateString(contractType),
    contractStartDt: validateString(contractStartDt),
    contractEndDt: validateString(contractEndDt),
  };
  type IValuationFormErrorsObjectKey = keyof typeof formErrors;

  // Attempt to detect errors during load
  // if (Object.values(formErrors).find((value) => value === true) !== -1) {
  //   validate(StepAccordion.Schema, formErrors);
  // }

  const onValidatedFieldChange = (newValue:null | string | Date | boolean | ContractDto, section: string, field: string | null) => {
    const f = field as IValuationFormErrorsObjectKey;
    formErrors[f] = validateAny(newValue);
    validate(StepAccordion.Contract, formErrors);
    handleFieldChange(newValue, section, field);
  };
  const configList:ConfigState['api']['data']['fetchGetConfigList'] = useSelector(
    (state: StoreState) => state.configReducer?.api?.data?.fetchGetConfigList,
  );

  const {
    USAGE_TYPE, CONTRACT_TYPE,
  } = configList;
  const usageTypeSelectOptions:ISelectOption[] = typeof (USAGE_TYPE) !== 'undefined' && USAGE_TYPE.length > 0 ? USAGE_TYPE.map((type, index) => ({ id: index, value: type })) : [];
  const contractTypeSelectOptions:ISelectOption[] = typeof (CONTRACT_TYPE) !== 'undefined' && CONTRACT_TYPE.length > 0 ? CONTRACT_TYPE.map((type, index) => ({ id: index, value: type })) : [];

  return (
    <Grid container>
      <WppAccordion text="Contract">
        <Grid id="contract-accordion-k" container sx={{ width: 'auto' }}>
          <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Select Contract"
                value={contractId !== undefined ? contractId?.toString() : ''}
                disabled={addNewContract}
                options={contractList}
                useIdOrValue={SelectType.useId}
                onChange={(event: SelectChangeEvent) => {
                  // if (assetData.contract) {
                  //   onValidatedFieldChange(event.target.value, 'contract', 'contractId');
                  //   onValidatedFieldChange(event.target.value, 'asset', 'contractId');
                  // } else {
                  handleFieldChange(event.target.value, 'contract', 'contractId');
                  handleFieldChange(event.target.value, 'asset', 'contractId');
                  // }
                }}
                // error={formErrors.contractId}
              />
            </Grid>
            <Grid
              item
              sx={{
                m: 1, ml: 6, mt: 3, width: itemWidth,
              }}
            >
              <WppToggle
                label="New Contract"
                name="newContract"
                required
                checked={addNewContract}
                onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                  setAddNewContract(checked);
                  onValidatedFieldChange({ ...DEFAULT_ASSET_CONTRACT }, 'contract', null);
                  onValidatedFieldChange(null, 'contract', 'contractId');
                  onValidatedFieldChange(null, 'asset', 'contractId');
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                disabled={!addNewContract}
                label="Contract Name"
                value={typeof (contractName) !== 'undefined' ? contractName : ''}
                onChange={(event) => onValidatedFieldChange(event.target.value, 'contract', 'contractName')}
                error={formErrors.contractName}
                color={formErrors.contractName ? 'error' : 'primary'}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Contract Type"
                value={typeof (contractType) !== 'undefined' ? contractType : ''}
                options={contractTypeSelectOptions}
                disabled={!addNewContract}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => onValidatedFieldChange(event.target.value, 'contract', 'contractType')}
                error={formErrors.contractType}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Usage Type"
                value={typeof (usageType) !== 'undefined' ? usageType : ''}
                options={usageTypeSelectOptions}
                disabled={!addNewContract}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => onValidatedFieldChange(event.target.value, 'contract', 'usageType')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                disabled={!addNewContract}
                label="Contract Description"
                value={typeof (contractDesc) !== 'undefined' ? contractDesc : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'contract', 'contractDesc')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  disabled={!addNewContract}
                  disablePast
                  value={typeof (contractStartDt) !== 'undefined' ? makeLocalAppearUTC(contractStartDt) : ''}
                  onChange={(value:any) => onValidatedFieldChange(dayjs(value.$d.toString()).format('YYYY-MM-DD'), 'contract', 'contractStartDt')}
                  renderInput={(params) => (
                    <TextField
                      disabled={!addNewContract}
                      variant="standard"
                      focused
                      required
                      {...params}
                      error={formErrors.contractStartDt}
                      color={formErrors.contractStartDt ? 'error' : 'primary'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  disabled={!addNewContract}
                  disablePast
                  value={typeof (contractEndDt) !== 'undefined' ? makeLocalAppearUTC(contractEndDt) : ''}
                  onChange={(value:any) => {
                    onValidatedFieldChange(dayjs(value.$d.toString()).format('YYYY-MM-DD'), 'contract', 'contractEndDt');
                  }}
                  renderInput={(params) => (
                    <TextField
                      disabled={!addNewContract}
                      variant="standard"
                      focused
                      required
                      {...params}
                      error={formErrors.contractEndDt}
                      color={formErrors.contractEndDt ? 'error' : 'primary'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item sx={{ m: 1, width: itemWidth }} />
          </Grid>
        </Grid>
      </WppAccordion>
    </Grid>
  );
}

export default ContractAccordion;
