import { GridSortModel } from '@mui/x-data-grid';
import {
  DESELECTED_NOTIFICATION_TABLE_ROW, DEFAULT_NOTIFICATION_GRID_SORT_ARRAY, EMPTY_NOTIFICATION_OUTBOUND, DEFAULT_NOTIFICATION_INBOUND,
} from '../../services/defaults/notification-defaults';
import { NotificationAction, NotificationActionOptions, NotificationState } from '../../services/models/notification-models.d';

export const NotificationDefaultState = {
  api: {
    flags: {
      fetchPostNotificationFlag: false,
      fetchDeleteNotificationFlag: false,
      fetchGetNotificationByAssetNameFlag: false,
    },
    data: {
      fetchGetNotificationByAssetName: { ...EMPTY_NOTIFICATION_OUTBOUND },
    },
  },
  formData: {
    notificationFormData: { ...DEFAULT_NOTIFICATION_INBOUND },
  },
  notificationEdits: 0,
  tables: {
    selectedNotificationTableRow: { ...DESELECTED_NOTIFICATION_TABLE_ROW },
  },
  sortModel: DEFAULT_NOTIFICATION_GRID_SORT_ARRAY,
};

function notificationReducer(
  state:NotificationState = NotificationDefaultState,
  action:NotificationAction,
): NotificationState {
  switch (action.type) {
    case NotificationActionOptions.SET_NOTIFICATION_API_FLAG:
      return {
        ...state,
        api: {
          ...state.api,
          flags: {
            ...state.api.flags,
            ...action.payload,
          },
        },
      };
    case NotificationActionOptions.SET_NOTIFICATION_API_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          data: {
            ...state.api.data,
            ...action.payload,
          },
        },
      };
    case NotificationActionOptions.SET_NOTIFICATION_SELECTED_TABLE_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          ...action.payload,
        },
      };
    case NotificationActionOptions.SET_NOTIFICATION_DATA_EDITS:
      return {
        ...state,
        ...action.payload,
      };
    case NotificationActionOptions.SET_NOTIFICATION_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload as GridSortModel,
      };
    case NotificationActionOptions.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default notificationReducer;
