import React from 'react';
import { Grid } from '@mui/material';
import {
  WppToggle,
} from '@platform-ui-kit/components-library-react';
import CoreAttributesAccordion from './asset-core-attributes-accordion';
import FileConfigurationAccordion from './schema-accordion';
import { AssetMultiStepGetResponse, ContractDto, ValuationDto } from '../../../../services/models/asset-models.d';
import ContractAccordion from './contract-accordion';
// import SourceAccordion from './source-accordion';
// import TargetAccordion from './target-accordion';
import ValuationAccordion from './valuation-accordion';
import { BooleanType } from '../../../../services/models/select-models.d';
import {
  IAssetFormErrors,
  ISchemaFormErrors,
  IValuationFormErrors,
  IContractFormErrors,
} from '../../../../services/models/validation-models.d';
import Tooltip from '../../../../ui-component/tooltip';

export interface AssetConfigurationProps {
  assetData:AssetMultiStepGetResponse
  handleFieldChange:(newValue:null | string | Date | boolean | ValuationDto | ContractDto, section: string, field: string | null) => void,
  validate:(accordion: number, formErrors: IAssetFormErrors | ISchemaFormErrors | IValuationFormErrors | IContractFormErrors) => void;
}

function AssetConfiguration(props:AssetConfigurationProps) {
  const {
    assetData,
    handleFieldChange,
    validate,
  } = props;
  const itemWidth = '150px';

  let allowSchemaDiscoveryFlg;
  let dataCatalogPushFlg;
  let fileFormat;

  if (typeof assetData.contract !== 'undefined') {
    ({
      allowSchemaDiscoveryFlg,
      dataCatalogPushFlg,
    } = assetData.asset);
  }

  if (typeof assetData.fileConfig !== 'undefined') {
    ({
      fileFormat,
    } = assetData.fileConfig);
  }

  return (
    <Grid container>
      <CoreAttributesAccordion
        assetData={assetData}
        handleFieldChange={handleFieldChange}
        validate={validate}
      />
      <FileConfigurationAccordion
        assetData={assetData}
        handleFieldChange={handleFieldChange}
        validate={validate}
      />
      <ValuationAccordion
        handleFieldChange={handleFieldChange}
        assetData={assetData}
        validate={validate}
      />
      <ContractAccordion
        assetData={assetData}
        handleFieldChange={handleFieldChange}
        validate={validate}
      />
      {/* <SourceAccordion
        assetData={assetData}
        handleFieldChange={handleFieldChange}
      />
      <TargetAccordion
        assetData={assetData}
        handleFieldChange={handleFieldChange}
      /> */}
      <Grid container sx={{ width: 'auto' }}>
        <Grid container item xs={12} sx={{ mt: 2, ml: 3, justifyContent: 'space-between' }}>
          <Grid item sx={{ m: 1, ml: 2, width: itemWidth }}>
            <WppToggle
              label="Push to Catalog"
              checked={dataCatalogPushFlg === BooleanType.True}
              required
              onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                if (checked) {
                  handleFieldChange(BooleanType.True, 'asset', 'dataCatalogPushFlg');
                } else {
                  handleFieldChange(BooleanType.False, 'asset', 'dataCatalogPushFlg');
                }
              }}
            />
          </Grid>
          <Grid item sx={{ m: 1, ml: 2, width: 200 }}>
            <Tooltip disableHoverListener={fileFormat === 'delimited'} title="This toggle is enabled only if File Separator is set to 'Delimited'">
              <WppToggle
                label="Schema Discoverable"
                checked={allowSchemaDiscoveryFlg === BooleanType.True}
                required
                disabled={fileFormat === 'Fixed'}
                onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                  if (checked) {
                    handleFieldChange(BooleanType.True, 'asset', 'allowSchemaDiscoveryFlg');
                  } else {
                    handleFieldChange(BooleanType.False, 'asset', 'allowSchemaDiscoveryFlg');
                  }
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item sx={{ m: 1, width: itemWidth }} />
          <Grid item sx={{ m: 1, width: itemWidth }} />
          <Grid item sx={{ m: 1, width: itemWidth }} />
          <Grid item sx={{ m: 1, width: itemWidth }} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AssetConfiguration;
