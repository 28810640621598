import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, responsiveFontSizes } from '@mui/material';
import {
  BrowserRouter,
} from 'react-router-dom';

import Routes from './Routes';

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      primary: {
        light: string,
        main: string,
        dark: string,
      },
      secondary: {
        light: string,
        main: string,
        dark: string,
      },
      background: {
        default: string,
        paper: string,
      },
      success: {
        main: string,
      },
      error: {
        main: string,
      }
    }
    typography: {
      fontFamily: string,
    }
  }
}

let theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      light: '#C1C7CC', // Gray
      main: '#53565B', // Gray
      dark: '#000000', // Unset
    },
    secondary: {
      light: '#EEE8FF', // Purple
      main: '#8509e8', // Purple
      dark: '#5E00B5', // Purple
    },
    background: {
      default: '#EDEDED',
      paper: '#FFFFFF',
    },
    success: {
      main: '#66bb6a',
    },
    error: {
      main: '#E81C22',
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
  },
});

theme = responsiveFontSizes(theme);

const App: React.FC = function RenderApp(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter basename="ontario-ui">
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
