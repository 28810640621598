import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';

// Reducers
import assetReducer from './reducers/asset-reducer';
import explorerReducer from './reducers/explorer-reducer';
import masterDataReducer from './reducers/master-data-reducer';
import notificationReducer from './reducers/notification-reducer';
import catalogReducer from './reducers/data-catalog-reducer';
import userReducer from './reducers/user-reducer';
import snackbarReducer from './reducers/snackbar-reducer';

// Models
import { AssetState } from '../services/models/asset-models.d';
import { ConfigState } from '../services/models/config-models.d';
import { ExplorerState } from '../services/models/explorer-models.d';
import { MasterDataState } from '../services/models/master-data-models.d';
import { SnackbarState } from '../services/models/snackbar-models.d';
import { NotificationState } from '../services/models/notification-models.d';
import { CatalogState } from '../services/models/data-catalog-models.d';
import { UserState } from '../services/models/user-models.d';
import configReducer from './reducers/config-reducer';

export interface StoreState {
  assetReducer: AssetState;
  explorerReducer: ExplorerState;
  configReducer: ConfigState;
  masterDataReducer: MasterDataState;
  notificationReducer: NotificationState;
  catalogReducer: CatalogState;
  userReducer: UserState;
  snackbarReducer: SnackbarState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const reducer = combineReducers({
  assetReducer,
  explorerReducer,
  configReducer,
  masterDataReducer,
  notificationReducer,
  userReducer,
  snackbarReducer,
  catalogReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
