import React, { useState, useEffect } from 'react';
import {
  Grid, SelectChangeEvent, TextField,
} from '@mui/material';
import {
  WppAccordion,
} from '@platform-ui-kit/components-library-react';
import { useSelector } from 'react-redux';
import { AssetMultiStepGetResponse } from '../../../../services/models/asset-models.d';
import { MasterDataState } from '../../../../services/models/master-data-models.d';
import { StoreState } from '../../../../store';
import Select from '../../../../ui-component/select';
import {
  AssetStatusOptions, ISelectOption, SelectType,
} from '../../../../services/models/select-models.d';
import { validateString, validateNumber, validateAny } from '../../../../ui-component/validation';
import { IAssetFormErrors } from '../../../../services/models/validation-models.d';
import { StepAccordion } from '../../../../services/models/stepper-models.d';
import { ConfigState } from '../../../../services/models/config-models.d';

export interface AssetCoreAttributesAccordionProps {
  assetData:AssetMultiStepGetResponse
  handleFieldChange:(newValue:string | Date | boolean, section: string, field: string) => void,
  validate:(accordion: number, formErrors: IAssetFormErrors) => void;
}

function AssetCoreAttributesAccordion(props:AssetCoreAttributesAccordionProps) {
  const [filterDatasetList, setFilterDatasetList] = useState<any[]>([]);
  const itemWidth = '150px';
  const {
    assetData,
    handleFieldChange,
    validate,
  } = props;

  const collectionList:MasterDataState['api']['data']['fetchGetCollectionList']['collectionList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCollectionList?.collectionList,
  );

  const categoryList:MasterDataState['api']['data']['fetchGetCategoryList']['categoryList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCategoryList?.categoryList,
  );

  const tagList:MasterDataState['api']['data']['fetchGetTagList']['tagList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetTagList?.tagList,
  );

  const datasetList: MasterDataState['api']['data']['fetchGetDatasetList']['datasetList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetDatasetList?.datasetList,
  );

  // let assetId;
  // let accountId;
  let datasetId;
  let assetName;
  let assetDesc;
  // let assetLongDesc;
  let assetType;
  let assetOwner;
  let collectionId: any;
  let categoryId;
  let tagId;
  // let sourceId;
  // let targetId;
  // let fileConfigId;
  // let valuationId;
  // let contractId;
  // let loadType;
  // let derivedAssetFlg;
  // let dataCatalogPushFlg;
  // let allowSchemaDiscoveryFlg;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;
  // let versionId;
  let collectionName: unknown;

  if (typeof assetData.asset !== 'undefined') {
    ({
      // assetId,
      // accountId,
      datasetId,
      assetName,
      // filePattern,
      assetDesc,
      // assetLongDesc,
      assetType,
      assetOwner,
      collectionId,
      categoryId,
      tagId,
      // sourceId,
      // targetId,
      // fileConfigId,
      // valuationId,
      // contractId,
      // loadType,
      // derivedAssetFlg,
      // dataCatalogPushFlg,
      // allowSchemaDiscoveryFlg,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
      // versionId,
      collectionName,
    } = assetData.asset);
  }

  const formErrors:IAssetFormErrors = {
    assetName: validateString(assetName),
    assetDesc: validateString(assetDesc),
    status: validateString(status),
    collectionId: validateNumber(collectionId),
    categoryId: validateNumber(categoryId),
    tagId: validateNumber(tagId),
    datasetId: validateNumber(datasetId),
  };
  type IAssetFormErrorsObjectKey = keyof typeof formErrors;

  // const initNewRule = () => {
  //   const errors:IAttributeRuleFormErrors = { ...formErrors };
  //   errors[selectedAttrbtIndex][selectedRuleIndex].ruleName = true;
  //   errors[selectedAttrbtIndex][selectedRuleIndex].ruleExpression = true;
  //   errors[selectedAttrbtIndex][selectedRuleIndex].status = true;
  //   validate(StepAccordion.Rule, errors);
  //   setFormErrors(errors);
  // };

  const onValidatedFieldChange = (newValue:string | Date | boolean, section: string, field: string) => {
    const f = field as IAssetFormErrorsObjectKey;
    formErrors[f] = validateAny(newValue);
    validate(StepAccordion.Asset, formErrors);
    handleFieldChange(newValue, section, field);
  };

  const configList:ConfigState['api']['data']['fetchGetConfigList'] = useSelector(
    (state: StoreState) => state.configReducer?.api?.data?.fetchGetConfigList,
  );

  const {
    ASSET_TYPE,
  } = configList;
  const assetTypeSelectOptions: ISelectOption[] = typeof (ASSET_TYPE) !== 'undefined' && ASSET_TYPE.length > 0 ? ASSET_TYPE.map((type, index) => ({ id: index, value: type })) : [];

  const getCollectionName = (cid: string | number) => {
    const item = collectionList.find((obj) => obj.collectionId === cid);
    return item !== undefined ? item.collectionName : '';
  };

  const handleCollectionFieldChange = (collName: string | number) => {
    let filterList = [];
    const datasetListArrFilter = datasetList?.length > 0 ? datasetList.sort((a, b) => a?.datasetName.localeCompare(b?.datasetName)) : [];
    if (collName === 'ao') {
      filterList = datasetListArrFilter.filter((item) => item.datasetCode.substring(0, 1) === 'W');
    } else if (collName === 'claritas-census') {
      filterList = datasetListArrFilter.filter((item) => item.datasetName === 'census_2022');
    } else {
      filterList = datasetListArrFilter.filter((item) => item?.datasetName === '2022');
    }
    setFilterDatasetList(filterList);
    let newDatasetId;
    if (filterList.length === 1 || assetData?.asset?.assetId === undefined) {
      newDatasetId = filterList[0].datasetId;
    } else {
      newDatasetId = '';
    }
    handleFieldChange(newDatasetId.toString(), 'asset', 'datasetId');
  };
  useEffect(() => {
    let arr = [];
    const datasetListArr = datasetList?.length > 0 ? datasetList.sort((a, b) => a?.datasetName.localeCompare(b?.datasetName)) : [];

    if (collectionName === 'ao') {
      arr = datasetListArr.filter((item) => item.datasetCode.substring(0, 1) === 'W');
    } else if (collectionName && collectionName === 'claritas-census') {
      arr = datasetListArr.filter((item) => item.datasetName === 'census_2022');
    } else {
      arr = datasetListArr.filter((item) => item?.datasetName === '2022');
    }
    setFilterDatasetList(arr);
  }, [collectionName, datasetList]);

  return (
    <Grid container>
      <WppAccordion text="Core Attributes" expandedByDefault>
        <Grid container sx={{ width: 'auto' }}>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid container item sx={{ width: itemWidth, flexDirection: 'column' }}>
              <Grid item sx={{ m: 1 }}>
                <TextField
                  variant="standard"
                  focused
                  required
                  label="Asset Name"
                  value={typeof (assetName) !== 'undefined' ? assetName : ''}
                  onChange={(event) => onValidatedFieldChange(event.target.value, 'asset', 'assetName')}
                  error={formErrors.assetName}
                  color={formErrors.assetName ? 'error' : 'primary'}
                />
              </Grid>
              <Grid item sx={{ m: 1 }}>
                <Select
                  title="Status"
                  options={AssetStatusOptions}
                  value={typeof (status) !== 'undefined' ? status : ''}
                  useIdOrValue={SelectType.useValue}
                  onChange={(
                    event:SelectChangeEvent,
                  ) => onValidatedFieldChange(event.target.value, 'asset', 'status')}
                  error={formErrors.status}
                />
              </Grid>
            </Grid>
            <Grid container item sx={{ width: itemWidth, flexDirection: 'column' }}>
              <Grid item sx={{ m: 1 }}>
                <TextField
                  variant="standard"
                  focused
                  required
                  label="Description"
                  value={typeof (assetDesc) !== 'undefined' ? assetDesc : ''}
                  onChange={(event) => onValidatedFieldChange(event.target.value, 'asset', 'assetDesc')}
                  error={formErrors.assetDesc}
                  color={formErrors.assetDesc ? 'error' : 'primary'}
                />
              </Grid>
              <Grid item sx={{ m: 1 }}>
                <TextField
                  variant="standard"
                  focused
                  label="Owner"
                  value={typeof (assetOwner) !== 'undefined' ? assetOwner : ''}
                  onChange={(event) => handleFieldChange(event.target.value, 'asset', 'assetOwner')}
                />
              </Grid>
            </Grid>
            <Grid container item sx={{ width: itemWidth, flexDirection: 'column' }}>
              <Grid item sx={{ m: 1 }}>
                <Select
                  title="Asset Type"
                  value={typeof (assetType) !== 'undefined' ? assetType : ''}
                  options={assetTypeSelectOptions}
                  useIdOrValue={SelectType.useValue}
                  onChange={(event: SelectChangeEvent) => onValidatedFieldChange(event.target.value, 'asset', 'assetType')}
                />
              </Grid>
            </Grid>
            <Grid container item sx={{ width: itemWidth, flexDirection: 'column' }}>
              <Grid item sx={{ m: 1 }}>
                <Select
                  title="Category"
                  value={categoryId?.toString()}
                  disabled={false}
                  options={categoryList}
                  useIdOrValue={SelectType.useId}
                  onChange={(event: SelectChangeEvent) => onValidatedFieldChange(event.target.value, 'asset', 'categoryId')}
                  error={formErrors.categoryId}
                />
              </Grid>
            </Grid>
            <Grid container item sx={{ width: itemWidth, flexDirection: 'column' }}>
              <Grid item sx={{ m: 1 }}>
                <Select
                  title="Tag"
                  value={tagId?.toString()}
                  disabled={false}
                  options={tagList}
                  useIdOrValue={SelectType.useId}
                  onChange={(event: SelectChangeEvent) => onValidatedFieldChange(event.target.value, 'asset', 'tagId')}
                  error={formErrors.tagId}
                />
              </Grid>
            </Grid>
            <Grid container item sx={{ width: itemWidth, flexDirection: 'column' }}>
              <Grid item sx={{ m: 1 }}>
                <Select
                  title="Collection"
                  value={collectionId?.toString()}
                  disabled={false}
                  options={collectionList}
                  useIdOrValue={SelectType.useId}
                  onChange={(event: SelectChangeEvent) => {
                    handleFieldChange(event.target.value, 'asset', 'collectionId');
                    handleCollectionFieldChange(getCollectionName(event.target.value));
                  }}
                  error={formErrors.collectionId}
                />
              </Grid>
            </Grid>
            <Grid container item sx={{ width: itemWidth, flexDirection: 'column' }}>
              <Grid item sx={{ m: 1 }}>
                <Select
                  title="Dataset"
                  value={datasetId}
                  // disabled={filterDatasetList.length === 1 && !assetData?.asset?.assetId}
                  options={filterDatasetList}
                  useIdOrValue={SelectType.useId}
                  onChange={(event: SelectChangeEvent) => onValidatedFieldChange(event.target.value, 'asset', 'datasetId')}
                  error={formErrors?.datasetId}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </WppAccordion>
    </Grid>
  );
}

export default AssetCoreAttributesAccordion;
