import React, { Dispatch, SetStateAction } from 'react';
import { Card, Grid } from '@mui/material';
import CoreAttributesAccordion from './attrb-core-attributes-accordion';
import RulesAccordion from './RulesAccordion';
import { AssetMultiStepGetResponse } from '../../../../../services/models/asset-models.d';
import { IAttributeFormErrors, IAttributeRuleFormErrors } from '../../../../../services/models/validation-models.d';

export interface AttributeEditorProps {
  assetData:AssetMultiStepGetResponse,
  rulesEnabled: boolean,
  setRulesEnabled: Dispatch<SetStateAction<boolean>>,
  validate:(accordion: number, formErrors: IAttributeFormErrors[] | IAttributeRuleFormErrors) => void;
}

function AttributeEditor(props:AttributeEditorProps) {
  const {
    assetData,
    rulesEnabled,
    setRulesEnabled,
    validate,
  } = props;

  return (
    <Card sx={{ width: '100%' }}>
      <Grid container sx={{ flexDirection: 'column' }}>
        <Grid container item xs={12} sx={{ flexDirection: 'column' }}>
          <CoreAttributesAccordion
            assetData={assetData}
            rulesEnabled={rulesEnabled}
            setRulesEnabled={setRulesEnabled}
            validate={validate}
          />
          {rulesEnabled
            ? (
              <RulesAccordion
                assetData={assetData}
                validate={validate}
              />
            )
            : <div />}
        </Grid>
      </Grid>
    </Card>

  );
}

export default AttributeEditor;
