import {
  SnackbarState,
  SnackbarAction,
  SnackbarActionOptions,
} from '../../services/models/snackbar-models.d';

export const setSnackbarActionCreator: (update: SnackbarState) => SnackbarAction = (update: SnackbarState) => ({
  type: SnackbarActionOptions.SET_SNACKBAR,
  payload: update,
});

export const snackbarMaker:(
  open:SnackbarState['open'],
  message:SnackbarState['message'],
  severity:SnackbarState['severity']
) => SnackbarState = (open, message, severity) => {
  const snackbar:SnackbarState = { open, message, severity };
  return snackbar;
};
