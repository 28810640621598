import React, { Dispatch, SetStateAction } from 'react';

// WPP Open
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { GridCellParams, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import Table from '../../../../ui-component/table';
import Cell from '../../../../ui-component/cell';
import {
  AssetAttrbtDto, AssetMultiStepGetResponse, AttributeTableRow,
} from '../../../../services/models/asset-models.d';
import { setAssetSelectedTableRowActionCreator, setFormDataActionCreator } from '../../../../store/actions/asset-actions';
import { EMPTY_ASSET_ATTRBT } from '../../../../services/defaults/asset-defaults';
import { BooleanType } from '../../../../services/models/select-models.d';

export interface AttributeConfigurationTableProps {
  assetData:AssetMultiStepGetResponse
  setRulesEnabled: Dispatch<SetStateAction<boolean>>,
}

function AttributeConfigurationTable(props:AttributeConfigurationTableProps): JSX.Element {
  const { assetData, setRulesEnabled } = props;
  const dispatch = useDispatch();

  let rows:AssetAttrbtDto[] = [];

  if (typeof assetData.assetAttrbtList !== 'undefined' && assetData.assetAttrbtList !== null) {
    rows = assetData.assetAttrbtList;
  }

  const columnFormat = [
    {
      field: 'assetAttrbtId', headerText: 'Asset Attribute Id', // Hidden
    },
    {
      field: 'assetId', headerText: 'Asset Id', // Hidden
    },
    {
      field: 'assetAttrbtName', description: '', headerText: 'Attribute Name', minWidth: 150,
    },
    {
      field: 'assetAttrbtDesc', description: '', headerText: 'Description', minWidth: 150,
    },
    {
      field: 'assetAttrbtKeyTypeCode', description: '', headerText: 'Key Type Code', minWidth: 150,
    },
    {
      field: 'dataType', description: '', headerText: 'Data Type', minWidth: 150,
    },
    {
      field: 'categoryId', description: '', headerText: 'Category Id', minWidth: 150,
    },
    {
      field: 'tagId', description: '', headerText: 'Tag Id', minWidth: 150,
    },
    {
      field: 'lineageId', description: '', headerText: 'Lineage Id', minWidth: 150,
    },
    {
      field: 'assetAttrbtSize', description: '', headerText: 'Size', minWidth: 150,
    },
    {
      field: 'assetAttrbtNullable', description: '', headerText: 'Nullable', minWidth: 150,
    },
    {
      field: 'assetAttrbtPrecision', description: '', headerText: 'Precision', minWidth: 150,
    },
    {
      field: 'assetAttrbtScale', description: '', headerText: 'Scale', minWidth: 150,
    },
    {
      field: 'colIndex', description: '', headerText: 'Column Index', minWidth: 150,
    },
    {
      field: 'colStartPos', description: '', headerText: 'Column Start Position', minWidth: 170,
    },
    {
      field: 'colEndPos', description: '', headerText: 'Column End Position', minWidth: 170,
    },
    {
      field: 'dupColFlg', description: '', headerText: 'Duplicate Column Flag', minWidth: 170,
    },
    {
      field: 'colLabel', description: '', headerText: 'Column Label', minWidth: 150,
    },
    {
      field: 'dateFormat', description: '', headerText: 'Date Format', minWidth: 150,
    },
    {
      field: 'timeFormat', description: '', headerText: 'Time Format', minWidth: 150,
    },
    {
      field: 'fieldType', description: '', headerText: 'Field Type', minWidth: 150,
    },
    {
      field: 'upperCaseExcludFlg', description: '', headerText: 'Uppercase Excluded Flag', minWidth: 200,
    },
    {
      field: 'selectiveKillfillFlg', description: '', headerText: 'Selective Kill Fill Flag', minWidth: 170,
    },
    {
      field: 'rulesEnabledFlg', description: '', headerText: 'Rules Enabled', minWidth: 150,
    },
    {
      field: 'licenceFlg', description: '', headerText: 'Licence', minWidth: 150,
    },
    {
      field: 'rulesEnabledFlg', description: '', headerText: 'Rules Enabled', minWidth: 150,
    },
    {
      field: 'licenceFlg', description: '', headerText: 'Licence', minWidth: 150,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150,
    },
    {
      field: 'createdUserId', description: '', headerText: 'Created User Id', minWidth: 150,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', description: '', headerText: 'Updated User Id', minWidth: 150,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'versionId', description: '', headerText: 'Version Id', minWidth: 150,
    },
  ];

  const columnVisibilityModel = {
    assetAttrbtId: false,
    assetId: false,
  };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: false,
      filterable: false,
    };
    return columnFormatted;
  }));

  const populateAttributeForm = (selectedAttributeTableRow:AttributeTableRow) => {
    if (selectedAttributeTableRow?.assetAttrbtId !== null) {
      const attributeFormDataName = 'attributeFormData';
      const assetAttrbtDto: AssetAttrbtDto = { ...EMPTY_ASSET_ATTRBT };

      assetAttrbtDto.assetAttrbtId = selectedAttributeTableRow.assetAttrbtId !== null ? selectedAttributeTableRow.assetAttrbtId : -1;
      assetAttrbtDto.assetId = selectedAttributeTableRow.assetId !== null ? selectedAttributeTableRow.assetId : -1;
      assetAttrbtDto.assetAttrbtName = selectedAttributeTableRow.assetAttrbtName !== null ? selectedAttributeTableRow.assetAttrbtName : '';
      assetAttrbtDto.assetAttrbtDesc = selectedAttributeTableRow.assetAttrbtDesc !== null ? selectedAttributeTableRow.assetAttrbtDesc : '';
      assetAttrbtDto.assetAttrbtKeyTypeCode = selectedAttributeTableRow.assetAttrbtKeyTypeCode !== null ? selectedAttributeTableRow.assetAttrbtKeyTypeCode : '';
      assetAttrbtDto.dataType = selectedAttributeTableRow.dataType !== null ? selectedAttributeTableRow.dataType : '';
      // assetAttrbtDto.categoryId = selectedAttributeTableRow.categoryId !== null ? selectedAttributeTableRow.categoryId : '';
      assetAttrbtDto.tagId = selectedAttributeTableRow.tagId !== null ? selectedAttributeTableRow.tagId : -1;
      // assetAttrbtDto.lineageId = selectedAttributeTableRow.lineageId !== null ? selectedAttributeTableRow.lineageId : '';
      assetAttrbtDto.assetAttrbtSize = selectedAttributeTableRow.assetAttrbtSize !== null ? selectedAttributeTableRow.assetAttrbtSize : 0;
      assetAttrbtDto.assetAttrbtNullable = selectedAttributeTableRow.assetAttrbtNullable !== null ? selectedAttributeTableRow.assetAttrbtNullable : 'N';
      assetAttrbtDto.assetAttrbtPrecision = selectedAttributeTableRow.assetAttrbtPrecision !== null ? selectedAttributeTableRow.assetAttrbtPrecision : 0;
      // assetAttrbtDto.assetAttrbtScale = selectedAttributeTableRow.assetAttrbtScale !== null ? selectedAttributeTableRow.assetAttrbtScale : '';
      // assetAttrbtDto.colIndex = selectedAttributeTableRow.colIndex !== null ? selectedAttributeTableRow.colIndex : '';
      // assetAttrbtDto.colStartPos = selectedAttributeTableRow.colStartPos !== null ? selectedAttributeTableRow.colStartPos : '';
      // assetAttrbtDto.colEndPos = selectedAttributeTableRow.colEndPos !== null ? selectedAttributeTableRow.colEndPos : '';
      assetAttrbtDto.dupColFlg = selectedAttributeTableRow.dupColFlg !== null ? selectedAttributeTableRow.dupColFlg : '';
      // assetAttrbtDto.colLabel = selectedAttributeTableRow.colLabel !== null ? selectedAttributeTableRow.colLabel : '';
      // assetAttrbtDto.dateFormat = selectedAttributeTableRow.dateFormat !== null ? selectedAttributeTableRow.dateFormat : '';
      // assetAttrbtDto.timeFormat = selectedAttributeTableRow.timeFormat !== null ? selectedAttributeTableRow.timeFormat : '';
      assetAttrbtDto.defaultValue = selectedAttributeTableRow.defaultValue !== null ? selectedAttributeTableRow.defaultValue : '';
      // assetAttrbtDto.upperCaseExcludFlg = selectedAttributeTableRow.upperCaseExcludFlg !== null ? selectedAttributeTableRow.upperCaseExcludFlg : '';
      // assetAttrbtDto.selectiveKillfillFlg = selectedAttributeTableRow.selectiveKillfillFlg !== null ? selectedAttributeTableRow.selectiveKillfillFlg : '';
      assetAttrbtDto.rulesEnabledFlg = selectedAttributeTableRow.rulesEnabledFlg !== null ? selectedAttributeTableRow.rulesEnabledFlg : 'N';
      setRulesEnabled(assetAttrbtDto.rulesEnabledFlg === BooleanType.True);
      assetAttrbtDto.licenceFlg = selectedAttributeTableRow.licenceFlg !== null ? selectedAttributeTableRow.licenceFlg : 'N';
      assetAttrbtDto.status = selectedAttributeTableRow.status !== null ? selectedAttributeTableRow.status : 'Draft';
      // assetAttrbtDto.createdUserId = selectedAttributeTableRow.createdUserId !== null ? selectedAttributeTableRow.createdUserId : '';
      // assetAttrbtDto.createdDatetime = selectedAttributeTableRow.createdDatetime !== null ? selectedAttributeTableRow.createdDatetime : '';
      // assetAttrbtDto.updatedUserId = selectedAttributeTableRow.updatedUserId !== null ? selectedAttributeTableRow.updatedUserId : '';
      // assetAttrbtDto.updatedDatetime = selectedAttributeTableRow.updatedDatetime !== null ? selectedAttributeTableRow.updatedDatetime : '';
      // assetAttrbtDto.versionId = selectedAttributeTableRow.versionId !== null ? selectedAttributeTableRow.versionId : '';
      // assetAttrbtDto.assetAttrbtRuleList = selectedAttributeTableRow.assetAttrbtRuleList !== null ? selectedAttributeTableRow.assetAttrbtRuleList : '';

      dispatch(setFormDataActionCreator({ [attributeFormDataName]: assetAttrbtDto }));
    }
  };

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const tableName = 'selectedAttributeTableRow';
    const selectedAttributeTableRowObj:AttributeTableRow = Object.assign({}, ...rowDetails);
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: selectedAttributeTableRowObj }));
    populateAttributeForm(selectedAttributeTableRowObj);
  };

  const onPageChange = () => null;

  const onPageSizeChange = () => null;

  const onSortModelChange = () => null; // Not implemented

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={rows.length > 100}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={rows.length > 100 ? 1 : rows.length}
        rowCount={rows.length}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
}

export default AttributeConfigurationTable;
