import React, { ReactElement } from 'react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { Tooltip as MUITooltip } from '@mui/material';

export interface TooltipProps {
  children: ReactElement,
  disableHoverListener: boolean,
  title: string,
}

function Tooltip(props:TooltipProps) {
  const { disableHoverListener, title, children } = props;
  return (
    <MUITooltip
      disableHoverListener={disableHoverListener}
      placement="bottom-start"
      title={(
        <WppTypography
          type="s-body"
          style={{ '--wpp-typography-color': 'white' }}
        >
          {title}
        </WppTypography>
      )}
    >
      {children}
    </MUITooltip>
  );
}

export default Tooltip;
