import React, { useEffect } from 'react';
import {
  Card,
  Grid,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Buffer } from 'buffer';
import Select from '../../../ui-component/select';
import { StatusOptions, SelectType, ISelectOption } from '../../../services/models/select-models.d';
import FormButtons from '../../../ui-component/form-buttons';
import { ConfigState } from '../../../services/models/config-models.d';
import { EMPTY_RULE, DESELECTED_RULE_TABLE_ROW } from '../../../services/defaults/master-data-defaults';
import {
  MasterDataState, MasterDataPostType, MasterDataTableNames,
} from '../../../services/models/master-data-models.d';
import { StoreState } from '../../../store';
import {
  setMasterDataDataActionCreator, setMasterDataEditsActionCreator, setMasterDataSelectedTableRowActionCreator, fetchPostMasterDataActionCreator,
} from '../../../store/actions/master-data-actions';
import useAuthUser from '../../../hooks/use-auth-user';

function AssetRuleForm() {
  window.Buffer = window.Buffer || Buffer;
  const dispatch = useDispatch();
  const activeUser = useAuthUser();
  const itemWidth = '150px';

  const ruleData:MasterDataState['api']['data']['fetchGetRuleData'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetRuleData,
  );

  const masterDataEdits:MasterDataState['masterDataEdits'] = useSelector(
    (state: StoreState) => state.masterDataReducer.masterDataEdits,
  );

  const configList:ConfigState['api']['data']['fetchGetConfigList'] = useSelector(
    (state: StoreState) => state.configReducer?.api?.data?.fetchGetConfigList,
  );

  // This useEffect handles UI changes, such as a user editing a field
  useEffect(() => {}, [masterDataEdits]);

  const handleFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newResponse:any = ruleData;
    const dataName = 'fetchGetRuleData';
    const editCounterName = 'masterDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newResponse[field] = newValue;
    }
    dispatch(setMasterDataDataActionCreator({ [dataName]: newResponse }));
    dispatch(setMasterDataEditsActionCreator({ [editCounterName]: masterDataEdits + 1 }));
  };

  const onClear = () => {
    const fetchGetRuleData = 'fetchGetRuleData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetRuleData]: { ...EMPTY_RULE } }));
    const selectedTableName = 'selectedRuleTableRow';
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_RULE_TABLE_ROW } }));
  };

  const handlePostRule = (postType:MasterDataPostType) => {
    let newRule: any = null;
    let ruleId = -1;
    let createdDatetime = '';
    let updatedDatetime = '';
    if (typeof ruleData !== 'undefined' && ruleData !== null) {
      // Fix for backend issue is to add base64 encoding
      ruleData.ruleExpression = Buffer.from(ruleData.ruleExpression).toString('base64');
      if (ruleData.ruleId !== -1) {
        ({
          createdDatetime, updatedDatetime, ...newRule
        } = ruleData);
      } else {
        ({
          ruleId, createdDatetime, updatedDatetime, ...newRule
        } = ruleData);
      }
    }
    if (typeof activeUser.email !== 'undefined') {
      newRule.updatedUserId = activeUser.email;
      if (postType === MasterDataPostType.CREATE) {
        newRule.createdUserId = activeUser.email;
      }
    }
    dispatch(fetchPostMasterDataActionCreator(newRule, postType, MasterDataTableNames.RULE));
    onClear();
  };

  const handleSubmit = async () => {
    let ruleId = -1;
    const ruleObj = ruleData;
    ({ ruleId } = ruleObj);
    if (ruleId === -1) {
      handlePostRule(MasterDataPostType.CREATE);
    } else {
      handlePostRule(MasterDataPostType.UPDATE);
    }
  };

  // let ruleId;
  let ruleName;
  let ruleDesc;
  let status;
  // let errorCode;
  // let accountId;
  let ruleCategory;
  let ruleType;
  let ruleExpression;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;

  if (typeof ruleData !== 'undefined'
    && ruleData !== null) {
    ({
      // ruleId,
      ruleName,
      ruleDesc,
      status,
      // errorCode,
      // accountId,
      ruleCategory,
      ruleType,
      ruleExpression,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = ruleData);
  }

  const { RULE_CATEGORY, RULE_TYPE } = configList;
  const ruleCategorySelectOptions:ISelectOption[] = RULE_CATEGORY !== null ? RULE_CATEGORY?.map((ruleCategoryOption, index) => ({ id: index, value: ruleCategoryOption })) : [];
  const ruleTypeSelectOptions:ISelectOption[] = RULE_TYPE !== null ? RULE_TYPE?.map((ruleTypeOption, index) => ({ id: index, value: ruleTypeOption })) : [];

  return (
    <Grid item xs={12}>
      <Card sx={{
        p: 2, elevation: 0, width: '100%', height: '100%',
      }}
      >
        <Grid container>
          <FormButtons
            onSave={handleSubmit}
            onClear={onClear}
          />
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="Rule Name"
                value={typeof (ruleName) !== 'undefined' ? ruleName : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'ruleName')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="Rule Description"
                value={typeof (ruleDesc) !== 'undefined' ? ruleDesc : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'ruleDesc')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Rule Category"
                value={typeof (ruleCategory) !== 'undefined' ? ruleCategory : ''}
                options={ruleCategorySelectOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(
                  event:SelectChangeEvent,
                ) => handleFieldChange(event.target.value, 'ruleCategory')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Rule Type"
                value={typeof (ruleType) !== 'undefined' ? ruleType : ''}
                options={ruleTypeSelectOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(
                  event:SelectChangeEvent,
                ) => handleFieldChange(event.target.value, 'ruleType')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                multiline
                label="Rule Expression"
                value={typeof (ruleExpression) !== 'undefined' ? ruleExpression : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'ruleExpression')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Status"
                value={typeof (status) !== 'undefined' ? status : ''}
                disabled={false}
                options={StatusOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(
                  event:SelectChangeEvent,
                ) => handleFieldChange(event.target.value, 'status')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default AssetRuleForm;
