import React, {
  useEffect,
  useState,
} from 'react';

// material-ui
import {
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DatasetTable from './dataset-table';
import DatasetForm from './dataset-form';
import MainContent from '../../../layout/MainLayout/MainContent';
import {
  FetchGetMasterDataParams, MasterDataTableNames, MasterDataState, DatasetDto, MasterDataPostType, OrderDirection,
} from '../../../services/models/master-data-models.d';
import { DEFAULT_MASTER_DATA_PAGINATION, DESELECTED_DATASET_TABLE_ROW, EMPTY_DATASET } from '../../../services/defaults/master-data-defaults';
import { StatusOption } from '../../../services/models/select-models.d';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { StoreState } from '../../../store';
import {
  fetchGetMasterDataActionCreator, fetchPostMasterDataActionCreator, setMasterDataDataActionCreator, setMasterDataSelectedTableRowActionCreator,
} from '../../../store/actions/master-data-actions';

function Dataset() {
  const dispatch = useDispatch();

  let datasetList:MasterDataState['api']['data']['fetchGetDatasetList']['datasetList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetDatasetList?.datasetList,
  );

  const selectedDatasetTableRow:MasterDataState['tables']['selectedDatasetTableRow'] = useSelector(
    (state: StoreState) => state.masterDataReducer.tables?.selectedDatasetTableRow,
  );
  const fetchGetDatasetListFlag: MasterDataState['api']['flags']['fetchGetDatasetListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetDatasetListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetDatasetList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetDatasetList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetDatasetList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetDatasetList?.listOrder,
  );

  let limit = 0; // The page size, or max records displayed
  if (typeof pagination !== 'undefined') {
    ({ limit } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
    } = listOrder);
  }

  if (datasetList === null || typeof datasetList === 'undefined') {
    datasetList = [];
  }

  useEffect(() => {
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.DATASET));
  }, [dispatch]);

  const tableHeight = '730px';
  // const tableWidth = '77vw';
  const tableWidth = '101%';
  const [showDatasetUI, setShowDatasetUI] = useState<boolean>(false);

  const getSelectedDataset = () : DatasetDto => {
    const datasetDto: DatasetDto = { ...EMPTY_DATASET };
    datasetDto.datasetId = selectedDatasetTableRow.datasetId !== null ? selectedDatasetTableRow.datasetId : -1;
    datasetDto.datasetName = selectedDatasetTableRow.datasetName !== null ? selectedDatasetTableRow.datasetName : '';
    datasetDto.datasetDesc = selectedDatasetTableRow.datasetDesc !== null ? selectedDatasetTableRow.datasetDesc : '';
    datasetDto.datasetCode = selectedDatasetTableRow.datasetCode !== null ? selectedDatasetTableRow.datasetCode : '';
    datasetDto.status = selectedDatasetTableRow.status !== null ? selectedDatasetTableRow.status : '';
    datasetDto.createdUserId = selectedDatasetTableRow.createdUserId !== null ? selectedDatasetTableRow.createdUserId : '';
    datasetDto.createdDatetime = selectedDatasetTableRow.createdDatetime !== null ? selectedDatasetTableRow.createdDatetime : '';
    datasetDto.updatedUserId = selectedDatasetTableRow.updatedUserId !== null ? selectedDatasetTableRow.updatedUserId : '';
    datasetDto.updatedDatetime = selectedDatasetTableRow.updatedDatetime !== null ? selectedDatasetTableRow.updatedDatetime : '';
    return datasetDto;
  };

  const handleDelete = async () => {
    const datasetDto: DatasetDto = getSelectedDataset();
    if (datasetDto.datasetId !== -1) {
      datasetDto.status = StatusOption.Inactive;
      dispatch(fetchPostMasterDataActionCreator(datasetDto, MasterDataPostType.DELETE, MasterDataTableNames.DATASET));
      // const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      // dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.DATASET));
      setShowDatasetUI(false);
      const fetchGetDatasetData = 'fetchGetDatasetData';
      dispatch(setMasterDataDataActionCreator({ [fetchGetDatasetData]: { ...EMPTY_DATASET } }));
      const selectedTableName = 'selectedDatasetTableRow';
      dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_DATASET_TABLE_ROW } }));
    }
  };

  const handleSearchDataset = (newSearchValue: string) => {
    if (!fetchGetDatasetListFlag) {
      const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = 'datasetName';
      fetchGetMasterDataParams.searchValue = newSearchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.DATASET, ShowSnackbar.True));
    }
  };

  return (
    <MainContent
      title="Dataset"
      buttonTitles={{
        addTitle: 'Add Dataset',
        editTitle: 'Edit Dataset',
        deleteTitle: 'Delete Dataset',
        summaryTitle: 'Dataset Summary',
        importTitle: 'Import Dataset',
        copyTitle: 'Copy Dataset',
      }}
      addCallback={() => {
        const fetchGetDatasetData = 'fetchGetDatasetData';
        const datasetDto: DatasetDto = { ...EMPTY_DATASET };
        dispatch(setMasterDataDataActionCreator({ [fetchGetDatasetData]: datasetDto }));
        setShowDatasetUI(!showDatasetUI);
      }}
      editCallback={() => {
        setShowDatasetUI(!showDatasetUI);
      }}
      deleteCallback={() => handleDelete()}
      summaryCallback={() => null} // TODO
      importCallback={() => null} // TODO
      copyCallback={() => null} // TODO
      searchCallback={(newSearchValue) => handleSearchDataset(newSearchValue)}
      searchPlaceholder="Search Datasets"
      editDisabled={selectedDatasetTableRow?.datasetId === null}
      deleteDisabled={selectedDatasetTableRow?.datasetId === null}
      copyDisabled
      summaryDisabled
      importDisabled
    >
      <Grid
        container
        item
        sx={{
          width: tableWidth, height: tableHeight, justifyContent: 'space-between',
        }}
      >
        <Grid item xs={showDatasetUI ? 8 : 12}>
          <DatasetTable rows={datasetList} />
        </Grid>
        {showDatasetUI
          ? (
            <Grid container item xs={4} sx={{ pl: 2.5 }}>
              <DatasetForm />
            </Grid>
          )
          : <Grid item />}
      </Grid>
    </MainContent>
  );
}

export default Dataset;
