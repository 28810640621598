import React from 'react';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import {
  GridCellParams, GridRenderCellParams, GridSortModel, GridValueGetterParams,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../ui-component/table';
import Cell from '../../../ui-component/cell';
import {
  MasterDataState, CategoryDto, CategoryTableRow, FetchGetMasterDataParams, MasterDataTableNames, OrderDirection,
} from '../../../services/models/master-data-models.d';
import { DEFAULT_MASTER_DATA_PAGINATION, EMPTY_CATEGORY } from '../../../services/defaults/master-data-defaults';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { StoreState } from '../../../store';
import { setMasterDataSelectedTableRowActionCreator, setMasterDataDataActionCreator, fetchGetMasterDataActionCreator } from '../../../store/actions/master-data-actions';
// import { IdPrefix } from '../../../services/models/ui-component-model';
// import TableToolbar from '../../../ui-component/table-toolbar';

export interface CategoryTableProps {
  rows: CategoryDto[],
}
const CategoryTable = function RenderCategoryTable(props:CategoryTableProps) {
  const { rows } = props;
  const dispatch = useDispatch();

  const fetchGetCategoryListFlag: MasterDataState['api']['flags']['fetchGetCategoryListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetCategoryListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetCategoryList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCategoryList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetCategoryList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetCategoryList?.listOrder,
  );

  // let records = 0; // records returned on page, can be less than limit
  let page = 0; // current page index
  let limit = 0; // The page size, or max records displayed
  let totalRecords = 0; // Total number of records
  // let totalPages; // Total pages based on limit
  if (typeof pagination !== 'undefined') {
    ({
      // records,
      page,
      limit,
      totalRecords,
      // totalPages,
    } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  let searchKey = '';
  let searchValue = '';
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
      searchKey,
      searchValue,
    } = listOrder);
  }

  const columnFormat = [
    {
      field: 'categoryId', description: '', headerText: 'Category ID', minWidth: 50, valueGetter: undefined, sortable: true,
    },
    {
      field: 'categoryName', description: '', headerText: 'Category Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'categoryDesc', description: '', headerText: 'Category Description', minWidth: 175, valueGetter: undefined, sortable: false,
    },
    {
      field: 'categoryParentId', description: '', headerText: 'Category Parent ID', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'categoryLevel', description: '', headerText: 'Category Level', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdUserId', headerText: 'Created User ID', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', headerText: 'Updated User ID', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const columnVisibilityModel = {
    createdUserId: false,
    updatedUserId: false,
  };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: column.sortable,
      filterable: false,
    };
    return columnFormatted;
  }));

  const getSelectedCategory = (selectedCategoryTableRow: CategoryTableRow) : CategoryDto => {
    const categoryDto: CategoryDto = { ...EMPTY_CATEGORY };
    categoryDto.categoryId = selectedCategoryTableRow.categoryId !== null ? selectedCategoryTableRow.categoryId : -1;
    categoryDto.categoryName = selectedCategoryTableRow.categoryName !== null ? selectedCategoryTableRow.categoryName : '';
    categoryDto.categoryDesc = selectedCategoryTableRow.categoryDesc !== null ? selectedCategoryTableRow.categoryDesc : '';
    categoryDto.categoryParentId = selectedCategoryTableRow.categoryParentId !== null ? selectedCategoryTableRow.categoryParentId : -1;
    categoryDto.categoryLevel = selectedCategoryTableRow.categoryLevel !== null ? selectedCategoryTableRow.categoryLevel : -1;
    categoryDto.status = selectedCategoryTableRow.status !== null ? selectedCategoryTableRow.status : '';
    categoryDto.createdUserId = selectedCategoryTableRow.createdUserId !== null ? selectedCategoryTableRow.createdUserId : '';
    categoryDto.createdDatetime = selectedCategoryTableRow.createdDatetime !== null ? selectedCategoryTableRow.createdDatetime : '';
    categoryDto.updatedUserId = selectedCategoryTableRow.updatedUserId !== null ? selectedCategoryTableRow.updatedUserId : '';
    categoryDto.updatedDatetime = selectedCategoryTableRow.updatedDatetime !== null ? selectedCategoryTableRow.updatedDatetime : '';
    return categoryDto;
  };

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const selectedTableName = 'selectedCategoryTableRow';
    const selectedCategoryTableRow:CategoryTableRow = Object.assign({}, ...rowDetails);
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: selectedCategoryTableRow }));
    const fetchGetCategoryData = 'fetchGetCategoryData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetCategoryData]: getSelectedCategory(selectedCategoryTableRow) }));
  };

  const onPageChange = (updatedPage: number) => {
    if (!fetchGetCategoryListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = updatedPage;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CATEGORY, ShowSnackbar.True));
    }
  };

  const onPageSizeChange = (updatedPageSize: number) => {
    if (!fetchGetCategoryListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = updatedPageSize;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CATEGORY, ShowSnackbar.True));
    }
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!fetchGetCategoryListFlag) {
      const fetchGetMasterDataParams: FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = page;
      fetchGetMasterDataParams.limit = limit;
      if (typeof model[0].field !== 'undefined') {
        fetchGetMasterDataParams.sortByFieldName = model[0].field;
      }
      if (typeof model[0].sort !== 'undefined') {
        fetchGetMasterDataParams.sortOrder = model[0].sort;
      }
      fetchGetMasterDataParams.searchKey = searchKey;
      fetchGetMasterDataParams.searchValue = searchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.CATEGORY, ShowSnackbar.True));
    }
  };

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        id="category-table"
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={false}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={limit || 0}
        rowCount={totalRecords || 0}
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
};

export default CategoryTable;
