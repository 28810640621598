/* eslint-disable react/prop-types */
import React from 'react';
import {
  DataGrid, GridColDef, GridCellParams, GridSortModel, GridFilterModel,
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
// import { AssetTableRow } from '../services/models/assetmodels.d';

export interface TableProps {
  columns: GridColDef[],
  columnVisibilityModel: { [key:string]: boolean } | undefined,
  customLoadingOverlay: () => JSX.Element,
  customToolbar: () => JSX.Element,
  filterModel: GridFilterModel,
  hideFooterPagination: boolean,
  loading:boolean,
  onCellClick: (params:GridCellParams) => void,
  onPageChange: (page:number) => void,
  onPageSizeChange: (updatedPageSize:number) => void,
  onSortModelChange: (model: GridSortModel) => void
  pageSize:number,
  rowCount:number,
  rows: any,
  headerHeight: number,
  // eslint-disable-next-line react/no-unused-prop-types
  id?: string,
}

const Table = function RenderTable(props:TableProps) {
  const {
    columns,
    columnVisibilityModel,
    customLoadingOverlay,
    customToolbar,
    filterModel,
    hideFooterPagination,
    loading,
    onCellClick,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    pageSize,
    rowCount,
    rows,
    headerHeight,
    id,
  } = props;

  let idCounter = 0;
  const myRows = rows?.map((row:any) => {
    idCounter += 1;
    return { ...row, id: idCounter };
  });

  return (
    <Box
      id={id}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <DataGrid
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        components={{
          LoadingOverlay: customLoadingOverlay,
          Toolbar: customToolbar,
        }}
        disableColumnSelector
        filterModel={filterModel}
        hideFooterPagination={hideFooterPagination}
        loading={loading}
        onCellClick={(params) => onCellClick(params)}
        onPageChange={(updatedPage) => onPageChange(updatedPage)}
        onPageSizeChange={(updatedPageSize) => onPageSizeChange(updatedPageSize)}
        onSortModelChange={(model) => onSortModelChange(model)}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        rowHeight={35}
        headerHeight={headerHeight}
        rows={loading ? [] : myRows}
        rowsPerPageOptions={[10, 25, 100]}
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
        sx={{
          border: 1,
          borderColor: 'grey.400',
          bgcolor: 'background.paper',
          '& .MuiDataGrid-toolbarContainer': {
            borderBottom: 'solid 1px',
            borderColor: 'grey.300',
          },
        }}
      />
    </Box>
  );
};

Table.defaultProps = {
  id: 'defaultId',
};

export default Table;
