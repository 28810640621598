import React, { useEffect } from 'react';

// material-ui
import {
  Box,
  Card, Grid, Tooltip,
} from '@mui/material';
import { WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react';
import { useSelector } from 'react-redux';
import { CatalogState } from '../../services/models/data-catalog-models.d';
import CatalogDetailsTable from './catalog-details-table';
import { StoreState } from '../../store';

export interface ItemProps {
  label: string,
  content: string,
}

function Item(props: ItemProps) {
  const { label, content } = props;
  const tooltipVisibleMinLength = 20;
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Grid container item xs={12}>
      <Grid item xs={4}>
        <WppTypography type="s-strong">
          {label}
        </WppTypography>
      </Grid>
      <Grid item xs={8}>
        <Box
          sx={{
            p: 0.5,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            // maxWidth: 200,
            overflow: 'hidden',
          }}
        >
          {content.length >= tooltipVisibleMinLength
            ? (
              <Tooltip disableHoverListener={false} title={content}>
                <Box
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: 300,
                    overflow: 'hidden',
                  }}
                >
                  <WppTypography type="m-body">
                    <span style={{ marginRight: '8px' }}>: </span>
                    {content}
                  </WppTypography>
                </Box>
              </Tooltip>
            )
            : (
              <WppTypography type="m-body">
                <span style={{ marginRight: '8px' }}>: </span>
                {content}
              </WppTypography>
            )}
        </Box>
      </Grid>
    </Grid>
  );
}

function CatalogDetails() {
  const tableHeight = '500px';
  const tableWidth = '101%';

  const catalogDetails: CatalogState['api']['data']['fetchGetCatalogAssetName']['catalogDataList'] = useSelector(
    (state: StoreState) => state.catalogReducer.api?.data?.fetchGetCatalogAssetName?.catalogDataList,
  );

  const catalogDetailsFlag: CatalogState['api']['flags']['fetchGetCatalogAssetNameFlag'] = useSelector(
    (state: StoreState) => state.catalogReducer.api?.flags?.fetchGetCatalogAssetNameFlag,
  );

  // let organizationCode;
  let collectionName;
  // let collectionDescription;
  let datasetName;
  let collectionStatus;
  let collectionSummary;
  let collectionOwner;
  let collectionDate;
  // let sourceType;
  // let sourceName;
  // let organizationName;
  let assetName;
  let assetDescription;
  let assetStatus;
  let assetSteward;
  // let assetCreated;
  // let assetModified;
  let tags;
  let autoSync;
  // let attributeName;
  // let attributeDescription;
  // let dataType;
  // let businessName;
  // let attributeIsNullable;
  // let attributeCreated;
  // let attributeModified;
  // let attributeStatus;
  let category;
  let subCategory;
  let group;
  let level;
  let compilation;
  let variableSource;
  let dataProvider;
  let royalty;
  let restrictions;
  let licenseUseFlag;
  let appendUseFlag;
  let digitalUseFlag;
  let directUseFlag;
  let modelUseFlag;
  let machineLearningType;
  let cardinality;
  let percentNull;
  let percentNullRounded;
  let minValue;
  let maxValue;
  let minValueLength;
  let maxValueLength;
  let quartile;
  let numericColumn;
  // let validValues;
  // let geoLocation;

  if (typeof catalogDetails[0] !== 'undefined') {
    // eslint-disable-next-line prefer-destructuring
    ({
      // organizationCode,
      collectionName,
      // collectionDescription,
      datasetName,
      collectionStatus,
      collectionSummary,
      collectionOwner,
      collectionDate,
      // sourceType,
      // sourceName,
      // organizationName,
      assetName,
      assetDescription,
      assetStatus,
      assetSteward,
      // assetCreated,
      // assetModified,
      tags,
      autoSync,
      // attributeName,
      // attributeDescription,
      // dataType,
      // businessName,
      // attributeIsNullable,
      // attributeCreated,
      // attributeModified,
      // attributeStatus,
      category,
      subCategory,
      group,
      level,
      compilation,
      variableSource,
      dataProvider,
      royalty,
      restrictions,
      licenseUseFlag,
      appendUseFlag,
      digitalUseFlag,
      directUseFlag,
      modelUseFlag,
      machineLearningType,
      cardinality,
      percentNull,
      percentNullRounded,
      minValue,
      maxValue,
      minValueLength,
      maxValueLength,
      quartile,
      numericColumn,
      // validValues,
      // geoLocation,
    } = catalogDetails[0]);
  }

  return (
    <Grid container xs={12} sx={{ m: 1 }}>
      <Card sx={{
        p: 2, elevation: 0, width: '99%', height: '100%',
      }}
      >
        <Grid container item xs={3} sx={{ justifyContent: 'flex-start' }}>
          <WppTypography tag="h4" type="2xl-heading">
            Details
          </WppTypography>
        </Grid>
        {
          catalogDetailsFlag ? (
            <Grid container xs={12} sx={{ width: 'auto', justifyContent: 'center' }}>
              <WppSpinner size="l" />
            </Grid>
          )
            : (
              <Grid container item xs={12}>
                <Grid container item xs={12} sx={{ mt: 2 }}>
                  <Grid container item xs={12}>
                    <div style={{ maxHeight: '280px', overflowY: 'auto', width: '100%' }}>
                      <div>
                        <Item label="Asset Name" content={assetName || ''} />
                        <Item label="Asset Description" content={assetDescription || ''} />
                        <Item label="Asset Status" content={assetStatus || ''} />
                        <Item label="Tags" content={tags || ''} />
                        <Item label="Collection Name" content={collectionName || ''} />
                        <Item label="Summary" content={collectionSummary || ''} />
                        <Item label="Collection Status" content={collectionStatus || ''} />
                        <Item label="Dataset Name" content={datasetName || ''} />
                      </div>
                      <div>
                        <Item label="Product Owner" content={collectionOwner || ''} />
                        <Item label="Collection Date" content={collectionDate || ''} />
                        <Item label="Data steward" content={assetSteward || ''} />
                        <Item label="Auto Sync" content={autoSync || ''} />
                        <Item label="Category" content={category || ''} />
                        <Item label="Sub Category" content={subCategory || ''} />
                        <Item label="Group" content={group || ''} />
                        <Item label="Level" content={level || ''} />
                        <Item label="Compilation" content={compilation || ''} />
                        <Item label="Variable Source" content={variableSource || ''} />
                        <Item label="Data Provider" content={dataProvider || ''} />
                        <Item label="Royalty" content={royalty || ''} />
                        <Item label="Restrictions" content={restrictions || ''} />
                        <Item label="License Use Flag" content={licenseUseFlag || ''} />
                        <Item label="Append Use Flag" content={appendUseFlag || ''} />
                        <Item label="Digital Use Flag" content={digitalUseFlag || ''} />
                        <Item label="Direct Use Flag" content={directUseFlag || ''} />
                        <Item label="ModelUse Flag" content={modelUseFlag || ''} />
                        <Item label="Machine Learning Type" content={machineLearningType || ''} />
                        <Item label="Cardinality" content={cardinality || ''} />
                        <Item label="Percent Null" content={percentNull || ''} />
                        <Item label="Percent Null Rounded" content={percentNullRounded || ''} />
                        <Item label="Min Value" content={minValue || ''} />
                        <Item label="Max Value" content={maxValue || ''} />
                        <Item label="Min Value Length" content={minValueLength || ''} />
                        <Item label="Max Value Length" content={maxValueLength || ''} />
                        <Item label="Quartile" content={quartile || ''} />
                        <Item label="Numeric Column" content={numericColumn || ''} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  sx={{
                    width: tableWidth, height: tableHeight, justifyContent: 'space-between', mt: 6,
                  }}
                >
                  <WppTypography type="l-strong">Columns</WppTypography>
                  <CatalogDetailsTable rows={catalogDetails} />
                </Grid>
              </Grid>
            )
        }
      </Card>
    </Grid>
  );
}

export default CatalogDetails;
