import React, { useEffect } from 'react';
import {
  Card,
  Grid,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StatusOptions, SelectType } from '../../../services/models/select-models.d';
import Select from '../../../ui-component/select';
import {
  MasterDataPostType,
  MasterDataState, MasterDataTableNames, TagDto,
} from '../../../services/models/master-data-models.d';
import FormButtons from '../../../ui-component/form-buttons';
import { DESELECTED_TAG_TABLE_ROW, EMPTY_TAG } from '../../../services/defaults/master-data-defaults';
import { StoreState } from '../../../store';
import {
  setMasterDataDataActionCreator, setMasterDataEditsActionCreator, setMasterDataSelectedTableRowActionCreator, fetchPostMasterDataActionCreator,
} from '../../../store/actions/master-data-actions';
import useAuthUser from '../../../hooks/use-auth-user';

function AssetTagForm() {
  const dispatch = useDispatch();
  const activeUser = useAuthUser();
  const itemWidth = '150px';

  const tagData:MasterDataState['api']['data']['fetchGetTagData'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetTagData,
  );

  const masterDataEdits:MasterDataState['masterDataEdits'] = useSelector(
    (state: StoreState) => state.masterDataReducer.masterDataEdits,
  );

  // This useEffect handles UI changes, such as a user editing a field
  useEffect(() => {}, [masterDataEdits]);

  const handleFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newResponse:any = tagData;
    const dataName = 'fetchGetTagData';
    const editCounterName = 'masterDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newResponse[field] = newValue;
    }
    dispatch(setMasterDataDataActionCreator({ [dataName]: newResponse }));
    dispatch(setMasterDataEditsActionCreator({ [editCounterName]: masterDataEdits + 1 }));
  };

  const onClear = () => {
    const fetchGetTagData = 'fetchGetTagData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetTagData]: { ...EMPTY_TAG } }));
    const selectedTableName = 'selectedTagTableRow';
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_TAG_TABLE_ROW } }));
  };

  const handlePostTag = (postType:MasterDataPostType) => {
    const tag: TagDto = tagData;
    if (typeof activeUser.email !== 'undefined') {
      tag.updatedUserId = activeUser.email;
      if (postType === MasterDataPostType.CREATE) {
        tag.createdUserId = activeUser.email;
      }
    }
    dispatch(fetchPostMasterDataActionCreator(tag, postType, MasterDataTableNames.TAG));
    onClear();
  };

  const handleSubmit = async () => {
    let tagId = -1;
    const tagObj = tagData;
    ({ tagId } = tagObj);
    if (tagId === -1) {
      handlePostTag(MasterDataPostType.CREATE);
    } else {
      handlePostTag(MasterDataPostType.UPDATE);
    }
  };

  // let tagId;
  let tagName;
  let tagDesc;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;

  // const tagObj = selectedTagTableRow.tagList[0];

  if (typeof tagData !== 'undefined'
    && tagData !== null) {
    ({
      // tagId,
      tagName,
      tagDesc,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = tagData);
  }
  return (
    <Grid item xs={12}>
      <Card sx={{
        p: 2, elevation: 0, width: '100%', height: '100%',
      }}
      >
        <Grid container>
          <FormButtons
            onSave={handleSubmit}
            onClear={onClear}
          />
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="Tag Name"
                value={typeof (tagName) !== 'undefined' ? tagName : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'tagName')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                rows={3}
                variant="standard"
                focused
                required
                label="Tag Description"
                value={typeof (tagDesc) !== 'undefined' ? tagDesc : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'tagDesc')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Status"
                value={typeof (status) !== 'undefined' ? status : ''}
                disabled={false}
                options={StatusOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(
                  event:SelectChangeEvent,
                ) => handleFieldChange(event.target.value, 'status')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default AssetTagForm;
