import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { WppAccordion } from '@platform-ui-kit/components-library-react';
import { useSelector } from 'react-redux';
import AttributeConfigurationTable from './attribute-configuration-table';
import AttributeEditor from './AttributeEditor';
import { AssetAttrbtRuleDto, AssetMultiStepGetResponse, AssetState } from '../../../../services/models/asset-models.d';
import AttributeRulesTable from './attribute-rules-table';
import { StoreState } from '../../../../store';
import { IAttributeFormErrors, IAttributeRuleFormErrors } from '../../../../services/models/validation-models.d';

export interface AttributeConfigurationProps {
  assetData:AssetMultiStepGetResponse,
  validate:(accordion: number, formErrors: IAttributeFormErrors[] | IAttributeRuleFormErrors) => void;
}

function AttributeConfiguration(props:AttributeConfigurationProps) {
  const {
    assetData,
    validate,
  } = props;
  const { assetAttrbtList } = assetData;
  const defaultHeight = 700;
  const defaultAccordionHeight = 525;
  const nonDefaultAccordionHeight = 200;
  const tableWidth = '101%';
  const [rulesEnabled, setRulesEnabled] = useState<boolean>(false);
  const selectedAttributeTableRow:AssetState['tables']['selectedAttributeTableRow'] = useSelector(
    (state: StoreState) => state.assetReducer.tables.selectedAttributeTableRow,
  );

  let rows:AssetAttrbtRuleDto[] = [];
  if (selectedAttributeTableRow.assetAttrbtId !== null && typeof (assetAttrbtList) !== 'undefined') {
    const assetAttrbt = assetAttrbtList.find((attribute) => (selectedAttributeTableRow.assetAttrbtId === attribute.assetAttrbtId));
    if (typeof assetAttrbt !== 'undefined') {
      const { assetAttrbtRuleList } = assetAttrbt;
      if (typeof assetAttrbtRuleList !== 'undefined') {
        rows = assetAttrbtRuleList;
      }
    }
  }

  return (
    <Grid
      container
      item
      sx={{
        justifyContent: 'space-between',
      }}
    >
      <Grid
        container
        item
        xs={5}
        sx={{
          pr: 2.5,
          width: tableWidth,
          overflow: 'auto',
          height: defaultHeight,
        }}
      >
        <WppAccordion text="Asset Attributes" expandedByDefault>
          <Grid container sx={{ width: '100%', height: rulesEnabled ? (defaultAccordionHeight / 2) : defaultAccordionHeight }}>
            {/* This is causing useResizeContainer error in console */}
            <AttributeConfigurationTable
              assetData={assetData}
              setRulesEnabled={setRulesEnabled}
            />
          </Grid>
        </WppAccordion>
        {rulesEnabled
          ? (
            <WppAccordion text="Attribute Rules" expandedByDefault>
              <Grid item sx={{ width: rulesEnabled ? '100%' : '0%', height: rulesEnabled ? nonDefaultAccordionHeight : '0%' }}>
                <AttributeRulesTable
                  rows={rows}
                />
              </Grid>
            </WppAccordion>
          )
          : <div />}
      </Grid>
      <Grid container item xs={7} sx={{ width: tableWidth, minheight: defaultHeight, overflow: 'auto' }}>
        <AttributeEditor
          assetData={assetData}
          rulesEnabled={rulesEnabled}
          setRulesEnabled={setRulesEnabled}
          validate={validate}
        />
      </Grid>
    </Grid>
  );
}

export default AttributeConfiguration;
