import { fetchGetConfigList } from '../../services/api/config-service-api';
import {
  ConfigAction, ConfigActionOptions, ConfigListResponse, Data, Flag, ConfigError,
} from '../../services/models/config-models.d';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { setSnackbarActionCreator, snackbarMaker } from './snackbar-actions';

export const setConfigFlagActionCreator: (update: Flag) => ConfigAction = (update: Flag) => ({
  type: ConfigActionOptions.SET_CONFIG_API_FLAG,
  payload: update,
});

export const setConfigDataActionCreator: (update: Data) => ConfigAction = (update: Data) => ({
  type: ConfigActionOptions.SET_CONFIG_API_DATA,
  payload: update,
});

export const handleConfigError:(errorMessage: string, error: ConfigError) => any = (defaultErrorMessage, error) => async (
  dispatch:(action:any) => any,
) => {
  let errorMessage = defaultErrorMessage;
  if (typeof error?.status?.errorList[0] !== 'undefined') {
    const [primaryError] = error.status.errorList;
    errorMessage = primaryError;
  }
  dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
};

// get all config
const fetchGetConfigListActionCreator:(
  showSnackbar?: ShowSnackbar,
) => any = (showSnackbar = ShowSnackbar.True) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchGetConfigListFlag';
  const dataName = 'fetchGetConfigList';
  const successMessage = 'Fetched config data successfully';
  const defaultErrorMessage = 'Issue fetching config data';
  dispatch(setConfigFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetConfigList();
  if (response.ok) {
    const fetchedData:ConfigListResponse = await response.json();
    dispatch(setConfigFlagActionCreator({ [flagName]: false }));
    dispatch(setConfigDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleConfigError(defaultErrorMessage, await response.json()));
  }
};

export default fetchGetConfigListActionCreator;
