import React, {
  useEffect,
  useState,
} from 'react';

// material-ui
import {
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TagTable from './tag-table';
import TagForm from './tag-form';
import MainContent from '../../../layout/MainLayout/MainContent';
import {
  FetchGetMasterDataParams, MasterDataTableNames, MasterDataState, TagDto, MasterDataPostType, OrderDirection,
} from '../../../services/models/master-data-models.d';
import { DEFAULT_MASTER_DATA_PAGINATION, DESELECTED_TAG_TABLE_ROW, EMPTY_TAG } from '../../../services/defaults/master-data-defaults';
import { StatusOption } from '../../../services/models/select-models.d';
import { ShowSnackbar } from '../../../services/models/snackbar-models.d';
import { StoreState } from '../../../store';
import {
  fetchGetMasterDataActionCreator, fetchPostMasterDataActionCreator, setMasterDataDataActionCreator, setMasterDataSelectedTableRowActionCreator,
} from '../../../store/actions/master-data-actions';

function Tag() {
  const dispatch = useDispatch();

  let tagList:MasterDataState['api']['data']['fetchGetTagList']['tagList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetTagList?.tagList,
  );

  const selectedTagTableRow:MasterDataState['tables']['selectedTagTableRow'] = useSelector(
    (state: StoreState) => state.masterDataReducer.tables?.selectedTagTableRow,
  );
  const fetchGetTagListFlag: MasterDataState['api']['flags']['fetchGetTagListFlag'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.flags?.fetchGetTagListFlag,
  );
  const pagination: MasterDataState['api']['data']['fetchGetTagList']['pagination'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetTagList?.pagination,
  );
  const listOrder: MasterDataState['api']['data']['fetchGetTagList']['listOrder'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetTagList?.listOrder,
  );

  let limit = 0; // The page size, or max records displayed
  if (typeof pagination !== 'undefined') {
    ({ limit } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
    } = listOrder);
  }

  if (tagList === null || typeof tagList === 'undefined') {
    tagList = [];
  }

  useEffect(() => {
    const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
    dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.TAG));
  }, [dispatch]);

  const tableHeight = '730px';
  // const tableWidth = '77vw';
  const tableWidth = '101%';
  const [showTagUI, setShowTagUI] = useState<boolean>(false);

  const getSelectedTag = () : TagDto => {
    const tagDto: TagDto = { ...EMPTY_TAG };
    tagDto.tagId = selectedTagTableRow.tagId !== null ? selectedTagTableRow.tagId : -1;
    tagDto.tagName = selectedTagTableRow.tagName !== null ? selectedTagTableRow.tagName : '';
    tagDto.tagDesc = selectedTagTableRow.tagDesc !== null ? selectedTagTableRow.tagDesc : '';
    tagDto.status = selectedTagTableRow.status !== null ? selectedTagTableRow.status : '';
    tagDto.createdUserId = selectedTagTableRow.createdUserId !== null ? selectedTagTableRow.createdUserId : '';
    tagDto.createdDatetime = selectedTagTableRow.createdDatetime !== null ? selectedTagTableRow.createdDatetime : '';
    tagDto.updatedUserId = selectedTagTableRow.updatedUserId !== null ? selectedTagTableRow.updatedUserId : '';
    tagDto.updatedDatetime = selectedTagTableRow.updatedDatetime !== null ? selectedTagTableRow.updatedDatetime : '';
    return tagDto;
  };

  // This currently sets status to inactive
  const handleDelete = async () => {
    const tagDto: TagDto = getSelectedTag();
    if (tagDto.tagId !== -1) {
      tagDto.status = StatusOption.Inactive;
      dispatch(fetchPostMasterDataActionCreator(tagDto, MasterDataPostType.DELETE, MasterDataTableNames.TAG));
      // const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      // dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.TAG));
      setShowTagUI(false);
      const fetchGetTagData = 'fetchGetTagData';
      dispatch(setMasterDataDataActionCreator({ [fetchGetTagData]: { ...EMPTY_TAG } }));
      const selectedTableName = 'selectedTagTableRow';
      dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_TAG_TABLE_ROW } }));
    }
  };

  const handleSearchTag = (newSearchValue: string) => {
    if (!fetchGetTagListFlag) {
      const fetchGetMasterDataParams:FetchGetMasterDataParams = { ...DEFAULT_MASTER_DATA_PAGINATION };
      fetchGetMasterDataParams.page = 0;
      fetchGetMasterDataParams.limit = limit;
      fetchGetMasterDataParams.sortByFieldName = sortByFieldName;
      fetchGetMasterDataParams.sortOrder = sortOrder;
      fetchGetMasterDataParams.searchKey = 'tagName';
      fetchGetMasterDataParams.searchValue = newSearchValue;
      dispatch(fetchGetMasterDataActionCreator(fetchGetMasterDataParams, MasterDataTableNames.TAG, ShowSnackbar.True));
    }
  };

  return (
    <MainContent
      title="Tag"
      buttonTitles={{
        addTitle: 'Add Tag',
        editTitle: 'Edit Tag',
        deleteTitle: 'Delete Tag',
        summaryTitle: 'Tag Summary',
        importTitle: 'Import Tag',
        copyTitle: 'Copy Tag',
      }}
      addCallback={() => {
        const fetchGetTagData = 'fetchGetTagData';
        const tagDto: TagDto = { ...EMPTY_TAG };
        dispatch(setMasterDataDataActionCreator({ [fetchGetTagData]: tagDto }));
        setShowTagUI(!showTagUI);
      }}
      editCallback={() => {
        setShowTagUI(!showTagUI);
      }}
      deleteCallback={() => handleDelete()}
      summaryCallback={() => null} // TODO
      importCallback={() => null} // TODO
      copyCallback={() => null} // TODO
      searchCallback={(newSearchValue) => handleSearchTag(newSearchValue)}
      searchPlaceholder="Search Tags"
      editDisabled={selectedTagTableRow?.tagId === null}
      deleteDisabled={selectedTagTableRow?.tagId === null}
      copyDisabled
      summaryDisabled
      importDisabled
    >
      <Grid
        container
        item
        sx={{
          width: tableWidth, height: tableHeight, justifyContent: 'space-between',
        }}
      >
        <Grid item xs={showTagUI ? 8 : 12}>
          <TagTable rows={tagList} />
        </Grid>
        {showTagUI
          ? (
            <Grid container item xs={4} sx={{ pl: 2.5 }}>
              <TagForm />
            </Grid>
          )
          : <Grid item />}
      </Grid>
    </MainContent>
  );
}

export default Tag;
