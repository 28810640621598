import { Grid } from '@mui/material';
import {
  WppButton, WppIconDone, WppIconTrash,
} from '@platform-ui-kit/components-library-react';
import React from 'react';
import Tooltip from './tooltip';

export interface FormButtonsProps {
  onSave?: () => void;
  onClear?: () => void;
  disableSave?: boolean,
  disableClear?: boolean,
}

function FormButtons(props:FormButtonsProps) {
  const {
    onSave, onClear, disableSave, disableClear,
  } = props;
  return (
    <Grid container item xs={12}>
      <Grid item sx={{ ml: 1, mr: 1 }}>
        <Tooltip disableHoverListener={typeof disableSave !== 'undefined' && disableSave} title="Save">
          <WppButton id="save-button" size="s" variant="secondary" onClick={onSave} disabled={disableSave}>
            <WppIconDone slot="icon-start" />
          </WppButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip disableHoverListener={typeof disableClear !== 'undefined' && disableClear} title="Clear">
          <WppButton id="clear-button" size="s" variant="secondary" onClick={onClear} disabled={disableClear}>
            <WppIconTrash slot="icon-start" />
          </WppButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

FormButtons.defaultProps = {
  onSave: undefined,
  onClear: undefined,
  disableSave: false,
  disableClear: false,
};

export default FormButtons;
