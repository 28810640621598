// GET
import { fetchGetAssetExplorer } from '../../services/api/asset-service-api';
import {
  Data,
  ExplorerAction,
  ExplorerActionOptions,
  FetchGetAssetExplorerParams,
  Flag,
  ExplorerGetResponse,
  ExplorerError,
} from '../../services/models/explorer-models.d';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { setSnackbarActionCreator, snackbarMaker } from './snackbar-actions';

export const setExplorerFlagActionCreator: (update: Flag) => ExplorerAction = (update: Flag) => ({
  type: ExplorerActionOptions.SET_ASSET_API_FLAG,
  payload: update,
});

export const setExplorerDataActionCreator: (update: Data) => ExplorerAction = (update: Data) => ({
  type: ExplorerActionOptions.SET_ASSET_API_DATA,
  payload: update,
});

export const handleExplorerError:(errorMessage: string, error: ExplorerError) => any = (defaultErrorMessage, error) => async (
  dispatch:(action:any) => any,
) => {
  let errorMessage = defaultErrorMessage;
  if (typeof error?.status?.errorList[0] !== 'undefined') {
    const [primaryError] = error.status.errorList;
    errorMessage = primaryError;
  }
  dispatch(setSnackbarActionCreator(snackbarMaker(true, errorMessage, 'error')));
};

// get all assets
export const fetchGetExplorerActionCreator: (
  fetchParams: FetchGetAssetExplorerParams,
  showSnackbar: ShowSnackbar
) => any = (fetchParams, showSnackbar) => async (
  dispatch:(action:any) => any,
  // getState:() => any,
) => {
  const flagName = 'fetchGetAssetExplorerFlag';
  const dataName = 'fetchGetAssetExplorer';
  const successMessage = 'Fetched explorer data successfully';
  const defaultErrorMessage = 'Issue fetching explorer data';
  dispatch(setExplorerFlagActionCreator({ [flagName]: true }));
  const response = await fetchGetAssetExplorer(fetchParams);
  if (response.ok) {
    const fetchedData:ExplorerGetResponse = await response.json();
    dispatch(setExplorerFlagActionCreator({ [flagName]: false }));
    dispatch(setExplorerDataActionCreator({ [dataName]: fetchedData }));
    if (showSnackbar) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
    }
  } else {
    dispatch(handleExplorerError(defaultErrorMessage, await response.json()));
  }
};
