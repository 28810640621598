import React, { useEffect, useState } from 'react';
// import 'react-js-cron/dist/styles.css';
// material-ui
import {
  Grid,
} from '@mui/material';
import { WppAccordion } from '@platform-ui-kit/components-library-react';
import cronstrue from 'cronstrue';
import { useDispatch, useSelector } from 'react-redux';
import { CronError } from 'react-js-cron';
import { AssetMultiStepGetResponse, ScheduleDetails } from '../../../../services/models/asset-models.d';
import { IAttributeFormErrors, IAttributeRuleFormErrors } from '../../../../services/models/validation-models.d';
import SchedulerForm from './scheduler-form';
import NotificationTable from './notification-table';
import ScheduleTable from './schedule-table';
import { EMPTY_SCHEDULE_DETAILS } from '../../../../services/defaults/asset-defaults';
import { StoreState } from '../../../../store';
import { FetchGetNotificationParams, NotificationState } from '../../../../services/models/notification-models.d';
import { BooleanType } from '../../../../services/models/select-models.d';
import NotificationForm from './notification-form';
import { fetchGetNotificationByAssetNameActionCreator } from '../../../../store/actions/notification-action';
import { setSnackbarActionCreator, snackbarMaker } from '../../../../store/actions/snackbar-actions';

export interface ScheduleStepProps {
  assetData:AssetMultiStepGetResponse,
  handleFieldChange:(newValue:string | Date | boolean, section: string, field: string) => void,
  validate:(accordion: number, formErrors: IAttributeFormErrors[] | IAttributeRuleFormErrors) => void;
  handlePutAsset: () => void;
}

function ScheduleStep(props:ScheduleStepProps) {
  const {
    assetData,
    handleFieldChange,
    // eslint-disable-next-line
    validate,
    handlePutAsset,
  } = props;

  const dispatch = useDispatch();

  const fetchGetNotificationByAssetName:NotificationState['api']['data']['fetchGetNotificationByAssetName']['notificationSubscriptionList'] = useSelector(
    (state: StoreState) => state.notificationReducer.api?.data?.fetchGetNotificationByAssetName?.notificationSubscriptionList,
  );

  useEffect(() => {
    const assetObj = assetData.asset;
    const fetchGetNotificationParams:FetchGetNotificationParams = {
      assetName: assetObj.assetName,
      accountCode: assetObj.accountCode,
    };
    dispatch(fetchGetNotificationByAssetNameActionCreator(fetchGetNotificationParams));
  }, [assetData.asset, dispatch]);

  const notificationRows: any = fetchGetNotificationByAssetName !== null && fetchGetNotificationByAssetName !== undefined
  && fetchGetNotificationByAssetName.length > 0 ? fetchGetNotificationByAssetName : [];
  const scheduleTableHeight = 260;
  const notificationTableHeight = 200;
  const tableWidth = '101%';

  let notificationEnableFlg;
  if (typeof assetData.asset !== 'undefined') {
    ({
      notificationEnableFlg,
    } = assetData.asset);
  }

  let schedule;
  if (typeof assetData.asset !== 'undefined') {
    ({
      schedule,
    } = assetData.fileConfig);
  }
  const defaultSchedule = schedule !== null && schedule !== undefined ? schedule : '0 0 * * *';
  const [scheduleExpression, setScheduleExpression] = useState(defaultSchedule);
  const [scheduleExpressionText, setScheduleExpressionText] = useState('');
  const [status, setStatus] = useState<string>('Active');
  const [error, onError] = useState<CronError>();

  const scheduleRows: ScheduleDetails[] = [];

  const [wantNotification, setWantNotification] = useState<boolean>(notificationEnableFlg === BooleanType.True);

  const scheduleDetails: ScheduleDetails = { ...EMPTY_SCHEDULE_DETAILS };
  if (scheduleExpressionText !== null && scheduleExpressionText !== undefined && scheduleExpressionText !== '') {
    scheduleDetails.scheduleExpression = scheduleExpressionText;
    try {
      scheduleDetails.scheduleDescription = cronstrue.toString(scheduleExpressionText);
    } catch (exceptionVar: any) {
      dispatch(setSnackbarActionCreator(snackbarMaker(true, exceptionVar, 'error')));
      scheduleDetails.scheduleDescription = 'Invalid cron expression';
    }
    scheduleDetails.status = status;
    scheduleRows.push(scheduleDetails);
  }

  return (
    <Grid container item>
      <Grid
        container
        item
        xs={5}
        sx={{
          pr: 2.5,
          width: tableWidth,
          overflow: 'auto',
        }}
      >
        <WppAccordion text="Schedule" expandedByDefault withDivider={false}>
          <Grid
            sx={{ width: tableWidth, height: scheduleTableHeight }}
          >
            <ScheduleTable
              rows={scheduleRows}
            />
          </Grid>
        </WppAccordion>
        {
              wantNotification && (
                <WppAccordion text="Notification Details" expandedByDefault>
                  <Grid
                    sx={{ width: tableWidth, height: notificationTableHeight }}
                  >
                    <NotificationTable
                      rows={notificationRows}
                    />
                  </Grid>
                </WppAccordion>
              )
            }
      </Grid>
      <Grid container item xs={7} sx={{ overflow: 'auto' }}>
        <WppAccordion text="Scheduling" expandedByDefault withDivider={false}>
          <Grid container sx={{ width: '100%', height: '220px' }}>
            <SchedulerForm
              scheduleExpression={scheduleExpression}
              setScheduleExpression={setScheduleExpression}
              scheduleExpressionText={scheduleExpressionText}
              setScheduleExpressionText={setScheduleExpressionText}
              status={status}
              setStatus={setStatus}
              wantNotification={wantNotification}
              setWantNotification={setWantNotification}
              handleFieldChange={handleFieldChange}
              handlePutAsset={handlePutAsset}
              error={error}
              onError={onError}
            />
          </Grid>
        </WppAccordion>
        {
            wantNotification && (
            <WppAccordion text="Notification" expandedByDefault>
              <NotificationForm
                assetData={assetData}
              />
            </WppAccordion>
            )
          }
      </Grid>

    </Grid>
  );
}

export default ScheduleStep;
