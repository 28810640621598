import { host, ontarioService, notificationRoute } from '../defaults/api-defaults';
import { FetchDeleteNotificationParams, FetchGetNotificationParams, NotificationInbound } from '../models/notification-models';
import { callServiceAsPromise } from './api';

// 100.64.25.168/notification-service
// const host = 'https://ont-dev-dtc-platform-access-npd-dev.choreograph.com';
// const ontarioService = '/notification-service/notification';
// const notificationRoute = '';
// const host = 'http://localhost:8086';
// const ontarioService = '/asset-service';
// const service = 'http://localhost:8080/asset-service';
// const service = 'http://localhost:8081/ontario-service/ontario/asset/';

// GET
export const fetchGetNotification:((params: FetchGetNotificationParams) => Promise<Response>) = (params) => {
  const {
    assetName,
    accountCode,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${notificationRoute}/all?assetName=${assetName}&accountCode=${accountCode}`);
};

// POST
export const fetchPostNotification:(
  (params: NotificationInbound) => Promise<Response>
) = (params) => callServiceAsPromise(`${host}${ontarioService}${notificationRoute}/save`, 'POST', JSON.stringify(params));

// DELETE
export const fetchDeleteNotificationById:((params: FetchDeleteNotificationParams) => Promise<Response>) = (params) => {
  const {
    transmissionId,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${notificationRoute}/${transmissionId}`, 'DELETE');
};
