import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  WppNavSidebarItem,
  WppIconGear,
  WppIconHome,
  WppIconStatisticDocument,
  // WppIconOrganization,
  WppIconStyleguide,
  WppIconBookSearch,
  WppNavSidebar,
  WppTypography,
  WppIconOrganization,
} from '@platform-ui-kit/components-library-react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styles from '../../../index.module.scss';
import { setAssetDataActionCreator, setAssetSelectedTableRowActionCreator } from '../../../store/actions/asset-actions';
import {
  DESELECTED_ASSET_TABLE_ROW,
  DESELECTED_ATTRIBUTE_TABLE_ROW,
  DESELECTED_ATTRIBUTE_RULES_TABLE_ROW,
  DEFAULT_ASSET,
  DEFAULT_ASSET_CONTRACT,
  DEFAULT_ASSET_VALUATION,
  DEFAULT_FILE_CONFIG,
} from '../../../services/defaults/asset-defaults';
import { NavigationPages, NavigationPageUrls } from '../../../services/models/navigation-models.d';
import useWindowDimensions from '../../../hooks/use-window-dimensions';

function Sidebar() {
  // const drawerWidth = 240;
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState<number>(NavigationPages.OVERVIEW);
  const { height } = useWindowDimensions();
  return (
    <WppNavSidebar>
      <Grid container sx={{ flexDirection: 'column', justifyContent: 'space-between', height: height - 70 }}>
        <Grid item>
          <img
            src="../../images/Choreograph-Black-RGB.png"
            width={150}
            className={styles.headerLogo}
            alt="Choreograph logo"
          />
          <WppNavSidebarItem
            active={activeItem === NavigationPages.OVERVIEW}
            label="Overview"
            onClick={() => {
              history.push(NavigationPageUrls.OVERVIEW);
              setActiveItem(NavigationPages.OVERVIEW);
            }}
          >
            <WppIconHome slot="icon-start" />
          </WppNavSidebarItem>
          <WppNavSidebarItem
            active={activeItem === NavigationPages.ASSETS}
            expanded={activeItem === NavigationPages.ASSETS || activeItem === NavigationPages.ASSET_LIST || activeItem === NavigationPages.ASSET_DETAILS}
            label="Assets"
            extended
          >
            <WppIconStatisticDocument slot="icon-start" />
            <WppNavSidebarItem
              active={activeItem === NavigationPages.ASSET_LIST}
              label="Asset List"
              onClick={() => {
                let tableName = 'selectedAssetTableRow';
                dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: DESELECTED_ASSET_TABLE_ROW }));
                tableName = 'selectedAttributeTableRow';
                dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: DESELECTED_ATTRIBUTE_TABLE_ROW }));
                tableName = 'selectedRuleTableRow';
                dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: DESELECTED_ATTRIBUTE_RULES_TABLE_ROW }));
                history.push(NavigationPageUrls.ASSET_LIST);
                setActiveItem(NavigationPages.ASSET_LIST);
              }}
            />
            <WppNavSidebarItem
              active={activeItem === NavigationPages.ASSET_DETAILS}
              label="Asset Configuration"
              onClick={() => {
                let tableName = 'selectedAssetTableRow';
                dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: DESELECTED_ASSET_TABLE_ROW }));
                tableName = 'selectedAttributeTableRow';
                dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: DESELECTED_ATTRIBUTE_TABLE_ROW }));
                tableName = 'selectedRuleTableRow';
                dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: DESELECTED_ATTRIBUTE_RULES_TABLE_ROW }));
                dispatch(setAssetDataActionCreator({
                  fetchGetAssetById: {
                    asset: { ...DEFAULT_ASSET },
                    contract: { ...DEFAULT_ASSET_CONTRACT },
                    valuation: { ...DEFAULT_ASSET_VALUATION },
                    fileConfig: { ...DEFAULT_FILE_CONFIG },
                  },
                }));

                history.push(NavigationPageUrls.ASSET_DETAILS);
                setActiveItem(NavigationPages.ASSET_DETAILS);
              }}
            />
          </WppNavSidebarItem>
          <WppNavSidebarItem
            active={activeItem === NavigationPages.USERS}
            label="Users"
            onClick={() => {
              history.push(NavigationPageUrls.USERS);
              setActiveItem(NavigationPages.USERS);
            }}
          >
            <WppIconOrganization slot="icon-start" />
          </WppNavSidebarItem>
          <WppNavSidebarItem
            active={activeItem === NavigationPages.MASTER_DATA}
            label="Master Data"
            expanded={activeItem === NavigationPages.MASTER_DATA || activeItem === NavigationPages.COLLECTIONS || activeItem === NavigationPages.DATASETS || activeItem === NavigationPages.RULES || activeItem === NavigationPages.TAGS || activeItem === NavigationPages.CATEGORIES}
            extended
          >
            <WppIconGear slot="icon-start" />
            <WppNavSidebarItem
              active={activeItem === NavigationPages.COLLECTIONS}
              label="Collection"
              onClick={() => {
                history.push(NavigationPageUrls.COLLECTIONS);
                setActiveItem(NavigationPages.COLLECTIONS);
              }}
            />
            <WppNavSidebarItem
              active={activeItem === NavigationPages.DATASETS}
              label="Dataset"
              onClick={() => {
                history.push(NavigationPageUrls.DATASETS);
                setActiveItem(NavigationPages.DATASETS);
              }}
            />
            <WppNavSidebarItem
              active={activeItem === NavigationPages.RULES}
              label="Rule"
              onClick={() => {
                history.push(NavigationPageUrls.RULES);
                setActiveItem(NavigationPages.RULES);
              }}
            />
            <WppNavSidebarItem
              active={activeItem === NavigationPages.TAGS}
              label="Tag"
              onClick={() => {
                history.push(NavigationPageUrls.TAGS);
                setActiveItem(NavigationPages.TAGS);
              }}
            />
            <WppNavSidebarItem
              active={activeItem === NavigationPages.CATEGORIES}
              label="Category"
              onClick={() => {
                history.push(NavigationPageUrls.CATEGORIES);
                setActiveItem(NavigationPages.CATEGORIES);
              }}
            />
          </WppNavSidebarItem>
          <WppNavSidebarItem
            active={activeItem === NavigationPages.DATA_CATALOG}
            label="Data Catalog"
            onClick={() => {
              history.push(NavigationPageUrls.DATA_CATALOG);
              setActiveItem(NavigationPages.DATA_CATALOG);
            }}
          >
            <WppIconStyleguide slot="icon-start" />
          </WppNavSidebarItem>
          <WppNavSidebarItem
            active={activeItem === NavigationPages.ASSET_EXPLORER}
            label="Asset Explorer"
            onClick={() => {
              history.push(NavigationPageUrls.ASSET_EXPLORER);
              setActiveItem(NavigationPages.ASSET_EXPLORER);
            }}
          >
            <WppIconBookSearch slot="icon-start" />
          </WppNavSidebarItem>
        </Grid>
        <Grid container item sx={{ mb: -3, justifyContent: 'center' }}>
          <WppTypography>Choreograph © 2023</WppTypography>
        </Grid>
      </Grid>
    </WppNavSidebar>
  );
}

export default Sidebar;
