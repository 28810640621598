import SEVERITY from '../../services/defaults/snackbar-defaults';
import {
  SnackbarState,
  SnackbarAction,
  SnackbarActionOptions,
} from '../../services/models/snackbar-models.d';

const defaultState:SnackbarState = {
  open: false,
  message: '',
  severity: SEVERITY,
};

function snackbarReducer(
  state:SnackbarState = defaultState,
  action:SnackbarAction,
): SnackbarState {
  switch (action.type) {
    case SnackbarActionOptions.SET_SNACKBAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default snackbarReducer;
