import React, { useEffect } from 'react';
import {
  WppInlineMessage, WppIconCross,
} from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { StoreState } from '../store';
import { SnackbarState } from '../services/models/snackbar-models.d';
import { setSnackbarActionCreator, snackbarMaker } from '../store/actions/snackbar-actions';

export default function Snackbar() {
  const dispatch = useDispatch();
  const snackbarWidth = '90%';
  const open:SnackbarState['open'] = useSelector((state: StoreState) => state.snackbarReducer.open);
  const message:SnackbarState['message'] = useSelector((state: StoreState) => state.snackbarReducer.message);
  const severity:SnackbarState['severity'] = useSelector((state: StoreState) => state.snackbarReducer.severity);
  const timeoutDefault = 8000;

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setSnackbarActionCreator(snackbarMaker(false, '', 'error')));
    }, timeoutDefault);
    return () => clearTimeout(timer);
  }, [dispatch, open]);

  return (
    open
      ? (
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <Grid item sx={{ maxWidth: snackbarWidth }}>
            <WppInlineMessage
              size="m"
              message={message}
              type={severity}
              showTooltipFrom={300}
            />
          </Grid>
          <Box sx={{ mt: 0.75 }}>
            <WppIconCross
              onClick={() => dispatch(setSnackbarActionCreator(snackbarMaker(false, '', 'error')))}
            />
          </Box>
        </Grid>
      )
      : null
  );
}
