export enum NavigationPageUrls {
  OVERVIEW = '/',
  ASSETS = '/asset-management',
  ASSET_LIST = '/asset-management/list-assets',
  ASSET_DETAILS = '/asset-management/asset-details',
  ASSET_SUMMARY = '/asset-management/asset-summary',
  USERS = '/user-management/users',
  MASTER_DATA = '/master-config',
  COLLECTIONS = '/master-config/collections',
  DATASETS = '/master-config/datasets',
  RULES = '/master-config/rules',
  TAGS = '/master-config/tags',
  CATEGORIES = '/master-config/categories',
  DATA_CATALOG = '/data-catalog',
  ASSET_EXPLORER = '/asset-explorer',
}

export enum NavigationPages {
  OVERVIEW,
  ASSETS,
  ASSET_LIST,
  ASSET_DETAILS,
  ASSET_SUMMARY,
  USERS,
  MASTER_DATA,
  COLLECTIONS,
  DATASETS,
  RULES,
  TAGS,
  CATEGORIES,
  DATA_CATALOG,
  ASSET_EXPLORER,
}
