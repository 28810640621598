import React, {
  ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainContent from '../../../layout/MainLayout/MainContent';
import { AssetState } from '../../../services/models/asset-models.d';
import { NavigationPageUrls } from '../../../services/models/navigation-models.d';
import { StoreState } from '../../../store';

export interface AssetCreationLayoutProps {
  title?: string,
  children: ReactNode,
}

function AssetCreationLayout(props:AssetCreationLayoutProps) {
  const {
    title,
    children,
  } = props;
  const history = useHistory();

  const assetId:AssetState['tables']['selectedAssetTableRow']['assetId'] = useSelector(
    (state: StoreState) => state.assetReducer.tables?.selectedAssetTableRow?.assetId,
  );

  const summaryDisabled = typeof assetId === 'undefined' || assetId === null;

  return (
    <MainContent
      title={title || 'Assets'}
      hideSearch
      buttonTitles={{
        addTitle: 'Add Asset',
        editTitle: 'Edit Asset',
        deleteTitle: 'Delete Asset',
        summaryTitle: 'Asset Summary',
        importTitle: 'Import Asset',
        copyTitle: 'Copy Asset',
      }}
      editCallback={() => history.push(NavigationPageUrls.ASSET_DETAILS)}
      summaryCallback={() => history.push(NavigationPageUrls.ASSET_SUMMARY)}
      summaryDisabled={summaryDisabled}
      addDisabled
      editDisabled
      deleteDisabled
      copyDisabled
      importDisabled
    >
      {children}
    </MainContent>
  );
}

AssetCreationLayout.defaultProps = {
  title: 'Assets',
};

export default AssetCreationLayout;
