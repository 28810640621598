import React from 'react';
import { WppTypography, WppInlineMessage } from '@platform-ui-kit/components-library-react';
import {
  GridCellParams, GridRenderCellParams, GridSortModel, GridValueGetterParams,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import Cell from '../../ui-component/cell';
import Table from '../../ui-component/table';
import {
  AssetDto, AssetState, AssetTableRow, FetchGetAssetsParams, OrderDirection,
} from '../../services/models/asset-models.d';
import { fetchGetAssetsActionCreator, setAssetSelectedTableRowActionCreator, setAssetSortModelActionCreator } from '../../store/actions/asset-actions';
import { StoreState } from '../../store';
import { DEFAULT_ASSET_PAGINATION } from '../../services/defaults/asset-defaults';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';

const ASSET_NA_REGION = '** Assets are related to North America Region only.';

export interface ListAllAssetsTableProps {
  rows:AssetDto[]
}

const ListAllAssetsTable = function RenderListAllAssetsTable(props: ListAllAssetsTableProps) {
  const { rows } = props;
  const dispatch = useDispatch();

  const fetchGetAssetListFlag: AssetState['api']['flags']['fetchGetAssetListFlag'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.flags?.fetchGetAssetListFlag,
  );
  const pagination: AssetState['api']['data']['fetchGetAssetList']['pagination'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetList?.pagination,
  );
  const listOrder: AssetState['api']['data']['fetchGetAssetList']['listOrder'] = useSelector(
    (state: StoreState) => state.assetReducer.api?.data?.fetchGetAssetList?.listOrder,
  );

  // let records = 0; // records returned on page, can be less than limit
  let page = 0; // current page index
  let limit = 0; // The page size, or max records displayed
  let totalRecords = 0; // Total number of records
  // let totalPages; // Total pages based on limit
  if (typeof pagination !== 'undefined') {
    ({
      // records,
      page,
      limit,
      totalRecords,
      // totalPages,
    } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  let searchKey = '';
  let searchValue = '';
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
      searchKey,
      searchValue,
    } = listOrder);
  }

  const columnFormat = [
    {
      field: 'accountId', description: '', headerText: 'Account ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'datasetId', description: '', headerText: 'Dataset ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'categoryId', description: '', headerText: 'Category ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'tagId', description: '', headerText: 'Tag ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'sourceId', description: '', headerText: 'Source ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'targetId', description: '', headerText: 'Target ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'valuationId', description: '', headerText: 'Valuation ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'contractId', description: '', headerText: 'Contract ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'versionId', description: '', headerText: 'Version ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'assetType', description: '', headerText: 'Type', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'sourceName', description: '', headerText: 'Source Name', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'targetName', description: '', headerText: 'Target Name', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'derivedAssetFlg', description: '', headerText: 'Derived Asset Flag', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'accountName', description: '', headerText: 'Account Name', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'assetLongDesc', description: '', headerText: 'Long Description', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'fileConfigId', description: '', headerText: 'File Config ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'collectionId', description: '', headerText: 'Collection ID', minWidth: 150, valueGetter: undefined, sortable: false, // Hidden
    },
    {
      field: 'assetId', description: '', headerText: 'Asset ID', minWidth: 50, valueGetter: undefined, sortable: true,
    },
    {
      field: 'assetName', description: '', headerText: 'Asset Name', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'assetDesc', description: '', headerText: 'Description', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'collectionName', description: '', headerText: 'Collection Name', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'datasetName', description: '', headerText: 'Dataset Name', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'categoryName', description: '', headerText: 'Category Name', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'tagName', description: '', headerText: 'Tag Name', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'allowSchemaDiscoveryFlg', description: '', headerText: 'Allow Schema Discovery Flag', minWidth: 220, valueGetter: undefined, sortable: false,
    },
    {
      field: 'notificationEnableFlg', description: '', headerText: 'Notification Enable Flag', minWidth: 175, valueGetter: undefined, sortable: false,
    },
    {
      field: 'dataCatalogPushFlg', description: '', headerText: 'Data Catalog Push Flag', minWidth: 175, valueGetter: undefined, sortable: false,
    },
    {
      field: 'assetOwner', description: '', headerText: 'Owner', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'valuationName', description: '', headerText: 'Valuation Name', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'loadType', description: '', headerText: 'Load Type', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'contractName', description: '', headerText: 'Contract Name', minWidth: 150, valueGetter: undefined, sortable: false,
    },
    {
      field: 'createdUserId', description: '', headerText: 'Created User ID', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', description: '', headerText: 'Updated User ID', minWidth: 150, valueGetter: undefined, sortable: true,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const columnVisibilityModel = {
    accountId: false,
    datasetId: false,
    collectionId: false,
    categoryId: false,
    tagId: false,
    sourceId: false,
    targetId: false,
    valuationId: false,
    contractId: false,
    versionId: false,
    assetCite: false,
    assetLicensing: false,
    assetTopic: false,
    assetType: false,
    assetOrigin: false,
    sourceName: false,
    targetName: false,
    derivedAssetFlg: false,
    accountName: false,
    assetLongDesc: false,
    fileConfigId: false,
  };

  // const filterModel = {
  //   items: [{ columnField: 'status', operatorValue: 'contains', value: 'a' }],
  // };

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: column.sortable,
      filterable: false,
    };
    return columnFormatted;
  }));

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const tableName = 'selectedAssetTableRow';
    const selectedAssetTableRow:AssetTableRow = Object.assign({}, ...rowDetails);
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: selectedAssetTableRow }));
  };

  const onPageChange = (updatedPage: number) => {
    if (!fetchGetAssetListFlag) {
      const fetchGetAssetsParams: FetchGetAssetsParams = { ...DEFAULT_ASSET_PAGINATION };
      fetchGetAssetsParams.page = updatedPage;
      fetchGetAssetsParams.limit = limit;
      fetchGetAssetsParams.sortByFieldName = sortByFieldName;
      fetchGetAssetsParams.sortOrder = sortOrder;
      fetchGetAssetsParams.searchKey = searchKey;
      fetchGetAssetsParams.searchValue = searchValue;
      dispatch(fetchGetAssetsActionCreator(fetchGetAssetsParams, ShowSnackbar.True));
    }
  };

  const onPageSizeChange = (updatedPageSize: number) => {
    if (!fetchGetAssetListFlag) {
      const fetchGetAssetsParams: FetchGetAssetsParams = { ...DEFAULT_ASSET_PAGINATION };
      fetchGetAssetsParams.page = 0;
      fetchGetAssetsParams.limit = updatedPageSize;
      fetchGetAssetsParams.sortByFieldName = sortByFieldName;
      fetchGetAssetsParams.sortOrder = sortOrder;
      fetchGetAssetsParams.searchKey = searchKey;
      fetchGetAssetsParams.searchValue = searchValue;
      dispatch(fetchGetAssetsActionCreator(fetchGetAssetsParams, ShowSnackbar.True));
    }
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!fetchGetAssetListFlag) {
      const fetchGetAssetsParams: FetchGetAssetsParams = { ...DEFAULT_ASSET_PAGINATION };
      fetchGetAssetsParams.page = page;
      fetchGetAssetsParams.limit = limit;
      if (typeof model[0].field !== 'undefined') {
        fetchGetAssetsParams.sortByFieldName = model[0].field;
      }
      if (typeof model[0].sort !== 'undefined') {
        fetchGetAssetsParams.sortOrder = model[0].sort;
      }
      fetchGetAssetsParams.searchKey = searchKey;
      fetchGetAssetsParams.searchValue = searchValue;
      dispatch(fetchGetAssetsActionCreator(fetchGetAssetsParams, ShowSnackbar.True));
      dispatch(setAssetSortModelActionCreator(model));
    }
  };

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <>
        <WppInlineMessage
          size="s"
          message={ASSET_NA_REGION}
          type="information"
          style={{
            position: 'relative',
            top: '-8px',
            color: '#8b919a',
          }}
        />
        <Table
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          customLoadingOverlay={customLoadingOverlay}
          customToolbar={customToolbar}
          filterModel={filterModel}
          hideFooterPagination={false}
          loading={false}
          onCellClick={onCellClick}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pageSize={limit || 0}
          rowCount={totalRecords || 0}
          rows={rows}
          onSortModelChange={onSortModelChange}
          headerHeight={headerHeight}
        />
      </>
    )
  );
};

export default ListAllAssetsTable;
