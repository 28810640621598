import { host, ontarioService, assetRoute } from '../defaults/api-defaults';
import {
  AssetMultiStepPutRequest,
  AssetMultiStepPostRequest,
  FetchDeleteAssetByIdParams,
  FetchGetAssetByIdParams,
  FetchGetAssetsParams,
  FetchGetCopyAssetParams,
  FetchGetAssetAttrbtsByIdParams,
} from '../models/asset-models.d';
import { FetchGetAssetExplorerParams } from '../models/explorer-models.d';
import { callServiceAsPromise } from './api';

// const host = 'https://ont-dev-dtc-platform-access-npd-dev.choreograph.com';
// const ontarioService = '/ontario-service';
// const assetRoute = '/assets';
// const host = 'http://localhost:8080';
// const ontarioService = '/asset-service';
// const service = 'http://localhost:8080/asset-service';
// const service = 'http://localhost:8081/ontario-service/ontario/asset/';

// PUT
export const fetchPutAsset:((params: AssetMultiStepPutRequest) => Promise<Response>) = (
  params,
) => callServiceAsPromise(`${host}${ontarioService}${assetRoute}/asset`, 'PUT', JSON.stringify(params));

// POST
export const fetchPostAsset:((params: AssetMultiStepPostRequest) => Promise<Response>) = (
  params,
) => callServiceAsPromise(`${host}${ontarioService}${assetRoute}/asset`, 'POST', JSON.stringify(params));

// DELETE
export const fetchDeleteAssetById:((params: FetchDeleteAssetByIdParams) => Promise<Response>) = (params) => {
  const { id } = params;
  return callServiceAsPromise(`${host}${ontarioService}${assetRoute}/asset/${id}`, 'DELETE');
};

// GET
export const fetchGetAssets:((params: FetchGetAssetsParams) => Promise<Response>) = (params) => {
  const {
    page,
    limit,
    sortByFieldName,
    sortOrder,
    searchKey,
    searchValue,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${assetRoute}/asset/all?page=${page}&limit=${limit}&sortByFieldName=${sortByFieldName}&sortOrder=${sortOrder}&searchKey=${searchKey}&searchValue=${searchValue}`);
};

// Copy Asset
export const fetchGetCopyAsset:((params: FetchGetCopyAssetParams) => Promise<Response>) = (
  params,
) => callServiceAsPromise(`${host}${ontarioService}${assetRoute}/asset/copy`, 'POST', JSON.stringify(params));

export const fetchGetAssetById:((params: FetchGetAssetByIdParams) => Promise<Response>) = (params) => {
  const { id, requestedEntities } = params;
  let entitiesString = requestedEntities.join('&requestedEntities=');
  if (entitiesString !== '') {
    entitiesString = `?requestEntities=, ${entitiesString}`;
  }
  return callServiceAsPromise(`${host}${ontarioService}${assetRoute}/asset/${id}${entitiesString}`);
};

export const fetchGetAssetExplorer:((params: FetchGetAssetExplorerParams) => Promise<Response>) = (params) => {
  const { collectionId, datasetId } = params;
  let str = '';
  if (collectionId) {
    str += `?collectionId=${collectionId}`;
  }
  if (datasetId) {
    str += (collectionId) ? '&' : '?';
    str += `&datasetId=${datasetId}`;
  }

  return callServiceAsPromise(`${host}${ontarioService}${assetRoute}/asset/explorer${str}`);
};

// Not yet implemented
export const fetchGetAssetAttrbtsById:((params: FetchGetAssetAttrbtsByIdParams) => Promise<Response>) = (params) => {
  const {
    assetId,
    page,
    limit,
  } = params;
  return callServiceAsPromise(`${host}${ontarioService}${assetRoute}/asset/attrbt?assetId=${assetId}&page=${page}&limit=${limit}`);
};
