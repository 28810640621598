// import './styles.less';
import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import type { EChartsOption } from 'echarts';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import themeJson from '@platform-ui-kit/components-library/dist/platform-ui-kit/wpp-theme.json';
// import { Theme, ThemeCatDataviz } from '@platform-ui-kit/components-library';
import { CatalogState, FetchGetCatalogDetails } from '../../services/models/data-catalog-models';
import { StoreState } from '../../store';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { fetchGetCatalogMetricsActionCreator } from '../../store/actions/data-catalog-actions';

function DataCatalogPieChart() {
  // * WPP documentation provides theming for echarts -- uncomment it to use it *
  // const themeType = 'light'; // (searchParams.get('themeType') as 'dark' | 'light') || 'dark';
  // const colors = Object.values(((themeJson as Theme).content.light?.color.dataviz.cat as ThemeCatDataviz)[themeType]);
  const chartRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const catalogMetrics: CatalogState['api']['data']['fetchGetCatalogMetrics'] = useSelector(
    (state: StoreState) => state.catalogReducer.api?.data?.fetchGetCatalogMetrics,
  );

  // const catalogMetricsFlag: CatalogState['api']['flags']['fetchGetCatalogMetricsFlag'] = useSelector(
  //   (state: StoreState) => state.catalogReducer.api?.flags?.fetchGetCatalogMetricsFlag,
  // );

  let collectionCount = 0;
  let datasetCount = 0;
  let assetCount = 0;
  // let columnCount;
  // let organizationCode;
  // let collectionData: DataHolder[] | undefined;
  // let status;

  if (typeof catalogMetrics !== 'undefined'
    && catalogMetrics !== null) {
    ({
      collectionCount,
      datasetCount,
      assetCount,
    } = catalogMetrics);
  }

  useEffect(() => {
    async function initChart(dom: HTMLDivElement) {
      const fetchParams: FetchGetCatalogDetails = {
        accountCode: '',
        organization: 'c9h-na',
        collection: '',
        datasetName: '',
        assetName: '',
      };
      const chart = echarts.init(dom);
      // const chart = echarts.init(dom, { color: colors });
      await dispatch(fetchGetCatalogMetricsActionCreator(fetchParams, ShowSnackbar.False));
      const option: EChartsOption = {
        series: [
          {
            type: 'pie',
            data: [
              {
                value: collectionCount === null ? 0 : collectionCount,
                name: `Collections (${collectionCount === null ? 0 : collectionCount})`,
              },
              {
                value: datasetCount === null ? 0 : datasetCount,
                name: `Datasets (${datasetCount === null ? 0 : datasetCount})`,
              },
              {
                value: assetCount === null ? 0 : assetCount,
                name: `Assets (${assetCount === null ? 0 : assetCount})`,
              },
            ],
            radius: '70%',
            stillShowZeroSum: true,
          },
        ],
      };
      chart.setOption(option);
    }
    if (chartRef.current) {
      initChart(chartRef.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetCount]);

  return (
    <div>
      <Box sx={{ width: 500, height: 400 }} ref={chartRef} />
    </div>
  );
}

export default DataCatalogPieChart;
