import { GridSortModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {
  LineageDto,
  AssetAttrbtDto,
  AssetTableRow,
  AttributeTableRow,
  RulesTableRow,
  AssetDto,
  AssetAttrbtRuleDto,
  ValuationDto,
  OrderDirection,
  ContractDto,
  TargetDto,
  SourceDto,
  AssetVersionDto,
  AssetScriptDto,
  FileConfigDto,
  ScheduleDetails,
} from '../models/asset-models.d';
import { RuleCategory, RuleType } from '../models/config-models.d';
import { BooleanType } from '../models/select-models.d';

// pagination defaults

// We will use this to create pagination by reference to this object until seaerch and sort are added in response object
// Once that is implemented will we store pagination in Redux from backend and set defaults with { ...DEFAULT_ASSET_PAGINATION }
export const DEFAULT_ASSET_PAGINATION = {
  page: 0,
  limit: 10,
  sortByFieldName: 'updatedDatetime',
  sortOrder: OrderDirection.DESC,
  searchKey: '',
  searchValue: '',
};

export const DEFAULT_ASSET_GRID_SORT_ARRAY:GridSortModel = [
  {
    field: 'updatedDatetime',
    sort: 'desc',
  },
];

// table defaults

export const DESELECTED_ASSET_TABLE_ROW:AssetTableRow = {
  assetId: null,
  accountName: null,
  accountCode: null,
  datasetName: null,
  assetName: null,
  assetDesc: null,
  assetLongDesc: null,
  assetType: null,
  assetOwner: null,
  collectionName: null,
  categoryName: null,
  tagName: null,
  sourceName: null,
  targetName: null,
  fileConfigId: null,
  valuationName: null,
  contractName: null,
  loadType: null,
  derivedAssetFlg: null,
  dataCatalogPushFlg: null,
  allowSchemaDiscoveryFlg: null,
  notificationEnableFlg: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};
export const DESELECTED_ATTRIBUTE_TABLE_ROW:AttributeTableRow = {
  assetAttrbtId: null,
  assetId: null,
  assetAttrbtName: null,
  assetAttrbtDesc: null,
  assetAttrbtKeyTypeCode: null,
  dataType: null,
  categoryId: null,
  tagId: null,
  lineageId: null,
  assetAttrbtSize: null,
  assetAttrbtNullable: null,
  assetAttrbtPrecision: null,
  assetAttrbtScale: null,
  colIndex: null,
  colStartPos: null,
  colEndPos: null,
  dupColFlg: null,
  colLabel: null,
  dateFormat: null,
  timeFormat: null,
  defaultValue: null,
  upperCaseExcludFlg: null,
  selectiveKillfillFlg: null,
  rulesEnabledFlg: null,
  licenceFlg: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
  assetAttrbtRuleList: null,
};

export const DESELECTED_ATTRIBUTE_RULES_TABLE_ROW:RulesTableRow = {
  assetAttrbtId: null,
  ruleId: null,
  ruleExeSeqNum: null,
  ruleName: null,
  ruleDesc: null,
  ruleCategory: null,
  ruleExpression: null,
  ruleType: null,
  ruleOperator: null,
  ruleValue: null,
  thresholdPercentage: null,
  existCheckFlg: null,
  lookupTable: null,
  lookupColumn: null,
  status: null,
  createdUserId: null,
  createdDatetime: null,
  updatedUserId: null,
  updatedDatetime: null,
};

// defaults for AssetMultiStepGetResponse

export const DEFAULT_ASSET: any = {
  accountId: 1,
  assetDesc: '',
  assetLongDesc: 'NA',
  assetName: '',
  categoryId: 1,
  collectionId: 1,
  contractId: 1,
  createdUserId: 'ontuser',
  datasetId: 1,
  sourceId: 1,
  status: 'Draft',
  tagId: 1,
  targetId: 1,
  updatedUserId: 'ontuser',
  valuationId: 1,
};

export const EMPTY_ASSET: AssetDto = {
  assetId: -1,
  accountId: 1,
  accountName: '',
  accountCode: '',
  datasetId: 1,
  datasetName: '',
  assetName: '',
  assetDesc: '',
  assetLongDesc: '',
  assetType: '',
  assetOwner: '',
  collectionName: '',
  frequencyName: '',
  categoryName: '',
  tagName: '',
  sourceName: '',
  targetName: '',
  fileConfigId: 1,
  valuationName: '',
  contractName: '',
  collectionId: 1,
  categoryId: 1,
  tagId: 1,
  sourceId: 1,
  targetId: 1,
  valuationId: 1,
  contractId: 1,
  loadType: '',
  derivedAssetFlg: '',
  dataCatalogPushFlg: '',
  allowSchemaDiscoveryFlg: '',
  notificationEnableFlg: '',
  status: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
  versionId: 1,
};

export const DEFAULT_ASSET_SOURCE: any = {
  sourceId: 10,
  sourceType: '',
  sourceName: '',
  sourceDesc: '',
  sourceDirectory: '',
  sourceTechnologyType: '',
  sourceConnection: '',
  authenticationKey: '',
  authenticationValue: '',
  createdUserId: 'ontuser',
  updatedUserId: 'ontuser',
  status: 'Active',
};

export const EMPTY_ASSET_SOURCE: SourceDto = {
  sourceId: -1,
  sourceType: '',
  sourceName: '',
  sourceDesc: '',
  sourceDirectory: '',
  sourceTechnologyType: '',
  sourceConnection: '',
  authenticationKey: '',
  authenticationValue: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
  status: '',
};

export const DEFAULT_ASSET_TARGET: any = {
  targetType: '',
  targetName: '',
  targetDesc: '',
  targetDirectory: '',
  targetTechnologyType: '',
  targetConnection: '',
  authenticationKey: '',
  authenticationValue: '',
  createdUserId: 'ontuser',
  updatedUserId: 'ontuser',
  status: 'Active',
};

export const EMPTY_ASSET_TARGET: TargetDto = {
  targetId: -1,
  targetType: '',
  targetName: '',
  targetDesc: '',
  targetDirectory: '',
  targetTechnologyType: '',
  targetConnection: '',
  authenticationKey: '',
  authenticationValue: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
  status: '',
};

export const EMPTY_ASSET_SCRIPT: AssetScriptDto = {
  assetId: -1,
  versionId: -1,
  assetScript: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};
export const EMPTY_ASSET_VERSION: AssetVersionDto = {
  assetId: -1,
  versionId: -1,
  versionDesc: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};
export const EMPTY_LINEAGES_ARRAY: LineageDto[] = [];
export const EMPTY_ASSET_DATASET = {
  datasetId: -1,
  assetId: -1,
  incomingFileName: '',
  status: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

// File config
export const DEFAULT_FILE_CONFIG: any = {
  createdUserId: 'ontuser',
  dupRecOrderby: 'NA',
  escapeChar: '\\',
  fileCharSet: 'UTF-8',
  fileFormat: 'delimited',
  filePattern: '',
  schedule: '0 0 * * *',
  filePath: '',
  quoteChar: '"',
  recordDelimiter: ',',
  retainDupRecPos: 'NA',
  skipLinesFromTop: 0,
  status: 'Active',
  updatedUserId: 'ontuser',
};

export const EMPTY_FILE_CONFIG: FileConfigDto = {
  fileConfigId: -1,
  fileFormat: '',
  recordDelimiter: '',
  filePattern: '',
  schedule: '',
  skipLinesFromTop: -1,
  escapeChar: '',
  quoteChar: '',
  fileCharSet: '',
  skipRecLenChkFlg: '',
  headerRecFlg: '',
  thresholdChkFlg: '',
  thresholdPercentage: null,
  thresholdNumOfRec: null,
  duplicateRecChkFlg: '',
  fatalFileFlg: '',
  dupRecOnAllRecFlg: '',
  dupRecCntThld: null,
  dupPercentThld: null,
  dropDupFlg: '',
  retainDupRecPos: '',
  dupRecOrderby: '',
  filePath: '',
  status: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

export const EMPTY_ASSET_CONTRACT: ContractDto = {
  contractId: -1,
  contractName: '',
  contractDesc: '',
  usageType: '',
  contractType: '',
  contractStartDt: '',
  contractEndDt: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
  status: '',
};

const today = dayjs(new Date().toLocaleDateString()).format('YYYY-MM-DD');

export const DEFAULT_ASSET_CONTRACT: any = {
  contractId: 1,
  contractName: '',
  contractDesc: '',
  usageType: '',
  contractType: '',
  contractStartDt: today,
  contractEndDt: today,
  createdUserId: 'ontuser',
  updatedUserId: 'ontuser',
  status: 'Active',
};

export const DEFAULT_ASSET_VALUATION: any = {
  valuationId: 1,
  valuationName: '',
  valuationDesc: '',
  valuationRecordCount: 1000,
  valuationFileFlg: BooleanType.True,
  valuationDays: 1000,
  valuationType: BooleanType.False,
  value: 0,
  valuationStartDt: today,
  valuationEndDt: today,
  currency: 'USD',
  createdUserId: 'ontuser',
  updatedUserId: 'ontuser',
  status: 'Active',
};

export const EMPTY_ASSET_VALUATION: ValuationDto = {
  valuationId: -1,
  valuationName: '',
  valuationDesc: '',
  valuationRecordCount: -1,
  valuationFileFlg: '',
  valuationDays: -1,
  valuationType: '',
  value: -1,
  valuationStartDt: '',
  valuationEndDt: '',
  currency: 'USD',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
  status: '',
};

export const EMPTY_ASSET_ATTRBT_ARRAY: AssetAttrbtDto[] = [];

// export const DEFAULT_ASSET_ATTRBT: AssetAttrbtDto = {
//   assetAttrbtId: 51,
//   assetId: 21,
//   assetAttrbtName: 'STNAMEP',
//   assetAttrbtDesc: 'Name of the state Updated',
//   assetAttrbtKeyTypeCode: 'ST  ',
//   dataType: 'varchar',
//   categoryId: 3,
//   tagId: 1,
//   lineageId: null,
//   assetAttrbtSize: 12,
//   assetAttrbtNullable: 'Y',
//   assetAttrbtPrecision: 0,
//   assetAttrbtScale: 0,
//   colIndex: 1,
//   colStartPos: 1,
//   colEndPos: 1,
//   dupColFlg: '',
//   colLabel: '',
//   dateFormat: '',
//   timeFormat: '',
//   fieldType: 'SOURCE',
//   upperCaseExcludFlg: ' ',
//   selectiveKillfillFlg: ' ',
//   status: 'Active',
//   createdDatetime: '',
//   createdUserId: 'ontuser',
//   updatedDatetime: '',
//   updatedUserId: 'ontuser',
//   assetAttrbtRuleList: [],
// };

export const EMPTY_ASSET_ATTRBT: any = {
  assetAttrbtName: '',
  assetAttrbtDesc: '',
  assetAttrbtKeyTypeCode: '',
  dataType: '',
  assetAttrbtSize: 0,
  assetAttrbtNullable: 'N',
  assetAttrbtPrecision: 0,
  assetAttrbtScale: 0,
  colLabel: 'N',
  colIndex: 0,
  categoryId: 1,
  defaultValue: '',
  rulesEnabledFlg: 'N',
  licenceFlg: 'N',
  tagId: 1,
  status: 'Active',
  createdUserId: 'ontuser',
  updatedUserId: 'ontuser',
  assetAttrbtRuleList: [],
};

// export const DEFAULT_ASSET_ATTRBT_RULE: AssetAttrbtRuleDto = {
//   assetAttrbtId: 51,
//   ruleId: 1,
//   ruleExeSeqNum: 14,
//   ruleName: 'plusU',
//   ruleDesc: 'do plusU',
//   ruleCategory: RuleCategory.BASIC,
//   ruleExpression: 'a+b',
//   ruleType: RuleType.EDIT,
//   ruleOperator: '>',
//   ruleValue: '12',
//   thresholdPercentage: 12,
//   existCheckFlg: 'Y',
//   lookupTable: 'No',
//   lookupColumn: 'No',
//   status: 'Active',
//   createdDatetime: '',
//   createdUserId: 'ontuser',
//   updatedDatetime: '',
//   updatedUserId: 'ontuser',
// };

export const EMPTY_ASSET_ATTRBT_RULE: AssetAttrbtRuleDto = {
  assetAttrbtId: -1,
  ruleId: -1,
  ruleExeSeqNum: -1,
  ruleName: '',
  ruleDesc: '',
  ruleCategory: RuleCategory.BASIC,
  ruleExpression: '',
  ruleType: RuleType.COMPARE,
  ruleOperator: '',
  ruleValue: '',
  thresholdPercentage: 0,
  existCheckFlg: '',
  lookupTable: '',
  lookupColumn: '',
  status: '',
  createdUserId: '',
  createdDatetime: '',
  updatedUserId: '',
  updatedDatetime: '',
};

export const EMPTY_SCHEDULE_DETAILS: ScheduleDetails = {
  scheduleExpression: '0 0 * * *',
  scheduleDescription: '',
  status: '',
};

export const EMPTY_STATUS = {
  code: '',
  message: '',
  desc: '',
};

export const EMPTY_PAGINATION = {
  records: 0,
  page: 0,
  limit: 0,
  totalRecords: 0,
  totalPages: 0,
};

export const BLANK_ASSET_DATA = {
  asset: { ...DEFAULT_ASSET },
  fileConfig: { ...DEFAULT_FILE_CONFIG },
  contract: { ...EMPTY_ASSET_CONTRACT },
  valuation: { ...EMPTY_ASSET_VALUATION },
  source: { ...EMPTY_ASSET_SOURCE },
  target: { ...EMPTY_ASSET_TARGET },
  assetScript: { ...EMPTY_ASSET_SCRIPT },
  assetVersion: { ...EMPTY_ASSET_VERSION },
  assetAttrbtList: [...EMPTY_ASSET_ATTRBT_ARRAY],
  lineageList: [...EMPTY_LINEAGES_ARRAY],
  status: { ...EMPTY_STATUS },
};

// defaults for AssetList

export const EMPTY_ASSET_ARRAY: AssetDto[] = [];

export const EMPTY_LIST_ORDER = {
  sortByFieldName: '',
  sortOrder: OrderDirection.DESC,
  searchKey: '',
  searchValue: '',
};

export const BLANK_ASSET_LIST = {
  assets: [...EMPTY_ASSET_ARRAY],
  pagination: { ...EMPTY_PAGINATION },
  listOrder: { ...EMPTY_LIST_ORDER },
  status: { ...EMPTY_STATUS },
};
