import React from 'react';
// import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import Tooltip from './tooltip';

export interface CellProps {
  params: GridRenderCellParams
}

function Cell(props:CellProps) {
  const { params } = props;
  const tooltipVisibleMinLength = 15;
  const tooltipVisibleMaxWidth = 225;

  const cellText = (
    <WppTypography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {params.value}
    </WppTypography>
  );

  return (
    <div>
      {params?.value?.length >= tooltipVisibleMinLength && params.colDef.computedWidth <= tooltipVisibleMaxWidth // Tooltip is generally hidden for date columns
        ? (
          <Tooltip
            disableHoverListener={false}
            title={params.value}
          >
            {cellText}
          </Tooltip>
        )
        : cellText}
    </div>

  );
}

export default Cell;
