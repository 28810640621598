import React from 'react';
import { GridCellParams, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { useDispatch } from 'react-redux';
import Cell from '../../../../ui-component/cell';
import Table from '../../../../ui-component/table';
import { NotificationInbound, NotificationTableRow } from '../../../../services/models/notification-models';
import { setFormDataActionCreator, setNotificationSelectedTableRowActionCreator } from '../../../../store/actions/notification-action';
import { EMPTY_NOTIFICATION_INBOUND } from '../../../../services/defaults/notification-defaults';

export interface NotificationTableProps {
  rows: any[],
}

function NotificationTable(props: NotificationTableProps) {
  const { rows } = props;
  // eslint-disable-next-line
  const dispatch = useDispatch();

  const columnFormat = [
    {
      field: 'transmissionId', description: '', headerText: 'Transmission Id', minWidth: 150,
    },
    {
      field: 'subscriptionId', description: '', headerText: 'Subscription Id', minWidth: 150,
    },
    {
      field: 'notificationId', description: '', headerText: 'Notification Id', minWidth: 150,
    },
    {
      field: 'notificationName', description: '', headerText: 'Notification Name', minWidth: 150,
    },
    {
      field: 'notificationDesc', description: '', headerText: 'Notification Description', minWidth: 200,
    },
    {
      field: 'notificationEvent', description: '', headerText: 'Notification Event', minWidth: 150,
    },
    {
      field: 'notificationLevel', description: '', headerText: 'Notification Level', minWidth: 150,
    },
    {
      field: 'notificationCategory', description: '', headerText: 'Notification Category', minWidth: 170,
    },
    {
      field: 'notificationValue', description: '', headerText: 'Notification Value', minWidth: 150,
    },
    {
      field: 'subscriptionName', description: '', headerText: 'Subscription Name', minWidth: 150,
    },
    {
      field: 'subscriptionDesc', description: '', headerText: 'Subscription Description', minWidth: 200,
    },
    {
      field: 'subscriptionCategory', description: '', headerText: 'Subscription Category', minWidth: 170,
    },
    {
      field: 'subscribedData', description: '', headerText: 'Subscribed Data', minWidth: 150,
    },
    {
      field: 'accountCode', description: '', headerText: 'Account Code', minWidth: 150,
    },
    {
      field: 'assetName', description: '', headerText: 'Asset Name', minWidth: 150,
    },
    {
      field: 'collectionName', description: '', headerText: 'Collection Name', minWidth: 150,
    },
    {
      field: 'notificationLabel', description: '', headerText: 'Notification Label', minWidth: 150,
    },
    {
      field: 'notificationContent', description: '', headerText: 'Notification Content', minWidth: 150,
    },
    {
      field: 'notificationRecipient', description: '', headerText: 'Notification Recipient', minWidth: 170,
    },
    {
      field: 'resendLimit', description: '', headerText: 'Resend Limit', minWidth: 150,
    },
    {
      field: 'refreshWindow', description: '', headerText: 'Refresh Window', minWidth: 150,
    },
    {
      field: 'status', description: '', headerText: 'Status', minWidth: 150,
    },
    {
      field: 'createdUserId', description: '', headerText: 'Created User Id', minWidth: 150,
    },
    {
      field: 'createdDatetime',
      description: '',
      headerText: 'Created Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
    {
      field: 'updatedUserId', description: '', headerText: 'Updated UserId', minWidth: 150,
    },
    {
      field: 'updatedDatetime',
      description: '',
      headerText: 'Updated Datetime',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value)
        .toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' }),
      sortable: true,
    },
  ];

  const filterModel = { items: [] };

  const columns = columnFormat.map(((column) => {
    const columnFormatted = {
      field: column.field,
      headerClassName: 'table-column-header',
      description: column.description,
      minWidth: column.minWidth,
      renderHeader: () => <WppTypography type="s-strong">{column.headerText}</WppTypography>,
      renderCell: (params:GridRenderCellParams) => <Cell params={params} />,
      valueGetter: column.valueGetter,
      sortable: false,
      filterable: false,
    };
    return columnFormatted;
  }));

  const columnVisibilityModel = {
    transmissionId: false,
    subscriptionId: false,
    notificationId: false,
    accountCode: false,
    collectionName: false,
    notificationLabel: false,
    notificationContent: false,
    resendLimit: false,
    createdUserId: false,
    createdDatetime: false,
    updatedUserId: false,
    updatedDatetime: false,
  };

  const getSelectedNotification = (selectedNotificationTableRow: NotificationTableRow) : NotificationInbound => {
    const notificationDto: NotificationInbound = { ...EMPTY_NOTIFICATION_INBOUND };
    notificationDto.transmissionId = selectedNotificationTableRow.transmissionId !== null ? selectedNotificationTableRow.transmissionId : -1;
    notificationDto.subscriptionId = selectedNotificationTableRow.subscriptionId !== null ? selectedNotificationTableRow.subscriptionId : -1;
    notificationDto.notificationId = selectedNotificationTableRow.notificationId !== null ? selectedNotificationTableRow.notificationId : -1;
    notificationDto.accountCode = selectedNotificationTableRow.accountCode !== null ? selectedNotificationTableRow.accountCode : '';
    notificationDto.assetName = selectedNotificationTableRow.assetName !== null ? selectedNotificationTableRow.assetName : '';
    notificationDto.collectionName = selectedNotificationTableRow.collectionName !== null ? selectedNotificationTableRow.collectionName : '';
    notificationDto.notificationEvent = selectedNotificationTableRow.notificationEvent !== null ? selectedNotificationTableRow.notificationEvent : '';
    notificationDto.notificationLevel = selectedNotificationTableRow.notificationLevel !== null ? selectedNotificationTableRow.notificationLevel : '';
    notificationDto.subscriptionCategory = selectedNotificationTableRow.subscriptionCategory !== null ? selectedNotificationTableRow.subscriptionCategory : '';
    notificationDto.subscriptionName = selectedNotificationTableRow.subscriptionName !== null ? selectedNotificationTableRow.subscriptionName : '';
    notificationDto.subscriptionDesc = selectedNotificationTableRow.subscriptionDesc !== null ? selectedNotificationTableRow.subscriptionDesc : '';
    notificationDto.subscribedData = selectedNotificationTableRow.subscribedData !== null ? selectedNotificationTableRow.subscribedData : '';
    notificationDto.notificationName = selectedNotificationTableRow.notificationName !== null ? selectedNotificationTableRow.notificationName : '';
    notificationDto.notificationDesc = selectedNotificationTableRow.notificationDesc !== null ? selectedNotificationTableRow.notificationDesc : '';
    notificationDto.notificationCategory = selectedNotificationTableRow.notificationCategory !== null ? selectedNotificationTableRow.notificationCategory : '';
    notificationDto.notificationValue = selectedNotificationTableRow.notificationValue !== null ? selectedNotificationTableRow.notificationValue : '';
    notificationDto.notificationLabel = selectedNotificationTableRow.notificationLabel !== null ? selectedNotificationTableRow.notificationLabel : '';
    notificationDto.notificationContent = selectedNotificationTableRow.notificationContent !== null ? selectedNotificationTableRow.notificationContent : '';
    notificationDto.notificationRecipient = selectedNotificationTableRow.notificationRecipient !== null ? selectedNotificationTableRow.notificationRecipient : '';
    notificationDto.resendLimit = selectedNotificationTableRow.resendLimit !== null ? selectedNotificationTableRow.resendLimit : -1;
    notificationDto.refreshWindow = selectedNotificationTableRow.refreshWindow !== null ? selectedNotificationTableRow.refreshWindow : '';
    notificationDto.status = selectedNotificationTableRow.status !== null ? selectedNotificationTableRow.status : '';
    notificationDto.createdUserId = selectedNotificationTableRow.createdUserId !== null ? selectedNotificationTableRow.createdUserId : '';
    notificationDto.createdDatetime = selectedNotificationTableRow.createdDatetime !== null ? selectedNotificationTableRow.createdDatetime : '';
    notificationDto.updatedUserId = selectedNotificationTableRow.updatedUserId !== null ? selectedNotificationTableRow.updatedUserId : '';
    notificationDto.updatedDatetime = selectedNotificationTableRow.updatedDatetime !== null ? selectedNotificationTableRow.updatedDatetime : '';
    return notificationDto;
  };

  const onCellClick = (params:GridCellParams) => {
    const rowDetails = columns.map((column) => (
      { [column.field]: params.row[column.field] }
    ));
    const selectedTableName = 'selectedNotificationTableRow';
    const selectedNotificationTableRow:NotificationTableRow = Object.assign({}, ...rowDetails);
    dispatch(setNotificationSelectedTableRowActionCreator({ [selectedTableName]: selectedNotificationTableRow }));
    const notificationFormData = 'notificationFormData';
    dispatch(setFormDataActionCreator({ [notificationFormData]: getSelectedNotification(selectedNotificationTableRow) }));
  };

  // const onCellClick = () => null;

  const onPageChange = () => null;

  const onPageSizeChange = () => null;

  const onSortModelChange = () => null; // Not implemented

  const customLoadingOverlay = () => <div />;

  const customToolbar = () => <div />;

  const headerHeight = 40;

  return (
    (
      <Table
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        customLoadingOverlay={customLoadingOverlay}
        customToolbar={customToolbar}
        filterModel={filterModel}
        hideFooterPagination={false}
        loading={false}
        onCellClick={onCellClick}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={100} // Will be dynamic
        rowCount={100} // Will be dynamic
        rows={rows}
        onSortModelChange={onSortModelChange}
        headerHeight={headerHeight}
      />
    )
  );
}

export default NotificationTable;
