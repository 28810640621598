import React, { useEffect } from 'react';
import {
  Card,
  Grid,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import {
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../ui-component/select';
import { SelectType, StatusOptions } from '../../services/models/select-models.d';
import FormButtons from '../../ui-component/form-buttons';
import {
  fetchPostUserActionCreator, setUserDataActionCreator, setUserEditsActionCreator, setUserSelectedTableRowActionCreator,
} from '../../store/actions/user-actions';
import { FetchGetUsersParams, UserMgmtRequest, UserState } from '../../services/models/user-models.d';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { StoreState } from '../../store';
import RoleAccordion from './role-form';
import { EMPTY_USER, DESELECTED_USER_TABLE_ROW, DEFAULT_USER_PAGINATION } from '../../services/defaults/user-defaults';

function UserForm() {
  const dispatch = useDispatch();

  const fetchGetUserByName:UserState['api']['data']['fetchGetUserByName'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetUserByName,
  );

  const userDataEdits:UserState['userDataEdits'] = useSelector(
    (state: StoreState) => state.userReducer.userDataEdits,
  );

  useEffect(() => {}, [userDataEdits]);

  const handleFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newResponse:any = fetchGetUserByName;
    const dataName = 'fetchGetUserByName';
    const editCounterName = 'userDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newResponse.user[field] = newValue;
    }
    dispatch(setUserDataActionCreator({ [dataName]: newResponse }));
    dispatch(setUserEditsActionCreator({ [editCounterName]: userDataEdits + 1 }));
  };

  const onClear = () => {
    const userFormDataName = 'userFormData';
    dispatch(setUserDataActionCreator({ [userFormDataName]: { ...EMPTY_USER } }));
    const selectedTableName = 'selectedUserTableRow';
    dispatch(setUserSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_USER_TABLE_ROW } }));
  };

  const handlePostUser = () => {
    const userRequest: UserMgmtRequest = { ...fetchGetUserByName };
    const fetchParams: FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
    dispatch(fetchPostUserActionCreator(userRequest, fetchParams, ShowSnackbar.True));
    onClear();
  };

  // const handlePutUser = () => {
  //   const userRequest: UserMgmtRequest = { ...fetchGetUserByName };
  //   const fetchParams: FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
  //   dispatch(fetchPostUserActionCreator(userRequest, fetchParams, ShowSnackbar.True));
  //   onClear();
  // };

  const handleSubmit = async () => {
    let userId = -1;
    const userObj = fetchGetUserByName;
    ({ userId } = userObj.user);
    if (userId === -1) {
      handlePostUser();
    } else {
      handlePostUser(); // Can be changed if PUT is implemented
    }
  };

  const itemWidth = '130px';
  const selectWidth = 130;

  // let userId;
  let userName;
  let firstName;
  let midName;
  let lastName;
  let email;
  let phone;
  // let lastLoginDatetime;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;
  // let roleName;
  // let permissions;
  // let resourceName;

  if (typeof fetchGetUserByName !== 'undefined'
    && fetchGetUserByName !== null
    && typeof fetchGetUserByName.user !== 'undefined'
    && fetchGetUserByName.user !== null) {
    ({
      // userId,
      userName,
      firstName,
      midName,
      lastName,
      email,
      phone,
      // lastLoginDatetime,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
      // roleName,
      // permissions,
      // resourceName,
    } = fetchGetUserByName.user);
  }

  // const handleSubmit = () => {
  //   const fetchParams: FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
  //   dispatch(fetchGetUsersActionCreator(fetchParams, ShowSnackbar.True));
  // };
  // const onClear = () => {};

  return (
    <Grid item xs={12}>
      <Card sx={{
        p: 2, elevation: 0, width: '100%', height: '100%',
      }}
      >
        <Grid container sx={{ m: 1 }}>
          <Grid item>
            <Grid container spacing={2} sx={{ width: 'auto' }}>
              <Grid container item sx={{ ml: -1 }}>
                <FormButtons
                  onSave={handleSubmit}
                  onClear={onClear}
                  disableSave
                  disableClear
                />
              </Grid>
              <Grid item xs={12}>
                <WppTypography tag="h2" type="xl-heading">
                  User
                </WppTypography>
              </Grid>
              <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    required
                    label="First Name"
                    value={typeof (firstName) !== 'undefined' ? firstName : ''}
                    onChange={(event) => handleFieldChange(event.target.value, 'firstName')}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    required
                    label="Middle Name"
                    value={typeof (midName) !== 'undefined' ? midName : ''}
                    onChange={(event) => handleFieldChange(event.target.value, 'midName')}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    required
                    label="Last Name"
                    value={typeof (lastName) !== 'undefined' ? lastName : ''}
                    onChange={(event) => handleFieldChange(event.target.value, 'lastName')}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    required
                    label="Phone Number"
                    value={typeof (phone) !== 'undefined' ? phone : ''}
                    onChange={(event) => handleFieldChange(event.target.value, 'phone')}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    required
                    label="Email Address"
                    value={typeof (email) !== 'undefined' ? email : ''}
                    onChange={(event) => handleFieldChange(event.target.value, 'email')}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    required
                    label="Username"
                    value={typeof (userName) !== 'undefined' ? userName : ''}
                    onChange={(event) => handleFieldChange(event.target.value, 'userName')}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <Select
                    title="Status"
                    value={typeof (status) !== 'undefined' ? status : ''}
                    disabled={false}
                    options={StatusOptions}
                    useIdOrValue={SelectType.useValue}
                    onChange={(
                      event:SelectChangeEvent,
                    ) => handleFieldChange(event.target.value, 'status')}
                    width={selectWidth}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }} />
              </Grid>
              <RoleAccordion />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default UserForm;
