import { GridSortModel } from '@mui/x-data-grid';
import {
  DEFAULT_CATALOG_DATA_GRID_SORT_ARRAY, DESELECTED_CATALOG_DATA_TABLE_ROW, EMPTY_CATALOG_DATA_RESPONSE, EMPTY_CATALOG_METRICS_RESPONSE,
} from '../../services/defaults/data-catalog-defaults';
import { CatalogAction, CatalogActionOptions, CatalogState } from '../../services/models/data-catalog-models.d';

export const CatalogDefaultState = {
  api: {
    flags: {
      fetchGetCatalogAssetNameFlag: false,
      fetchGetCatalogMetricsFlag: false,
    },
    data: {
      fetchGetCatalogAssetName: { ...EMPTY_CATALOG_DATA_RESPONSE },
      fetchGetCatalogMetrics: { ...EMPTY_CATALOG_METRICS_RESPONSE },
    },
    dataEu: {
      fetchGetCatalogAssetName: { ...EMPTY_CATALOG_DATA_RESPONSE },
      fetchGetCatalogMetrics: { ...EMPTY_CATALOG_METRICS_RESPONSE },
    },
    dataApac: {
      fetchGetCatalogAssetName: { ...EMPTY_CATALOG_DATA_RESPONSE },
      fetchGetCatalogMetrics: { ...EMPTY_CATALOG_METRICS_RESPONSE },
    },
    dataEmea: {
      fetchGetCatalogAssetName: { ...EMPTY_CATALOG_DATA_RESPONSE },
      fetchGetCatalogMetrics: { ...EMPTY_CATALOG_METRICS_RESPONSE },
    },
  },
  catalogEdits: 0,
  tables: {
    selectedAttributeTableRow: { ...DESELECTED_CATALOG_DATA_TABLE_ROW },
  },
  sortModel: DEFAULT_CATALOG_DATA_GRID_SORT_ARRAY,
};

function catalogReducer(
  state:CatalogState = CatalogDefaultState,
  action:CatalogAction,
): CatalogState {
  switch (action.type) {
    case CatalogActionOptions.SET_CATALOG_API_FLAG:
      return {
        ...state,
        api: {
          ...state.api,
          flags: {
            ...state.api.flags,
            ...action.payload,
          },
        },
      };
    case CatalogActionOptions.SET_CATALOG_API_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          data: {
            ...state.api.data,
            ...action.payload,
          },
        },
      };
    case CatalogActionOptions.SET_CATALOG_API_EU_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          dataEu: {
            ...action.payload,
          },
        },
      };
    case CatalogActionOptions.SET_CATALOG_API_APAC_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          dataApac: {
            ...action.payload,
          },
        },
      };
    case CatalogActionOptions.SET_CATALOG_API_EMEA_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          dataEmea: {
            ...action.payload,
          },
        },
      };
    case CatalogActionOptions.SET_CATALOG_SELECTED_TABLE_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          ...action.payload,
        },
      };
    case CatalogActionOptions.SET_CATALOG_DATA_EDITS:
      return {
        ...state,
        ...action.payload,
      };
    case CatalogActionOptions.SET_CATALOG_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload as GridSortModel,
      };
    default:
      return state;
  }
}

export default catalogReducer;
