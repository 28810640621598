import React, { useState } from 'react';
// material-ui
import {
  Grid, SelectChangeEvent, TextField,
} from '@mui/material';
import { WppToggle } from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationChannel,
  NotificationChannelOptions, NotificationEventOptions, RefreshWindowOptions, SelectType, StatusOptions, SubscriptionLevelOptions,
} from '../../../../services/models/select-models.d';
import FormButtons from '../../../../ui-component/form-buttons';
import Select from '../../../../ui-component/select';
import {
  FetchDeleteNotificationParams, FetchGetNotificationParams, NotificationInbound, NotificationState,
} from '../../../../services/models/notification-models';
import { StoreState } from '../../../../store';
import { AssetMultiStepGetResponse } from '../../../../services/models/asset-models';
import { DEFAULT_NOTIFICATION_INBOUND, DESELECTED_NOTIFICATION_TABLE_ROW } from '../../../../services/defaults/notification-defaults';
import { setFormDataActionCreator } from '../../../../store/actions/asset-actions';
import {
  fetchDeleteNotificationActionCreator, fetchPostNotificationActionCreator, setNotificationEditsActionCreator, setNotificationSelectedTableRowActionCreator,
} from '../../../../store/actions/notification-action';
import { ShowSnackbar } from '../../../../services/models/snackbar-models.d';

export interface NotificationFormProps {
  assetData:AssetMultiStepGetResponse,
}

function NotificationForm(props:NotificationFormProps) {
  // eslint-disable-next-line
  const { assetData } = props;
  const dispatch = useDispatch();

  const selectedNotificationTableRow:NotificationState['tables']['selectedNotificationTableRow'] = useSelector(
    (state: StoreState) => state.notificationReducer.tables?.selectedNotificationTableRow,
  );

  const notificationFormData:NotificationState['formData']['notificationFormData'] = useSelector(
    (state: StoreState) => state.notificationReducer.formData?.notificationFormData,
  );

  const notificationEdits:NotificationState['notificationEdits'] = useSelector(
    (state: StoreState) => state.notificationReducer.notificationEdits,
  );

  // let transmissionId;
  // let subscriptionId;
  // let notificationId;
  // let accountCode;
  // let assetName;
  // let collectionName;
  let notificationEvent;
  let notificationLevel;
  // let subscriptionCategory;
  // let subscriptionName;
  // let subscriptionDesc;
  // let subscribedData;
  let notificationName;
  // let notificationDesc;
  let notificationCategory;
  let notificationValue;
  // let notificationLabel;
  // let notificationContent;
  // let notificationRecipient;
  // let resendLimit;
  let refreshWindow;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;
  // Map<String, String> additionalNotificationParams;

  if (typeof notificationFormData !== 'undefined' && notificationFormData !== null) {
    ({
      // transmissionId,
      // subscriptionId,
      // notificationId,
      // accountCode,
      // assetName,
      // collectionName,
      notificationEvent,
      notificationLevel,
      // subscriptionCategory,
      // subscriptionName,
      // subscriptionDesc,
      // subscribedData,
      notificationName,
      // notificationDesc,
      notificationCategory,
      notificationValue,
      // notificationLabel,
      // notificationContent,
      // notificationRecipient,
      // resendLimit,
      refreshWindow,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
      // Map<String, String> additionalNotificationParams;
    } = notificationFormData);
  }

  const handleNotificationFormFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newData:any = notificationFormData;
    const dataName = 'notificationFormData';
    const editCounterName = 'notificationEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newData[field] = newValue;
    }
    dispatch(setFormDataActionCreator({ [dataName]: newData }));
    dispatch(setNotificationEditsActionCreator({ [editCounterName]: notificationEdits + 1 }));
  };
  // eslint-disable-next-line
  const onClear = () => {
    const notificationFormDataName = 'notificationFormData';
    dispatch(setFormDataActionCreator({ [notificationFormDataName]: { ...DEFAULT_NOTIFICATION_INBOUND } }));
    const selectedNotificationTableRowName = 'selectedNotificationTableRow';
    dispatch(setNotificationSelectedTableRowActionCreator({ [selectedNotificationTableRowName]: { ...DESELECTED_NOTIFICATION_TABLE_ROW } }));
  };

  const onAdd = () => {
    const notificationDataForPost: NotificationInbound = { ...notificationFormData };
    notificationDataForPost.assetName = assetData?.asset?.assetName;
    notificationDataForPost.accountCode = assetData?.asset?.accountCode;
    notificationDataForPost.collectionName = assetData?.asset?.collectionName;
    notificationDataForPost.subscribedData = notificationFormData.notificationLevel === 'Asset' ? assetData?.asset?.assetName : assetData?.asset?.collectionName;
    const getFetchParams: FetchGetNotificationParams = {
      assetName: assetData?.asset?.assetName,
      accountCode: assetData?.asset?.accountCode,
    };
    dispatch(fetchPostNotificationActionCreator(notificationDataForPost, getFetchParams, ShowSnackbar.True));
    onClear();
  };

  const onDelete = () => {
    if (selectedNotificationTableRow !== null
      && selectedNotificationTableRow !== undefined
      && selectedNotificationTableRow.transmissionId !== null
      && selectedNotificationTableRow.transmissionId !== undefined
      && selectedNotificationTableRow.transmissionId !== -1
    ) {
      const selectedTransmissionId = selectedNotificationTableRow.transmissionId;
      const deleteParams: FetchDeleteNotificationParams = {
        transmissionId: selectedTransmissionId,
      };
      const getFetchParams: FetchGetNotificationParams = {
        assetName: assetData?.asset?.assetName,
        accountCode: assetData?.asset?.accountCode,
      };
      dispatch(fetchDeleteNotificationActionCreator(deleteParams, getFetchParams, ShowSnackbar.True));
      onClear();
    }
  };

  const selectWidth = 130;
  const itemWidth = '130px';
  const [subscribed, setSubscribed] = useState<boolean>(false);
  return (
    <Grid container xs={12}>
      <Grid container item>
        <FormButtons
          onSave={onAdd}
          onClear={onDelete}
        />
        <Grid container item xs={12} sx={{ mt: 1, justifyContent: 'space-between' }}>
          <Grid item sx={{ m: 1, width: itemWidth }}>
            <TextField
              variant="standard"
              focused
              required
              label="Notification Name"
              value={typeof (notificationName) !== 'undefined' ? notificationName : ''}
              onChange={(event) => {
                handleNotificationFormFieldChange(event.target.value, 'notificationName');
                handleNotificationFormFieldChange(event.target.value, 'notificationDesc');
              }}
            />
          </Grid>
          <Grid item sx={{ m: 1, width: itemWidth }}>
            <Select
              title="Event"
              value={typeof (notificationEvent) !== 'undefined' ? notificationEvent : ''}
              options={NotificationEventOptions}
              useIdOrValue={SelectType.useValue}
              onChange={(event: SelectChangeEvent) => {
                handleNotificationFormFieldChange(event.target.value, 'notificationEvent');
              }}
              width={selectWidth}
            />
          </Grid>
          <Grid item sx={{ m: 1, width: itemWidth }}>
            <Select
              title="Channel"
              value={typeof (notificationCategory) !== 'undefined' ? notificationCategory : ''}
              options={NotificationChannelOptions}
              useIdOrValue={SelectType.useValue}
              onChange={(event: SelectChangeEvent) => {
                handleNotificationFormFieldChange(event.target.value, 'notificationCategory');
              }}
              width={selectWidth}
            />
          </Grid>
          <Grid item sx={{ m: 1, width: itemWidth }}>
            { (notificationCategory === NotificationChannel.File
              || notificationCategory === NotificationChannel.Database)
              && (
                <TextField
                  variant="standard"
                  focused
                  required
                  label="Value"
                  value={typeof (notificationValue) !== 'undefined' ? notificationValue : ''}
                  onChange={(event) => handleNotificationFormFieldChange(event.target.value, 'notificationValue')}
                />
              )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
          <Grid item sx={{ m: 1, width: itemWidth }}>
            <Select
              title="Status"
              value={typeof (status) !== 'undefined' ? status : ''}
              disabled={false}
              options={StatusOptions}
              useIdOrValue={SelectType.useValue}
              onChange={(event: SelectChangeEvent) => {
                handleNotificationFormFieldChange(event.target.value, 'status');
              }}
              width={selectWidth}
            />
          </Grid>
          <Grid item sx={{ m: 1, width: itemWidth, mt: 2 }}>
            <WppToggle
              label="Subscribe"
              required
              checked={subscribed}
              onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                if (checked) {
                  // handleNotificationFormFieldChange(BooleanType.True, 'subscriptionFlg');
                  setSubscribed(true);
                } else {
                  // handleNotificationFormFieldChange(BooleanType.False, 'subscriptionFlg');
                  setSubscribed(false);
                }
              }}
            />
          </Grid>
          <Grid item sx={{ m: 1, width: itemWidth }}>
            { subscribed && (
              <Select
                title="Level"
                value={typeof (notificationLevel) !== 'undefined' ? notificationLevel : ''}
                options={SubscriptionLevelOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => {
                  handleNotificationFormFieldChange(event.target.value, 'notificationLevel');
                }}
                width={selectWidth}
              />
            )}
          </Grid>
          <Grid item sx={{ m: 1, width: itemWidth }}>
            { subscribed && (
              <Select
                title="Refresh Window"
                value={typeof (refreshWindow) !== 'undefined' ? refreshWindow : ''}
                options={RefreshWindowOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => {
                  handleNotificationFormFieldChange(event.target.value, 'refreshWindow');
                }}
                width={selectWidth}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NotificationForm;
