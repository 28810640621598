import { GridSortModel } from '@mui/x-data-grid';
import {
  BLANK_ASSET_DATA,
  BLANK_ASSET_LIST,
  DESELECTED_ASSET_TABLE_ROW,
  DESELECTED_ATTRIBUTE_TABLE_ROW,
  DESELECTED_ATTRIBUTE_RULES_TABLE_ROW,
  DEFAULT_ASSET_GRID_SORT_ARRAY,
  EMPTY_ASSET_ATTRBT,
  EMPTY_ASSET_ATTRBT_RULE,
  EMPTY_SCHEDULE_DETAILS,
} from '../../services/defaults/asset-defaults';
import {
  AssetState,
  AssetAction,
  AssetActionOptions,
  AssetTableRow,
} from '../../services/models/asset-models.d';

export const AssetDefaultState = {
  api: {
    flags: {
      fetchPutAssetDataFlag: false,
      fetchPostAssetDataFlag: false,
      fetchDeleteAssetDataFlag: false,
      fetchGetAssetListFlag: false,
      fetchGetAssetByIdFlag: false,
    },
    data: {
      fetchGetAssetList: { ...BLANK_ASSET_LIST },
      fetchGetAssetById: { ...BLANK_ASSET_DATA },
      fetchGetAssetByIdForLookupRules: { ...BLANK_ASSET_DATA },
    },
  },
  formData: {
    attributeFormData: { ...EMPTY_ASSET_ATTRBT },
    attributeRuleFormData: { ...EMPTY_ASSET_ATTRBT_RULE },
    scheduleFormData: { ...EMPTY_SCHEDULE_DETAILS },
  },
  assetCreationFormData: {},
  assetDataEdits: 0,
  tables: {
    selectedAssetTableRow: { ...DESELECTED_ASSET_TABLE_ROW },
    selectedAttributeTableRow: { ...DESELECTED_ATTRIBUTE_TABLE_ROW },
    selectedRulesTableRow: { ...DESELECTED_ATTRIBUTE_RULES_TABLE_ROW },
    // selectedLookupRulesTableRow: DESELECTED_LOOKUP_RULES_TABLE_ROW,
  },
  sortModel: DEFAULT_ASSET_GRID_SORT_ARRAY,
};

function AssetReducer(
  state:AssetState = AssetDefaultState,
  action:AssetAction,
): AssetState {
  switch (action.type) {
    case AssetActionOptions.SET_ASSET_API_FLAG:
      return {
        ...state,
        api: {
          ...state.api,
          flags: {
            ...state.api.flags,
            ...action.payload,
          },
        },
      };
    case AssetActionOptions.SET_ASSET_API_DATA:
      return {
        ...state,
        api: {
          ...state.api,
          data: {
            ...state.api.data,
            ...action.payload,
          },
        },
      };
    case AssetActionOptions.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case AssetActionOptions.SET_ASSET_CREATION_FORM_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case AssetActionOptions.SET_ASSET_SELECTED_TABLE_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          ...action.payload,
        },
      };
    case AssetActionOptions.DELETE_ASSET_SELECTED_TABLE_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          selectedAssetTableRow: action.payload as AssetTableRow,
        },
      };
    case AssetActionOptions.SET_ASSET_DATA_EDITS:
      return {
        ...state,
        ...action.payload,
      };
    case AssetActionOptions.SET_ASSET_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload as GridSortModel,
      };
    default:
      return state;
  }
}

export default AssetReducer;
