export interface InfoDetailRecord {
  title: string,
  line1: string,
  line2: string,
  line3: string,
}

export enum IdPrefix {
  CATEGORY_PAGE = 'category-page',
  CATEGORY_FORM = 'category-form',
}
