import React, { useEffect, useState } from 'react';
import {
  Grid, SelectChangeEvent, TextField,
} from '@mui/material';
import {
  WppAccordion, WppToggle, WppTypography,
} from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../ui-component/select';
import {
  ISelectOption, SelectType,
} from '../../services/models/select-models.d';
import { FetchGetRoleResourcePermissionParams, FetchGetUsersParams, UserState } from '../../services/models/user-models.d';
import { StoreState } from '../../store';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import {
  fetchGetRoleResourcePermissionActionCreator, fetchGetRolesActionCreator, setUserDataActionCreator, setUserEditsActionCreator,
} from '../../store/actions/user-actions';
import { DEFAULT_USER_PAGINATION } from '../../services/defaults/user-defaults';

// export interface RoleAccordionProps {
// }

// function RoleAccordion(props:RoleAccordionProps) {
function RoleAccordion() {
  const itemWidth = '130px';
  const selectWidth = 130;
  const toggleWidth = '100px';
  // const {

  // } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchParams: FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
    fetchParams.limit = 100;
    dispatch(fetchGetRolesActionCreator(fetchParams, ShowSnackbar.True));
  }, [dispatch]);

  const roleList:UserState['api']['data']['fetchGetRoleList']['roleList'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetRoleList?.roleList,
  );

  const fetchGetUserByName:UserState['api']['data']['fetchGetUserByName'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetUserByName,
  );

  const userDataEdits:UserState['userDataEdits'] = useSelector(
    (state: StoreState) => state.userReducer.userDataEdits,
  );

  const fetchGetRoleResourcePermission:UserState['api']['data']['fetchGetRoleResourcePermission'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetRoleResourcePermission,
  );

  let roleName = '';
  let roleDesc;
  // let resources;

  if (typeof fetchGetUserByName !== 'undefined'
    && fetchGetUserByName !== null
    && typeof fetchGetUserByName.role !== 'undefined'
    && fetchGetUserByName.role !== null) {
    ({
      roleName,
      roleDesc,
      // resources,
    } = fetchGetUserByName.role);
  }

  const roleListSelectOptions:ISelectOption[] = roleList?.length > 0 ? roleList.map((role) => ({ id: role.roleId, value: role.roleName })) : [];

  const handleFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newResponse:any = fetchGetUserByName;
    const dataName = 'fetchGetUserByName';
    const editCounterName = 'userDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newResponse.role[field] = newValue;
    }
    dispatch(setUserDataActionCreator({ [dataName]: newResponse }));
    dispatch(setUserEditsActionCreator({ [editCounterName]: userDataEdits + 1 }));
  };
  const [addNewRole, setAddNewRole] = useState<boolean>(false);

  const getRoleResourcePermission = (roleNameStr: string) => {
    const fetchParams:FetchGetRoleResourcePermissionParams = {
      roleName: roleNameStr,
    };
    fetchGetRoleResourcePermissionActionCreator(fetchParams, ShowSnackbar.False);

    if (typeof fetchGetRoleResourcePermission !== 'undefined'
    && fetchGetRoleResourcePermission !== null
    && typeof fetchGetRoleResourcePermission.role !== 'undefined'
    && fetchGetRoleResourcePermission.role !== null) {
      ({
        roleName,
        roleDesc,
        // resources,
      } = fetchGetRoleResourcePermission.role);
    }
  };

  return (
    <Grid container>
      <WppAccordion text="Access" expandedByDefault>
        <Grid container>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Select Role"
                value={roleName !== undefined ? roleName : ''}
                disabled={addNewRole}
                options={roleListSelectOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(event: SelectChangeEvent) => {
                  handleFieldChange(event.target.value, 'roleName');
                  getRoleResourcePermission(event.target.value);
                }}
                width={selectWidth}
              />
            </Grid>
            <Grid
              item
              sx={{
                m: 1, mt: 3, width: itemWidth,
              }}
            >
              <WppToggle
                label="New Role"
                name="newRole"
                required
                checked={addNewRole}
                onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                  setAddNewRole(checked);
                  // handleFieldChange({ ...DEFAULT_ASSET_VALUATION }, 'valuation');
                  // handleFieldChange(null, 'valuation');
                }}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }} />
            <Grid item sx={{ m: 1, width: itemWidth }} />
          </Grid>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                disabled={!addNewRole}
                label="Role name"
                value={typeof (roleName) !== 'undefined' ? roleName : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'roleName')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                disabled={!addNewRole}
                label="Role description"
                value={typeof (roleDesc) !== 'undefined' ? roleDesc : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'roleDesc')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }} />
            <Grid item sx={{ m: 1, width: itemWidth }} />
          </Grid>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between', mt: 2 }}>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppTypography tag="p" type="m-body">
                Portal:
              </WppTypography>
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="View"
                name="view"
                required
                disabled={!addNewRole}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="Create"
                name="create"
                required
                disabled={!addNewRole}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="Edit"
                name="edit"
                required
                disabled={!addNewRole}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="Delete"
                name="delete"
                required
                disabled={!addNewRole}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="All"
                name="all"
                required
                disabled={!addNewRole}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ justifyContent: 'space-between', mt: 2 }}>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppTypography tag="p" type="m-body">
                User:
              </WppTypography>
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="View"
                name="view"
                required
                disabled={!addNewRole}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="Create"
                name="create"
                required
                disabled={!addNewRole}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="Edit"
                name="edit"
                required
                disabled={!addNewRole}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="Delete"
                name="delete"
                required
                disabled={!addNewRole}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: toggleWidth }}>
              <WppToggle
                label="All"
                name="all"
                required
                disabled={!addNewRole}
              />
            </Grid>
          </Grid>
        </Grid>
      </WppAccordion>
    </Grid>
  );
}

export default RoleAccordion;
