// Method implementation from Mozilla: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_request_options

const { fetch: originalFetch } = window;
// interceptor
window.fetch = async (...args) => {
  const [resource, config] = args;
  const response = await originalFetch(resource, config);
  if (!response.ok && response.status === 401) {
    // 401 - Delete session storage and show login
    window.sessionStorage.removeItem('jwt');
    window.location.reload();
    return Promise.reject(response);
  }
  return response;
};

// callService is only used for authentication call right now but should probably be removed
export default async function callService(url = '', method = 'GET', data: BodyInit | string | null = null, isSecured = true) {
  const response = await fetch(url, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...isSecured ? { Authorization: `Bearer ${window.sessionStorage.getItem('jwt')}` } : {},
    },
    body: data,
    redirect: 'follow',
    referrerPolicy: 'no-referrer-when-downgrade',
  });
  return response?.json();
}

export async function callServiceAsPromise(url = '', method = 'GET', data: BodyInit | string | null = null, isSecured = true) {
  return fetch(url, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...isSecured ? { Authorization: `Bearer ${window.sessionStorage.getItem('jwt')}` } : {},
    },
    body: data,
    redirect: 'follow',
    referrerPolicy: 'no-referrer-when-downgrade',
  });
}
