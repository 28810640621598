import React, { useEffect } from 'react';
import {
  Card,
  Grid,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StatusOptions, SelectType } from '../../../services/models/select-models.d';
import Select from '../../../ui-component/select';
import {
  MasterDataState, MasterDataTableNames, DatasetDto, MasterDataPostType,
} from '../../../services/models/master-data-models.d';
import FormButtons from '../../../ui-component/form-buttons';
import { DESELECTED_DATASET_TABLE_ROW, EMPTY_DATASET } from '../../../services/defaults/master-data-defaults';
import { StoreState } from '../../../store';
import {
  setMasterDataDataActionCreator, setMasterDataEditsActionCreator, setMasterDataSelectedTableRowActionCreator, fetchPostMasterDataActionCreator,
} from '../../../store/actions/master-data-actions';
import useAuthUser from '../../../hooks/use-auth-user';

function AssetDatasetForm() {
  const dispatch = useDispatch();
  const activeUser = useAuthUser();
  const itemWidth = '150px';

  const datasetData:MasterDataState['api']['data']['fetchGetDatasetData'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetDatasetData,
  );

  const masterDataEdits:MasterDataState['masterDataEdits'] = useSelector(
    (state: StoreState) => state.masterDataReducer.masterDataEdits,
  );

  // This useEffect handles UI changes, such as a user editing a field
  useEffect(() => {}, [masterDataEdits]);

  const handleFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newResponse:any = datasetData;
    const dataName = 'fetchGetDatasetData';
    const editCounterName = 'masterDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newResponse[field] = newValue;
    }
    dispatch(setMasterDataDataActionCreator({ [dataName]: newResponse }));
    dispatch(setMasterDataEditsActionCreator({ [editCounterName]: masterDataEdits + 1 }));
  };

  const onClear = () => {
    const fetchGetDatasetData = 'fetchGetDatasetData';
    dispatch(setMasterDataDataActionCreator({ [fetchGetDatasetData]: { ...EMPTY_DATASET } }));
    const selectedTableName = 'selectedDatasetTableRow';
    dispatch(setMasterDataSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_DATASET_TABLE_ROW } }));
    onClear();
  };

  const handlePostDataset = (postType:MasterDataPostType) => {
    const dataset: DatasetDto = datasetData;
    if (typeof activeUser.email !== 'undefined') {
      dataset.updatedUserId = activeUser.email;
      if (postType === MasterDataPostType.CREATE) {
        dataset.createdUserId = activeUser.email;
      }
    }
    dispatch(fetchPostMasterDataActionCreator(dataset, postType, MasterDataTableNames.DATASET));
  };

  const handleSubmit = async () => {
    let datasetId = -1;
    const datasetObj = datasetData;
    ({ datasetId } = datasetObj);
    if (datasetId === -1) {
      handlePostDataset(MasterDataPostType.CREATE);
    } else {
      handlePostDataset(MasterDataPostType.UPDATE);
    }
  };

  // let datasetId;
  let datasetName;
  let datasetDesc;
  let datasetCode;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;

  // const datasetObj = selectedDatasetTableRow.datasetList[0];

  if (typeof datasetData !== 'undefined'
    && datasetData !== null) {
    ({
      // datasetId,
      datasetName,
      datasetDesc,
      datasetCode,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
    } = datasetData);
  }
  return (
    <Grid item xs={12}>
      <Card sx={{
        p: 2, elevation: 0, width: '100%', height: '100%',
      }}
      >
        <Grid container>
          <FormButtons
            onSave={handleSubmit}
            onClear={onClear}
          />
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                variant="standard"
                focused
                required
                label="Dataset Name"
                value={typeof (datasetName) !== 'undefined' ? datasetName : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'datasetName')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                rows={3}
                variant="standard"
                focused
                required
                label="Dataset Description"
                value={typeof (datasetDesc) !== 'undefined' ? datasetDesc : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'datasetDesc')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <TextField
                rows={3}
                variant="standard"
                focused
                required
                label="Dataset Code"
                value={typeof (datasetCode) !== 'undefined' ? datasetCode : ''}
                onChange={(event) => handleFieldChange(event.target.value, 'datasetCode')}
              />
            </Grid>
            <Grid item sx={{ m: 1, width: itemWidth }}>
              <Select
                title="Status"
                value={typeof (status) !== 'undefined' ? status : ''}
                disabled={false}
                options={StatusOptions}
                useIdOrValue={SelectType.useValue}
                onChange={(
                  event:SelectChangeEvent,
                ) => handleFieldChange(event.target.value, 'status')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default AssetDatasetForm;
