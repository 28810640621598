import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import {
  AccessToken, OktaAuth, toRelativeUrl,
} from '@okta/okta-auth-js';
import oktaConfig from './services/defaults/okta-defaults';
import Login from './views/login/login';
import AssetExplorer from './views/asset-explorer';
import AssetSummary from './views/asset-management/AssetSummary';
import CreateAssetBase from './views/asset-management/create-asset-base';
import ListAllAssets from './views/asset-management/list-all-assets';
import Category from './views/config-management/category/category';
import Collection from './views/config-management/collection/collection';
import Dataset from './views/config-management/dataset/dataset';
import Rule from './views/config-management/rule/rule';
import Tag from './views/config-management/tag/tag';
import DataCatalog from './views/data-catalog-management';
import Users from './views/user-management/user';
import MainLayout from './layout/MainLayout';

const oktaAuth = new OktaAuth(oktaConfig);

function Routes() {
  const history = useHistory();
  const originalUrl = async (_oktaAuth:any, originalUri:any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    async function updateToken() {
      const authClient: OktaAuth = new OktaAuth(oktaConfig);
      const { tokenManager } = authClient;
      const accessToken: AccessToken = await tokenManager.get('accessToken') as AccessToken;
      // window.sessionStorage.setItem('jwt', accessToken?.accessToken);
      if (accessToken) {
        window.sessionStorage.setItem('jwt', accessToken?.accessToken);
      }
      // const idToken: IDToken = await tokenManager.get('idToken') as IDToken;
      // const userInfo: UserClaims = await authClient.token.getUserInfo(accessToken, idToken);
      // Todo: Save user info to Redux
    }
    updateToken();
  });

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={originalUrl}>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login/callback" component={LoginCallback} />
        <SecureRoute path="/user-management/users">
          <MainLayout>
            <Users />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/asset-management/list-assets">
          <MainLayout>
            <ListAllAssets />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/asset-management/asset-details">
          <MainLayout>
            <CreateAssetBase />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/asset-management/asset-summary">
          <MainLayout>
            <AssetSummary />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/master-config/rules">
          <MainLayout>
            <Rule />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/master-config/collections">
          <MainLayout>
            <Collection />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/master-config/datasets">
          <MainLayout>
            <Dataset />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/master-config/tags">
          <MainLayout>
            <Tag />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/master-config/categories">
          <MainLayout>
            <Category />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/asset-explorer">
          <MainLayout>
            <AssetExplorer />
          </MainLayout>
        </SecureRoute>
        <SecureRoute path="/data-catalog">
          <MainLayout>
            <DataCatalog />
          </MainLayout>
        </SecureRoute>
      </Switch>
    </Security>
  );
}

export default Routes;
