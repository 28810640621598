import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Grid, SelectChangeEvent, TextField,
} from '@mui/material';
import {
  WppAccordion, WppButton, WppIconAvailableCheckmark, WppIconDone, WppIconPlus, WppIconTrash, WppInlineMessage, WppToggle,
} from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';

import { DESELECTED_ATTRIBUTE_TABLE_ROW, EMPTY_ASSET_ATTRBT } from '../../../../../services/defaults/asset-defaults';
import {
  AssetAttrbtDto, AssetMultiStepGetResponse, AssetState,
} from '../../../../../services/models/asset-models.d';
import { ConfigState, DataType } from '../../../../../services/models/config-models.d';
import { MasterDataState } from '../../../../../services/models/master-data-models.d';
import {
  BooleanType, ISelectOption, KeyOptionsList, SelectType, StatusOption, StatusOptions,
} from '../../../../../services/models/select-models.d';
import { StepAccordion } from '../../../../../services/models/stepper-models.d';
import { DefaultAttributeFormError, IAttributeFormErrors } from '../../../../../services/models/validation-models.d';
import { StoreState } from '../../../../../store';
import {
  setAssetDataActionCreator, setAssetDataEditsActionCreator, setAssetSelectedTableRowActionCreator, setFormDataActionCreator,
} from '../../../../../store/actions/asset-actions';
import Select from '../../../../../ui-component/select';
import { setSnackbarActionCreator, snackbarMaker } from '../../../../../store/actions/snackbar-actions';
import Tooltip from '../../../../../ui-component/tooltip';

export interface AttrbCoreAttributesAccordionProps {
  assetData:AssetMultiStepGetResponse,
  rulesEnabled: boolean,
  // lineagesEnabled: boolean,
  setRulesEnabled: Dispatch<SetStateAction<boolean>>,
  // setlineagesEnabled: Dispatch<SetStateAction<boolean>>,
  validate:(accordion: number, formErrors: IAttributeFormErrors[]) => void;
}

function AttrbCoreAttributesAccordion(props:AttrbCoreAttributesAccordionProps) {
  const [deleteReadyToConfirm, setDeleteReadyToConfirm] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<IAttributeFormErrors[]>([]);
  // type IAttributeFormErrorsObjectKey = keyof IAttributeFormErrors;

  const dispatch = useDispatch();
  const itemWidth = '130px';
  const selectWidth = 130;
  const {
    assetData,
    // eslint-disable-next-line
    rulesEnabled,
    setRulesEnabled,
    validate,
  } = props;

  let allowSchemaDiscoveryFlg;

  if (typeof assetData.contract !== 'undefined') {
    ({
      allowSchemaDiscoveryFlg,
    } = assetData.asset);
  }

  const selectedAttributeTableRow:AssetState['tables']['selectedAttributeTableRow'] = useSelector(
    (state: StoreState) => state.assetReducer.tables.selectedAttributeTableRow,
  );

  const attributeFormData:AssetState['formData']['attributeFormData'] = useSelector(
    (state: StoreState) => state.assetReducer.formData.attributeFormData,
  );

  const assetDataEdits:AssetState['assetDataEdits'] = useSelector(
    (state: StoreState) => state.assetReducer.assetDataEdits,
  );

  useEffect(() => {
    if (assetData.assetAttrbtList && assetData.assetAttrbtList.length > 0 && formErrors.length !== assetData.assetAttrbtList.length) {
      const errors:IAttributeFormErrors[] = [];
      assetData.assetAttrbtList.forEach(() => {
        errors.push({ ...DefaultAttributeFormError });
      });
      setFormErrors(errors);
    }
  }, [formErrors, assetData.assetAttrbtList, setFormErrors]);

  let selectedAttrbtIndex = -1;
  const attrbtSelected = selectedAttributeTableRow.assetAttrbtId !== null && typeof (assetData?.assetAttrbtList) !== 'undefined';

  let assetAttrbtId = -1;
  // let assetId;
  let assetAttrbtName = '';
  let assetAttrbtDesc;
  let assetAttrbtKeyTypeCode: string;
  let dataType;
  // let categoryId;
  let tagId;
  // let lineageId;
  let assetAttrbtSize;
  let assetAttrbtNullable;
  let assetAttrbtPrecision;
  // let assetAttrbtScale;
  // let colIndex;
  // let colStartPos;
  // let colEndPos;
  let dupColFlg;
  // let colLabel;
  // let dateFormat;
  // let timeFormat;
  let defaultValue;
  // let upperCaseExcludFlg;
  // let selectiveKillfillFlg;
  let rulesEnabledFlg;
  let licenceFlg;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;
  // let versionId;
  // let assetAttrbtRuleList;

  if (selectedAttributeTableRow.assetAttrbtId !== null && typeof (assetData.assetAttrbtList) !== 'undefined') {
    assetData.assetAttrbtList.map((attrbt:AssetAttrbtDto, index:number) => {
      if (attrbt.assetAttrbtId === selectedAttributeTableRow.assetAttrbtId) {
        selectedAttrbtIndex = index;
      }
      return null;
    });
  }
  if (typeof attributeFormData !== 'undefined' && attributeFormData !== null) {
    ({
      // eslint-disable-next-line
      assetAttrbtId,
      // assetId,
      assetAttrbtName,
      assetAttrbtDesc,
      assetAttrbtKeyTypeCode,
      dataType,
      // categoryId,
      tagId,
      // lineageId,
      assetAttrbtSize,
      assetAttrbtNullable,
      assetAttrbtPrecision,
      // assetAttrbtScale,
      // colIndex,
      // colStartPos,
      // colEndPos,
      dupColFlg,
      // colLabel,
      // dateFormat,
      // timeFormat,
      defaultValue,
      // upperCaseExcludFlg,
      // selectiveKillfillFlg,
      rulesEnabledFlg,
      licenceFlg,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
      // versionId,
      // assetAttrbtRuleList,
    } = attributeFormData);
  }

  const handleAttributeFormFieldChange = (newValue:string | Date | boolean, field: string) => {
    const newData:any = { ...attributeFormData };
    const dataName = 'attributeFormData';
    const editCounterName = 'assetDataEdits';
    if (newValue !== null && field !== null && typeof (field) !== 'undefined') {
      newData[field] = newValue;
    }
    dispatch(setFormDataActionCreator({ [dataName]: { ...newData } }));
    dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
  };

  // TODO validation
  // const onValidatedFieldChange = (newValue:string | Date | boolean, field: string, attrbtId: number) => {
  //   const errors:IAttributeFormErrors[] = [...formErrors];
  //   const f = field as IAttributeFormErrorsObjectKey;
  //   errors[selectedAttrbtIndex][f] = validateAny(newValue);
  //   setFormErrors(errors);
  //   validate(StepAccordion.Attribute, errors);
  //   handleAttributeFieldChange(newValue, field, attrbtId);
  // };

  const configList:ConfigState['api']['data']['fetchGetConfigList'] = useSelector(
    (state: StoreState) => state.configReducer?.api?.data?.fetchGetConfigList,
  );
  const tagList:MasterDataState['api']['data']['fetchGetTagList']['tagList'] = useSelector(
    (state: StoreState) => state.masterDataReducer.api?.data?.fetchGetTagList?.tagList,
  );
  const tagListSelectOptions:ISelectOption[] = tagList.map((tag) => ({ id: tag.tagId, value: tag.tagName }));

  const onAdd = () => {
    let assetAttrbtList:AssetAttrbtDto[] = [];
    if (typeof (assetData.assetAttrbtList) !== 'undefined' && assetData.assetAttrbtList !== null) {
      ({ assetAttrbtList } = assetData);
    }
    assetAttrbtList.push({ ...attributeFormData });
    const attrbtListLength = assetAttrbtList.length;
    assetAttrbtList[attrbtListLength - 1].assetId = assetData?.asset?.assetId;
    assetAttrbtList[attrbtListLength - 1].assetAttrbtId = Math.random() + attrbtListLength;
    const errors = [...formErrors];
    errors.push({ ...DefaultAttributeFormError });
    validate(StepAccordion.Attribute, errors);
    setFormErrors(errors);
    const newAsset = { ...assetData };
    newAsset.assetAttrbtList = [...assetAttrbtList];
    const dataName = 'fetchGetAssetById';
    const editCounterName = 'assetDataEdits';
    const attributeFormDataName = 'attributeFormData';
    const tableName = 'selectedAttributeTableRow';
    dispatch(setAssetDataActionCreator({ [dataName]: newAsset }));
    dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: { ...DESELECTED_ATTRIBUTE_TABLE_ROW } }));
    dispatch(setFormDataActionCreator({ [attributeFormDataName]: { ...EMPTY_ASSET_ATTRBT } }));
    const successMessage = 'Added a new attribute';
    dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
  };

  const onEdit = () => {
    let assetAttrbtList:AssetAttrbtDto[] = [];
    if (typeof (assetData.assetAttrbtList) !== 'undefined' && assetData.assetAttrbtList !== null) {
      ({ assetAttrbtList } = assetData);
    }
    assetAttrbtList.splice(selectedAttrbtIndex, 1, { ...attributeFormData });
    const errors = [...formErrors];
    errors.splice(selectedAttrbtIndex, 1, { ...DefaultAttributeFormError });
    setFormErrors(errors);
    const newAsset = { ...assetData };
    newAsset.assetAttrbtList = [...assetAttrbtList];
    const dataName = 'fetchGetAssetById';
    const editCounterName = 'assetDataEdits';
    const attributeFormDataName = 'attributeFormData';
    const tableName = 'selectedAttributeTableRow';
    dispatch(setAssetDataActionCreator({ [dataName]: newAsset }));
    dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
    dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: { ...DESELECTED_ATTRIBUTE_TABLE_ROW } }));
    dispatch(setFormDataActionCreator({ [attributeFormDataName]: { ...EMPTY_ASSET_ATTRBT } }));
    const successMessage = 'Attribute Edited';
    dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
  };

  const onDelete = () => {
    if (deleteReadyToConfirm) {
      let assetAttrbtList:AssetAttrbtDto[] = [];
      if (attrbtSelected && (typeof selectedAttrbtIndex !== 'undefined') && selectedAttrbtIndex !== -1) {
        if (typeof (assetData.assetAttrbtList) !== 'undefined') {
          ({ assetAttrbtList } = assetData);
        }
        if (Number.isInteger(attributeFormData.assetAttrbtId)) {
          attributeFormData.status = StatusOption.Inactive;
          assetAttrbtList.splice(selectedAttrbtIndex, 1, { ...attributeFormData });
        } else {
          assetAttrbtList.splice(selectedAttrbtIndex, 1);
        }
        const errors = [...formErrors];
        errors.splice(selectedAttrbtIndex, 1);
        validate(StepAccordion.Attribute, errors);
        setFormErrors(errors);
        const tableName = 'selectedAttributeTableRow';
        const editCounterName = 'assetDataEdits';
        const attributeFormDataName = 'attributeFormData';
        dispatch(setFormDataActionCreator({ [attributeFormDataName]: { ...EMPTY_ASSET_ATTRBT } }));
        dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: { ...DESELECTED_ATTRIBUTE_TABLE_ROW } }));
        dispatch(setAssetDataEditsActionCreator({ [editCounterName]: assetDataEdits + 1 }));
        setDeleteReadyToConfirm(false);
        const successMessage = 'Deleted the selected attribute';
        dispatch(setSnackbarActionCreator(snackbarMaker(true, successMessage, 'success')));
      }
    } else {
      setDeleteReadyToConfirm(true);
    }
  };

  const {
    DATATYPE,
  } = configList;
  const dataTypeSelectOptions:ISelectOption[] = typeof (DATATYPE) !== 'undefined' && DATATYPE.length > 0 ? DATATYPE.map((type, index) => ({ id: index, value: type })) : [];
  const keyOptionSelectOptions:ISelectOption[] = KeyOptionsList.map((key) => ({ id: key.id, value: key.value }));

  const getKeyName = () : string => {
    const item = KeyOptionsList.find((obj) => obj.code === assetAttrbtKeyTypeCode);
    return item !== undefined ? item.value : '';
  };

  const getKeyCode = (value: string) : string => {
    const item = KeyOptionsList.find((obj) => obj.value === value);
    return item !== undefined ? item.code : '';
  };

  return (
    <Grid container xs={11} sx={{ ml: 2, mr: 2, mt: 2 }}>
      <WppAccordion text="Core Attributes" expandedByDefault withDivider={false}>
        <Grid container>
          <Grid container item>
            <Grid container item sx={{ justifyContent: 'space-between' }}>
              {
                allowSchemaDiscoveryFlg === 'Y' ? (
                  <Box sx={{ mb: 1, ml: 1 }}>
                    <WppInlineMessage
                      size="s"
                      message="Attributes added while Schema Discovery is enabled will be lost"
                      type="warning"
                      showTooltipFrom={200}
                    />
                  </Box>
                ) : null
              }
              <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    required
                    label="Attribute Name"
                    value={typeof (assetAttrbtName) !== 'undefined' ? assetAttrbtName : ''}
                    onChange={(event) => handleAttributeFormFieldChange(event.target.value, 'assetAttrbtName')}
                    // error={formErrors[selectedAttrbtIndex].assetAttrbtName}
                    // color={formErrors[selectedAttrbtIndex].assetAttrbtName ? 'error' : 'primary'}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    required
                    label="Description"
                    value={typeof (assetAttrbtDesc) !== 'undefined' ? assetAttrbtDesc : ''}
                    onChange={(event) => handleAttributeFormFieldChange(event.target.value, 'assetAttrbtDesc')}
                    // error={formErrors[selectedAttrbtIndex].assetAttrbtDesc}
                    // color={formErrors[selectedAttrbtIndex].assetAttrbtDesc ? 'error' : 'primary'}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <Select
                    title="Key Type"
                    value={getKeyName()}
                    disabled={false}
                    options={keyOptionSelectOptions}
                    useIdOrValue={SelectType.useValue}
                    onChange={(event: SelectChangeEvent) => {
                      handleAttributeFormFieldChange(getKeyCode(event.target.value), 'assetAttrbtKeyTypeCode');
                    }}
                    width={selectWidth}
                    // error={formErrors[selectedAttrbtIndex].assetAttrbtKeyTypeCode}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <Select
                    title="Status"
                    value={typeof (status) !== 'undefined' ? status : ''}
                    disabled={false}
                    options={StatusOptions}
                    useIdOrValue={SelectType.useValue}
                    onChange={(event: SelectChangeEvent) => handleAttributeFormFieldChange(event.target.value, 'status')}
                    width={selectWidth}
                    // error={formErrors[selectedAttrbtIndex].status}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <Select
                    title="Data Type"
                    value={typeof (dataType) !== 'undefined' ? dataType : ''}
                    disabled={false}
                    options={dataTypeSelectOptions}
                    useIdOrValue={SelectType.useValue}
                    onChange={(event: SelectChangeEvent) => handleAttributeFormFieldChange(event.target.value, 'dataType')}
                    width={selectWidth}
                    // error={formErrors[selectedAttrbtIndex].dataType}
                  />
                </Grid>
                <Grid item sx={{ width: itemWidth, m: 1 }}>
                  <TextField
                    variant="standard"
                    focused
                    label="Default Value"
                    value={typeof (defaultValue) !== 'undefined' ? defaultValue : 'undefined'}
                    onChange={(event) => handleAttributeFormFieldChange(event.target.value, 'defaultValue')}
                    // error={formErrors[selectedAttrbtIndex].defaultValue}
                    // color={formErrors[selectedAttrbtIndex].defaultValue ? 'error' : 'primary'}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <TextField
                    variant="standard"
                    focused
                    label="Length"
                    value={typeof (assetAttrbtSize) !== 'undefined' ? assetAttrbtSize : ''}
                    onChange={(event) => handleAttributeFormFieldChange(event.target.value, 'assetAttrbtSize')}
                    // error={formErrors[selectedAttrbtIndex].assetAttrbtSize}
                    // color={formErrors[selectedAttrbtIndex].assetAttrbtSize ? 'error' : 'primary'}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  {
                        (dataType === DataType.FLOAT || dataType === DataType.DECIMAL) && (
                          <TextField
                            variant="standard"
                            focused
                            label="Decimal/Precision"
                            value={typeof (assetAttrbtPrecision) !== 'undefined' ? assetAttrbtPrecision : ''}
                            onChange={(event) => handleAttributeFormFieldChange(event.target.value, 'assetAttrbtPrecision')}
                            // error={formErrors[selectedAttrbtIndex].assetAttrbtPrecision}
                            // color={formErrors[selectedAttrbtIndex].assetAttrbtPrecision ? 'error' : 'primary'}
                          />
                        )
                      }
                </Grid>
              </Grid>
              <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
                <Grid item sx={{ m: 1, width: itemWidth }}>
                  <Select
                    title="Tag"
                    value={tagId?.toString()}
                    disabled={false}
                    options={tagListSelectOptions}
                    useIdOrValue={SelectType.useId}
                    onChange={(event: SelectChangeEvent) => handleAttributeFormFieldChange(event.target.value, 'tagId')}
                    width={selectWidth}
                    // error={formErrors[selectedAttrbtIndex].tagId}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: itemWidth, mt: 2, mb: 1.5,
                  }}
                >
                  <WppToggle
                    label="Duplicate"
                    checked={dupColFlg === BooleanType.True}
                    required
                    onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                      if (checked) {
                        handleAttributeFormFieldChange(BooleanType.True, 'dupColFlg');
                      } else {
                        handleAttributeFormFieldChange(BooleanType.False, 'dupColFlg');
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: itemWidth, mt: 2, mb: 1.5, ml: 1,
                  }}
                >
                  <WppToggle
                    label="Allow Null"
                    name="allowNull"
                    required
                    checked={assetAttrbtNullable === BooleanType.True}
                    onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                      if (checked) {
                        handleAttributeFormFieldChange(BooleanType.True, 'assetAttrbtNullable');
                      } else {
                        handleAttributeFormFieldChange(BooleanType.False, 'assetAttrbtNullable');
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: itemWidth, mt: 2, mb: 1.5, ml: 1,
                  }}
                >
                  <WppToggle
                    label="License"
                    name="License"
                    required
                    checked={licenceFlg === BooleanType.True}
                    onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                      if (checked) {
                        handleAttributeFormFieldChange(BooleanType.True, 'licenceFlg');
                      } else {
                        handleAttributeFormFieldChange(BooleanType.False, 'licenceFlg');
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
                <Grid
                  item
                  sx={{
                    width: itemWidth, mt: 2, mb: 1.5,
                  }}
                >
                  <WppToggle
                    label="Enable Rules"
                    checked={rulesEnabledFlg === BooleanType.True}
                    required
                    onWppChange={({ detail: { checked } }:{ detail: { checked: boolean } }) => {
                      if (checked) {
                        handleAttributeFormFieldChange(BooleanType.True, 'rulesEnabledFlg');
                        setRulesEnabled(true);
                      } else {
                        handleAttributeFormFieldChange(BooleanType.False, 'rulesEnabledFlg');
                        setRulesEnabled(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item sx={{ m: 1, width: itemWidth }} />
                <Grid item sx={{ m: 1, width: itemWidth }} />
                <Grid item sx={{ m: 1, width: itemWidth }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sx={{ ml: 1, mt: 1 }}>
          {
            selectedAttributeTableRow?.assetAttrbtId === -1 || selectedAttributeTableRow?.assetAttrbtId === null ? (
              <Grid item sx={{ mr: 1 }}>
                <Tooltip disableHoverListener={false} title="Add Attribute">
                  <WppButton size="s" variant="secondary" onClick={() => onAdd()}>
                    <WppIconPlus slot="icon-start" />
                  </WppButton>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item sx={{ mr: 1 }}>
                <Tooltip disableHoverListener={false} title="Save Changes">
                  <WppButton size="s" variant="secondary" onClick={() => onEdit()}>
                    <WppIconDone slot="icon-start" />
                  </WppButton>
                </Tooltip>
              </Grid>
            )
          }
          <Grid item sx={{ mr: 1 }}>
            <Tooltip disableHoverListener={typeof !attrbtSelected !== 'undefined' && !attrbtSelected} title={deleteReadyToConfirm ? 'Confirm Deletion' : 'Delete'}>
              <WppButton size="s" variant="secondary" disabled={!attrbtSelected} onClick={() => onDelete()}>
                {deleteReadyToConfirm
                  ? <WppIconAvailableCheckmark slot="icon-start" />
                  : <WppIconTrash slot="icon-start" />}
              </WppButton>
            </Tooltip>
          </Grid>
          {/* <Grid item>
            <Tooltip disableHoverListener={false} title="Clear">
              <WppButton size="s" variant="secondary" onClick={() => onClear()}>
                <WppIconRemoveCircle slot="icon-start" />
              </WppButton>
            </Tooltip>
          </Grid> */}
        </Grid>
      </WppAccordion>
    </Grid>
  );
}

export default AttrbCoreAttributesAccordion;
