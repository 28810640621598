export enum StepTitle {
  AssetConfigurationStep = 'Asset Configuration',
  AttributeConfigurationStep = 'Attribute Configuration',
  ScheduleStep = 'Schedule',
  PreviewAssetStep = 'Preview',
}

export enum StepName {
  AssetConfigurationStep = 'Asset',
  AttributeConfigurationStep = 'Attribute',
  ScheduleStep = 'Schedule',
  PreviewAssetStep = 'Preview',
}

export enum ActiveStep {
  AssetConfigurationStep,
  AttributeConfigurationStep,
  ScheduleStep,
  PreviewAssetStep,
}

export enum StepAccordion {
  Asset,
  Schema,
  Valuation,
  Contract,
  Attribute,
  Rule,
}
