import React, { useEffect, useState } from 'react';

// material-ui
import {
  Grid,
} from '@mui/material';
import { WppTree, WppTypography } from '@platform-ui-kit/components-library-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ExplorerObjectType, ExplorerState, FetchGetAssetExplorerParams } from '../../services/models/explorer-models.d';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import { AssetTableRow } from '../../services/models/asset-models.d';
import { DESELECTED_ASSET_TABLE_ROW } from '../../services/defaults/asset-defaults';
import { StoreState } from '../../store';
import { setAssetSelectedTableRowActionCreator } from '../../store/actions/asset-actions';
import { fetchGetExplorerActionCreator } from '../../store/actions/explorer-actions';
import { NavigationPageUrls } from '../../services/models/navigation-models.d';

// ==============================|| ASSET EXPLORER ||============================== //

function AssetExplorer() {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState<string | undefined>(undefined);
  const [selectedDatasetId, setSelectedDatasetId] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const history = useHistory();

  const rows:ExplorerState['api']['data']['fetchGetAssetExplorer'] = useSelector(
    (state: StoreState) => state.explorerReducer.api?.data?.fetchGetAssetExplorer,
  );

  useEffect(() => {
    // const defaultSort:string[] = [];
    const fetchGetAssetExplorerParams:FetchGetAssetExplorerParams = {};
    dispatch(fetchGetExplorerActionCreator(fetchGetAssetExplorerParams, ShowSnackbar.False));
  }, [dispatch]);

  useEffect(() => {
    const tree:any[] = [];

    if (rows.collectionsMap !== null) {
      Object.keys(rows.collectionsMap).forEach((collectionKey: string) => {
        let collectionChildren = [];

        if (selectedCollectionId === collectionKey && rows.dataSetMap && Object.keys(rows.dataSetMap).length > 0) {
          collectionChildren = Object.keys(rows.dataSetMap).map((datasetKey: string) => {
            let datasetChildren:any[] = [];

            if ((selectedDatasetId === datasetKey && rows.assetMap && Object.keys(rows.assetMap).length > 0)) {
              datasetChildren = Object.keys(rows.assetMap).map((assetMapKey: string) => ({
                id: assetMapKey,
                title: rows.assetMap[parseInt(assetMapKey, 10)],
                disabled: false,
                type: ExplorerObjectType.ASSET,
              }));
            } else if (!rows.assetMap) {
              collectionChildren = [{ title: 'loading...', disabled: true }];
            } else {
              collectionChildren = [{ title: 'No Data', disabled: true }];
            }

            return {
              id: datasetKey,
              title: rows.dataSetMap[parseInt(datasetKey, 10)],
              disabled: false,
              type: ExplorerObjectType.DATASET,
              children: datasetChildren,
            };
          });
        } else if (!rows.dataSetMap) {
          collectionChildren = [{ title: 'loading...', disabled: true }];
        } else {
          collectionChildren = [{ title: 'No Data', disabled: true }];
        }

        tree.push({
          id: collectionKey,
          title: rows.collectionsMap[parseInt(collectionKey, 10)],
          type: ExplorerObjectType.COLLECTION,
          children: collectionChildren,
        });
      });
    }

    setTreeData(tree);
  }, [rows, selectedCollectionId, selectedDatasetId, setTreeData]);

  const handleTreeClick = (event: CustomEvent) => {
    if (event.detail.item.type === ExplorerObjectType.COLLECTION) {
      const collectionId = event.detail.id;
      setSelectedCollectionId(collectionId);
      const fetchGetAssetExplorerParams:FetchGetAssetExplorerParams = {
        collectionId,
      };
      dispatch(fetchGetExplorerActionCreator(fetchGetAssetExplorerParams, ShowSnackbar.False));
    } else if (event.detail.item.type === ExplorerObjectType.DATASET) {
      const datasetId = event.detail.id;
      setSelectedDatasetId(datasetId);
      const fetchGetAssetExplorerParams:FetchGetAssetExplorerParams = {
        collectionId: selectedCollectionId,
        datasetId,
      };
      dispatch(fetchGetExplorerActionCreator(fetchGetAssetExplorerParams, ShowSnackbar.False));
    } else if (event.detail.item.type === ExplorerObjectType.ASSET) {
      const assetId = event.detail.id;
      const tableName = 'selectedAssetTableRow';
      const selectedAssetTableRow:AssetTableRow = { ...DESELECTED_ASSET_TABLE_ROW, assetId };
      dispatch(setAssetSelectedTableRowActionCreator({ [tableName]: selectedAssetTableRow }));
      history.push(NavigationPageUrls.ASSET_SUMMARY);
    }
  };

  return (
    <Grid container spacing={2} xs={12} sx={{ m: 1, flexDirection: 'column' }}>
      <Grid container item xs={3} sx={{ justifyContent: 'flex-start' }}>
        <WppTypography tag="h2" type="2xl-heading">
          Asset Explorer
        </WppTypography>
      </Grid>
      <Grid item>
        <WppTree data={treeData} onWppChange={handleTreeClick} />
      </Grid>
    </Grid>
  );
}

export default AssetExplorer;
