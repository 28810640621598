import React, {
  useEffect,
  useState,
} from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MainContent from '../../layout/MainLayout/MainContent';
import UserTable from './list-all-users-table';
import UserForm from './user-form';
import { StoreState } from '../../store';
import {
  FetchDeleteUserParams, FetchGetUsersParams, UserDto, UserState,
} from '../../services/models/user-models';
import {
  DEFAULT_USER_PAGINATION, DESELECTED_USER_TABLE_ROW, EMPTY_USER, OrderDirection,
} from '../../services/defaults/user-defaults';
import { ShowSnackbar } from '../../services/models/snackbar-models.d';
import {
  fetchDeleteUserActionCreator,
  fetchGetUsersActionCreator, setUserSelectedTableRowActionCreator,
} from '../../store/actions/user-actions';

function Users() {
  const dispatch = useDispatch();

  let userList:UserState['api']['data']['fetchGetUserList']['userList'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetUserList?.userList,
  );
  const selectedUserTableRow:UserState['tables']['selectedUserTableRow'] = useSelector(
    (state: StoreState) => state.userReducer.tables?.selectedUserTableRow,
  );
  const fetchGetUserListFlag: UserState['api']['flags']['fetchGetUserListFlag'] = useSelector(
    (state: StoreState) => state.userReducer.api?.flags?.fetchGetUserListFlag,
  );
  const pagination: UserState['api']['data']['fetchGetUserList']['pagination'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetUserList?.pagination,
  );
  const listOrder: UserState['api']['data']['fetchGetUserList']['listOrder'] = useSelector(
    (state: StoreState) => state.userReducer.api?.data?.fetchGetUserList?.listOrder,
  );

  let limit = 0; // The page size, or max records displayed
  if (typeof pagination !== 'undefined') {
    ({ limit } = pagination);
  }

  let sortByFieldName = '';
  let sortOrder = OrderDirection.DESC;
  if (typeof listOrder !== 'undefined') {
    ({
      sortByFieldName,
      sortOrder,
    } = listOrder);
  }

  if (userList === null || typeof userList === 'undefined') {
    userList = [];
  }

  useEffect(() => {
    const fetchParams: FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
    dispatch(fetchGetUsersActionCreator(fetchParams, ShowSnackbar.True));
  }, [dispatch]);

  const tableHeight = '730px';
  // const tableWidth = '77vw';
  const tableWidth = '101%';
  const [showUserUI, setShowUserUI] = useState<boolean>(false);

  const getSelectedUser = () => {
    const userDto: UserDto = { ...EMPTY_USER };
    userDto.userId = selectedUserTableRow.userId !== null ? selectedUserTableRow.userId : -1;
    userDto.userName = selectedUserTableRow.userName !== null ? selectedUserTableRow.userName : '';
    userDto.firstName = selectedUserTableRow.firstName !== null ? selectedUserTableRow.firstName : '';
    userDto.midName = selectedUserTableRow.midName !== null ? selectedUserTableRow.midName : '';
    userDto.lastName = selectedUserTableRow.lastName !== null ? selectedUserTableRow.lastName : '';
    userDto.email = selectedUserTableRow.email !== null ? selectedUserTableRow.email : '';
    userDto.phone = selectedUserTableRow.phone !== null ? selectedUserTableRow.phone : '';
    userDto.lastLoginDatetime = selectedUserTableRow.lastLoginDatetime !== null ? selectedUserTableRow.lastLoginDatetime : '';
    userDto.status = selectedUserTableRow.status !== null ? selectedUserTableRow.status : '';
    userDto.createdUserId = selectedUserTableRow.createdUserId !== null ? selectedUserTableRow.createdUserId : '';
    userDto.createdDatetime = selectedUserTableRow.createdDatetime !== null ? selectedUserTableRow.createdDatetime : '';
    userDto.updatedUserId = selectedUserTableRow.updatedUserId !== null ? selectedUserTableRow.updatedUserId : '';
    userDto.updatedDatetime = selectedUserTableRow.updatedDatetime !== null ? selectedUserTableRow.updatedDatetime : '';
    userDto.roleName = selectedUserTableRow.roleName !== null ? selectedUserTableRow.roleName : '';
    userDto.accountName = selectedUserTableRow.accountName !== null ? selectedUserTableRow.accountName : '';
    return userDto;
  };

  const handleDeleteUser = async () => {
    const userDto: UserDto = getSelectedUser();
    if (userDto.userId !== -1 && userDto.userName !== 'undefined') {
      const fetchDeleteUserParams:FetchDeleteUserParams = { userName: userDto.userName };
      const fetchGetUsersParams:FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
      dispatch(fetchDeleteUserActionCreator(fetchDeleteUserParams, fetchGetUsersParams, ShowSnackbar.True));
      setShowUserUI(false);
      const selectedTableName = 'selectedUserTableRow';
      dispatch(setUserSelectedTableRowActionCreator({ [selectedTableName]: { ...DESELECTED_USER_TABLE_ROW } }));
    }
  };

  const handleSearchUser = (newSearchValue: string) => {
    if (!fetchGetUserListFlag) {
      const fetchGetUsersParams:FetchGetUsersParams = { ...DEFAULT_USER_PAGINATION };
      fetchGetUsersParams.page = 0;
      fetchGetUsersParams.limit = limit;
      fetchGetUsersParams.sortByFieldName = sortByFieldName;
      fetchGetUsersParams.sortOrder = sortOrder;
      fetchGetUsersParams.searchKey = 'userName';
      fetchGetUsersParams.searchValue = newSearchValue;
      dispatch(fetchGetUsersActionCreator(fetchGetUsersParams, ShowSnackbar.True));
    }
  };

  return (
    <MainContent
      title="Users"
      buttonTitles={{
        addTitle: 'Add User',
        editTitle: 'Edit User',
        deleteTitle: 'Delete User',
        summaryTitle: 'User Summary',
        importTitle: 'Import User',
        copyTitle: 'Copy User',
      }}
      addCallback={() => null} // Users are added via backend on first Okta login
      editCallback={() => {
        setShowUserUI(!showUserUI);
      }}
      deleteCallback={() => handleDeleteUser()}
      summaryCallback={() => null} // TODO
      importCallback={() => null} // TODO
      copyCallback={() => null} // TODO
      searchCallback={(newSearchValue) => handleSearchUser(newSearchValue)}
      searchPlaceholder="Search Users"
      editDisabled
      // editDisabled={selectedUserTableRow?.userId === null}
      deleteDisabled
      // deleteDisabled={selectedUserTableRow?.userId === null}
      copyDisabled
      // copyDisabled={selectedUserTableRow?.userId === null}
      summaryDisabled
      importDisabled
      addDisabled
    >
      <Grid
        container
        item
        sx={{
          width: tableWidth, height: tableHeight, justifyContent: 'space-between',
        }}
      >
        <Grid item xs={showUserUI ? 6 : 12}>
          <UserTable rows={userList} />
        </Grid>
        {showUserUI
          ? (
            <Grid container item xs={6} sx={{ pl: 2.5 }}>
              <UserForm />
            </Grid>
          )
          : <Grid item />}
      </Grid>
    </MainContent>
  );
}

export default Users;
