// redux reducer state

export interface ConfigState {
  api: {
    flags: {
      fetchGetConfigListFlag: boolean,
    },
    data: {
      fetchGetConfigList: ConfigListResponse,
    },
  },
}

// redux actions

export enum ConfigActionOptions {
  SET_CONFIG_API_FLAG = 'SET_CONFIG_API_FLAG',
  SET_CONFIG_API_DATA = 'SET_CONFIG_API_DATA',
}

export interface Flag {
  [key: string]: boolean;
}

export interface Data {
  [key: string]: any
}

export interface ConfigAction {
  type: ConfigActionOptions,
  payload: Flag | Data
}

// ontario service models

export interface FetchPostAuthenticateParams {
  username: string,
  password: string,
}

export interface FetchPostAuthenticateResponse {
  status: string;
  token: string;
}

export interface ConfigListResponse {
  [key: string]: string[];
}

export enum Character {
  UTF8 = 'UTF-8',
  ASCII = 'ASCII',
}

export enum Currency {
  CAD = 'CAD',
  USD = 'USD',
}

export enum DataType {
  BYTE = 'Byte',
  SHORT = 'Short',
  INTEGER = 'Integer',
  LONG = 'Long',
  FLOAT = 'Float',
  DOUBLE = 'Double',
  DECIMAL = 'Decimal',
  STRING = 'String',
  VARCHAR = 'Varchar',
  CHAR = 'Char',
  BINARY = 'Binary',
  BOOLEAN = 'Boolean',
  TIMESTAMP = 'Timestamp',
  DATE = 'Date',
  ARRAY = 'Array',
  MAP = 'Map',
  STRUCT = 'Struct',
}

export enum Delimiter {
  BLANK_STRING = '',
  PERIOD = '.',
  BAR = '|',
  DASH = '-',
  UNDERSCORE = '_',
  COLON = ':',
  SEMICOLON = ';',
}

export enum Escape {
  DOUBLE_QUOTE = '"',
  BACKSLASH = '\\',
  TAB = '\t',
}

export enum Notification {
  EMAIL = 'Email',
  SMS = 'SMS',
  SLACK = 'Slack',
}

export enum Quote {
  DOUBLE_QUOTE = '"',
}

export enum RuleCategory {
  BASIC = 'Basic',
  PROFILE = 'Profile',
  LOOKUP = 'Lookup',
}

export enum RuleType {
  COMPARE = 'Compare',
  REPLACE = 'Replace',
  EDIT = 'Edit',
}

// Error Handling

export interface ErrorStatus {
  code: string,
  message: string
  desc: string | null,
  errorList: string[]
}
export interface ConfigError {
  // assetId: number,
  status: ErrorStatus
}
