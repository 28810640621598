import React from 'react';

// material-ui
import Box from '@mui/material/Box';
import {
  WppTypography, WppToggle, WppAccordion,
} from '@platform-ui-kit/components-library-react';
import { Grid } from '@mui/material';
import { AssetMultiStepGetResponse } from '../../../services/models/asset-models.d';
import { BooleanType } from '../../../services/models/select-models.d';
import Tooltip from '../../../ui-component/tooltip';

export interface AssetDetailsSummaryProps {
  assetData:AssetMultiStepGetResponse,
}

export interface ItemProps {
  label: string,
  content: string,
}

function Item(props: ItemProps) {
  const { label, content } = props;
  const tooltipVisibleMinLength = 13;
  return (
    <Grid container item xs={12}>
      <Grid item xs={5}>
        <WppTypography type="m-strong">
          {label}
        </WppTypography>
      </Grid>
      <Grid item xs={7}>
        <Box
          sx={{
            p: 0.5,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 150,
            overflow: 'hidden',
          }}
        >
          {content.length >= tooltipVisibleMinLength
            ? (
              <Tooltip disableHoverListener={false} title={content}>
                <WppTypography type="m-body">
                  :
                  {' '}
                  {content}
                </WppTypography>
              </Tooltip>
            )
            : (
              <WppTypography type="m-body">
                :
                {' '}
                {content}
              </WppTypography>
            )}
        </Box>
      </Grid>
    </Grid>
  );
}

function AssetDetailsSummary(props:AssetDetailsSummaryProps) {
  const {
    assetData,
  } = props;

  // let assetId;
  // let accountId;
  // let datasetId;
  let assetName;
  let assetDesc;
  // let assetLongDesc;
  // let assetLicensing;
  // let assetTopic;
  let assetType;
  let assetOwner;
  let collectionName;
  // let frequencyId;
  let categoryName;
  let tagName;
  let sourceName;
  // let targetId;
  // let fileConfigId;
  // let valuationId;
  // let contractId;
  // let loadType;
  // let derivedAssetFlg;
  let dataCatalogPushFlg;
  let allowSchemaDiscoveryFlg;
  let status;
  // let createdUserId;
  // let createdDatetime;
  // let updatedUserId;
  // let updatedDatetime;
  // let versionId;
  let notificationEnableFlg;

  let filePattern;

  if (typeof assetData.asset !== 'undefined') {
    ({
      // assetId,
      // accountId,
      // datasetId,
      assetName,
      assetDesc,
      // assetLongDesc,
      // assetLicensing,
      // assetTopic,
      assetType,
      assetOwner,
      collectionName,
      // frequencyId,
      categoryName,
      tagName,
      sourceName,
      // targetId,
      // fileConfigId,
      // valuationId,
      // contractId,
      // loadType,
      // derivedAssetFlg,
      dataCatalogPushFlg,
      allowSchemaDiscoveryFlg,
      status,
      // createdUserId,
      // createdDatetime,
      // updatedUserId,
      // updatedDatetime,
      // versionId,
      notificationEnableFlg,
    } = assetData.asset);
  }

  if (typeof assetData.fileConfig !== 'undefined') {
    ({
      filePattern,
    } = assetData.fileConfig);
  }

  return (
    <Grid container xs={12}>
      <WppAccordion text="Asset Details" expandedByDefault>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <Item label="Asset Name" content={assetName || ''} />
            <Item label="Description" content={assetDesc || ''} />
            <Item label="Source" content={sourceName || ''} />
            <Item label="Collection" content={collectionName || ''} />
            <Item label="Asset Category" content={categoryName || ''} />
          </Grid>
          <Grid container item xs={6}>
            <Item label="File Pattern" content={filePattern || ''} />
            <Item label="Tag" content={tagName || ''} />
            <Item label="Status" content={status || ''} />
            <Item label="Asset Type" content={assetType || ''} />
            <Item label="Owner" content={assetOwner || ''} />
          </Grid>
          <Grid item sx={{ mt: 1, ml: 2 }}>
            <WppToggle
              label="Push To Catalog"
              required
              checked={dataCatalogPushFlg === BooleanType.True}
              disabled
            />
          </Grid>
          <Grid item sx={{ mt: 1, ml: 2 }}>
            <WppToggle
              label="Schema Discovery"
              required
              checked={allowSchemaDiscoveryFlg === BooleanType.True}
              disabled
            />
          </Grid>
          <Grid item sx={{ mt: 1, ml: 2 }}>
            <WppToggle
              label="Enable Notifications"
              name="wantNotification"
              required
              disabled
              checked={notificationEnableFlg === BooleanType.True}
            />
          </Grid>
        </Grid>
      </WppAccordion>
    </Grid>
  );
}

export default AssetDetailsSummary;
